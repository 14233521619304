import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
import { doPost, doPut } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import { errorToast } from "../../../Utils/Toastify";

const EditProjectManager = ({
  onClose,
  item = {
    username: "",
    user_type: "",
    employee_id: "",
    name: "",
    office_name: "",
    address: "",
    contact_number: "",
    id: "",
    email: "",
  },
}) => {
  const {
    address,
    confirmPassword,
    contact_number,
    employee_id,
    name,
    office_name,
    user_type,
    username,
    email,
    id,
  } = item;

  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const handleSubmitImage = (e) => {
    setImages(e);
  };
  const initialState = {
    username,
    user_type,
    employee_id,
    name,
    office_name,
    address,
    contact_number,
  };
  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        await doPut(`/user/project-manager/${id}/edit`, {
          ...states,

          user_type: "Project Manager",
          level: "1",
        });
      } else {
        throw Error("Validation failed");
      }
    } catch (error) {
      throw error;
    }
  };
  const { mutate } = useMutation({
    mutationKey: "ProjectManager",
    mutationFn: handleSubmit,
    onError: (error) => {
      if (error && error.message && typeof error.message === "string") {
        return errorToast(error.message);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        return toast.error("Error while editing project manager!!");
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("ProjectManager");
      toast.success("Project Manager Edited Successfully");
      onClose();
    },
  });
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };

  return (
    <ModalLayout title={"Edit Project manager"} onClose={handleClose}>
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to edit project manager ??"}
          caption={"Please verify all details before clicking yes "}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            mutate();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel editing project manager??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      <div className="addpm-main">
        <div className="addpm-main--submit">
          <PrimaryButton
            content={"Edit"}
            onClick={() => setConfirmationModal(true)}
            icon={"fa-regular fa-edit"}
          />
        </div>
        <Input
          placeholder={"Project manager Id"}
          label={"Project manager Id"}
          onChange={handleChange}
          name={"employee_id"}
          error={errors.employee_id}
          defaultValue={initialState.employee_id}
        />
        <Input
          placeholder={"Project manager Name"}
          label={"Project manager Name"}
          onChange={handleChange}
          name="name"
          error={errors.name}
          defaultValue={initialState.name}
        />
        <Input
          placeholder={"User Id"}
          label={"User Id"}
          onChange={handleChange}
          name="username"
          error={errors.username}
          defaultValue={initialState.username}
        />
        <Input
          placeholder={"Email"}
          label={"Email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
          defaultValue={initialState.email}
        />
        <Input
          placeholder={"Derectrate/Office Name"}
          label={"Derectrate/Office Name"}
          onChange={handleChange}
          name="office_name"
          error={errors.office_name}
          defaultValue={initialState.office_name}
        />

        <Input
          placeholder={"Address"}
          label={"Address"}
          onChange={handleChange}
          name="address"
          error={errors.address}
          defaultValue={initialState.address}
        />
        <Input
          placeholder={"Contact Number"}
          label={"Contact Number"}
          onChange={handleChange}
          name="contact_number"
          error={errors.contact_number}
          defaultValue={initialState.contact_number}
          intlNotRequired={true}
        />

        <div className="addpm-main--single">
          <FileInput handleSubmitImage={handleSubmitImage} />
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditProjectManager;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Store/Store";
import { QueryClientProvider, QueryClient } from "react-query";
import LoadingModal from "./UI/LoadingModal/LoadingModal";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 15 * 1000 * 60,
      staleTime: 60 * 1000,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
        <LoadingModal />
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </React.StrictMode>
  </QueryClientProvider>
);

reportWebVitals();

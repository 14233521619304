import React from "react";

const ActionButton = ({ onClick, icon, title, customStyle, type }) => {
  return (
    <button
      className={`actionbutton ${type ? "actionbutton-" + type : ""}`}
      onClick={onClick}
      title={title ? title : "Action Button"}
      style={customStyle}
    >
      {icon && <i className={icon}></i>}
    </button>
  );
};

export default ActionButton;

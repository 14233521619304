import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../Components/Modal/ConfirmationModal";

import TopBar from "../../Components/TopBar/TopBar";
import { baseUrl } from "../../Constants/Constants";
import { DUMMY_USER } from "../../Constants/Images";
import { doGet, doPut } from "../../Services/Axios";
import ActionButton from "../../UI/Button/ActionButton";
import SmallButton from "../../UI/Button/SmallButton";
import Preview from "../../UI/Preview/Preview";
import StatusBar from "../../UI/StatusBar/StatusBar";

import Table from "../../UI/Table/Table";
import { errorToast, successToast } from "../../Utils/Toastify";
import AddEmployeeModal from "./AddProjectManager/AddPMModal";
import EditProjectManager from "./EdtProjectManager/EditProjectManager";
import ChangePassword from "../../Components/ChangePassword/ChangePassword";

export const fetchProjectManager = async () => {
  try {
    const { data } = await doGet("user/project-manager/view-all");
    return data;
  } catch (error) {
    throw error;
  }
};
const ProjectManager = () => {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(null);
  const { user } = useSelector((state) => state.System);
  const [editUser, setEditUser] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: "ProjectManager",
    queryFn: fetchProjectManager,
    enabled: true,
    cacheTime: 30 * 60 * 1000,
    staleTime: 0,
  });

  const handleOpenModal = () => {
    setOpenModal((prev) => !prev);
  };
  let Columns = [
    { name: "sn", sortable: true, searchable: true },
    { name: "image", sortable: true, searchable: true },
    { name: "employee_id", sortable: true, searchable: true },
    { name: "name", sortable: true, searchable: true },
    { name: "email", sortable: true, searchable: true },
    { name: "office_name", sortable: true, searchable: true },
    { name: "address", sortable: true, searchable: true },
    { name: "contact_number", sortable: true, searchable: true },
    { name: "level", sortable: true, searchable: true },
    { name: "username", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "created_at", sortable: true, searchable: true },
    { name: "action", sortable: false, searchable: false },
  ];
  const handleEditProjectManager = (e) => {
    setEditUser(e);
    setOpenEditModal((prev) => !prev);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal((prev) => !prev);
  };
  const handleStatusUpdate = async (id) => {
    await doPut("user/status", {
      id: id,
    });
    return id;
  };
  const handleProjectManagerStatus = useMutation(handleStatusUpdate, {
    onError: (err, newData, context) => {
      errorToast(err.message);
      queryClient.setQueryData("ProjectManager", context);
    },
    onMutate: async (_id) => {
      await queryClient.cancelQueries({ queryKey: "ProjectManager" });
      const previousData = JSON.parse(
        JSON.stringify(queryClient.getQueryData("ProjectManager"))
      );
      queryClient.setQueryData("ProjectManager", (oldData) => {
        oldData.users = oldData.users.map((i) =>
          i.id === _id ? ((i.status = i.status === 1 ? 0 : 1), i) : i
        );
        return oldData;
      });
      return previousData;
    },
    onSuccess: () => successToast("Status Updated"),
  }).mutate;
  console.log(data && data.users);
  return (
    <div className="admin">
      {openModal && (
        <AddEmployeeModal
          onClose={handleOpenModal}
          onSuccess={handleOpenModal}
        />
      )}
      {openChangePasswordModal && (
        <ChangePassword
          onClose={() => setOpenChangePasswordModal(null)}
          userId={openChangePasswordModal.id}
        />
      )}
      {openEditModal && (
        <EditProjectManager onClose={handleCloseEditModal} item={editUser} />
      )}
      <TopBar title={"ProjectManager"}>
        <SmallButton
          icon={"fa-light fa-plus"}
          content={"Add Project Manager"}
          onClick={handleOpenModal}
        />
      </TopBar>
      <div className="admin-main">
        <div className="admin-main--table">
          <Table
            Rows={
              data && data.users && data.users.length > 0
                ? data.users.map((item, index) => ({
                    sn: index + 1,
                    images: (
                      <Preview
                        alt={item.name + "'s Image"}
                        file={
                          item.images
                            ? `${baseUrl.split("api/v1")[0]}storage/${
                                item.images
                              }`
                            : DUMMY_USER
                        }
                        clickable={item.images}
                      />
                    ),

                    employee_id: item.employee_id,
                    name: item.name,
                    email: item.email ?? "N/A",
                    office_name: item.office_name,
                    address: item.address,
                    contact_number: item.contact_number,
                    level: item.level,
                    username: item.username,
                    status:
                      user.user.id === item.id ? (
                        "N/A"
                      ) : (
                        <StatusBar
                          status={item.status}
                          handler={() => handleProjectManagerStatus(item.id)}
                        />
                      ),
                    created_at: item.created_at.split("T")[0],
                    action: (
                      <div style={{ display: "flex", gap: 1 }}>
                        <ActionButton
                          type="primary"
                          title={"Change Password"}
                          icon={"fa-regular fa-key"}
                          onClick={() => setOpenChangePasswordModal(item)}
                        />
                        <ActionButton
                          type="primary"
                          title={"Edit"}
                          icon={"fa-regular fa-edit"}
                          onClick={() => handleEditProjectManager(item)}
                        />
                      </div>
                    ),
                  }))
                : []
            }
            Columns={Columns}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectManager;

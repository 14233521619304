import DashboardScreen from "../Pages/Dashboard/Dashboard";
import LoginScreen from "../Pages/Login/Login";
import NotFound from "../Pages/Notfound/Notfound";
import Projects from "../Pages/Projects/View/Projects";
import ProjectManager from "../Pages/ProjectManager/ProjectManager";
import Notice from "../Pages/Notice/Notice";
import Report from "../Pages/Report/Report";
import AccountChief from "../Pages/AccountChief/AccountChief";
import Admin from "../Pages/Admin/Admin";
import CreateProject from "../Pages/Projects/Create/CreateProject";
import Jumper from "../Utils/Jumper";
import WrapWithFrame from "../Utils/WrapWithFrame";
import Loan from "../Pages/Projects/Loan/Loan";
import SubProject from "../Pages/Projects/SubProject/SubProject";
import CreateSubProject from "../Pages/Projects/SubProject/Create/CreateSubProject";
import AC_Projects from "../Pages/AC_Projects/View/AC_Projects";
import AC_SubProjects from "../Pages/AC_Projects/SubProject/AC_SubProject";
import PM_Projects from "../Pages/PM_Projects/View/PM_Projects";
import PM_SubProjects from "../Pages/PM_Projects/SubProject/PM_SubProject";
import Contractor from "../Pages/AC_Projects/SubProject/Contractor/Contractor";
import SubContractor from "../Pages/AC_Projects/SubProject/Contractor/SubContractor/SubContractor";
import PMSubContractor from "../Pages/PM_Projects/SubProject/PMContractor/SubContractor/SubContractor";
import PMContractor from "../Pages/PM_Projects/SubProject/PMContractor/Contractor";
import ACBudget from "../Pages/ACBudget/ACBudget";
import AdminContractor from "../Pages/Projects/SubProject/AdminContractor/Contractor";
import AC_Billing from "../Pages/AC_Projects/SubProject/Billing/Billing";
import AdminSubContractor from "../Pages/Projects/SubProject/AdminContractor/SubContractor/SubContractor";
import PM_Billing from "../Pages/PM_Projects/SubProject/Billing/Billing";
import Billing from "../Pages/Projects/SubProject/Billing/Billing";
import PMBudget from "../Pages/PM_Projects/SubProject/Budget/PMBudget";
import AdminBudget from "../Pages/Projects/SubProject/Budget/AdminBudget";

const {
  ACCOUNT_CHIEF_PATH,
  ADMIN_PATH,
  CREATE_PROJECT,
  DASHBOARD_PATH,
  LOGIN_PATH,
  NOTFOUND_PATH,
  NOTICE_PATH,
  PROJECTS_PATH,
  PROJECT_MANAGER_PATH,
  REPORTS_PATH,
  LOAN,
  SUB_PROJECT,
  CREATE_SUB_PROJECT,
  AC_PROJECTS_PATH,
  AC_SUB_PROJECT,
  PM_PROJECTS_PATH,
  PM_SUB_PROJECT,
  CONTRACTOR_PATH,
  SUB_CONTRACTOR_PATH,
  PM_CONTRACTOR_PATH,
  PM_SUB_CONTRACTOR_PATH,
  AC_BUDGET_PATH,
  AC_CONTRACTOR_PATH,
  AC_BILLING_PATH,
  AC_SUB_CONTRACTOR_PATH,
  PM_BILLING_PATH,
  BILLING_PATH,
  PM_BUDGET_PATH,
  BUDGET_PATH,
} = {
  //!Common
  LOGIN_PATH: "/login",
  DASHBOARD_PATH: "/",
  NOTICE_PATH: "/notice",
  REPORTS_PATH: "/report",
  NOTFOUND_PATH: "*",

  //!Admin
  ADMIN_PATH: "/admin",
  PROJECT_MANAGER_PATH: "/projectmanager",
  ACCOUNT_CHIEF_PATH: "/accountchief",
  PROJECTS_PATH: "/projects",
  CREATE_PROJECT: "/project/create",
  LOAN: "/project/loan/:id",
  SUB_PROJECT: "/subproject/:id",
  CREATE_SUB_PROJECT: "/subproject/create/:id",
  CONTRACTOR_PATH: "/contractor/:id",
  SUB_CONTRACTOR_PATH: "/subcontractor/:id",
  BILLING_PATH: "/billing/:id",
  BUDGET_PATH: "/budget/:id",

  //!PM
  PM_PROJECTS_PATH: "/pm/projects",
  PM_SUB_PROJECT: "/pm/projects/:id",
  PM_CONTRACTOR_PATH: "/pm/contractor/:id",
  PM_SUB_CONTRACTOR_PATH: "/pm/subcontractor/:id",
  PM_BILLING_PATH: "/pm/projects/:id/billing",
  PM_BUDGET_PATH: "/pm/budget/:id",
  //!AC
  AC_PROJECTS_PATH: "/ac/projects",
  AC_SUB_PROJECT: "/ac/projects/:id",
  AC_CONTRACTOR_PATH: "/ac/contractor/:id",
  AC_SUB_CONTRACTOR_PATH: "/ac/subcontractor/:id",

  AC_BUDGET_PATH: "/ac/budget/:id",

  AC_BILLING_PATH: "/ac/projects/:id/billing",
};

export const RouteConfig = [
  //!Common
  {
    path: LOGIN_PATH,
    component: (
      <Jumper>
        <LoginScreen />,
      </Jumper>
    ),
  },
  {
    path: NOTICE_PATH,
    component: (
      <WrapWithFrame index={7}>
        <Notice />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
      "Project Manager": true,
      "Account Chief": true,
    },
  },
  {
    path: NOTFOUND_PATH,
    component: <NotFound />,
  },
  {
    path: REPORTS_PATH,
    component: (
      <WrapWithFrame index={8}>
        <Report />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
      "Project Manager": true,
      "Account Chief": true,
    },
  },
  {
    path: DASHBOARD_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={0}>
        <DashboardScreen />
      </WrapWithFrame>
    ),
  },

  //!Admin
  {
    path: ADMIN_PATH,
    component: (
      <WrapWithFrame index={1}>
        <Admin />,
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },
  {
    path: PROJECT_MANAGER_PATH,
    component: (
      <WrapWithFrame index={3} frameLess={false}>
        <ProjectManager />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },

  {
    path: ACCOUNT_CHIEF_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={4}>
        <AccountChief />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },
  {
    path: CREATE_PROJECT,
    component: <CreateProject />,
    permission: {
      Admin: true,
    },
  },
  {
    path: CREATE_SUB_PROJECT,
    component: <CreateSubProject />,
    permission: {
      Admin: true,
    },
  },
  {
    path: LOAN,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <Loan />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },
  {
    path: SUB_PROJECT,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <SubProject />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },
  {
    path: PROJECTS_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <Projects />
      </WrapWithFrame>
    ),

    permission: {
      Admin: true,
    },
  },
  {
    path: CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <AdminContractor />
      </WrapWithFrame>
    ),

    permission: {
      Admin: true,
    },
  },
  {
    path: SUB_CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <AdminSubContractor />
      </WrapWithFrame>
    ),

    permission: {
      Admin: true,
    },
  },
  {
    path: BILLING_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <Billing />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },

  {
    path: BUDGET_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={2}>
        <AdminBudget />
      </WrapWithFrame>
    ),
    permission: {
      Admin: true,
    },
  },

  //!PM
  {
    path: PM_PROJECTS_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PM_Projects />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  {
    path: PM_SUB_PROJECT,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PM_SubProjects />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  {
    path: PM_SUB_CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PMSubContractor />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  {
    path: PM_CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PMContractor />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  {
    path: PM_BILLING_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PM_Billing />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  {
    path: PM_BUDGET_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={6}>
        <PMBudget />
      </WrapWithFrame>
    ),
    permission: {
      "Project Manager": true,
    },
  },
  //!AC
  {
    path: AC_PROJECTS_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <AC_Projects />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
  {
    path: AC_CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <Contractor />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
  {
    path: AC_SUB_CONTRACTOR_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <SubContractor />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
  {
    path: AC_SUB_PROJECT,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <AC_SubProjects />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
  {
    path: AC_BILLING_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <AC_Billing />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
  {
    path: AC_BUDGET_PATH,
    component: (
      <WrapWithFrame frameLess={false} index={5}>
        <ACBudget />
      </WrapWithFrame>
    ),
    permission: {
      "Account Chief": true,
    },
  },
];

export const SidebarConfig = [
  {
    to: DASHBOARD_PATH,
    name: "Dashboard",
    icon: "fa-light fa-grid-2",
    permission: {
      Admin: true,
      "Project Manager": true,
      "Account Chief": true,
    },
  },
  {
    to: ADMIN_PATH,
    name: "Admin",
    icon: "fa-light fa-user-shield",
    permission: {
      Admin: true,
    },
  },
  {
    to: PROJECTS_PATH,
    name: "Projects",
    icon: "fa-light fa-diagram-project",
    permission: {
      Admin: true,
    },
  },
  {
    to: PROJECT_MANAGER_PATH,
    name: "Project Manager",
    icon: "fa-light fa-user-tie",
    permission: {
      Admin: true,
    },
  },
  {
    to: ACCOUNT_CHIEF_PATH,
    name: "Account Chief",
    icon: "fa-light fa-users",
    permission: {
      Admin: true,
    },
  },
  {
    to: AC_PROJECTS_PATH,
    name: "Projects",
    icon: "fa-light fa-diagram-project",
    permission: {
      "Account Chief": true,
    },
  },
  {
    to: PM_PROJECTS_PATH,
    name: "Projects",
    icon: "fa-light fa-diagram-project",
    permission: {
      "Project Manager": true,
    },
  },
  {
    to: NOTICE_PATH,
    name: "Notices",
    icon: "fa-light fa-square-info",
    permission: {
      Admin: true,
      "Project Manager": true,
      "Account Chief": true,
    },
  },
  {
    to: REPORTS_PATH,
    name: "Reports",
    icon: "fa-light fa-file-spreadsheet",
    permission: {
      Admin: true,
      "Project Manager": true,
      "Account Chief": true,
    },
  },
];

import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { doPost, doPut } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import TextArea from "../../../UI/TextArea/TextArea";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";

const EditNotice = ({
  onClose,
  item = {
    title: "",
    description: "",
    status: 1,
    document: "",
    id: "",
  },
}) => {
  const { title, description, status, id, document } = item;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);

  const queryClient = useQueryClient();

  const initialState = {
    title,
    description,
    status,
    document,
  };

  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    if (validate()) {
      await doPut(`/notice/${id}/edit`, {
        ...states,
      });
    }
  };
  const { mutate } = useMutation({
    mutationKey: "notices",
    mutationFn: handleSubmit,
    onError: (error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        return toast.error("Error while editing notice!!");
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("notices");
      toast.success("Admin Edited Successfully");
      onClose();
    },
  });
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };

  return (
    <ModalLayout title={"Edit Notice"} onClose={handleClose}>
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to edit notice ??"}
          caption={"Please verify all details before clicking yes"}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            mutate();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel editing notice ??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      <div className="addemployee-main">
        <Input
          placeholder={"Title"}
          label={"Title"}
          onChange={handleChange}
          name="title"
          error={errors.title}
          defaultValue={initialState.title}
        />
        <div className="editnotice-description">
          <TextArea
            placeholder={"Description"}
            label={"Description"}
            onChange={handleChange}
            name="description"
            error={errors.description}
            defaultValue={initialState.description}
          />
        </div>
        <div className="addemployee-main--submit">
          <PrimaryButton
            content={"Edit Notice"}
            onClick={() => setConfirmationModal(true)}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditNotice;

//!Global
export const currencyList = [
  { value: "SDR", key: "SDR-Special Drawing Right" },
  { value: "INR", key: "INR-Indian Rupee" },
  { value: "USD", key: "USD-U.S. Dollar" },
  { value: "EUR", key: "EUR-European Euro" },
  { value: "GBP", key: "GBP-UK Pound Sterling" },
  { value: "CHF", key: "CHF-Swiss Franc" },
  { value: "AUD", key: "AUD-Australian Dollar" },
  { value: "CAD", key: "CAD-Canadian Dollar" },
  { value: "SGD", key: "SGD-Singapore Dollar" },
  { value: "JPY", key: "JPY-Japanese Yen" },
  { value: "CNY", key: "CNY-Chinese Yuan" },
  { value: "SAR", key: "SAR-Saudi Arabian Riyal" },
  { value: "QAR", key: "QAR-Qatari Riyal" },
  { value: "THB", key: "THB-Thai Baht" },
  { value: "AED", key: "AED-UAE Dirham" },
  { value: "MYR", key: "MYR-Malaysian Ringgit" },
  { value: "KRW", key: "KRW-South Korean Won" },
  { value: "SEK", key: "SEK-Swedish Kroner" },
  { value: "DKK", key: "DKK-Danish Kroner" },
  { value: "HKD", key: "HKD-Hong Kong Dollar" },
  { value: "KWD", key: "KWD-Kuwaity Dinar" },
  { value: "BHD", key: "BHD-Bahrain Dinar" },
];

//!Loans
export const InternalSource = {
  "GoN Loan": true,
  Share: true,
};
export const LoanFrame = {
  //!Loan Information
  type: null, //!Grant Share
  loan_number: null,
  donor_agency: null,
  donor_currency: null,
  loan_amount: null,
  loan_amount_usd: null,
  loan_amount_npr: null,
  donor_agreement_date: null,
  donor_agreement_date_ad: null,

  donor_service_charge_flag: true | false,
  donor_service_charge_type: null,
  donor_service_charge: null,
  donor_service_charge_amount: null,
  donor_service_charge_amount_usd: null,
  donor_service_charge_amount_npr: null,

  forex_risk_bearer_flag: true | false,
  forex_risk_bearer: null,
  forex_risk_bearer_type: null,

  penalty_flag: true | false,
  penalty_percentage: null,

  grace_period_start_date: null,
  grace_period_start_date_ad: null,
  grace_period_end_date: null,
  grace_period_end_date_ad: null,
  grace_period_interest_rate: null,
  interest_rate_after_grace_period: null,

  installment_start_date: null,
  installment_start_date_ad: null,
  installment_end_date: null,
  installment_end_date_ad: null,
  installment_period: null,
  no_of_installment: null,

  idc_effective_date: null,
  idc_effective_date_ad: null,
  idc_ending_date: null,
  idc_ending_date_ad: null,
  idc_no_of_days: null,
  idc_nea_portion: null,
  idc_gon_portion: null,
  idc_payable_to_gon: null,
  idc_transfer_to_share: null,
  idc_transfer_to_cwip: null,
  idc_transfer_to_loan: null,
};

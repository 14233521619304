import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";

import useForex from "../../../Hooks/useForex";
import { doPut } from "../../../Services/Axios";

import PrimaryButton from "../../../UI/Button/PrimaryButton";
import DateInput from "../../../UI/DateInput/DateInput";

import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import Select from "../../../UI/Select/Select";
import { errorToast } from "../../../Utils/Toastify";
import { useBudgetForm } from "../useBudgetForm";
import { useEditBudgetForm } from "../useEditBudgetForm";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../../Slice/SystemSlice";
const EditBudget = ({
  onClose,
  project,
  donorCurrency,
  loans,
  contractors,
  budget,
  apgProp,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { nepaliDate } = useSelector((state) => state.System);
  const { filterForexWithDate, getConversionRate } = useForex();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isFirstPart, setIsFirstPart] = useState(true);
  const {
    handleChange,
    errors,
    states,
    handleCurrency,

    selectedApg,
    selectedId,
    handleAddApg,
    handleApgChange,
    handleApgCurrency,
    apg,

    apgError,
    setSelectedApg,
    setSelectedId,
    setApg,
    isFormvalid,
    setApgError,

    validateForm,
  } = useEditBudgetForm(
    {
      serial_number: budget.serial_number,
      loan_id: budget.loan_id,
      agreement_date: budget.agreement_date,
      agreement_date_ad: budget.agreement_date_ad,
      donor_currency: donorCurrency && donorCurrency[0],
      donor_agency: budget.donor_agency,
      fiscal_year: budget.fiscal_year,
      activity_number: budget.activity_number,
      budget_head: budget.budget_head,
      budget_re_imburse: budget.budget_re_imburse,
      expenses_head: budget.expenses_head,
      contract_id: budget.contract_id,
      sdr_conversion_rate: budget.sdr_conversion_rate,
      approved_budget: budget.approved_budget,
      approved_budget_npr: budget.approved_budget_npr,
      approved_budget_usd: budget.approved_budget_usd,
      estimated_cost: budget.estimated_cost,
      estimated_cost_usd: budget.estimated_cost_usd,
      estimated_cost_npr: budget.estimated_cost_npr,
    },
    filterForexWithDate,
    apgProp
  );

  const submitForm = async () => {
    try {
      dispatch(toggleLoader(true));
      if (validateForm()) {
        // const formData = new FormData();
        // formData.append("project_id", project);
        // formData.append("approved_budget_currency", states.donor_currency);
        // formData.append("estimated_cost_currency", states.donor_currency);
        // for (let i = 0; i < apg.length; i++) {
        //   Object.keys(apg[i]).forEach((el) => {
        //     formData.append(`advancePaymentGuarantee[${i}][${el}]`, apg[i][el]);
        //   });
        // }
        // for (let index = 0; index < Object.entries(states).length; index++) {
        //   formData.append(
        //     Object.entries(states)[index][0],
        //     Object.entries(states)[index][1]
        //   );
        // }
        // for (let index = 0; index < images.length; index++) {
        //   formData.append("images[]", images[index]);
        // }
        // formData.set("donor_currency", states.loan_id.split("-")[1]);
        // formData.set("loan_id", states.loan_id.split("-")[0]);
        const currency = await getConversionRate(states.agreement_date_ad, {
          USD: true,
          [states.donor_currency.toUpperCase()]: true,
        });
        const response = await doPut(`/budget-info/${budget.id}/edit`, {
          id: states.budget_id,
          project_id: project,
          ...states,
          loan_id: states.loan_id,
          donor_currency: states.donor_currency,
          agreement_date: budget.agreement_date,
          agreement_date_ad: budget.agreement_date_ad,
          advancePaymentGuarantee: apg,
          currency: currency,
        });
        queryClient.refetchQueries(["budget"]);
        onClose();
      } else {
        return errorToast("Validation error");
      }
    } catch (error) {
      return errorToast("Error while editing budget");
    } finally {
      dispatch(toggleLoader(false));
    }
  };
  useEffect(() => {
    apg && apg[0] && setSelectedId(apg[0].id);
    apg && apg[0] && setSelectedApg(apg[0].id);
  }, []);

  const handleClose = () => {
    setCloseConfirmationModal(true);
  };
  useEffect(() => {
    if (!isFirst && !isFirstPart) {
      if (states.sdr_conversion_rate) {
        states.approved_budget_npr &&
          states.sdr_conversion_rate &&
          !isNaN(+states.sdr_conversion_rate) &&
          handleCurrency(
            {
              target: {
                name: "approved_budget_npr",
                value: states.approved_budget_npr,
              },
            },
            +states.sdr_conversion_rate
          );
        states.estimated_cost_npr &&
          states.sdr_conversion_rate &&
          !isNaN(+states.sdr_conversion_rate) &&
          handleCurrency(
            {
              target: {
                name: "estimated_cost_npr",
                value: states.estimated_cost_npr,
              },
            },
            +states.sdr_conversion_rate
          );
        apg.forEach((item) => {
          handleApgCurrency(
            item.id,
            {
              target: {
                name: "advance_amount_npr",
                value: item.advance_amount_npr,
              },
            },
            +states.sdr_conversion_rate
          );
        });
        setApgError([]);
      } else {
        states.approved_budget_npr &&
          handleCurrency({
            target: {
              name: "approved_budget_npr",
              value: states.approved_budget_npr,
            },
          });
        states.estimated_cost_npr &&
          handleCurrency({
            target: {
              name: "estimated_cost_npr",
              value: states.estimated_cost_npr,
            },
          });
        apg.forEach((item) => {
          handleApgCurrency(item.id, {
            target: {
              name: "advance_amount_npr",
              value: item.advance_amount_npr,
            },
          });
        });
      }
    } else {
      if (budget.agreement_date_ad !== states.agreement_date_ad) {
        setIsFirstPart(false);
      }
      if (
        budget.agreement_date_ad === states.agreement_date_ad &&
        isFirstPart
      ) {
        setIsFirst(false);
      }
    }
  }, [states.agreement_date_ad, isFirst]);

  return (
    <ModalLayout onClose={handleClose} title={"Edit Budget"}>
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to edit budget ??"}
          caption={"Please verify before clicking yes"}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            submitForm();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel editing budget ??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      {contractors.length > 0 && loans.length > 0 ? (
        <div className="insertbudget-wrapper">
          <div className="insertbudget-submit">
            <PrimaryButton
              content={"Edit Budget"}
              onClick={() => setConfirmationModal(true)}
            />
          </div>
          {budget.remarks && (
            <div className="insertbudget-remarks">{budget.remarks}</div>
          )}
          <div className="insertbudget">
            <div className="insertbudget-title">Basic Details</div>
            <div className="insertbudget-first">
              <div
                style={{
                  height: "5rem",
                }}
              >
                <Select
                  name="loan_id"
                  label={"Loan number"}
                  error={errors.loan_id}
                  defaultValue={`${states.loan_id}|${states.donor_currency}|${states.donor_agency}`}
                  handler={handleChange}
                  preventMouse
                  options={loans.map((item) => {
                    return {
                      key: `${item.donor_agency}-${item.loan_number}-${item.donor_currency}`,
                      value: `${item.id}|${item.donor_currency}|${item.donor_agency}`,
                    };
                  })}
                />
              </div>

              <Select
                options={Object.keys(nepaliDate.NEPALI_YEARS).map((i) => ({
                  key: `${i}/${(+i + 1).toString()}`,
                }))}
                error={errors.fiscal_year}
                defaultValue={states.fiscal_year}
                handler={handleChange}
                name={"fiscal_year"}
                label={"Fiscal Year"}
                preventMouse
              />

              <Select
                handler={handleChange}
                name="contract_id"
                label={"Contract Id"}
                error={errors.contract_id}
                defaultValue={states.contract_id}
                options={
                  contractors
                    ? contractors
                        .filter((i) => i.progress_status == 1)
                        .map((el) => {
                          return {
                            key: `${el.contractor_name}-${el.contract_number}-${el.contractor_address}`,
                            value: el.id,
                          };
                        })
                    : []
                }
              />
            </div>
            <div
              style={{
                height: "5rem",
              }}
            >
              <DateInput
                onChange={handleChange}
                name="agreement_date"
                label={"Budget receive Date(Deposited in bank)"}
                error={errors.agreement_date}
                customDate={states.agreement_date_ad}
              />
            </div>
            <Input
              disabled={true}
              name="donor_agency"
              placeholder={"Eg.Asian Development Bank"}
              label={"Donor agency"}
              error={errors.donor_agency}
              defaultValue={states.donor_agency}
            />

            <Input
              onChange={handleChange}
              name="activity_number"
              label={"activity number"}
              placeholder={"Eg.ACT1231"}
              error={errors.activity_number}
              defaultValue={states.activity_number}
            />
            <Input
              onChange={handleChange}
              name="serial_number"
              label={"serial_number"}
              placeholder="Eg. SE12312"
              error={errors.serial_number}
              defaultValue={states.serial_number}
            />
            {/* <Input
              onChange={handleChange}
              name="directorate_office"
              label={"directorate/officename"}
              placeholder={"Eg.Dhangadi Electricity"}
              error={errors.directorate_office}
              defaultValue={states.directorate_office}
            />
            <Input
              onChange={handleChange}
              name="directorate_office_code"
              label={"directorate/officecode"}
              placeholder={"Eg.OFI111"}
              error={errors.directorate_office_code}
              defaultValue={states.directorate_office_code}
            /> */}

            {/* <Input
              onChange={handleChange}
              name="office_name"
              label={"Office name"}
              placeholder={"Eg.Dharan Electricity"}
              error={errors.office_name}
              defaultValue={states.office_name}
            />
            <Input
              onChange={handleChange}
              name="office_code"
              label={"Office Code"}
              placeholder={"Eg.OFF113"}
              error={errors.office_code}
              defaultValue={states.office_code}
            /> */}
            <div className="insertbudget-title">Budget Details</div>
            <Input
              onChange={handleChange}
              name="budget_head"
              placeholder={"Eg.BH11"}
              label={"budget head"}
              error={errors.budget_head}
              defaultValue={states.budget_head}
            />
            <Input
              onChange={handleChange}
              name="expenses_head"
              placeholder={"Eg.EH123"}
              label={"Expenses head"}
              error={errors.expenses_head}
              defaultValue={states.expenses_head}
            />

            <Input
              onChange={handleChange}
              name="budget_re_imburse"
              label={"Reinburse"}
              placeholder={"Eg.BRI111"}
              error={errors.budget_re_imburse}
              defaultValue={states.budget_re_imburse}
            />
            <div className="insertbudget-title">Amount Details</div>
            <Input
              onChange={handleChange}
              name="approved_budget_npr"
              placeholder={"Eg.1000"}
              label={"Approved Budget(In Npr)"}
              onBlur={handleCurrency}
              error={errors.approved_budget_npr}
              defaultValue={states.approved_budget_npr}
            />
            <div
              className=""
              style={{ display: "flex", gap: "1rem", gridColumn: "2/4" }}
            >
              <Input
                onChange={handleChange}
                name="approved_budget"
                label={"In Donor Currency"}
                placeholder={"AUTO"}
                disabled={true}
                customStyle={{ width: "50%" }}
                error={errors.approved_budget}
                defaultValue={states.approved_budget}
              />
              <Input
                onChange={handleChange}
                name="approved_budget_usd"
                label={"In USD"}
                placeholder={"AUTO"}
                error={errors.approved_budget_usd}
                defaultValue={states.approved_budget_usd}
                disabled={true}
                customStyle={{ width: "50%" }}
              />
            </div>
            {states.donor_currency === "SDR" ? (
              <Input
                label={"1USD to SDR Conversion Rate"}
                required
                name="sdr_conversion_rate"
                placeholder={"eg: 1.4"}
                onChange={handleChange}
                defaultValue={states.sdr_conversion_rate}
                error={errors.sdr_conversion_rate}
              />
            ) : null}

            <div className="insertbudget-currency">
              <Input
                onChange={handleChange}
                name="estimated_cost_npr"
                onBlur={handleCurrency}
                label={"Estimate cost"}
                placeholder={"Eg.1000"}
                error={errors.estimated_cost_npr}
                defaultValue={states.estimated_cost_npr}
              />
              <div style={{ display: "flex", gap: "1rem", gridColumn: "2/4" }}>
                <Input
                  onChange={handleChange}
                  name="estimated_cost"
                  label={"In Donor Currency"}
                  disabled={true}
                  placeholder={"AUTO"}
                  customStyle={{ width: "50%" }}
                  error={errors.estimated_cost}
                  defaultValue={states.estimated_cost}
                />
                <Input
                  onChange={handleChange}
                  name="estimated_cost_usd"
                  label={"In USD"}
                  placeholder={"AUTO"}
                  error={errors.estimated_cost_usd}
                  defaultValue={states.estimated_cost_usd}
                  disabled={true}
                  customStyle={{ width: "50%" }}
                />
              </div>
            </div>
          </div>

          <div className="insertbudget-apg">
            <div className="insertbudget-apg--title__wrapper">
              <div className="insertbudget-apg--title">
                <div>APG(Advance Payment Guarentee)</div>
                <div className="insertbudget-apg--title__select">
                  <Select
                    options={apg.map((item, i) => {
                      return {
                        key: !item.bank_name ? `item-${i}` : item.bank_name,
                        value: item.id,
                      };
                    })}
                    handler={(e) => {
                      setSelectedId(e.target.value);
                      setSelectedApg(e.target.value);
                    }}
                    defaultValue={selectedApg}
                    error={!isFormvalid}
                  />
                  <PrimaryButton
                    content={
                      <div>
                        <i className="fa-regular fa-add"></i>
                        Add Apg
                      </div>
                    }
                    onClick={handleAddApg}
                  />
                </div>
              </div>
            </div>
            {!selectedId || selectedApg === "Select an option" ? (
              <p className="notfound notfound-bordered">
                {apg && apg[0]
                  ? "Please choose one option from drop down"
                  : "No custom duty found"}
              </p>
            ) : (
              apg
                .filter((item) => +item.id === +selectedId)
                .map((item, index) => (
                  <div>
                    <div className="insertbudget-apg--remove">
                      <div className="insertbudget-apg--title__remove">
                        <PrimaryButton
                          onClick={() => {
                            setApg(
                              apg.filter((item) => {
                                return +item.id !== +selectedId;
                              })
                            );
                            setApgError(
                              apgError.filter((item) => {
                                return +item.id !== +selectedId;
                              })
                            );
                          }}
                          customStyle={{ backgroundColor: "#c71c1c" }}
                          content={
                            <>
                              <i className="fa-regular fa-trash"></i>
                              Delete
                            </>
                          }
                        />
                      </div>
                    </div>
                    <div className="insertbudget-apg--main">
                      <Input
                        label={"Name of Bank:"}
                        name={"bank_name"}
                        placeholder="Eg. Sidhartha Bank"
                        onChange={(e) => {
                          handleApgChange(item.id, e);
                          setSelectedApg(item.id);
                          setSelectedId(item.id);
                        }}
                        defaultValue={item.bank_name}
                        error={
                          apgError[item.id] ? apgError[item.id].bank_name : null
                        }
                      />
                      <Input
                        label={"Guarentee number"}
                        placeholder="Eg. 123123"
                        name={"advance_guarantee_no"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        defaultValue={item.advance_guarantee_no}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_guarantee_no
                            : null
                        }
                      />
                      <Input
                        label={"Validity Period"}
                        placeholder="Eg. 5"
                        name={"validity_period"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        defaultValue={item.validity_period_ad}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].validity_period
                            : null
                        }
                      />
                      <Input
                        label={"Advance amount(In Npr)"}
                        placeholder="Eg. 10000"
                        name="advance_amount_npr"
                        onBlur={(e) => handleApgCurrency(item.id, e)}
                        onChange={(e) => handleApgChange(item.id, e)}
                        defaultValue={item.advance_amount_npr}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_amount_npr
                            : null
                        }
                      />
                      <div className="insertbudget-apg--currency">
                        <Input
                          label={"In USD"}
                          disabled={true}
                          customStyle={{ width: "50%" }}
                          defaultValue={item.advance_amount_usd}
                          error={
                            apgError[item.id]
                              ? apgError[item.id].advance_amount_usd
                              : null
                          }
                        />
                        <Input
                          label={"In Donor Currency"}
                          disabled={true}
                          customStyle={{ width: "50%" }}
                          defaultValue={item.advance_amount}
                          error={
                            apgError[item.id]
                              ? apgError[item.id].advance_amount
                              : null
                          }
                        />
                      </div>
                      <DateInput
                        label={"Advance Release date"}
                        placeholder="YYYY-MM-DD"
                        name={"advance_release_date"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        customDate={item.advance_release_date_ad}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_release_date_ad
                            : null
                        }
                      />
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "40vw",
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="notfound">Loans and Contracts are required</div>
        </div>
      )}
    </ModalLayout>
  );
};
export default EditBudget;

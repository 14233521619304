import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useForex from "../../Hooks/useForex";
import { doPut } from "../../Services/Axios";
import { currencyList, InternalSource } from "../../Store/DataSet";
import PrimaryButton from "../../UI/Button/PrimaryButton";
import DateInput from "../../UI/DateInput/DateInput";
import Input from "../../UI/Input/Input";
import ModalLayout from "../../UI/Modal/ModalLayout";
import Select from "../../UI/Select/Select";
import TextArea from "../../UI/TextArea/TextArea";
import ConfirmationModal from "../Modal/ConfirmationModal";
import { errorToast } from "../../Utils/Toastify";
import { useQueryClient } from "react-query";
import { useEditContractForm } from "./useEditContract";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../Slice/SystemSlice";
const EditContractModal = ({ title, onClose, donorCurrency, item }) => {
  const queryClient = useQueryClient();
  const location =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const dispatch = useDispatch();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isFirstPart, setIsFirstPart] = useState(true);

  const { filterForexWithDate, getConversionRate } = useForex();
  const { errors, handleChange, states, validate, handleCurrency } =
    useEditContractForm(
      {
        type: item.type,
        contract_number: item.contract_number,
        contractor_name: item.contractor_name,
        contractor_address: item.contractor_address,
        is_resident: item.is_resident ? "Yes" : "No",
        donor_currency: item.donor_currency,
        company_registration_type: item.company_registration_type,
        types_of_service: item.types_of_service,
        company_registration_number: item.company_registration_number,
        agreement_amount: item.agreement_amount,
        agreement_amount_usd: item.agreement_amount_usd,
        agreement_amount_npr: item.agreement_amount_npr,
        agreement_date: item.agreement_date,
        agreement_date_ad: item.agreement_date_ad,
        retention_percentage: item.retention_percentage,
        performance_security_amount: item.performance_security_amount,
        performance_security_amount_npr: item.performance_security_amount_npr,
        performance_security_amount_usd: item.performance_security_amount_usd,
        performance_security_validity_period:
          item.performance_security_validity_period,
        performance_security_validity_period_ad:
          item.performance_security_validity_period_ad,
        performance_security_guarantee_number:
          item.performance_security_guarantee_number,
        performance_security_bank_name: item.performance_security_bank_name,
        lc_amount: item.lc_amount,
        lc_amount_npr: item.lc_amount_npr,
        lc_amount_usd: item.lc_amount_usd,
        lc_number: item.lc_number,
        lc_issuer_bank: item.lc_issuer_bank,
        lc_duration: item.lc_duration,
        lc_duration_ad: item.lc_duration_ad,
        lc_date: item.lc_date,
        lc_date_ad: item.lc_date_ad,
        payment_terms: item.payment_terms,
        work_completion_date_ad: item.work_completion_date_ad,
        sdr_conversion_rate: item.sdr_conversion_rate,
      },
      filterForexWithDate
    );
  const handleSubmitForm = async () => {
    try {
      dispatch(toggleLoader(true));
      if (validate()) {
        const currency = await getConversionRate(states.agreement_date_ad, {
          USD: true,
          [states.donor_currency.toUpperCase()]: true,
        });
        const response = await doPut(`contract/${item.id}/edit`, {
          ...states,
          project_id: location,
          lc_currency: states.donor_currency,
          is_resident: states.is_resident === "Yes" ? 1 : 0,
          currency: currency,
        });
        handleShowConfirmationModal();
        queryClient.refetchQueries("contractor");
        dispatch(toggleLoader(false));
        onClose();
      } else {
        dispatch(toggleLoader(false));
        errorToast("Validation error");
      }
    } catch (error) {
      errorToast("Error while editing contract");
    }
    dispatch(toggleLoader(false));
  };
  const handleShowConfirmationModal = () => {
    setConfirmationModal((prev) => !prev);
  };
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };
  useEffect(() => {
    if (!isFirst && !isFirstPart) {
      if (states.sdr_conversion_rate) {
        states.agreement_amount_npr &&
          states.sdr_conversion_rate &&
          !isNaN(+states.sdr_conversion_rate) &&
          handleCurrency(
            {
              target: {
                name: "agreement_amount_npr",
                value: states.agreement_amount_npr,
              },
            },
            +states.sdr_conversion_rate
          );
        states.performance_security_amount_npr &&
          states.sdr_conversion_rate &&
          !isNaN(+states.sdr_conversion_rate) &&
          handleCurrency(
            {
              target: {
                name: "performance_security_amount_npr",
                value: states.performance_security_amount_npr,
              },
            },
            +states.sdr_conversion_rate
          );
        states.lc_amount_npr &&
          states.sdr_conversion_rate &&
          !isNaN(+states.sdr_conversion_rate) &&
          handleCurrency(
            {
              target: {
                name: "lc_amount_npr",
                value: states.lc_amount_npr,
              },
            },
            +states.sdr_conversion_rate
          );
      } else {
        states.agreement_amount_npr &&
          handleCurrency({
            target: {
              name: "agreement_amount_npr",
              value: states.agreement_amount_npr,
            },
          });
        states.performance_security_amount_npr &&
          handleCurrency({
            target: {
              name: "performance_security_amount_npr",
              value: states.performance_security_amount_npr,
            },
          });
        states.lc_amount_npr &&
          handleCurrency({
            target: {
              name: "lc_amount_npr",
              value: states.lc_amount_npr,
            },
          });
      }
    } else {
      if (item.agreement_date_ad !== states.agreement_date_ad) {
        setIsFirstPart(false);
      }
      if (item.agreement_date_ad === states.agreement_date_ad && isFirstPart) {
        setIsFirst(false);
      }
    }
  }, [states.agreement_date_ad]);

  return (
    <ModalLayout
      title={`Edit ${title}`}
      onClose={handleClose}
      isLanguageChangeable={true}
    >
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to edit contract ??"}
          caption={"Please verify all details before clicking yes"}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            handleSubmitForm();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel editing contract ??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      <div className="contractormodal">
        <div className="contractormodal-submit">
          <PrimaryButton
            content={"Edit Contractor"}
            onClick={handleShowConfirmationModal}
          />
        </div>
        {item.remarks && (
          <div className="contractormodal-remarks">{item.remarks}</div>
        )}
        <div className="contractormodal-title">Basic Details</div>
        <div>
          <Select
            options={[
              { key: "ICB", value: "ICB" },
              { key: "NCB", value: "NCB" },
            ]}
            defaultValue={states.type}
            name="type"
            label="Contractor type"
            handler={handleChange}
            error={errors.type}
          />
        </div>
        <DateInput
          label={"Contract Agreement Date"}
          onChange={handleChange}
          customDate={states.agreement_date_ad}
          name="agreement_date"
          error={errors.agreement_date}
        />
        <Input
          label={"Contract Number"}
          name={"contract_number"}
          placeholder={"eg. 123123"}
          defaultValue={states.contract_number}
          error={errors.contract_number}
          onChange={handleChange}
        />
        <div className="contractormodal-resident">
          <Select
            label="Resident type"
            name={"is_resident"}
            defaultValue={states.is_resident ? "Yes" : "No"}
            handler={handleChange}
            options={[
              { key: "Resident", value: "Yes" },
              { key: "Non-Resident", value: "No" },
            ]}
            error={errors.is_resident}
          />
        </div>
        <div className="contractormodal-vat">
          <Select
            label="Company Registration type"
            name={"company_registration_type"}
            handler={handleChange}
            defaultValue={states.company_registration_type}
            options={[
              { key: "VAT", value: "vat" },
              { key: "PAN", value: "pan" },
            ]}
            error={errors.company_registration_type}
          />
        </div>
        <Input
          type={"text"}
          label="Contractor Name"
          name={"contractor_name"}
          onChange={handleChange}
          error={errors.contractor_name}
          placeholder="eg. XYZ ltd."
          defaultValue={states.contractor_name}
        />
        <Input
          type={"text"}
          label="Contractor Address"
          name={"contractor_address"}
          onChange={handleChange}
          placeholder="eg. kathmandu"
          error={errors.contractor_address}
          defaultValue={states.contractor_address}
        />
        <Input
          label="Regstration number"
          placeholder={"eg. 123111"}
          onChange={handleChange}
          name="company_registration_number"
          error={errors.company_registration_number}
          defaultValue={states.company_registration_number}
        />
        <div className="contractormodal-title">Basic Details</div>
        <div>
          <Select
            label={"Currency"}
            name="donor_currency"
            defaultValue={states.donor_currency}
            handler={handleChange}
            options={
              InternalSource[states.type]
                ? [{ value: "NPR", key: "NPR-Nepali Rupee" }]
                : !donorCurrency || donorCurrency.length < 1
                ? currencyList
                : currencyList.filter(
                    (i) => donorCurrency.indexOf(i.value) > -1
                  )
            }
            error={errors.donor_currency}
          />
        </div>
        {states.donor_currency === "SDR" && (
          <Input
            label={"1USD to SDR Conversion Rate"}
            required
            name="sdr_conversion_rate"
            placeholder={"eg: 1.4"}
            onChange={handleChange}
            defaultValue={states.sdr_conversion_rate}
            error={errors.sdr_conversion_rate}
          />
        )}
        <Input
          label="Contract Agreement Amount (In Npr)"
          placeholder={"eg. 123123"}
          onBlur={handleCurrency}
          onChange={handleChange}
          name="agreement_amount_npr"
          error={errors.agreement_amount_npr}
          defaultValue={states.agreement_amount_npr}
        />
        <Input
          label={"In Donor Currency"}
          placeholder={"AUTO"}
          disabled={true}
          error={errors.agreement_amount_npr}
          defaultValue={states.agreement_amount}
        />
        <Input
          label="In USD"
          placeholder={"AUTO"}
          disabled={true}
          defaultValue={states.agreement_amount_usd}
          error={errors.agreement_amount_npr}
        />

        <div>
          <Select
            label="Company service type"
            name="types_of_service"
            handler={handleChange}
            defaultValue={states.types_of_service}
            options={[
              { key: "Supply", value: "Supply" },
              { key: "Construction", value: "Construction" },
              { key: "Service", value: "Service" },
            ]}
            error={errors.types_of_service}
          />
        </div>
        <DateInput
          label={"work completion date"}
          customDate={states.work_completion_date_ad}
          error={errors.work_completion_date}
          name="work_completion_date"
          onChange={handleChange}
        />

        <Input
          label={"Retention"}
          name={"retention_percentage"}
          placeholder="eg. 10%"
          onChange={handleChange}
          defaultValue={states.retention_percentage}
          error={errors.retention_percentage}
        />

        <div className="contractormodal-performance">
          <div className="">Performance Security:</div>

          <div className="contractormodal-performance--main">
            <Input
              label={"Amount(In Npr)"}
              name="performance_security_amount_npr"
              onChange={handleChange}
              onBlur={handleCurrency}
              placeholder="eg. 1000"
              defaultValue={states.performance_security_amount_npr}
              error={errors.performance_security_amount_npr}
            />
            <Input
              label={"In Donor Currency"}
              name="performance_security_amount"
              disabled={true}
              placeholder="eg. 1000"
              defaultValue={states.performance_security_amount}
              error={errors.performance_security_amount}
            />
            <Input
              label={"In USD"}
              name="performance_security_amount_usd"
              disabled={true}
              placeholder="AUTO"
              defaultValue={states.performance_security_amount_usd}
              error={errors.performance_security_amount}
            />
            <DateInput
              label={"Validity Period"}
              placeholder="5 Years"
              name={"performance_security_validity_period"}
              onChange={handleChange}
              customDate={states.performance_security_validity_period_ad}
              error={errors.performance_security_validity_period}
            />
            <Input
              label={"Guarantee Number"}
              name="performance_security_guarantee_number"
              onChange={handleChange}
              placeholder="eg. 100"
              defaultValue={states.performance_security_guarantee_number}
              error={errors.performance_security_guarantee_number}
            />
            <Input
              label={"Name of Bank"}
              name="performance_security_bank_name"
              onChange={handleChange}
              placeholder="eg. XYZ bank"
              defaultValue={states.performance_security_bank_name}
              error={errors.performance_security_bank_name}
            />
          </div>
        </div>
        <div className="contractormodal-lc">
          <div className="">LC Information:</div>
          <div className="contractormodal-lc--main">
            <DateInput
              label={"Date"}
              name="lc_date"
              onChange={handleChange}
              customDate={states.lc_date_ad}
              error={errors.lc_date}
            />

            <Input
              label={"Amount(In Npr)"}
              name="lc_amount_npr"
              disabled={!states.donor_currency}
              onBlur={handleCurrency}
              onChange={handleChange}
              placeholder="eg. Rs.1000"
              defaultValue={states.lc_amount_npr}
              error={errors.lc_amount_npr}
            />
            <Input
              label={"In Donor Currency"}
              name="lc_amount"
              placeholder="eg. Rs.1000"
              defaultValue={states.lc_amount}
              error={errors.lc_amount_npr}
              disabled={true}
            />
            <Input
              label={"In USD"}
              name="lc_amount_usd"
              placeholder="eg. Rs.1000"
              defaultValue={states.lc_amount_usd}
              error={errors.lc_amount_npr}
              disabled={true}
            />
            <Input
              label={"LC Number"}
              name="lc_number"
              onChange={handleChange}
              placeholder="1000"
              defaultValue={states.lc_number}
              error={errors.lc_number}
            />
            <Input
              label={"Issuer  Bank"}
              name="lc_issuer_bank"
              onChange={handleChange}
              placeholder="eg. abc bank"
              defaultValue={states.lc_issuer_bank}
              error={errors.lc_issuer_bank}
            />
            <DateInput
              label={"Duration"}
              name="lc_duration"
              onChange={handleChange}
              placeholder="eg. 5 years"
              customDate={states.lc_duration_ad}
              error={errors.lc_duration}
            />
            <div className="contractormodal-paymentterm">
              <TextArea
                label={"Payment term"}
                name={"payment_terms"}
                onChange={handleChange}
                placeholder="eg. payment term"
                defaultValue={states.payment_terms}
                error={errors.payment_terms}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditContractModal;

import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";
import Input from "../../../../UI/Input/Input";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { useQuery } from "react-query";
import { errorToast, successToast } from "../../../../Utils/Toastify";
import { useDispatch, useSelector } from "react-redux";
import SmallButton from "../../../../UI/Button/SmallButton";
import { toggleLoader } from "../../../../Slice/SystemSlice";
import useForex from "../../../../Hooks/useForex";
import CreateSubProjectLoanController from "./_createSubProjectLoan";

const { fetchProjectLoans, submitForm, fetchLoans } =
  CreateSubProjectLoanController;

export default function CreateSubProjectLoan({
  onClose,
  project_id,
  sub_project_id,
}) {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.System);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [selectedLoans, setSelectedLoans] = useState({});
  const [subProjectName, setSubProjectName] = useState({
    value: "",
    error: "",
  });
  const [usedLoans, setUsedLoans] = useState({});

  const { data, isLoading, error } = useQuery({
    queryKey: ["OneTimeProject", project_id],
    queryFn: () => fetchProjectLoans(project_id),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });

  const subProjectLoans = useQuery({
    queryKey: ["Loans", sub_project_id, "subproject"],
    queryFn: () => fetchLoans(sub_project_id),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });

  const handleLoanSelection = (id) => {
    selectedLoans[id]
      ? setSelectedLoans((prev) => (delete prev[id], { ...prev }))
      : setSelectedLoans((prev) => ({ ...prev, [id]: true }));
  };

  const handleSubmit = async () => {
    dispatch(toggleLoader(true));
    const name = subProjectName.value;
    const id = project_id;
    const loans = Object.keys(selectedLoans);
    const type = data.project[0] && data.project[0].type;

    if (!type) return errorToast("Invalid project");

    try {
      await submitForm(id, loans, name, type);
      successToast("Sub Project Created");
      onClose(false);
    } catch (error) {
      errorToast(error.message);
    } finally {
      dispatch(toggleLoader(false));
    }
  };

  //!Confirmation
  const handleConfirmationModal = (action) => {
    setConfirmationModal(true);
    switch (action) {
      case "BACK":
        setConfirmationDetails({
          text: "Are you sure you want to cancel?",
          caption: "All data will be reset",
          action: () => onClose(false),
        });
        break;
      case "SAVE":
        if (!subProjectName.value)
          return errorToast("Sub-Project name must not be empty");

        if (Object.keys(selectedLoans).length < 1) {
          setConfirmationModal(false);
          errorToast("Please select one or more loans");
          return;
        }
        setConfirmationDetails({
          text: "Are you sure you want to save?",
          caption: "Please verify all details before clicking yes",
          action: () => handleSubmit(),
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    subProjectLoans.data &&
      subProjectLoans.data.loan.length > 0 &&
      subProjectLoans.data.loan.forEach((i) =>
        setUsedLoans((prev) => ({ ...prev, [i.loan_id]: true }))
      );
  }, [subProjectLoans.data]);

  console.log(usedLoans);
  return (
    <ModalLayout
      onClose={() => handleConfirmationModal("BACK")}
      title="Loan/Grant"
    >
      {data && data.loan && data.loan.length > 0 ? (
        <>
          {confirmationModal && confirmationDetails && (
            <ConfirmationModal
              onSuccess={() => (
                confirmationDetails.action(), setConfirmationModal(false)
              )}
              onClose={() => setConfirmationModal(false)}
              text={confirmationDetails.text}
              caption={confirmationDetails.caption}
            />
          )}
          <div className="subprojectcreateloan">
            <h3 className="subprojectcreateloan-heading">Selected Loans:</h3>
            <div className="subprojectcreateloan-loans">
              {data.loan
                .filter((i) => usedLoans[i.id])
                .map((i, _) => (
                  <div
                    className={`subprojectcreateloan-loans--item subprojectcreateloan-loans--item_selected`}
                    key={i.id}
                  >
                    <p className="subprojectcreateloan-loans--item_date">
                      {i.donor_agreement_date}
                    </p>
                    <p className="subprojectcreateloan-loans--item_agency">
                      {i.donor_agency} | {i.loan_number}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      {i.donor_currency} {i.loan_amount}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      USD {i.loan_amount_usd}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      NPR {i.loan_amount_npr}
                    </p>
                  </div>
                ))}
            </div>
            <div className="subprojectcreateloan-actions">
              <h3 className="subprojectcreateloan-heading">
                Select Loans: {Object.keys(selectedLoans).length}/
                {data.loan.length}
              </h3>
              <SmallButton
                title={"Add Loans"}
                content={"Add Selected"}
                icon={"fa-light fa-plus"}
                onClick={() => handleConfirmationModal("SAVE")}
              />
            </div>
            <div className="subprojectcreateloan-loans">
              {data.loan
                .filter((i) => !usedLoans[i.id])
                .map((i, _) => (
                  <div
                    className={`subprojectcreateloan-loans--item ${
                      selectedLoans[i.id]
                        ? "subprojectcreateloan-loans--item_selected"
                        : ""
                    }`}
                    onClick={() => handleLoanSelection(i.id)}
                    key={i.id}
                  >
                    <p className="subprojectcreateloan-loans--item_date">
                      {i.donor_agreement_date}
                    </p>
                    <p className="subprojectcreateloan-loans--item_agency">
                      {i.donor_agency} | {i.loan_number}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      {i.donor_currency} {i.loan_amount}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      USD {i.loan_amount_usd}
                    </p>
                    <p className="subprojectcreateloan-loans--item_currency">
                      NPR {i.loan_amount_npr}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <p className="notfound notfound-bordered">
          No Loans Found (Atlease one or more should be present on Project)
        </p>
      )}
    </ModalLayout>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  controller: null,

  //!Preparation
  projectSources: {
    Internal: { key: "Internal" },
    External: { key: "External" },
  },
  loanType: {
    Internal: {
      "GoN Loan": { key: "GoN Loan" },
      Share: { key: "Share" },
    },
    External: {
      "Donor Loan": { key: "Donor Loan" },
      "Grant Share": { key: "Grant Share" },
    },
  },

  //!Project
  projectName: null,

  //!Loan & Settings
  loans: [],
  errors: {},

  //!Misc
  selectedSource: null,
  selectedLoanType: null,
  selectedLoan: null,

  //!System
  editMode: false,
  isReady: false,
};

const createProjectSlice = createSlice({
  name: "CreateProjectSlice",
  initialState,
  reducers: {
    //!Setups
    setController: (state, { payload }) => {
      state.controller = payload;
    },

    setConfig: (state, { payload }) => {
      switch (payload.type) {
        case "PROJECT":
          state.selectedSource = state.projectSources[payload.data].key;
          const availableData = state.loanType[payload.data];
          state.selectedLoanType =
            availableData[Object.keys(availableData)[0]].key;
          break;
        case "LOAN_TYPE":
          state.selectedLoanType =
            state.loanType[state.selectedSource][payload.data].key;
          break;
        case "LOAN":
          state.selectedLoan = payload.data;
          break;
        default:
      }
    },

    toggleKeyBoolean: (state, { payload }) => {
      state[payload] = !state[payload];
    },

    //!Loans
    setLoan: (state) => {
      state.loans.push({
        loan_index: state.loans[state.loans.length - 1]
          ? state.loans[state.loans.length - 1].loan_index + 1
          : 0,
        type: state.loanType[state.selectedSource][state.selectedLoanType].key,
      });
    },

    updateLoan: (state, { payload: { data, error } }) => {
      const index = state.loans.findIndex(
        (i) => i.loan_index === data.loan_index
      );
      if (index < 0) return;
      console.log(data);
      state.loans[index] = data;
      state.errors[data.loan_index] = error;
    },

    removeLoan: (state, { payload }) => {
      state.loans = state.loans.filter(
        (item, index) => item.loan_index !== payload
      );
      state.errors[payload] && delete state.errors[payload];
      if (state.loans.length < 1) state.editMode = null;
    },

    resetLoan: (state) => {
      state.loans = [];
      state.errors = {};
      state.projectName = null;
      state.editMode = false;
    },

    deleteLoanKey: (state, { payload }) => {
      state.loans = state.loans.map((item, index) => {
        if (item.loan_index === state.selectedLoan) {
          delete item[payload];
        }
        return item;
      });
    },

    //!Filling Data
    setProjectName: (state, { payload }) => {
      state.projectName = payload;
      if (!payload) state.errors["projectName"] = "Field is Required";
      else {
        state.errors["projectName"] = null;
      }
    },
    fillLoanData: (state, { payload: { name, value, error } }) => {
      const index = state.loans.findIndex(
        (i) => i.loan_index === state.selectedLoan
      );
      if (index < 0) return;
      state.loans[index][name] = value;
      if (state.errors[state.selectedLoan]) {
        state.errors[state.selectedLoan][name] = error;
      } else {
        state.errors[state.selectedLoan] = {
          [name]: error,
        };
      }
    },

    //!Submit
    submitForm: (state) => {
      let isRequired = "Field is Required";
      let shouldContain = "Contains only Number";
      let isRate = "Rate is required";
      let shouldRangeBetween = "Ranges between 0 - 100";

      if (state.loans.length < 1) return;

      let status = 0;
      state.errors = {};
      if (!state.projectName) state.errors["projectName"] = isRequired;
      for (let i = 0; i < state.loans.length; i++) {
        const item = state.loans[i];
        const err = {};

        //!Basic Details
        // if (isNaN(item.loan_number)) err["loan_number"] = shouldContain;
        if (!item.loan_number) err["loan_number"] = isRequired;
        if (!item.donor_agency) err["donor_agency"] = isRequired;
        if (!item.donor_currency) err["donor_currency"] = isRequired;
        if (isNaN(item.loan_amount)) err["loan_amount"] = shouldContain;
        if (!item.loan_amount) err["loan_amount"] = isRequired;
        if (!item.loan_amount_usd) err["loan_amount_usd"] = isRequired;
        if (!item.loan_amount_npr) err["loan_amount_npr"] = isRequired;
        if (!item.donor_agreement_date)
          err["donor_agreement_date"] = isRequired;
        if (!item.donor_agreement_date_ad)
          err["donor_agreement_date_ad"] = isRequired;

        //!Donor Service Charge
        if (item.donor_service_charge_flag) {
          if (!item.donor_service_charge_type)
            err["donor_service_charge_type"] = isRequired;
          if (!item.donor_service_charge)
            err["donor_service_charge"] = isRequired;
          if (!item.donor_service_charge_amount)
            err["donor_service_charge_amount"] = isRequired;
          if (isNaN(item.donor_service_charge_amount))
            err["donor_service_charge_amount"] = shouldContain;
          if (!item.donor_service_charge_amount_usd)
            err["donor_service_charge_amount_usd"] = isRequired;
          if (!item.donor_service_charge_amount_npr)
            err["donor_service_charge_amount_npr"] = isRequired;
        } else {
          state.loans[item.loan_index]["donor_service_charge_flag"] = false;
        }

        //!Forex
        if (item.forex_risk_bearer_flag) {
          if (!item.forex_risk_bearer) err["forex_risk_bearer"] = isRequired;
          if (item.forex_risk_bearer === "NEA" && !item.forex_risk_bearer_type)
            err["forex_risk_bearer_type"] = isRequired;
        } else {
          state.loans[item.loan_index]["forex_risk_bearer_flag"] = false;
        }

        //!Penalty
        if (item.penalty_flag) {
          if (item.penalty_percentage < 0 || item.penalty_percentage > 100)
            err["penalty_percentage"] = shouldRangeBetween;
          if (isNaN(item.penalty_percentage))
            err["penalty_percentage"] = shouldContain;
          if (!item.penalty_percentage) err["penalty_percentage"] = isRate;
        } else {
          state.loans[item.loan_index]["penalty_flag"] = false;
        }

        //!Grace Period
        if (!item.grace_period_start_date)
          err["grace_period_start_date"] = isRequired;
        if (!item.grace_period_start_date_ad)
          err["grace_period_start_date_ad"] = isRequired;
        if (!item.grace_period_end_date)
          err["grace_period_end_date"] = isRequired;
        if (!item.grace_period_end_date_ad)
          err["grace_period_end_date_ad"] = isRequired;
        if (!item.grace_period_interest_rate)
          err["grace_period_interest_rate"] = isRequired;
        if (!item.interest_rate_after_grace_period)
          err["interest_rate_after_grace_period"] = isRequired;

        //!Installment
        if (!item.installment_start_date)
          err["installment_start_date"] = isRequired;
        if (!item.installment_start_date_ad)
          err["installment_start_date_ad"] = isRequired;
        if (!item.installment_end_date)
          err["installment_end_date"] = isRequired;
        if (!item.installment_end_date_ad)
          err["installment_end_date_ad"] = isRequired;
        if (!item.installment_period) err["installment_period"] = isRequired;
        if (!item.no_of_installment) err["no_of_installment"] = isRequired;

        if (item.type !== "Grant Share") {
          //!IDC
          if (!item.idc_effective_date) err["idc_effective_date"] = isRequired;
          if (!item.idc_effective_date_ad)
            err["idc_effective_date_ad"] = isRequired;
          if (!item.idc_ending_date) err["idc_ending_date"] = isRequired;
          if (!item.idc_ending_date_ad) err["idc_ending_date_ad"] = isRequired;
          if (!item.idc_no_of_days) err["idc_no_of_days"] = isRequired;

          //!IDC Conversion
          if (item.idc_nea_portion < 0 || item.idc_nea_portion > 100)
            err["idc_nea_portion"] = shouldRangeBetween;
          if (isNaN(item.idc_nea_portion))
            err["idc_nea_portion"] = shouldContain;
          if (!item.idc_nea_portion) err["idc_nea_portion"] = isRate;

          if (item.idc_gon_portion < 0 || item.idc_gon_portion > 100)
            err["idc_gon_portion"] = shouldRangeBetween;
          if (isNaN(item.idc_gon_portion))
            err["idc_gon_portion"] = shouldContain;
          if (!item.idc_gon_portion) err["idc_gon_portion"] = isRate;

          if (item.idc_payable_to_gon < 0 || item.idc_payable_to_gon > 100)
            err["idc_payable_to_gon"] = shouldRangeBetween;
          if (isNaN(item.idc_payable_to_gon))
            err["idc_payable_to_gon"] = shouldContain;
          if (!item.idc_payable_to_gon) err["idc_payable_to_gon"] = isRate;

          if (
            item.idc_transfer_to_share < 0 ||
            item.idc_transfer_to_share > 100
          )
            err["idc_transfer_to_share"] = shouldRangeBetween;
          if (isNaN(item.idc_transfer_to_share))
            err["idc_transfer_to_share"] = shouldContain;
          if (!item.idc_transfer_to_share)
            err["idc_transfer_to_share"] = isRate;

          if (item.idc_transfer_to_cwip < 0 || item.idc_transfer_to_cwip > 100)
            err["idc_transfer_to_cwip"] = shouldRangeBetween;
          if (isNaN(item.idc_transfer_to_cwip))
            err["idc_transfer_to_cwip"] = shouldContain;
          if (!item.idc_transfer_to_cwip) err["idc_transfer_to_cwip"] = isRate;

          if (item.idc_transfer_to_loan < 0 || item.idc_transfer_to_loan > 100)
            err["idc_transfer_to_loan"] = shouldRangeBetween;
          if (isNaN(item.idc_transfer_to_loan))
            err["idc_transfer_to_loan"] = shouldContain;
          if (!item.idc_transfer_to_loan) err["idc_transfer_to_loan"] = isRate;
        }

        const doesErrorExist = Object.keys(err).find(
          (j) => typeof err[j] === "string"
        );

        if (doesErrorExist) {
          state.errors[item.loan_index] = err;
          status++;
        }
      }

      if (status !== 0) {
        state.isReady = false;
        return;
      }

      state.isReady = true;
      return;
    },
  },
});

export const {
  setProjectName,
  fillLoanData,
  setConfig,
  setController,
  resetLoan,
  setLoan,
  removeLoan,
  toggleKeyBoolean,
  deleteLoanKey,
  submitForm,
  updateLoan,
} = createProjectSlice.actions;

export default createProjectSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import CreateProjectReducer from "../Slice/CreateProject";

import AdminReducer from "../Slice/AdminSlice";
import AccountChiefReducer from "../Slice/AccountChiefSlice";
import SystemReducer from "../Slice/SystemSlice";
import ProjectManagerReducer from "../Slice/ProjectManagerSlice";

const store = configureStore({
  reducer: {
    //!Official
    CreateProject: CreateProjectReducer,

    System: SystemReducer,
    Admin: AdminReducer,
    AccountChief: AccountChiefReducer,
    ProjectManager: ProjectManagerReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

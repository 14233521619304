export const getAccessTokenFromLocalStorage = () => {
  let user = JSON.parse(localStorage.getItem("NEA-SoftwareAdminPanel"));
  return user?.token.access;
};
export const getRefreshTokenFromLocalStorage = () => {
  let user = JSON.parse(localStorage.getItem("NEA-SoftwareAdminPanel"));
  return user?.token.refresh;
};

export const setUserDetailsToLocalStorage = (userDetails) =>
  localStorage.setItem("NEA-SoftwareAdminPanel", JSON.stringify(userDetails));

export const getUserFromLocalStorage = () => {
  let user = JSON.parse(localStorage.getItem("NEA-SoftwareAdminPanel"));
  return user ? user : null;
};

export const setTokenToLocalStorage = (token) => {
  let user = JSON.parse(localStorage.getItem("NEA-SoftwareAdminPanel"));
  if (!user) return false;
  setUserDetailsToLocalStorage({
    token,
    user: user.user,
  });
  return true;
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("NEA-SoftwareAdminPanel");
  return !JSON.parse(localStorage.getItem("NEA-SoftwareAdminPanel"))
    ? true
    : false;
};

export const intlFormat = (amount) =>
  new Intl.NumberFormat("en-IN").format(amount);

import React, { useState } from "react";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { baseUrl } from "../../../../Constants/Constants";
import { DUMMY_USER } from "../../../../Constants/Images";
import { doGet, doPut } from "../../../../Services/Axios";
import ActionButton from "../../../../UI/Button/ActionButton";
import ModalLayout from "../../../../UI/Modal/ModalLayout";
import Preview from "../../../../UI/Preview/Preview";
import StatusBar from "../../../../UI/StatusBar/StatusBar";
import Table from "../../../../UI/Table/Table";
import View from "../../../../UI/View/View";
import { errorToast, successToast } from "../../../../Utils/Toastify";

export const fetchProjectManager = async (userid) => {
  try {
    const { data } = await doGet(`user/project-manager/view-all`);
    if (!data || !data.users || data.users.lenght < 1) return { users: [] };
    if (!userid) return data;

    const currentUser = data.users.find((i) => i.id === userid);
    return {
      users: [currentUser, ...data.users.filter((i) => i.id !== userid)],
    };
  } catch (error) {
    throw error;
  }
};
const ProjectManager = ({ onClose, project, refetch }) => {
  const [confirmation, setConfirmation] = useState(null);
  let Columns = [
    { name: "sn", sortable: true, searchable: true },
    { name: "image", sortable: true, searchable: true },
    { name: "employee_id", sortable: true, searchable: true },
    { name: "name", sortable: true, searchable: true },
    { name: "office_name", sortable: true, searchable: true },
    { name: "address", sortable: true, searchable: true },
    { name: "contact_number", sortable: true, searchable: true },
    { name: "level", sortable: true, searchable: true },
    { name: "username", sortable: true, searchable: true },
    { name: "action", sortable: false, searchable: false },
  ];
  const { data, isLoading } = useQuery({
    queryKey: "ProjectManager",
    queryFn: () => fetchProjectManager(project.project_manager),
    enabled: true,
    cacheTime: 5 * 60 * 1000,
  });

  const handleAssignProjectManager = async (item) => {
    try {
      await doPut("/sub-project/user/assign", {
        user_id: item.id,
        type: "Project Manager",
        sub_project_id: project.id,
        project_id: project.parent_id,
      });
      successToast("Project manager assigned");
      refetch();
      onClose(false);
    } catch (error) {
      errorToast("Error assigning project manager");
    }
  };
  return (
    project && (
      <ModalLayout onClose={onClose} title={"Assign Project Manager"}>
        {confirmation && (
          <ConfirmationModal
            onSuccess={() => (
              handleAssignProjectManager(confirmation), setConfirmation(null)
            )}
            onClose={() => setConfirmation(null)}
            text={
              "Are you sure you want to assign new employee to this sub-project?"
            }
            caption={
              "Accepting this will permanently apply this employee to this sub-project"
            }
          />
        )}
        <div className="assignaccount">
          <Table
            Columns={Columns}
            Rows={
              data && data.users && data.users.length > 0
                ? data.users.map((item, index) => ({
                    sn: index + 1,
                    images: (
                      <Preview
                        alt={item.name + "'s Image"}
                        file={
                          item.images
                            ? `${baseUrl.split("api/v1")[0]}storage/app/${
                                item.images
                              }`
                            : DUMMY_USER
                        }
                        clickable={item.images}
                      />
                    ),
                    employee_id: item.employee_id,
                    name: item.name,
                    office_name: item.office_name,
                    address: item.address,
                    contact_number: item.contact_number,
                    level: item.level,
                    username: item.username,
                    action: !item.status ? (
                      <View
                        customStyle={{
                          backgroundColor: "grey",
                        }}
                        label={"Unavailable"}
                        type={"button"}
                      />
                    ) : (
                      <View
                        customStyle={{
                          backgroundColor:
                            item.id === project.project_manager
                              ? "red"
                              : "green",
                        }}
                        label={
                          item.id === project.project_manager
                            ? "Assigned"
                            : "Assign"
                        }
                        onClick={() =>
                          item.id === project.project_manager
                            ? null
                            : setConfirmation(item)
                        }
                        type={"button"}
                      ></View>
                    ),
                  }))
                : []
            }
            loading={isLoading}
          ></Table>
        </div>
      </ModalLayout>
    )
  );
};

export default ProjectManager;

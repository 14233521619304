export default class NepaliDate {
  constructor() {
    this.NEPALI_NUMBER = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    this.NEPALI_WEEK = [
      "अाईतबार",
      "सोमबार",
      "मङ्गलबार",
      "बुधबार",
      "बिहिबार",
      "शुक्रबार",
      "शनिवार",
    ];
    this.ENLISH_WEEK = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    this.ENGLISH_MONTH = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.NEPALI_MONTH = [
      "वैशाख",
      "जेठ",
      "असार",
      "साउन",
      "भदौ",
      "असोज",
      "कार्तिक",
      "मङ्सिर",
      "पुस",
      "माघ",
      "फाल्गुण",
      "चैत",
    ];
    this.NEPALI_YEARS = {
      // 1975: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      // 1976: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      // 1977: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1978: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1979: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      // 1980: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      // 1981: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1982: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      // 1983: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      // 1984: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      // 1985: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1986: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      // 1987: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      // 1988: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      // 1989: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1990: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      // 1991: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      // 1992: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      // 1993: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1994: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      // 1995: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      // 1996: [31, 31, 32, 32, 31, 30, 29, 30, 30, 29, 30, 30],
      // 1997: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      // 1998: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      // 1999: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2000: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2001: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2002: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2003: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2004: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2005: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2006: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2007: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2008: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      2009: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2010: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2011: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2012: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2013: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2014: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2015: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2016: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2017: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2018: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2019: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2020: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2021: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2022: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2023: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2024: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2025: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2026: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2027: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2028: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2029: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
      2030: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2031: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2032: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2033: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2034: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2035: [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      2036: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2037: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2038: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2039: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2040: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2041: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2042: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2043: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2044: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2045: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2046: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2047: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2048: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2049: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2050: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2051: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2052: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2053: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2054: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2055: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2056: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
      2057: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2058: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2059: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2060: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2061: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2062: [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
      2063: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2064: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2065: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2066: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      2067: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2068: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2069: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2070: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2071: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2072: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2073: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2074: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2075: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2076: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2077: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2078: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2079: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2080: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2081: [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2082: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2083: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
      2084: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
      2085: [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
      2086: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2087: [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
      2088: [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
      2089: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2090: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2091: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2092: [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2093: [30, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2094: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
      2095: [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
      2096: [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2097: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
      2098: [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
      2099: [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30],
      2100: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2101: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2102: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2103: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2104: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2105: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2106: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2107: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2108: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      2109: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2110: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2111: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2112: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2113: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2114: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2115: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2116: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2117: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2118: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2119: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2120: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2121: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2122: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2123: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2124: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2125: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2126: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2127: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2128: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2129: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
      2130: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2131: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
      2132: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2133: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2134: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2135: [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
      2136: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2137: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2138: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2139: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2140: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2141: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2142: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2143: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
      2144: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2145: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
      2146: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
      2147: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
      2148: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
      2149: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
      2150: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    };
    this.FIRST_DAY = "1943-4-14";
    this.START_NP = 2000;
    this.START_EN = 1943;
    this.END_NP = 2150;
    this.END_EN = 2092;
    this.EN_MONTHS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  }

  get = () => ({
    year: this.NEPALI_YEARS,
    month: this.NEPALI_MONTH,
    week: this.NEPALI_WEEK,
    firstDay: this.FIRST_DAY,
    startNP: this.START_NP,
    startEN: this.START_EN,
    endNP: this.END_NP,
    endEN: this.END_EN,
  });

  validateNP = (year, month, day) => {
    if (!this.NEPALI_YEARS[year]) return false;
    if (month > 12 || month < 1) return false;
    if (day > this.NEPALI_YEARS[year][month - 1] || day < 1) return false;
    return true;
  };

  validateEN = (year, month, day) => {
    if (year < this.START_EN || year > this.END_EN) return false;
    if (month > 12 || month < 1) return false;
    if (day > new Date(year, month, 0).getDate() || day < 1) return false;
    return true;
  };

  getNepaliDateBack = (year, month, day) => {
    console.log(year, month, day);
    if (!this.validateEN(year, month, day)) return "Invalid Date";

    const providedDate = `${year}-${month}-${day}`;
    const startDate = new Date(this.FIRST_DAY);
    const endDate = new Date(providedDate);
    const difference = endDate - startDate;
    const days = Math.ceil(difference / (1000 * 3600 * 24));

    let totalDays = 0;
    let acquiredDays;

    for (let i = this.START_NP; i < this.END_NP; i++) {
      const element = this.NEPALI_YEARS[i];
      const currentTotalDays = element.reduce((a, i) => a + i, 0);
      totalDays += currentTotalDays;

      console.log(currentTotalDays);
      if (totalDays === days) {
        year = i + 1;
        month = 1;
        day = 1;
      }
      if (totalDays > days) {
        year = i;
        let prevTotalDays = totalDays - currentTotalDays;
        console.log(currentTotalDays);
        for (let j = 0; j < 12; j++) {
          prevTotalDays += this.NEPALI_YEARS[i][j];
          if (prevTotalDays >= days) {
            month = j + 1;
            console.log(
              year,
              month,
              day,
              this.NEPALI_YEARS[i][j],
              prevTotalDays,
              days
            );

            // if (prevTotalDays === days) {
            //   day = this.NEPALI_YEARS[i][j];
            //   console.log("here");
            // } else {
            const tempDays = prevTotalDays - days;
            acquiredDays = this.NEPALI_YEARS[i][j] - tempDays;
            // }
            break;
          }
        }
        break;
      }
    }

    return {
      year,
      month: month,
      day: acquiredDays || day,
    };
  };

  getNepaliDate = (year, month, day) => {
    if (!this.validateEN(year, month, day)) return "Invalid Date";

    // REFERENCE
    let np_year = this.START_NP;
    let np_month = 1;
    let np_day = 1;

    // DIFFERENCE
    // calculating days count from the reference date
    const [y, m, d] = this.FIRST_DAY.split("-");
    let difference = Math.abs(
      this._getTotalDaysFromEnglishDate(year, month, day) -
        this._getTotalDaysFromEnglishDate(+y, +m, +d)
    );

    // YEAR
    // Incrementing year until the difference remains less than 365
    let year_data_index = this.START_NP;
    console.log(year_data_index, this.NEPALI_YEARS[year_data_index]);
    while (
      difference >=
      this.NEPALI_YEARS[year_data_index].reduce((a, i) => a + i, 0)
    ) {
      difference -= this.NEPALI_YEARS[year_data_index].reduce(
        (a, i) => a + i,
        0
      );
      np_year += 1;
      year_data_index += 1;
    }

    // MONTH
    // Incrementing month until the difference remains less than next nepali month days (mostly 31)
    let i = 0;
    while (difference >= this.NEPALI_YEARS[year_data_index][i]) {
      difference -= this.NEPALI_YEARS[year_data_index][i];
      np_month += 1;
      i += 1;
    }

    // DAY
    // Remaining difference is the day
    np_day += difference;

    return { year: np_year, month: np_month, day: np_day };
  };

  _getTotalDaysFromEnglishDate = (year, month, day) => {
    let total_days = year * 365 + day;
    for (let i = 0; i < month - 1; i++) {
      total_days += this.EN_MONTHS[i];
    }

    if (month <= 2) {
      year -= 1;
    }
    total_days +=
      Math.floor(year / 4) - Math.floor(year / 100) + Math.floor(year / 400);

    return total_days;
  };

  getEnglishDate = (year, month, day) => {
    if (!this.validateNP(year, month, day)) return "Invalid Date";

    const startDate = new Date(this.FIRST_DAY);
    let dayCount = 0;
    const months = month - 1;
    let i = this.START_NP;
    for (i; i < year; i++) {
      dayCount += this.NEPALI_YEARS[i].reduce((a, item) => a + item, 0);
    }
    for (let j = 0; j < months; j++) {
      dayCount += this.NEPALI_YEARS[i][j];
    }
    dayCount += day - 1;

    const nep = new Date(startDate.setDate(startDate.getDate() + dayCount));
    return nep;
  };

  getTodayDate = () => {
    const date = new Date();
    const data = {
      AD: null,
      BS: null,
    };
    data.AD = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    data.BS = this.getNepaliDate(data.AD.year, data.AD.month, data.AD.day);

    return data;
  };
  getNepaliFormat = (number) => {
    if (isNaN(number)) return number;
    const newData = number
      .toString()
      .split("")
      .map((i) => this.NEPALI_NUMBER[+i])
      .join("");

    return newData;
  };
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalLayout from "../../../../UI/Modal/ModalLayout";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";
import CreateLoan from "../../../../Components/CreateLoan/CreateLoan";
import Select from "../../../../UI/Select/Select";
import AddLoanController from "./_addloan";
import { useEffect } from "react";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { toggleLoader } from "../../../../Slice/SystemSlice";
import { errorToast, successToast } from "../../../../Utils/Toastify";
import useForex from "../../../../Hooks/useForex";

const AddLoanModal = ({ onClose, data, refetch, defaultCurrency }) => {
  const { getConversionRate } = useForex();
  const dispatch = useDispatch();
  const { id, source } = data;
  const { loanType, validateForm, submitForm } = AddLoanController;

  const [selectedLoanType, setSelectedLoanType] = useState(
    Object.keys(loanType[source]).map((i) => loanType[source][i])[0].key
  );
  const [loan, setLoan] = useState(null);
  const [error, setError] = useState({});

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);

  const handleLoan = (data, error) => {
    setLoan(data);
    setError(error);
  };

  useEffect(() => {
    setLoan({ key: Date.now(), type: selectedLoanType, project_id: id });
  }, [selectedLoanType]);

  const initiateSubmit = async () => {
    dispatch(toggleLoader(true));
    const response = validateForm(loan);
    setError(response.errors);
    setLoan(response.loan);
    if (!response.status) {
      errorToast("Validation Failed");
      return dispatch(toggleLoader(false));
    }

    try {
      await submitForm(loan, getConversionRate);
      successToast("Loan Created");
      onClose(false);
      refetch();
    } catch (error) {
      errorToast(error.message);
    }

    dispatch(toggleLoader(false));
    return;
  };

  const handleConfirmationModal = (action) => {
    setConfirmationModal(true);
    switch (action) {
      case "BACK":
        setConfirmationDetails({
          text: "Are you sure you want to cancel?",
          caption: "All data will be reset",
          action: () => onClose(false),
        });
        break;
      case "SAVE":
        setConfirmationDetails({
          text: "Are you sure you want to save this loan/grant?",
          caption: "Please verify all details before clicking yes",
          action: () => initiateSubmit(),
        });
        break;
      default:
        break;
    }
  };

  return (
    loan &&
    loanType &&
    id &&
    source && (
      <ModalLayout
        title={"Add Loan/Grant"}
        onClose={() => handleConfirmationModal("BACK")}
        isLanguageChangeable={true}
      >
        {confirmationModal && confirmationDetails && (
          <ConfirmationModal
            onSuccess={() => (
              confirmationDetails.action(), setConfirmationModal(false)
            )}
            onClose={() => setConfirmationModal(false)}
            text={confirmationDetails.text}
            caption={confirmationDetails.caption}
          />
        )}
        <div className="addloan-header">
          <div className="addloan-header__type">
            <Select
              options={Object.keys(loanType[source]).map(
                (i) => loanType[source][i]
              )}
              handler={(e) => setSelectedLoanType(e.target.value)}
              defaultValue={selectedLoanType}
            />
          </div>
          <PrimaryButton
            title={"Save Now"}
            content={"Save Now"}
            onClick={() => handleConfirmationModal("SAVE")}
          />
        </div>
        <div className="addloan-main">
          <CreateLoan
            defaultCurrency={
              defaultCurrency && defaultCurrency[0]
                ? [...new Set(defaultCurrency.map((i) => i.donor_currency))]
                : null
            }
            loan={loan}
            errors={error}
            changeHandler={handleLoan}
          />
        </div>
      </ModalLayout>
    )
  );
};

export default AddLoanModal;

import React, { useEffect, useRef, useState } from "react";
import { doGet } from "../../../Services/Axios";
import { errorToast } from "../../../Utils/Toastify";
import { useQuery } from "react-query";
import Input from "../../../UI/Input/Input";
import useComponentVisible from "./useVisible";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import Select from "../../../UI/Select/Select";
import NEA from "../../../Assets/Images/NEA_COLOR.png";
import DateInput from "../../../UI/DateInput/DateInput";
import { useSelector } from "react-redux";
const ProjectWiseDirectPayment = () => {
  const tableRef = useRef();
  const { nepaliDate } = useSelector((state) => state.System);
  const todaysDate = nepaliDate.getTodayDate().BS;
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedResponseProject, setSelectedResponseProject] = useState([]);
  const [dateState, setDateState] = useState();

  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [fiteredProjects, setFilteredProjects] = useState([]);
  const [reportType, setReportType] = useState();
  const fetchProject = async (pageParam) => {
    try {
      const { data } = await doGet(`/project/all-list`);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllProjects"],
    queryFn: () => fetchProject(),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching projects"),
  });
  const fetchReport = async (date) => {
    try {
      if (
        !date ||
        !/^\d{4}\/(?!0000)(\d{3}[1-9]|\d[1-9]\d{2}|\d{2}[1-9]\d|\d{1}[1-9]\d{2})$/.test(
          date
        )
      ) {
        setSelectedProject("");
        setFilterTerm("");
        return errorToast("Please Enter valid date");
      }
      const { data } = await doGet(
        `reports/${date.split("/")[0]}%252f${date.split("/")[1]}/direct_payment`
      );

      data && Object.values(data.direct_payment_loan).length > 0
        ? setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_loan: Object.values(data.direct_payment_loan),
          }))
        : setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_loan: [],
          }));

      data && Object.values(data.direct_payment_share).length > 0
        ? setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_share: Object.values(data.direct_payment_share),
          }))
        : setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_share: [],
          }));
    } catch (error) {
      errorToast(
        error.message === "date is undefined"
          ? "Please enter valid fiscal year"
          : "Error while fetching report"
      );
      setSelectedProject("");
      setFilterTerm("");
      setIsComponentVisible(false);
    }
  };
  // const handleSelect = async (item) => {
  //   try {
  //     setFilterTerm(item.name);
  //     setSelectedProject(item);
  //     fetchReport(item.id);
  //     setIsComponentVisible(false);
  //   } catch (error) {}
  // };
  // const handleFilter = (e) => {
  //   setFilterTerm(e.target.value);
  // };
  useEffect(() => {
    setFilteredProjects(
      data &&
        data.projects &&
        filterTerm !== null &&
        data.projects.filter((el) =>
          el.name.toLowerCase().includes(filterTerm.toLowerCase())
        )
    );
    selectedProject &&
      selectedProject.name !== filterTerm &&
      setIsComponentVisible(true);
  }, [
    data,
    filterTerm,
    selectedProject && selectedProject.name,
    selectedProject && selectedProject.created_at,
  ]);

  const handleDateChange = (e) => {
    const { value } = e.target;
    setDateState(value);
    fetchReport(value);
  };

  const nepali = {
    0: "\u0966", // ०
    1: "\u0967", // १
    2: "\u0968", // २
    3: "\u0969", // ३
    4: "\u096A", // ४
    5: "\u096B", // ५
    6: "\u096C", // ६
    7: "\u096D", // ७
    8: "\u096E", // ८
    9: "\u096F", //
  };
  const projectsFromLoan = selectedResponseProject.direct_payment_loan
    ? selectedResponseProject.direct_payment_loan.map(
        (item) => item.project_name
      )
    : [];
  const projectsFromShare = selectedResponseProject.direct_payment_share
    ? selectedResponseProject.direct_payment_share.map((el) => el.project_name)
    : [];
  const AllProjects = Array.from(
    new Set([...projectsFromLoan, ...projectsFromShare])
  );

  function numberConverter(value) {
    if (!value) return "";

    return value
      ? `${value}`
          .split("")
          .map((el) => nepali[el] || el)
          .join("")
      : "";
  }
  console.log(AllProjects);
  return (
    <>
      <div className="directpayment">
        <div className="directpayment-heading">
          <div className="directpayment-heading--title">Direct Payment</div>
          <div className="directpayment-heading--actions">
            <div className="directpayment-heading--actions__date">
              <Select
                options={Object.keys(nepaliDate.NEPALI_YEARS).map((i) => ({
                  key: `${i}/${+i + 1}`,
                }))}
                defaultValue={dateState}
                handler={handleDateChange}
                name={"fiscal_year"}
                preventMouse
                width={"15rem"}
              />
            </div>
            <DownloadTableExcel
              currentTableRef={tableRef.current}
              filename={`DirectPayment_${Date.now()}`}
              sheet="Direct Payment"
            >
              <PrimaryButton
                content={"Export"}
                title="Donor Info"
                icon={"fa-light fa-file-spreadsheet"}
                className={"directpayment-heading--actions__export"}
              />
            </DownloadTableExcel>
          </div>
        </div>

        <div className="directpayment-wrapper">
          <table
            width={"100%"}
            className="directpayment-wrapper--table"
            ref={tableRef}
            border={1}
          >
            <thead>
              <tr>
                <th colSpan={9}>
                  <h1>नेपाल विद्युत प्राधिकरण</h1>
                </th>
              </tr>
              <tr>
                <th colSpan={9}>अर्थ निर्देशनालय</th>
              </tr>
              <tr>
                <th colSpan={9}>संस्थागत वित्तिय विभाग</th>
              </tr>
              <tr>
                <th colSpan={9}>वित्तिय योजना तथा लगानी महाशाखा</th>
              </tr>
              <tr>
                <th colSpan={9}>
                  आ.व.{" "}
                  {dateState &&
                    dateState
                      .split("")
                      .map((el) => nepali[el] || "/")
                      .join("")}{" "}
                  को पहिलो अर्धबार्षिक पौष मसान्त सम्मको बैदेशिक सहायताबाट
                  संचालित आयोजनाहरुको (बैदेशिक अनुदान (शेयर) तथा ऋण सहयोग गर्ने
                  एजेन्सी/बैंकवाईज) सोझै भुक्तानी खर्च विवरण
                </th>
              </tr>
              <tr>
                <th rowSpan={2}>क्र.सं.</th>

                <th rowSpan={2}>बजेट उप शिर्षक नं.</th>
                <th rowSpan={2}>आयोजनाको नाम</th>
                <th rowSpan={2}>स्रोत</th>
                <th rowSpan={2}>बजेट रकम (रू. लाखमा)</th>
                <th rowSpan={2}>निकाशा विधि</th>

                <th colSpan={2}>
                  {dateState &&
                    dateState
                      .split("")
                      .map((el) => nepali[el] || "/")
                      .join("")}{" "}
                  खर्च रकम
                </th>
                <th rowSpan={2}>कैफियत</th>
              </tr>
              <tr>
                <th>शेयर रकम रू.</th>
                <th>ऋण रकम रू.</th>
              </tr>
            </thead>
            <tbody>
              {AllProjects.map((el, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        rowSpan={
                          +(selectedResponseProject.direct_payment_loan
                            ? selectedResponseProject.direct_payment_loan.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          +(selectedResponseProject.direct_payment_share
                            ? selectedResponseProject.direct_payment_share.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          1
                        }
                      >
                        {numberConverter(index + 1)}
                      </td>
                      <td
                        rowSpan={
                          +(selectedResponseProject.direct_payment_loan
                            ? selectedResponseProject.direct_payment_loan.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          +(selectedResponseProject.direct_payment_share
                            ? selectedResponseProject.direct_payment_share.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          1
                        }
                      >
                        {selectedResponseProject &&
                        selectedResponseProject.direct_payment_share
                          ? selectedResponseProject.direct_payment_share.filter(
                              (elx) => elx.project_name === el
                            ).length > 0
                            ? selectedResponseProject.direct_payment_share.filter(
                                (elx) => elx.project_name === el
                              )[0].budget_head
                            : selectedResponseProject &&
                              selectedResponseProject.direct_payment_loan
                            ? selectedResponseProject.direct_payment_loan.filter(
                                (elx) => elx.project_name === el
                              ).length > 0
                              ? selectedResponseProject.direct_payment_loan.filter(
                                  (elx) => elx.project_name === el
                                )[0].budget_head
                              : "-"
                            : "-"
                          : "-"}
                      </td>
                      <td
                        rowSpan={
                          +(selectedResponseProject.direct_payment_loan
                            ? selectedResponseProject.direct_payment_loan.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          +(selectedResponseProject.direct_payment_share
                            ? selectedResponseProject.direct_payment_share.filter(
                                (i) => i.project_name === el
                              ).length
                            : 0) +
                          1
                        }
                      >
                        {el}
                      </td>
                    </tr>

                    <>
                      {selectedResponseProject &&
                      selectedResponseProject.direct_payment_loan
                        ? selectedResponseProject.direct_payment_loan
                            .filter((elx) => elx.project_name === el)
                            .map((item, index) => (
                              <tr key={index}>
                                <td>{item.agency_shrot}</td>

                                <td>
                                  {numberConverter(
                                    (+item.budget_amount_npr / 100000).toFixed(
                                      3
                                    )
                                  )}
                                </td>
                                <td>{item.budget_re_imburse}</td>
                                <td></td>
                                <td>{numberConverter(item.total_loan)}</td>
                                <td></td>
                              </tr>
                            ))
                        : []}
                    </>

                    <>
                      {selectedResponseProject &&
                      selectedResponseProject.direct_payment_share
                        ? selectedResponseProject.direct_payment_share
                            .filter((elx) => elx.project_name === el)
                            .map((item, index) => (
                              <tr key={index}>
                                <td>{item.agency_shrot}</td>

                                <td>
                                  {numberConverter(
                                    +item.budget_amount_npr / 100000
                                  )}
                                </td>
                                <td>{item.budget_re_imburse}</td>
                                <td>{numberConverter(item.total_share)}</td>
                                <td></td>
                                <td></td>
                              </tr>
                            ))
                        : []}
                    </>
                  </>
                );
              })}
              <tr style={{ backgroundColor: "#80808036" }}>
                <td></td>
                <td></td>
                <td></td>
                <td>जम्मा</td>

                <td>
                  {numberConverter(
                    (
                      (+(selectedResponseProject &&
                      selectedResponseProject.direct_payment_loan
                        ? selectedResponseProject.direct_payment_loan.reduce(
                            (sum, first) => +first.budget_amount_npr + sum,
                            0
                          )
                        : 0) +
                        +(selectedResponseProject &&
                        selectedResponseProject.direct_payment_share
                          ? selectedResponseProject.direct_payment_share.reduce(
                              (sum, first) => +first.budget_amount_npr + sum,
                              0
                            )
                          : 0)) /
                      100000
                    ).toFixed(3)
                  )}
                </td>
                <td></td>
                <td>
                  {selectedResponseProject &&
                  selectedResponseProject.direct_payment_share
                    ? selectedResponseProject.direct_payment_share.reduce(
                        (sum, first) => +first.total_share + sum,
                        0
                      ) == 0
                      ? ""
                      : selectedResponseProject.direct_payment_share.reduce(
                          (sum, first) => +first.total_share + sum,
                          0
                        )
                    : ""}
                </td>
                <td>
                  {numberConverter(
                    selectedResponseProject &&
                      selectedResponseProject.direct_payment_loan
                      ? selectedResponseProject.direct_payment_loan.reduce(
                          (sum, first) => +first.total_loan + sum,
                          0
                        ) == 0
                        ? ""
                        : selectedResponseProject.direct_payment_loan.reduce(
                            (sum, first) => +first.total_loan + sum,
                            0
                          )
                      : ""
                  )}
                </td>
                <td></td>
              </tr>

              {(!selectedResponseProject ||
                !selectedResponseProject.direct_payment_loan) &&
                (!selectedResponseProject ||
                  !selectedResponseProject.direct_payment_share) && (
                  <tr>
                    <td colSpan={"100%"}>No Data Found</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default ProjectWiseDirectPayment;

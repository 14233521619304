import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
import { doPost } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import { errorToast } from "../../../Utils/Toastify";

const AddAccountChief = ({ onClose }) => {
  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const handleSubmitImage = (e) => {
    setImages(e);
  };
  const initialState = {
    username: "",
    newPassword: "",
    confirmPassword: "",
    user_type: "",
    employee_id: "",
    email: "",
    name: "",
    office_name: "",
    address: "",
    contact_number: "",
  };
  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        const formData = new FormData();
        formData.append("user_type", "Account Chief");
        formData.append("level", "1");
        Object.keys(states).forEach((item) => {
          ["user_type", "level", "password"].indexOf(item) < 0 &&
            formData.append(item, states[item]);
        });
        formData.append("password", states.newPassword);
        formData.append("email", states.email);
        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }
        await doPost("/user/account-chief/create", formData);
      } else {
        throw Error("Validation failed");
      }
    } catch (error) {
      throw error;
    }
  };

  const { mutate } = useMutation({
    mutationKey: "AccountChief",
    mutationFn: handleSubmit,
    onError: (error) => {
      if (error && error.message && typeof error.message === "string") {
        return errorToast(error.message);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        return toast.error("Error while adding account chief!!");
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("AccountChief");
      toast.success("Account chief added successfully");
      onClose();
    },
  });
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };
  console.log(states);
  return (
    <ModalLayout title={"Add Account Chief"} onClose={handleClose}>
      <div className="addac-main">
        {confirmationModal && (
          <ConfirmationModal
            text={"Do you really  want to add account chief ??"}
            caption={"Please verify all details before clicking yes"}
            onClose={() => setConfirmationModal(false)}
            onSuccess={() => {
              mutate();
              setConfirmationModal(false);
            }}
          />
        )}
        {closeConfirmationModal && (
          <ConfirmationModal
            caption={"this will remove all filled data"}
            text={"Do you really  want to cancel adding account chief ??"}
            onClose={() => setCloseConfirmationModal(false)}
            onSuccess={() => {
              setConfirmationModal(false);
              onClose();
            }}
          />
        )}
        <div className="addac-main--submit">
          <PrimaryButton
            content={"Create"}
            onClick={() => setConfirmationModal(true)}
            icon={"fa-regular fa-plus"}
          />
        </div>
        <Input
          placeholder={"Account Chief Id"}
          label={"Account Chief Id"}
          onChange={handleChange}
          name={"employee_id"}
          error={errors.employee_id}
        />
        <Input
          placeholder={"Account Chief Name"}
          label={"Account Chief Name"}
          onChange={handleChange}
          name="name"
          error={errors.name}
        />
        <Input
          placeholder={"User Id"}
          label={"User Id"}
          onChange={handleChange}
          name="username"
          error={errors.username}
        />
        <Input
          placeholder={"Email"}
          label={"Email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
        />

        <Input
          placeholder={"Derectrate/Office Name"}
          label={"Derectrate/Office Name"}
          onChange={handleChange}
          name="office_name"
          error={errors.office_name}
        />

        <Input
          placeholder={"Address"}
          label={"Address"}
          onChange={handleChange}
          name="address"
          error={errors.address}
        />
        <Input
          placeholder={"Contact Number"}
          label={"Contact Number"}
          onChange={handleChange}
          name="contact_number"
          error={errors.contact_number}
          intlNotRequired={true}
        />

        <Input
          placeholder={"password"}
          label={"password"}
          onChange={handleChange}
          name="newPassword"
          type={"password"}
          error={errors.newPassword}
        />
        <Input
          placeholder={"confirm Password"}
          label={"confirm Password"}
          name="confirmPassword"
          type="password"
          onChange={handleChange}
          error={errors.confirmPassword}
        />
        <div className="addac-main--single">
          <FileInput isSingle={true} handleSubmitImage={handleSubmitImage} />
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddAccountChief;

import React, { useState } from "react";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import DateInput from "../../../UI/DateInput/DateInput";
import { doPut } from "../../../Services/Axios";
import { errorToast, successToast } from "../../../Utils/Toastify";
import { useQueryClient } from "react-query";

export default function CloseProjectModal({ onClose, item }) {
  const [date, setDate] = useState({});
  const queryClient = useQueryClient();
  const handleDate = (e) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    try {
      const { data } = await doPut("/sub-project/close", {
        ...date,
        sub_project_id: item.id,
      });
      successToast("Requested for closing project");
      queryClient.refetchQueries(["AC_SubProjects"]);
      onClose();
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <ModalLayout title={"Close Project"} onClose={onClose}>
      <div className="closeproject">
        <div className="closeproject-title">
          Are you sure you want to close this project?
        </div>
        <div className="closeproject-date">
          <DateInput
            onChange={handleDate}
            name={"loan_repayment_effective_date"}
            label={"Loan Repayment Effective Date"}
            customDate={date.loan_repayment_effective_date_ad}
            required
          />
        </div>
        <div className="closeproject-option">
          <div className="closeproject-option--cross" onClick={onClose}>
            No
          </div>
          <div className="closeproject-option--check" onClick={handleSubmit}>
            Yes
          </div>
        </div>
      </div>
    </ModalLayout>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { doGet } from "../../../../Services/Axios";
import { errorToast } from "../../../../Utils/Toastify";
import { useQuery } from "react-query";
import Input from "../../../../UI/Input/Input";
import useComponentVisible from "../useVisible";
import Select from "../../../../UI/Select/Select";
import CWIPLedger from "./CWIPLedger";
import ContractLedger from "./ContractLedger";
import DisbursementLedger from "./DisbursementLedger";
import ContractIDCLedger from "./ContractIDCLedger";
import IDCCONVERSION from "./IDCconversion";
import ForexConversion from "./ForexConversion";
import ContractLoan from "./ContractLoan";
import ContractRetentionLedger from "./ContractRetentionLedger";
import ContractAdvanceLedger from "./ContractAdvanceLedger";
import ContractVatLedger from "./ContractVatLedger";
import ContractNonResident from "./ContractNonResident";
import ContractTDSLedger from "./ContractTDSLedger";
import ContractPenalty from "./ContractPenalty";
import ForexLedger from "./ForexLedger";
import LoanContractProfile from "./LoanContractProfile";
import CustomDutyLedger from "./CustomDutyLedger";

const Ledger = () => {
  const [ledgerType, setLedgerType] = useState("Select Ledger");
  const [selectedProject, setSelectedProject] = useState(null);
  const [subProjeccts, setSubProjects] = useState([]);
  const [subSelectedProjeccts, setSelectedSubProjects] = useState();
  const [selectedResponseProject, setSelectedResponseProject] = useState(null);
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [fiteredProjects, setFilteredProjects] = useState([]);
  const fetchProjects = async (pageParam) => {
    try {
      const { data } = await doGet(`/project/all-lists`);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllProjects-List"],
    queryFn: () => fetchProjects(),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching projects"),
  });
  const fetchReport = async (id) => {
    try {
      const { data } = await doGet(
        `reports/ctr_loan_ledger/${id}/loan_grant_contract_profile`
      );
      setSelectedResponseProject(data);
    } catch (error) {}
  };
  const handleSelect = async (item) => {
    try {
      fetchReport(item.id);
      setSelectedProject(item);
      setFilterTerm(`${item.name} - Office Code (${item.office_code})`);
      setIsComponentVisible(false);
    } catch (error) {}
  };
  const handleFilter = (e) => {
    setFilterTerm(e.target.value);
    e.target.value === "" && setSelectedProject("");
    setSubProjects([]);
  };
  useEffect(() => {
    setFilteredProjects(
      data &&
        data.projects &&
        filterTerm !== null &&
        data.projects.filter((el) =>
          el.name.toLowerCase().includes(filterTerm.toLowerCase())
        )
    );
    selectedProject &&
      selectedProject.name !== filterTerm &&
      setIsComponentVisible(true);
  }, [data, filterTerm]);
  const OPTIONS = [
    {
      key: "Select Ledger",
    },
    {
      key: "LOAN/CONTRACT PROFILE",
    },
    {
      key: "CONTRACT CWIP LEDGER",
    },
    {
      key: "CONTRACT LEDGER",
    },
    {
      key: "DISBURSEMENT LEDGER",
    },
    {
      key: "CONTRACT IDC LEDGER",
    },
    {
      key: "IDC CONVERSION LEDGER",
    },
    {
      key: "FOREX LEDGER",
    },
    {
      key: "CONTRACT LOAN LEDGER",
    },
    {
      key: "CONTRACT ADVANCE LEDGER",
    },
    {
      key: "CONTRACT RETENTION LEDGER",
    },
    {
      key: "CONTRACT VAT LEDGER",
    },
    {
      key: "CONTRACT 5% TAX  LEDGER FOR NON RESIDENT",
    },
    {
      key: "CONTRACT 1.5 %  TDS  LEDGER",
    },
    {
      key: "CONTRACT LD (PENALTY)  LEDGER",
    },
    // {
    //   key: "CONTRACT CUSTOM DUTY  LEDGER",
    // },
    // {
    //   key: "CONTRACT CUSTOM REBATE LEDGER",
    // },
    // {
    //   key: "CONTRACT CUSTOM LC  LEDGER",
    // },
  ];

  const fetchSubProject = async (item) => {
    try {
      const response = await doGet(`/sub-project/${item.id}/show`);
      setSubProjects(response.data.sub_project.data);
    } catch (error) {}
  };

  return (
    <>
      <div className="ledger">
        <div className="ledger-heading">
          <div className="ledger-heading--title">Ledger</div>
          <div className="ledger-heading--actions">
            <div className="ledger-heading--actions__wrapper">
              <div className="ledger-heading--actions__main" ref={ref}>
                <div className="ledger-heading--actions__input--wrapper">
                  <Input
                    className="ledger-heading--actions__input"
                    placeholder={"Select Project"}
                    onChange={handleFilter}
                    defaultValue={filterTerm}
                    onFocus={() => {
                      setIsComponentVisible(true);

                      setFilteredProjects(
                        data && data.projects
                          ? data.projects.filter((item) =>
                              item.name
                                .toLowerCase()
                                .includes(filterTerm.toLowerCase())
                            )
                          : []
                      );
                    }}
                    customStyle={{
                      all: "unset",
                      border: "none",
                    }}
                  />
                  {fiteredProjects &&
                    fiteredProjects.length > 0 &&
                    isComponentVisible && (
                      <div className="ledger-heading--actions__dropdown">
                        <div className="ledger-heading--actions__dropdown--relative">
                          <div className="ledger-heading--actions__dropdown-project">
                            {fiteredProjects.map((el, index) => (
                              <div
                                className="ledger-heading--actions__dropdown-project-item"
                                key={index}
                                onClick={() => handleSelect(el)}
                              >
                                {el.name}
                              </div>
                            ))}
                          </div>
                          {subProjeccts.length > 0 && (
                            <div className="ledger-heading--actions__dropdown-subproject">
                              {subProjeccts.map((item, index) => (
                                <div
                                  key={index}
                                  className="ledger-heading--actions__dropdown-subproject--item"
                                  onClick={() => handleSelect(item)}
                                >
                                  {item.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
                <div className="ledger-heading--actions__select">
                  <Select
                    options={OPTIONS}
                    defaultValue={ledgerType}
                    handler={(e) => setLedgerType(e.target.value)}
                    width="25rem"
                    preventMouse
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ledger-wrapper">
          {ledgerType === "CONTRACT LEDGER" && (
            <ContractLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT CWIP LEDGER" && (
            <CWIPLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "DISBURSEMENT LEDGER" && (
            <DisbursementLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT IDC LEDGER" && (
            <ContractIDCLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "IDC CONVERSION LEDGER" && (
            <IDCCONVERSION selectedProject={selectedProject} />
          )}
          {ledgerType === "FOREX LEDGER" && (
            <ForexLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "FOREX CONVERSION LEDGER" && (
            <ForexConversion selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT LOAN LEDGER" && (
            <ContractLoan selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT RETENTION LEDGER" && (
            <ContractRetentionLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT 1.5 %  TDS  LEDGER" && (
            <ContractTDSLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "LOAN/CONTRACT PROFILE" && (
            <LoanContractProfile selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT 5% TAX  LEDGER FOR NON RESIDENT" && (
            <ContractNonResident selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT VAT LEDGER" && (
            <ContractVatLedger selectedProject={selectedProject} />
          )}
          {ledgerType === "CONTRACT LD (PENALTY)  LEDGER" && (
            <ContractPenalty selectedProject={selectedProject} />
          )}

          {ledgerType === "CONTRACT ADVANCE LEDGER" && (
            <ContractAdvanceLedger selectedProject={selectedProject} />
          )}
          {/* {ledgerType === "CONTRACT CUSTOM REBATE LEDGER" && (
            <ContractRebateLedger
              selectedProject={selectedProject}
            />
          )} */}
          {/* {ledgerType === "CONTRACT CUSTOM DUTY  LEDGER" && (
            <CustomDutyLedger selectedProject={selectedProject} />
          )} */}

          {/* {ledgerType === "CONTRACT CUSTOM LC  LEDGER" && (
            <ContractCustomLC
              selectedProject={selectedProject}
            />
          )*/}

          {!ledgerType ||
            (ledgerType == "Select Ledger" && (
              <p className="notfound">Please select any ledger</p>
            ))}
        </div>
      </div>
    </>
  );
};
export default Ledger;

import React from "react";

export default function StatusBar({ status, handler, isProgress, disabled }) {
  const criteria = {
    1: "Running",
    2: "",
  };
  return (
    <div
      className={`statusbar statusbar-${
        isProgress
          ? status == 3
            ? "completed"
            : "running"
          : status
          ? "active"
          : "inactive"
      }`}
      onClick={!disabled ? (handler ? handler : null) : null}
    >
      {isProgress
        ? status == 3
          ? "Completed"
          : "Running"
        : status
        ? "Active"
        : "Inactive"}
    </div>
  );
}

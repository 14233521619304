import React from "react";
import ModalLayout from "../../../UI/Modal/ModalLayout";

const ForexModal = ({ onClose, forex }) => {
  const TITLE = [
    { key: "forex risk bearer", value: "forex_risk_bearer" },
    { key: "forex risk bearer type", value: "forex_risk_bearer_type" },
  ];

  return (
    <ModalLayout onClose={onClose} title="Forex Details">
      <div className="viewloan-donordetails">
        <div className="viewloan-donordetails--grid">
          {TITLE.map((item) => (
            <div key={item} className="viewloan-donordetails--grid__item">
              <div className="viewloan-donordetails--grid__item-key">
                {item.key} -
              </div>
              <div className="viewloan-donrdetails--grid__item-value">
                {forex[item.value] ? forex[item.value] : "N/A"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default ForexModal;

import { doGet } from "../../../../Services/Axios";

const BillingController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "loan number", sortable: true, searchable: true },
    { name: "contractor name", sortable: true, searchable: true },
    { name: "contract number", sortable: true, searchable: true },
    { name: "transaction type", sortable: true, searchable: false },
    { name: "particular", sortable: true, searchable: true },
    { name: "forex conversion basis", sortable: true, searchable: false },
    { name: "bill type", sortable: true, searchable: false },
    { name: "Bill details", sortable: true, searchable: false },
    { name: "documents", sortable: true, searchable: true },
    { name: "created at", sortable: false, searchable: false },
  ],
  fetchSubProjectBill: async (id) => {
    try {
      const { data } = await doGet(`/bills-infos/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  statusEnums: {
    0: { label: "In Progress", color: "#f1d900" },
    1: { label: "Approved", color: "#1fb626" },
    2: { label: "Rejected", color: "#c71c1c" },
  },
};

export default BillingController;

import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../../Components/TopBar/TopBar";
import { useSelector } from "react-redux";
import StatusBar from "../../../UI/StatusBar/StatusBar";
import Table from "../../../UI/Table/Table";

import { errorToast, successToast } from "../../../Utils/Toastify";

import SubProjectController from "./_acsubProject";
import View from "../../../UI/View/View";
import ContractModal from "../../../Components/ContractModal/ContractModal";
import UpdatePreInformation from "./UpdatePreInformation/ViewPreInformation";
import ViewPreInformation from "./PreInformation/ViewPreInformation";
import { doPut } from "../../../Services/Axios";
import CloseProjectModal from "./CloseProjectModal";

const AC_SubProjects = () => {
  const { user } = useSelector((state) => state.System);
  const { id } = useParams();
  const navigate = useNavigate();
  const [closeProjectModal, setCloseProjectModal] = useState(null);

  useLayoutEffect(() => {
    if (!id) return navigate(-1);
  }, []);

  const { Columns, fetchSubProject, statusEnums, handleFetchContractor } =
    SubProjectController;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AC_SubProjects"],
    queryFn: () => fetchSubProject(user.user.id, "Account Chief"),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching subprojects"),
  });
  const [preInformation, setPreInformation] = useState();
  const [showEditPreInformation, setShowEditPreInformation] = useState();
  const [showPreInformation, setShowPreInformation] = useState();

  const handleEditPreInformation = (item) => {
    setPreInformation(item);
    setShowEditPreInformation(true);
  };
  const handleShowPreInformation = (item) => {
    setPreInformation(item);
    setShowPreInformation(true);
  };
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="viewsubproject">
      {closeProjectModal && (
        <CloseProjectModal
          onClose={() => setCloseProjectModal(null)}
          item={closeProjectModal}
        />
      )}
      {showPreInformation && preInformation && (
        <ViewPreInformation
          onClose={() => setShowPreInformation(false)}
          subproject={preInformation}
        />
      )}
      {showEditPreInformation && preInformation && (
        <UpdatePreInformation
          onClose={() => setShowEditPreInformation(false)}
          subproject={preInformation}
        />
      )}

      <TopBar title={"Sub Project"} subtitle={"Sub Project"} backPage={true} />
      <div className="viewsubproject-main">
        <div className="viewsubproject-main--table">
          <Table
            Columns={Columns}
            Rows={
              data &&
              data.sub_project_details &&
              data.sub_project_details.length > 0
                ? data.sub_project_details
                    .filter((i) => i.parent_id == id)
                    .map((item, index) => ({
                      sn: index + 1,
                      project_name: item.name,

                      pre_information: statusEnums[item.progress_status] ? (
                        <View
                          label={
                            <span>
                              <i className="fa-regular fa-eye"></i>
                              {statusEnums[item.progress_status].label}
                            </span>
                          }
                          onClick={() =>
                            item.progress_status == "2" ||
                            item.progress_status == "1"
                              ? handleShowPreInformation(item)
                              : handleEditPreInformation(item)
                          }
                          type="button"
                          customStyle={{
                            backgroundColor:
                              statusEnums[item.progress_status].color,
                          }}
                        />
                      ) : (
                        "N/A"
                      ),
                      contractor:
                        item.progress_status == "2" ? (
                          <View
                            label="Manage"
                            to={`/ac/contractor/${item.id}`}
                          />
                        ) : (
                          "N/A"
                        ),
                      budget:
                        item.progress_status == "2" ? (
                          <View label="Manage" to={`/ac/budget/${item.id}`} />
                        ) : (
                          "N/A"
                        ),
                      billing:
                        item.progress_status == "2" ? (
                          <View
                            label="Manage"
                            to={`/ac/projects/${item.id}/billing`}
                          />
                        ) : (
                          "N/A"
                        ),
                      status: (
                        <StatusBar
                          status={item.sub_status}
                          isProgress={true}
                          disabled={true}
                        />
                      ),
                      created_at: item.created_at.split(" ")[0],
                      action:
                        item.sub_status == 1 ? (
                          <View
                            customStyle={{ backgroundColor: "#c71c1c" }}
                            label="Request for close"
                            type={"button"}
                            onClick={() => setCloseProjectModal(item)}
                          />
                        ) : item.sub_status == 2 ? (
                          <View
                            customStyle={{ backgroundColor: "#f1d900" }}
                            label="In Progress"
                          />
                        ) : item.sub_status == 3 ? (
                          item.loan_repayment_effective_date
                        ) : (
                          <View
                            customStyle={{ backgroundColor: "#c71c1c" }}
                            label="Rejected (Re-apply)"
                            type={"button"}
                            onClick={() => setCloseProjectModal(item)}
                          />
                        ),
                    }))
                : []
            }
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AC_SubProjects;

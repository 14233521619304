import { useEffect } from "react";
import { useState } from "react";
import { errorToast } from "../../Utils/Toastify";

export const useEditBudgetForm = (
  initialStates,
  filterForexWithDate,
  apgProp
) => {
  const [states, setStates] = useState(initialStates);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [selectedApg, setSelectedApg] = useState({});
  const [selectedId, setSelectedId] = useState();
  const [apg, setApg] = useState(apgProp ? apgProp : []);
  const [apgError, setApgError] = useState({});
  const [isFormvalid, setFormValid] = useState(true);
  const [isFirstLoanId, setIsFirstLoanId] = useState(true);
  const [isFirstAgreement, setIsFirstAgreement] = useState(true);
  const validate = (fieldValues = states) => {
    const {
      serial_number,
      donor_agency,
      donor_currency,
      fiscal_year,
      activity_number,
      directorate_office,
      directorate_office_code,
      office_name,
      office_code,
      expenses_head,
      budget_head,
      contract_id,
      budget_re_imburse,
      approved_budget_npr,
      estimated_cost_npr,
      budget_receive_date,
    } = fieldValues;
    let temp = { ...errors };
    setErrors({ ...temp });
    if ("loan_id" in fieldValues) {
      temp.donor_currency = donor_currency
        ? ""
        : "Donor currency  is required" ||
          temp.donor_currency.toLowerCase() !== "SELECT AN OPTION".toLowerCase()
        ? ""
        : "Donor currency  is required";
    }
    if ("fiscal_year" in fieldValues) {
      temp.fiscal_year =
        (fiscal_year ? "" : "Fiscal year is required") ||
        (/^\d{4}\/\d{4}$/.test(fiscal_year) ? "" : "Fiscal year is invalid");
    }
    if ("serial_number" in fieldValues) {
      temp.serial_number = serial_number ? "" : "Serial number is required";
    }
    if ("donor_agency" in fieldValues) {
      temp.donor_agency = donor_agency ? "" : "Donor agency is required";
    }
    if ("budget_receive_date" in fieldValues) {
      temp.budget_receive_date = budget_receive_date
        ? ""
        : "Budget received date is required";
    }
    if ("activity_number" in fieldValues) {
      temp.activity_number = activity_number
        ? ""
        : "Activity number is required";
    }
    if ("directorate_office" in fieldValues) {
      temp.directorate_office = directorate_office
        ? ""
        : "Directorate Office name is required";
    }
    if ("directorate_office_code" in fieldValues) {
      temp.directorate_office_code = directorate_office_code
        ? ""
        : "Directorate Office code is required";
    }
    if ("office_name" in fieldValues) {
      temp.office_name = office_name ? "" : "Office code is required";
    }
    if ("office_code" in fieldValues) {
      temp.office_code = office_code ? "" : "Office code is required";
    }
    if ("expenses_head" in fieldValues) {
      temp.expenses_head = expenses_head ? "" : "Expenses head is required";
    }
    if ("budget_head" in fieldValues) {
      temp.budget_head = budget_head ? "" : "Budget head is required";
    }
    if ("contract_id" in fieldValues) {
      temp.contract_id = contract_id ? "" : "Contract Id is required";
    }
    if ("budget_re_imburse" in fieldValues) {
      temp.budget_re_imburse = budget_re_imburse
        ? ""
        : "Budget re imburse is required";
    }
    if ("approved_budget_npr" in fieldValues) {
      temp.approved_budget_npr = approved_budget_npr
        ? ""
        : "Approved Budget is required";
    }
    if ("estimated_cost_npr" in fieldValues) {
      temp.estimated_cost_npr = estimated_cost_npr
        ? ""
        : "Estmate cost is required";
    }
    setErrors({ ...temp });
    if (fieldValues === states)
      return Object.values(temp).every((item) => item === "");
  };
  const handleAddApg = () => {
    const rand = Math.random();
    setApg((prev) => [
      ...prev,
      {
        id: rand,
        bank_name: "",
        advance_guarantee_no: "",
        validity_period: "",
        validity_period_ad: "",

        advance_amount: "",
        advance_amount_npr: "",
        advance_amount_usd: "",
        advance_release_date: "",
        advance_release_date_ad: "",
      },
    ]);
    setSelectedId(rand);
    setSelectedApg(rand);
  };
  const validateForm = () => {
    const mainError = validate();
    const apgErrorV = apgValidate();

    return !apgErrorV || !mainError ? false : true;
  };
  const apgValidate = () => {
    // let validForm = true;
    const tempError = { ...apgError };
    for (let i = 0; i < apg.length; i++) {
      const element = apg[i];
      const individualErr = {};
      Object.keys(element).forEach((item) => {
        if (!element[item]) individualErr[item] = "Field is Required";
        // if (item === "advance_guarantee_no") {
        //   if (isNaN(+element[item])) {
        //     individualErr[item] = "Contains only number";
        //   }
        // }
        // if (item === "advance_amount_npr") {
        //   if (isNaN(+element[item])) {
        //     individualErr[item] = "Contains only number";
        //   }
        // }
        const doesErrorExist = Object.values(individualErr).find(
          (i) => typeof i === "string"
        );
        console.log(
          Object.values(individualErr).find((i) => typeof i === "string")
        );
        doesErrorExist && (tempError[element.id] = individualErr);
      });
    }
    setApgError(tempError);

    return Object.keys(tempError).some((layer) =>
      Object.values(tempError[layer]).some((value) => typeof value === "string")
    )
      ? false
      : true;

    // for (let i = 0; i < apg.length; i++) {
    //   let tempErr = {};

    //   for (let j = 0; j < Object.keys(apg[i]).length; j++) {
    //     if (Object.keys(apg[i])[j] === "advance_guarantee_no") {
    //       tempErr = {
    //         ...tempErr,
    //         [Object.keys(apg[i])[j]]: apg[i][Object.keys(apg[i])[j]]
    //           ? isNaN(apg[i][Object.keys(apg[i])[j]]) === false
    //             ? undefined
    //             : "Guarentee number is invalid"
    //           : "Guarentee number is required",
    //         id: apg[i]["id"],
    //       };
    //     } else if (Object.keys(apg[i])[j] === "advance_amount_npr") {
    //       tempErr = {
    //         ...tempErr,
    //         [Object.keys(apg[i])[j]]: apg[i][Object.keys(apg[i])[j]]
    //           ? isNaN(apg[i][Object.keys(apg[i])[j]]) === false
    //             ? undefined
    //             : "Advance amount is invalid"
    //           : "Advance amount is required",
    //         id: apg[i]["id"],
    //       };
    //     } else {
    //       tempErr = {
    //         ...tempErr,
    //         [Object.keys(apg[i])[j]]: apg[i][Object.keys(apg[i])[j]]
    //           ? undefined
    //           : "Is required",
    //         id: apg[i]["id"],
    //       };
    //     }
    //   }

    //   apgError.findIndex((element) => element.id === apg[i].id) === -1
    //     ? setApgError((prev) => {
    //         return [...prev, tempErr];
    //       })
    //     : setApgError((prev) =>
    //         prev.map((el, ind) => {
    //           if (i === ind) {
    //             return tempErr;
    //           } else {
    //             return el;
    //           }
    //         })
    //       );
    //   errors.push(tempErr);
    // }

    // for (let i = 0; i < errors.length; i++) {
    //   Object.values(errors[i]).forEach((el) => {
    //     validForm = typeof el === "string" ? false : true;
    //   });
    // }
    // return validForm;
  };
  const validateKey = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!value) return "Field is required";
    switch (name) {
      // case "advance_guarantee_no":
      //   if (isNaN(+value)) return "Contains only number";

      // case "advance_amount_npr":
      //   if (isNaN(+value)) return "Contains only number";

      default:
        return null;
    }
  };
  const handleApgChange = (id, e) => {
    setApg((prev) =>
      prev.map((item, index) => {
        if (item.id === id) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        } else {
          return item;
        }
      })
    );

    const isValid = validateKey(e);

    isValid
      ? setApgError((prev) => ({
          ...prev,
          [id]: { ...prev[id], [e.target.name]: isValid },
        }))
      : setApgError((prev) => ({
          ...prev,
          [id]: { ...prev[id], [e.target.name]: isValid ? isValid : null },
        }));
    // setApgError((prev) => {
    //   if (apgError[id]) {
    //     if (e.target.name === "advance_guarantee_no") {
    //       setApgError((prevs) => {
    //         return {
    //           ...prevs,
    //           [id]: {
    //             ...prevs[id],
    //             [e.target.name]: !isNaN(e.target.value)
    //               ? e.target.value
    //                 ? undefined
    //                 : "Guarentee number is required"
    //               : "Guarentee number is invalid",
    //           },
    //         };
    //       });
    //     } else if (e.target.name === "advance_amount_npr") {
    //       return {
    //         ...prev,
    //         [e.target.name]: !isNaN(e.target.value)
    //           ? e.target.value
    //             ? undefined
    //             : "Adavance amount is required"
    //           : "Advance amount is invalid",
    //       };
    //     } else {
    //       return {
    //         ...prev,
    //         [e.target.name]: e.target.value ? undefined : "Already exist",
    //       };
    //     }
    //   } else {
    //     return prev;
    //   }
    // });
  };

  const handleApgCurrency = async (id, e, sdr_conversion_rate) => {
    if (!states.donor_currency) {
      errorToast("Donor currency must be set");
      return setApg((prev) => [
        ...prev.map((item, i) => {
          if (id === item.id) {
            return (
              delete item[`${e.target.name}_usd`],
              delete item[`${e.target.name}`],
              delete item[`${e.target.name}_npr`],
              item
            );
          } else {
            return item;
          }
        }),
      ]);
    }
    if (!states.agreement_date_ad) {
      errorToast("Budget approval date must be set");
      return setApg((prev) => [
        ...prev.map((item, i) => {
          if (id === item.id) {
            return (
              delete item[`${e.target.name}_usd`],
              delete item[`${e.target.name}`],
              delete item[`${e.target.name}_npr`],
              item
            );
          } else {
            return item;
          }
        }),
      ]);
    }
    if (isNaN(+e.target.value) || +e.target.value < 0) {
      setApg((prev) => [
        ...prev.map((item, i) => {
          if (id === item.id) {
            errorToast("Invalid amount");
            return (
              delete item[`${e.target.name}_usd`],
              delete item[`donor_currency`],
              delete item[`donor_currency_npr`],
              item
            );
          } else {
            return item;
          }
        }),
      ]);

      return;
    }

    const convertedData = await filterForexWithDate(
      "npr",
      +e.target.value,
      [{ currency: states.donor_currency }],
      states.agreement_date_ad
    );

    if (!convertedData || !convertedData[0]) return;
    let usd_value =
      convertedData.filter((item) => item.currency.toLowerCase() === "usd")
        .length > 0
        ? convertedData.filter(
            (item) => item.currency.toLowerCase() === "usd"
          )[0].value
        : 0;

    setApg((prev) =>
      prev.map((item, i) => {
        if (id === item.id) {
          return {
            ...prev[i],
            [`${e.target.name}`.replace("_npr", "")]:
              states.donor_currency !== "SDR"
                ? convertedData.filter(
                    (item) =>
                      item.currency.toLowerCase() ===
                      states.donor_currency.toLowerCase()
                  ).length > 0
                  ? convertedData.filter(
                      (item) =>
                        item.currency.toLowerCase() ===
                        states.donor_currency.toLowerCase()
                    )[0].value
                  : 0
                : !sdr_conversion_rate
                ? !isNaN(+states.sdr_conversion_rate)
                  ? usd_value * states.sdr_conversion_rate
                  : usd_value * 1
                : !isNaN(+sdr_conversion_rate)
                ? usd_value * sdr_conversion_rate
                : usd_value * 1,
            [`${e.target.name}`.replace("_npr", "_usd")]: usd_value,
          };
        } else {
          return { ...item };
        }
      })
    );
  };
  const resetTime = (e) => {
    e.setHours(0);
    e.setMinutes(0);
    e.setSeconds(0);
    e.setMilliseconds(0);
    return e;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "loan_id") {
      if (value !== "Select an option") {
        setStates((prev) => ({
          ...prev,
          donor_agency: value.split("|")[2],
          donor_currency: value.split("|")[1],
          loan_id: value.split("|")[0],
          approved_budget_npr: "",
          approved_budget_usd: "",
          approved_budget: "",
          estimated_cost: "",
          estimated_cost_npr: "",
          estimated_cost_usd: "",
        }));
        setApg((prev) =>
          prev.map((item) => {
            return {
              ...item,
              advance_amount: "",
              advance_amount_usd: "",
              advance_amount_npr: "",
            };
          })
        );
        setApgError([]);
      }
    } else if (name === "agreement_date") {
      const d1 = resetTime(new Date(value));
      const d2 = resetTime(new Date(initialStates.agreement_date));
      if (
        !isFirstAgreement &&
        value !== states.agreement_date &&
        d1.getTime() !== d2.getTime()
      ) {
        initialStates.agreement_date = value;
      }
      setIsFirstAgreement(false);
    } else {
      setStates((prev) => ({ ...prev, [name]: value }));
    }

    validate({ [name]: value });
    setTouched({ ...touched, [name]: true });
  };
  const handleCurrency = async (e, sdr_conversion_rate) => {
    if (!states.donor_currency) {
      setStates((prev) => ({
        ...prev,
        [e.target.name]: "",
        [`${e.target.name}`.replace("_npr", "")]: "",
        [`${e.target.name}`.replace("_npr", "_usd")]: "",
      }));
      return errorToast("Currency must be set");
    }
    if (!states.agreement_date) {
      setStates((prev) => ({
        ...prev,
        [e.target.name]: "",
        [`${e.target.name}`.replace("_npr", "")]: "",
        [`${e.target.name}`.replace("_npr", "_usd")]: "",
      }));
      return errorToast("Business receive date must be set");
    }
    if (isNaN(+e.target.value) || +e.target.value < 0 || !e.target.value) {
      setStates(
        (prev) => (
          delete prev[`${e.target.name}_usd`],
          delete prev[`${e.target.name}_npr`],
          delete prev[`donor_currency`],
          prev
        )
      );
      return;
    }

    const convertedData = await filterForexWithDate(
      "npr",
      +e.target.value,
      [{ currency: states.donor_currency }],
      states.agreement_date_ad
    );

    if (!convertedData || !convertedData[0]) return;

    let usd_value =
      convertedData.filter((item) => item.currency.toLowerCase() === "usd")
        .length > 0
        ? convertedData.filter(
            (item) => item.currency.toLowerCase() === "usd"
          )[0].value
        : 0;

    setStates((prev) => ({
      ...prev,
      [`${e.target.name}`.replace("_npr", "")]:
        states.donor_currency !== "SDR"
          ? convertedData.filter(
              (item) =>
                item.currency.toLowerCase() ===
                states.donor_currency.toLowerCase()
            ).length > 0
            ? convertedData.filter(
                (item) =>
                  item.currency.toLowerCase() ===
                  states.donor_currency.toLowerCase()
              )[0].value
            : 0
          : !sdr_conversion_rate
          ? !isNaN(+states.sdr_conversion_rate)
            ? usd_value * +states.sdr_conversion_rate
            : usd_value * 1
          : !isNaN(+sdr_conversion_rate)
          ? usd_value * sdr_conversion_rate
          : usd_value * 1,
      [`${e.target.name}`.replace("_npr", "_usd")]: usd_value,
    }));
  };
  const isFormValidFrom = (fieldValues = states) => {
    const {
      donor_agency,
      donor_currency,
      fiscal_year,
      activity_number,
      directorate_office,
      directorate_office_code,
      office_name,
      office_code,
      expenses_head,
      budget_head,
      contract_id,
      budget_re_imburse,
      approved_budget_npr,
      estimated_cost_npr,
    } = fieldValues;
    let temp = { ...errors };
    setErrors({ ...temp });
    if ("loan_id" in fieldValues) {
      temp.donor_currency = donor_currency
        ? ""
        : "Donor currency  is required" ||
          temp.donor_currency.toLowerCase() !== "SELECT AN OPTION".toLowerCase()
        ? ""
        : "Donor currency  is required";
    }
    if ("fiscal_year" in fieldValues) {
      temp.fiscal_year =
        (fiscal_year ? "" : "Fiscal year is required") ||
        (/^\d{4}\/\d{4}$/.test(fiscal_year) ? "" : "Fiscal year is invalid");
    }
    if ("donor_agency" in fieldValues) {
      temp.donor_agency = donor_agency ? "" : "Donor agency is required";
    }

    if ("activity_number" in fieldValues) {
      temp.activity_number = activity_number
        ? ""
        : "Activity number is required";
    }
    if ("directorate_office" in fieldValues) {
      temp.directorate_office = directorate_office
        ? ""
        : "Directorate Office name is required";
    }
    if ("directorate_office_code" in fieldValues) {
      temp.directorate_office_code = directorate_office_code
        ? ""
        : "Directorate Office code is required";
    }
    if ("office_name" in fieldValues) {
      temp.office_name = office_name ? "" : "Office code is required";
    }
    if ("office_code" in fieldValues) {
      temp.office_code = office_code ? "" : "Office code is required";
    }
    if ("expenses_head" in fieldValues) {
      temp.expenses_head = expenses_head ? "" : "Expenses head is required";
    }
    if ("budget_head" in fieldValues) {
      temp.budget_head = budget_head ? "" : "Budget head is required";
    }
    if ("contract_id" in fieldValues) {
      temp.contract_id = contract_id ? "" : "Contract number is required";
    }
    if ("budget_re_imburse" in fieldValues) {
      temp.budget_re_imburse = budget_re_imburse
        ? ""
        : "Budget re imburse is required";
    }
    if ("approved_budget_npr" in fieldValues) {
      temp.approved_budget_npr = approved_budget_npr
        ? ""
        : "Approved Budget is required";
    }
    if ("estimated_cost_npr" in fieldValues) {
      temp.estimated_cost_npr = estimated_cost_npr
        ? ""
        : "Estmate cost is required";
    }

    if (fieldValues === states)
      return Object.values(temp).every((item) => item === "");
  };

  return {
    states,
    handleChange,
    errors,
    touched,
    validate,
    handleCurrency,
    isFormValidFrom,
    selectedApg,
    selectedId,
    handleApgChange,
    handleApgCurrency,
    handleAddApg,
    apg,
    validateForm,
    apgError,
    apgValidate,
    setSelectedId,
    setSelectedApg,
    setApg,
    isFormvalid,
    setFormValid,
    setApgError,
  };
};

import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import TopBar from "../../../Components/TopBar/TopBar";
import { doGet } from "../../../Services/Axios";
import StatusBar from "../../../UI/StatusBar/StatusBar";
import Table from "../../../UI/Table/Table";
import View from "../../../UI/View/View";
import { errorToast } from "../../../Utils/Toastify";
import ProjectController from "./_project";
import { Link } from "react-router-dom";

const Projects = () => {
  const [page, setPage] = useState(1);
  const { Columns, fetchProject } = ProjectController;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["Projects", page],
    queryFn: () => fetchProject(page),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching projects"),
  });

  const handlePagination = (type) => {
    if (
      data &&
      data.projects &&
      data.projects.next_page_url &&
      type === "pos"
    ) {
      setPage((prev) => prev + 1);
    } else if (
      data &&
      data.projects &&
      data.projects.prev_page_url &&
      type === "neg"
    ) {
      setPage((prev) => prev - 1);
    }
  };
  useEffect(() => {
    refetch();
  }, [page]);
  return (
    <div className="viewproject">
      <TopBar
        title={"Project"}
        children={
          <div className="sidebar-action">
            <Link
              to={"/project/create"}
              className="sidebar-action__create"
              title="New Project"
            >
              <i className="fa-light fa-plus sidebar-action__create__icon"></i>

              <span className="sidebar-action__create__text">
                Create Project
              </span>
            </Link>
          </div>
        }
      />
      <div className="viewproject-main">
        <div className="viewproject-main--table">
          <Table
            hasNextPage={
              data && data.projects && data.projects.next_page_url
                ? true
                : false
            }
            hasPrevPage={
              data && data.projects && data.projects.prev_page_url
                ? true
                : false
            }
            Columns={Columns}
            Rows={
              data &&
              data.projects &&
              data.projects.data &&
              data.projects.data.map((item, index) => ({
                sn: index + 1,
                source: item.type,
                project_name: item.name,
                loan: (
                  <View
                    label={"Loan/Grant/s"}
                    prefix={item.total_loans}
                    to={`/project/loan/${item.id}?from=project`}
                  />
                ),
                sub_projects: (
                  <View
                    label={"Sub Project/s"}
                    prefix={item.total_subProject}
                    to={`/subproject/${item.id}`}
                  />
                ),
                status: <StatusBar status={item.status} isProgress={true} />,
                created_by: item.user_name ? item.user_name : "N/A",
                created_at: item.created_at.split(" ")[0],
              }))
            }
            loading={isLoading}
            handlePagination={handlePagination}
            pageProp={page}
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;

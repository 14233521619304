import React, { useState } from "react";
import { baseUrl } from "../../../Constants/Constants";
import { doPost } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import SmallButton from "../../../UI/Button/SmallButton";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import TextArea from "../../../UI/TextArea/TextArea";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FileInput from "../../../UI/FileInput/FileInput";
import { NoticeController } from "../NoticeController";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
const CreateNotice = ({ handleClose }) => {
  const queryClient = useQueryClient();
  const initialStates = {
    title: "",
    description: "",
  };
  const [image, setImage] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const { errors, handleChange, states, validate } = useForm(initialStates);
  const handleCreateNotice = async () => {
    try {
      if (validate()) {
        const form = new FormData();
        form.append("title", states.title);
        form.append("description", states.description);
        console.log(image);
        Array.from(image).forEach((item) => form.append("images[]", item));
        await doPost(`notice/create`, form);
      } else {
        throw new Error("Validation error");
      }
    } catch (error) {
      throw error;
    }
  };
  const { mutate } = useMutation({
    mutationKey: "Notices",
    mutationFn: handleCreateNotice,
    onError: (error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        console.log(error);
        return toast.error("Error while adding notice!!");
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("notices");
      toast.success("Notice Added Successfully");
      handleClose();
    },
  });

  const handleSubmitImage = (e) => {
    setImage(e);
  };

  const { data } = useQuery("notices", NoticeController, {
    cacheTime: 15 * 60 * 1000,
  });
  const onClose = () => {
    setCloseConfirmationModal(true);
  };
  return (
    <ModalLayout onClose={onClose} title={"Create Notice"}>
      <div className="createnotice">
        {confirmationModal && (
          <ConfirmationModal
            text={"Do you really  want to insert notice ??"}
            caption={"Please verify all details before clicking yes"}
            onClose={() => setConfirmationModal(false)}
            onSuccess={() => {
              mutate();
              setConfirmationModal(false);
            }}
          />
        )}
        {closeConfirmationModal && (
          <ConfirmationModal
            caption={"this will remove all filled data"}
            text={"Do you really  want to cancel inserting notice ??"}
            onClose={() => setCloseConfirmationModal(false)}
            onSuccess={() => {
              setConfirmationModal(false);
              handleClose();
            }}
          />
        )}
        <Input
          onChange={handleChange}
          name="title"
          placeholder={"Eg. Electricity memorandum "}
          label={"Title"}
          error={errors.title}
        />
        <div className="createnotice-add">
          <FileInput handleSubmitImage={handleSubmitImage} />
        </div>
        <TextArea
          placeholder={"Eg. Name of the description"}
          name="description"
          onChange={handleChange}
          error={errors.description}
        />
        <div className="createnotice-create">
          <PrimaryButton
            content={"Create"}
            onClick={() => setConfirmationModal(true)}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreateNotice;

import React from "react";
import { Link } from "react-router-dom";
import { SidebarConfig } from "../../Services/Routes";
import { NEA_COLOR } from "../../Constants/Images";
import { useSelector } from "react-redux";

export default function Sidebar({ active }) {
  const { sideBar, user } = useSelector((state) => state.System);
  return (
    <div
      className={`sidebar ${sideBar ? "" : "close"} ${
        user &&
        user.user &&
        user.user.user_type &&
        user.user.user_type === "Admin"
          ? "between"
          : "normal"
      }`}
    >
      <div className="sidebar-logo">
        <img
          src={NEA_COLOR}
          alt=""
          className={`sidebar-logo__image${sideBar ? "" : "-small"}`}
        />
        {sideBar && (
          <p className="sidebar-logo__text">
            Borrowing & Lending <br /> AIS
          </p>
        )}
      </div>
      <ul className="sidebar-menu">
        {SidebarConfig.map(({ to, name, icon, permission }, index) =>
          permission && user && user.user && user.user.user_type
            ? permission[user.user.user_type] && (
                <li
                  className={`sidebar-menu--list ${
                    active === index ? "sidebar-menu--list__active" : ""
                  }`}
                  key={index}
                  title={name}
                >
                  <Link
                    to={to}
                    className={`sidebar-menu--list__link ${
                      active === index ? "sidebar-menu--list__active__link" : ""
                    }`}
                  >
                    <i className={icon}></i>
                    {sideBar && name}
                  </Link>
                </li>
              )
            : null
        )}
      </ul>
      {user &&
        user.user &&
        user.user.user_type &&
        user.user.user_type === "Admin" && (
          <div className="sidebar-action">
            <Link
              to={"/project/create"}
              className="sidebar-action__create"
              title="New Project"
            >
              <i className="fa-light fa-plus sidebar-action__create__icon"></i>
              {sideBar && (
                <span className="sidebar-action__create__text">
                  Create Project
                </span>
              )}
            </Link>
          </div>
        )}
    </div>
  );
}

import React from "react";
import { doPut } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";

const ChangePassword = ({ onClose }) => {
  const initialState = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };
  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        const resp = await doPut("/", states);
      }
    } catch (error) {}
  };
  return (
    <ModalLayout onClose={onClose} title={"Change Password"}>
      <div className="changepassword">
        <div className="changepassword-main">
          <Input
            name={"password"}
            error={errors.password}
            onChange={handleChange}
            label={"Old Password"}
          />
          <Input
            name={"newPassword"}
            error={errors.newPassword}
            onChange={handleChange}
            label={"New Password"}
          />
          <Input
            name={"confirmPassword"}
            error={errors.confirmPassword}
            onChange={handleChange}
            label={"Confirm Password"}
          />
          <div className="changepassword-main--submit">
            <PrimaryButton content={"Change Password"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangePassword;

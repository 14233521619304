import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const DescriptionModal = ({ description, onClose }) => {
  return (
    <ModalLayout onClose={onClose} title={"Payment terms"}>
      <div className="contractor-descriptionmodal">
        {description.payment_terms}
      </div>
    </ModalLayout>
  );
};

export default DescriptionModal;

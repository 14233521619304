import axios from "axios";
import { baseUrl } from "../Constants/Constants";
import {
  getAccessTokenFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  removeUserFromLocalStorage,
  setTokenToLocalStorage,
} from "./Helpers";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  function (request) {
    if (request.url === "auth/refresh") {
      request.headers[
        "Authorization"
      ] = `Bearer ${getRefreshTokenFromLocalStorage()}`;
      return request;
    } else if (request.url.indexOf("auth/login") > -1) {
      return request;
    } else {
      request.headers[
        "Authorization"
      ] = `Bearer ${getAccessTokenFromLocalStorage()}`;
      return request;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response.data.token_expired &&
      error.response.data.token_expired === "Token has expired" &&
      originalRequest.url.includes("auth/refresh")
    ) {
      removeUserFromLocalStorage();
      window.location.href = "/login";
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        instance
          .post("auth/refresh")
          .then((response) => {
            setTokenToLocalStorage(response.data.token);
            instance.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token.refresh;
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.token.refresh;
            processQueue(null, response.data.data.refresh);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export const doGet = (uri, params = {}) => instance.get(uri, params);
export const doPost = (uri, params = {}) => instance.post(uri, params);
export const doPut = (uri, params = {}) => instance.put(uri, params);
export const doDelete = (uri, params = {}) => instance.delete(uri, params);
export const doAxios = {
  get: (uri, params = {}) => axios.get(uri, params),
  post: (uri, params = {}) => axios.post(uri, params),
  put: (uri, params = {}) => axios.put(uri, params),
  delete: (uri, params = {}) => axios.delete(uri, params),
};

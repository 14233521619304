import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../../UI/Modal/ModalLayout";
import AddBillController from "./_addbill";
import { errorToast, successToast } from "../../../../../Utils/Toastify";
import ConfirmationModal from "../../../../../Components/Modal/ConfirmationModal";
import Loading from "../../../../../UI/Loader/Loader";
import SmallButton from "../../../../../UI/Button/SmallButton";
import Select from "../../../../../UI/Select/Select";
import Input from "../../../../../UI/Input/Input";
import AdvanceOrRetentionBill from "./AdvanceOrRetentionBill";
import FileInput from "../../../../../UI/FileInput/FileInput";
import useForex from "../../../../../Hooks/useForex";
import VatBill from "./VatBill";
import CommercialInvoiceBill from "./CommercialInvoiceBill";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../../../../Slice/SystemSlice";
import { useQuery, useQueryClient } from "react-query";

//!Controller
const {
  fetchContractors,
  fetchLoans,
  validateKey,
  transactionTypes,
  isValidValue,
  validateData,
  submitForm,
} = AddBillController;

export default function AddBill({ onClose, projectid }) {
  const { filterForexWithDate, getConversionRate } = useForex();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [state, setState] = useState({});
  const [stateError, setStateError] = useState({});
  const [documents, setDocuments] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);

  //!Query
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["Loans", projectid, "subproject"],
    queryFn: () => fetchLoans(projectid),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });
  const contractorsData = useQuery({
    queryKey: ["Contractor", projectid],
    queryFn: () => fetchContractors(projectid),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching contractors"),
  });

  const deleteKeyFromState = (...keys) => {
    setState(
      (prev) => (
        keys.forEach((i) => i && typeof i === "string" && delete prev[i]), prev
      )
    );
  };

  //!Effects
  const handleChainEffect = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "transaction_type":
        state.transaction_type &&
          state.transaction_type !== value &&
          setState((prev) => ({
            loan_id: prev.loan_id,
            donor_currency: prev.donor_currency,
            particular: prev.particular,
            forex_conversion_basis: prev.forex_conversion_basis,
            bill_type: prev.bill_type,
            transaction_type: prev.transaction_type,
            contract_id: prev.contract_id,
          }));
        setStateError((prev) => ({
          loan_id: prev.loan_id,
          donor_currency: prev.donor_currency,
          particular: prev.particular,
          forex_conversion_basis: prev.forex_conversion_basis,
          bill_type: prev.bill_type,
          transaction_type: prev.transaction_type,
          contract_id: prev.contract_id,
        }));
        return;

      case "forex_conversion_basis":
        if (!state.forex_conversion_basis || !state.transaction_type) return;
        //!ADVANCE
        if (state.transaction_type === "ADVANCE/RETENTION") {
          handleConversion({
            target: {
              conversion_basis: value,
              name: "advance_disbursed_npr",
              value: state.advance_disbursed_npr,
            },
          });
        }
        //!VAT BILL
        if (state.transaction_type === "VAT BILL") {
          handleConversion({
            target: {
              conversion_basis: value,
              name: "bill_amount_npr",
              value: state.bill_amount_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "bill_amount_inclusive_vat_npr",
              value: state.bill_amount_inclusive_vat_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "advance_amount_npr",
              value: state.advance_amount_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "other_deduction_npr",
              value: state.other_deduction_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "total_deduction_npr",
              value: state.total_deduction_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "net_payable_amount_npr",
              value: state.net_payable_amount_npr,
            },
          });
        }
        //!COMMERCIAL BILL
        if (state.transaction_type === "COMMERCIAL INVOICE (LC)") {
          handleConversion({
            target: {
              conversion_basis: value,
              name: "invoice_bill_amount_npr",
              value: state.invoice_bill_amount_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "invoice_bill_amount_inclusive_vat_npr",
              value: state.invoice_bill_amount_inclusive_vat_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "commercial_advance_amount_npr",
              value: state.commercial_advance_amount_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "commercial_other_deduction_npr",
              value: state.commercial_other_deduction_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "commercial_total_deduction_npr",
              value: state.commercial_total_deduction_npr,
            },
          });
          handleConversion({
            target: {
              conversion_basis: value,
              name: "commercial_net_payable_amount_npr",
              value: state.commercial_net_payable_amount_npr,
            },
          });
        }

        return;
      case "commercial_disbursed_date":
        if (
          state.commercial_disbursed_date &&
          state.commercial_disbursed_date !== value
        ) {
          handleConversion({
            target: {
              name: "invoice_bill_amount_npr",
              value: state.invoice_bill_amount_npr,
            },
          });
          handleConversion({
            target: {
              name: "invoice_bill_amount_inclusive_vat_npr",
              value: state.invoice_bill_amount_inclusive_vat_npr,
            },
          });
          handleConversion({
            target: {
              name: "commercial_advance_amount_npr",
              value: state.commercial_advance_amount_npr,
            },
          });
          handleConversion({
            target: {
              name: "commercial_other_deduction_npr",
              value: state.commercial_other_deduction_npr,
            },
          });
          handleConversion({
            target: {
              name: "commercial_total_deduction_npr",
              value: state.commercial_total_deduction_npr,
            },
          });
          handleConversion({
            target: {
              name: "commercial_net_payable_amount_npr",
              value: state.commercial_net_payable_amount_npr,
            },
          });
        }

        return;
      case "disbursed_date":
        if (state.disbursed_date && state.disbursed_date !== value) {
          handleConversion({
            target: { name: "bill_amount_npr", value: state.bill_amount_npr },
          });
          handleConversion({
            target: {
              name: "bill_amount_inclusive_vat_npr",
              value: state.bill_amount_inclusive_vat_npr,
            },
          });
          handleConversion({
            target: {
              name: "advance_amount_npr",
              value: state.advance_amount_npr,
            },
          });
          handleConversion({
            target: {
              name: "other_deduction_npr",
              value: state.other_deduction_npr,
            },
          });
          handleConversion({
            target: {
              name: "total_deduction_npr",
              value: state.total_deduction_npr,
            },
          });
          handleConversion({
            target: {
              name: "net_payable_amount_npr",
              value: state.net_payable_amount_npr,
            },
          });
        }

        return;
      case "advance_disbursed_date":
        if (
          state.advance_disbursed_date &&
          state.advance_disbursed_date !== value
        ) {
          handleConversion({
            target: {
              name: "advance_disbursed_npr",
              value: state.advance_disbursed_npr,
            },
          });
        }
        return;
      default:
    }
  };
  const handleDependentValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      //!Common
      case "sdr_conversion_rate":
        if (isNaN(+value) || +value < 0) return;

        if (
          state.transaction_type === "ADVANCE/RETENTION" &&
          state.advance_disbursed_usd &&
          !isNaN(+state.advance_disbursed_usd) &&
          +state.advance_disbursed_usd > -1
        ) {
          setState((prev) => ({
            ...prev,
            advance_disbursed: (+value * prev.advance_disbursed_usd).toFixed(3),
          }));
        }
        if (state.transaction_type === "VAT BILL") {
          const billAmount =
            state.bill_amount_usd &&
            !isNaN(+state.bill_amount_usd) &&
            +state.bill_amount_usd > -1;
          const billAmountWithVat =
            state.bill_amount_inclusive_vat_usd &&
            !isNaN(+state.bill_amount_inclusive_vat_usd) &&
            +state.bill_amount_inclusive_vat_usd > -1;
          const advanceAmount =
            state.advance_amount_usd &&
            !isNaN(+state.advance_amount_usd) &&
            +state.advance_amount_usd > -1;
          const otherDeductionAmount =
            state.other_deduction_usd &&
            !isNaN(+state.other_deduction_usd) &&
            +state.other_deduction_usd > -1;
          const totalDeductionAmount =
            state.total_deduction_usd &&
            !isNaN(+state.total_deduction_usd) &&
            +state.total_deduction_usd > -1;
          const netPayable =
            state.net_payable_amount_usd &&
            !isNaN(+state.net_payable_amount_usd) &&
            +state.net_payable_amount_usd > -1;

          setState((prev) => ({
            ...prev,
            bill_amount: billAmount
              ? (+value * prev.bill_amount_usd).toFixed(3)
              : prev.bill_amount_usd,
            bill_amount_inclusive_vat: billAmountWithVat
              ? (+value * prev.bill_amount_inclusive_vat_usd).toFixed(3)
              : prev.bill_amount_inclusive_vat_usd,
            advance_amount: advanceAmount
              ? (+value * prev.advance_amount_usd).toFixed(3)
              : prev.advance_amount_usd,
            other_deduction: otherDeductionAmount
              ? (+value * prev.other_deduction_usd).toFixed(3)
              : prev.other_deduction_usd,
            total_deduction: totalDeductionAmount
              ? (+value * prev.total_deduction_usd).toFixed(3)
              : prev.total_deduction_usd,
            net_payable_amount: netPayable
              ? (+value * prev.net_payable_amount_usd).toFixed(3)
              : prev.net_payable_amount_usd,
          }));
        }
        if (state.transaction_type === "COMMERCIAL INVOICE (LC)") {
          const billAmountInvoice =
            state.invoice_bill_amount_usd &&
            !isNaN(+state.invoice_bill_amount_usd) &&
            +state.invoice_bill_amount_usd > -1;
          const billAmountWithVatInvoice =
            state.invoice_bill_amount_inclusive_vat_usd &&
            !isNaN(+state.invoice_bill_amount_inclusive_vat_usd) &&
            +state.invoice_bill_amount_inclusive_vat_usd > -1;
          const advanceAmountCommercial =
            state.commercial_advance_amount_usd &&
            !isNaN(+state.commercial_advance_amount_usd) &&
            +state.commercial_advance_amount_usd > -1;
          const otherDeductionAmountCommercial =
            state.commercial_other_deduction_usd &&
            !isNaN(+state.commercial_other_deduction_usd) &&
            +state.commercial_other_deduction_usd > -1;
          const totalDeductionAmountCommercial =
            state.commercial_total_deduction_usd &&
            !isNaN(+state.commercial_total_deduction_usd) &&
            +state.commercial_total_deduction_usd > -1;
          const netPayableCommercial =
            state.commercial_net_payable_amount_usd &&
            !isNaN(+state.commercial_net_payable_amount_usd) &&
            +state.commercial_net_payable_amount_usd > -1;

          setState((prev) => ({
            ...prev,
            invoice_bill_amount: billAmountInvoice
              ? (+value * prev.invoice_bill_amount_usd).toFixed(3)
              : prev.invoice_bill_amount_usd,
            invoice_bill_amount_inclusive_vat: billAmountWithVatInvoice
              ? (+value * prev.invoice_bill_amount_inclusive_vat_usd).toFixed(3)
              : prev.invoice_bill_amount_inclusive_vat_usd,
            commercial_advance_amount: advanceAmountCommercial
              ? (+value * prev.commercial_advance_amount_usd).toFixed(3)
              : prev.commercial_advance_amount_usd,
            commercial_other_deduction: otherDeductionAmountCommercial
              ? (+value * prev.commercial_other_deduction_usd).toFixed(3)
              : prev.commercial_other_deduction_usd,
            commercial_total_deduction: totalDeductionAmountCommercial
              ? (+value * prev.commercial_total_deduction_usd).toFixed(3)
              : prev.commercial_total_deduction_usd,
            commercial_net_payable_amount: netPayableCommercial
              ? (+value * prev.commercial_net_payable_amount_usd).toFixed(3)
              : prev.commercial_net_payable_amount_usd,
          }));
        }
        break;
      //!Commercial With Vat Calculation
      case "commercial_vat_percentage":
      case "invoice_bill_amount_npr":
        const commercial_vat =
          name === "commercial_vat_percentage"
            ? value
            : state.commercial_vat_percentage;
        const commercial_bill =
          name === "invoice_bill_amount_npr"
            ? value
            : state.invoice_bill_amount_npr;

        if (
          isNaN(+commercial_bill) ||
          +commercial_bill < 0 ||
          (commercial_vat && (+commercial_vat < 0 || +commercial_vat > 100))
        )
          return deleteKeyFromState(
            "invoice_bill_amount_inclusive_vat",
            "invoice_bill_amount_inclusive_vat_npr",
            "invoice_bill_amount_inclusive_vat_usd",
            "commercial_net_payable_amount",
            "commercial_net_payable_amount_npr",
            "commercial_net_payable_amount_usd",
            "commercial_total_deduction",
            "commercial_total_deduction_npr",
            "commercial_total_deduction_usd"
          );

        handleInput({
          target: {
            name: "invoice_bill_amount_inclusive_vat_npr",
            value: commercial_vat
              ? +commercial_bill + +commercial_bill * (+commercial_vat / 100)
              : commercial_bill,
            billAmount: commercial_bill,
          },
        });
        break;

      //!Commercial Bill Deduction Calculation
      case "invoice_bill_amount_inclusive_vat_npr":
      case "recalculated_invoice_bill_amount_npr":
      case "commercial_advance_amount_npr":
      case "commercial_contract_tax_percentage":
      case "commercial_retention_percentage":
      case "commercial_liqudate_damage_percentage":
      case "commercial_other_deduction_npr":
        const commercialOtherDeductionAmount =
          name === "commercial_other_deduction_npr"
            ? value
            : state.commercial_other_deduction_npr;
        const commercialAdvanceAmount =
          name === "commercial_advance_amount_npr"
            ? value
            : state.commercial_advance_amount_npr;
        const commercialContractTaxPercentage =
          name === "commercial_contract_tax_percentage"
            ? value
            : state.commercial_contract_tax_percentage;
        const commercialRetentionPercentage =
          name === "commercial_retention_percentage"
            ? value
            : state.commercial_retention_percentage;
        const commercialLiqudateDamagePercentage =
          name === "commercial_liqudate_damage_percentage"
            ? value
            : state.commercial_liqudate_damage_percentage;

        const invoiceBillAmount = e.target.billAmount
          ? e.target.billAmount
          : state.invoice_bill_amount_npr;
        const invoiceBillAmountInclusiveVat =
          name === "invoice_bill_amount_inclusive_vat_npr"
            ? value
            : state.invoice_bill_amount_inclusive_vat_npr;

        if (
          !isValidValue(invoiceBillAmount) ||
          !isValidValue(invoiceBillAmountInclusiveVat) ||
          !isValidValue(commercialOtherDeductionAmount) ||
          !isValidValue(commercialAdvanceAmount) ||
          !isValidValue(commercialContractTaxPercentage, true) ||
          !isValidValue(commercialRetentionPercentage, true) ||
          !isValidValue(commercialLiqudateDamagePercentage, true)
        )
          return deleteKeyFromState(
            "commercial_total_deduction",
            "commercial_total_deduction_npr",
            "commercial_total_deduction_usd",
            "commercial_net_payable_amount",
            "commercial_net_payable_amount_npr",
            "commercial_net_payable_amount_usd"
          );

        const commercialFinalDeductionValue =
          +commercialAdvanceAmount +
          +commercialOtherDeductionAmount +
          invoiceBillAmount * (+commercialRetentionPercentage / 100) +
          invoiceBillAmount * (+commercialLiqudateDamagePercentage / 100) +
          invoiceBillAmount * (+commercialContractTaxPercentage / 100);

        handleInput({
          target: {
            name: "commercial_total_deduction_npr",
            value: (+commercialFinalDeductionValue).toFixed(5),
          },
        });
        handleConversion({
          target: {
            name: "commercial_total_deduction_npr",
            value: (+commercialFinalDeductionValue).toFixed(5),
          },
        });
        handleInput({
          target: {
            name: "commercial_net_payable_amount_npr",
            value:
              invoiceBillAmountInclusiveVat -
              (+commercialFinalDeductionValue).toFixed(5),
          },
        });
        handleConversion({
          target: {
            name: "commercial_net_payable_amount_npr",
            value:
              invoiceBillAmountInclusiveVat -
              (+commercialFinalDeductionValue).toFixed(5),
          },
        });
        break;
      //!Vat Bill With Vat Calculation
      case "vat_percentage":
      case "bill_amount_npr":
        const vat = name === "vat_percentage" ? value : state.vat_percentage;
        const bill = name === "bill_amount_npr" ? value : state.bill_amount_npr;
        if (vat === "" || bill === "" || +vat < 0 || +vat > 100 || +bill < 0)
          return deleteKeyFromState(
            "bill_amount_inclusive_vat",
            "bill_amount_inclusive_vat_npr",
            "bill_amount_inclusive_vat_usd"
          );

        handleInput({
          target: {
            name: "bill_amount_inclusive_vat_npr",
            value: +bill + +bill * (+vat / 100),
            billAmount: bill ? bill : null,
          },
        });
        break;
      //!Vat Bill Deduction Calculation
      case "bill_amount_inclusive_vat_npr":
      case "advance_amount_npr":
      case "prior_collection_of_vat_percentage":
      case "tds_percentage":
      case "retention_percentage":
      case "liqudate_damage_percentage":
      case "other_deduction_npr":
        const otherDeductionAmount =
          name === "other_deduction_npr" ? value : state.other_deduction_npr;
        const advanceAmount =
          name === "advance_amount_npr" ? value : state.advance_amount_npr;
        const priorCollectionPercentage =
          name === "prior_collection_of_vat_percentage"
            ? value
            : state.prior_collection_of_vat_percentage;
        const tdsPercentage =
          name === "tds_percentage" ? value : state.tds_percentage;
        const retentionPercentage =
          name === "retention_percentage" ? value : state.retention_percentage;
        const liqudateDamagePercentage =
          name === "liqudate_damage_percentage"
            ? value
            : state.liqudate_damage_percentage;

        const billAmount = e.target.billAmount
          ? +e.target.billAmount
          : +state.bill_amount_npr;
        const billAmountInclusiveVat =
          name === "bill_amount_inclusive_vat_npr"
            ? value
            : +state.bill_amount_inclusive_vat_npr;
        const vatAmount = billAmountInclusiveVat - billAmount;

        if (
          !isValidValue(billAmount) ||
          !isValidValue(billAmountInclusiveVat) ||
          !isValidValue(otherDeductionAmount) ||
          !isValidValue(advanceAmount) ||
          !isValidValue(priorCollectionPercentage, true) ||
          !isValidValue(tdsPercentage, true) ||
          !isValidValue(retentionPercentage, true) ||
          !isValidValue(liqudateDamagePercentage, true)
        )
          return deleteKeyFromState(
            "total_deduction",
            "total_deduction_npr",
            "total_deduction_usd",
            "net_payable_amount",
            "net_payable_amount_npr",
            "net_payable_amount_usd"
          );

        const finalDeductionValue =
          +advanceAmount +
          +otherDeductionAmount +
          billAmount * (+tdsPercentage / 100) +
          billAmount * (+retentionPercentage / 100) +
          billAmount * (+liqudateDamagePercentage / 100) +
          vatAmount * (+priorCollectionPercentage / 100);

        handleInput({
          target: {
            name: "total_deduction_npr",
            value: +finalDeductionValue.toFixed(5),
          },
        });
        handleConversion({
          target: {
            name: "total_deduction_npr",
            value: +finalDeductionValue.toFixed(5),
          },
        });
        handleInput({
          target: {
            name: "net_payable_amount_npr",
            value: billAmountInclusiveVat - +finalDeductionValue.toFixed(5),
          },
        });
        handleConversion({
          target: {
            name: "net_payable_amount_npr",
            value: billAmountInclusiveVat - +finalDeductionValue.toFixed(5),
          },
        });
        break;

      default:
        break;
    }
  };

  //!Functions
  const handleLoanNumber = (e) => {
    const seperation = e.target.value.split("|");
    if (!seperation[0] && !seperation[1])
      return errorToast("Invalid Loan Details");

    handleInput({ target: { name: "loan_id", value: +seperation[0] } });
    handleInput({ target: { name: "donor_currency", value: seperation[1] } });
  };
  const handleContractor = (e) => {
    const value = e.target.value;

    const contractor =
      contractorsData.data &&
      contractorsData.data.contract &&
      contractorsData.data.contract.find((i) => i.id == value);

    if (!contractor) return errorToast("Invalid contractor option");

    handleInput({ target: { name: "contract_id", value: value } });
  };
  const handleInput = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const validate = validateKey(e);
    setStateError((prev) => ({
      ...prev,
      [e.target.name]: validate,
    }));

    handleChainEffect(e);
    handleDependentValue(e);
  };
  const handleDocument = (images) => {
    setDocuments(images);
  };
  const handleCurrency = async (
    value,
    conversionDate,
    basis = state.forex_conversion_basis
  ) => {
    if (!state.donor_currency)
      return errorToast("Loan selection is required") && null;
    if (!conversionDate)
      return errorToast("Conversion date is required") && null;
    if (state.donor_currency === "SDR" && !state.sdr_conversion_rate)
      return errorToast("SDR Conversion rate is required") && null;
    if (isNaN(+value) || +value < 0) return null;

    const filteredData = await filterForexWithDate(
      "NPR",
      +value,
      [
        {
          currency:
            state.donor_currency === "SDR" ? "USD" : state.donor_currency,
        },
      ],
      conversionDate,
      basis
    );

    return filteredData && filteredData[0] ? filteredData : null;
  };
  const handleSubmit = async () => {
    dispatch(toggleLoader(true));

    const conversionDate = transactionTypes[state.transaction_type]
      ? state[transactionTypes[state.transaction_type]]
      : null;

    if (!conversionDate) return errorToast("Invalid data format");

    const response = validateData(state);
    try {
      if (!response.success) {
        setStateError(response.error);
        throw new Error("Validation failed");
      }
      setStateError({});
      const currency = await getConversionRate(conversionDate, {
        USD: true,
        [state.donor_currency.toUpperCase()]: true,
      });
      const serverResponse = await submitForm(
        state,
        projectid,
        documents,
        currency
      );
      successToast("Bill created");
      onClose(true);
      queryClient.refetchQueries(["BillInformation", projectid]);
    } catch (error) {
      errorToast(error.message);
    } finally {
      dispatch(toggleLoader(false));
    }
  };
  const handleBillType = (e) => {
    state.transaction_type
      ? handleConfirmationModal("BILLTYPE", {
          target: { name: e.target.name, value: e.target.value },
        })
      : handleInput(e);
  };

  //!Converion
  const handleConversion = async (e) => {
    const name = e.target.name.replace("_npr", "");
    const conversionDate = transactionTypes[state.transaction_type]
      ? state[transactionTypes[state.transaction_type]]
      : null;

    const convertedData = e.target.conversion_basis
      ? await handleCurrency(
          e.target.value,
          conversionDate,
          e.target.conversion_basis
        )
      : await handleCurrency(e.target.value, conversionDate);
    if (!convertedData) {
      setState(
        (prev) => (
          delete prev[`${name}_usd`], delete prev[`${name}`], { ...prev }
        )
      );
      return;
    }

    const usd = convertedData.find((i) => i.currency.toLowerCase() === "usd");

    const donor =
      state.donor_currency === "SDR" && usd
        ? { value: (+state.sdr_conversion_rate * +usd.value).toFixed(3) }
        : convertedData.find(
            (i) =>
              i.currency.toLowerCase() === state.donor_currency.toLowerCase()
          );

    if (!usd || !donor) {
      setState(
        (prev) => (
          delete prev[`${name}_usd`], delete prev[`${name}`], { ...prev }
        )
      );
      return;
    }
    setState((prev) => ({
      ...prev,
      [name]: donor.value + "",
      [`${name}_usd`]: (+usd.value).toFixed(3),
    }));
  };
  console.log(state);
  //!Confirmation
  const handleConfirmationModal = (action, event = null) => {
    setConfirmationModal(true);
    switch (action) {
      case "BACK":
        setConfirmationDetails({
          text: "Are you sure you want to cancel?",
          caption: "All data will be reset",
          action: () => onClose(false),
        });
        break;
      case "BILLTYPE":
        setConfirmationDetails({
          text: "Are you sure you want to change bill type?",
          caption: "Current bill details will be erased.",
          action: () => handleInput(event),
        });
        break;
      case "SAVE":
        setConfirmationDetails({
          text: "Are you sure you want to save this Bill?",
          caption: "Please verify all details before clicking yes",
          action: () => handleSubmit(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <ModalLayout
      title={"Add Bill"}
      onClose={() => handleConfirmationModal("BACK")}
    >
      {confirmationModal && confirmationDetails && (
        <ConfirmationModal
          onSuccess={() => (
            confirmationDetails.action(), setConfirmationModal(false)
          )}
          onClose={() => setConfirmationModal(false)}
          text={confirmationDetails.text}
          caption={confirmationDetails.caption}
        />
      )}
      {data &&
      data.loan &&
      data.loan.length > 0 &&
      contractorsData &&
      contractorsData.data &&
      contractorsData.data.contract &&
      contractorsData.data.contract.length > 0 ? (
        <>
          <div className="addbill">
            <div className="addbill-heading">
              <SmallButton
                content={"Save Changes"}
                title="Save Bill"
                icon={"fa-light fa-save"}
                onClick={() => handleConfirmationModal("SAVE")}
              />
            </div>
            <div className="addbill-form">
              <h4 className="addbill-form--header">Basic Details:</h4>
              <div className="addbill-form--wrapper">
                <Select
                  label={"Loan Number"}
                  name={"loan_id"}
                  options={data.loan.map((i) => ({
                    key: `${i.donor_agency} - ${i.loan_number}`,
                    value: `${i.id}|${i.donor_currency}`,
                  }))}
                  handler={handleLoanNumber}
                  required
                  preventMouse
                  width="31.5%"
                  defaultValue={
                    state.loan_id &&
                    state.donor_currency &&
                    `${state.loan_id}|${state.donor_currency}`
                  }
                  error={stateError.loan_id}
                />
                <Select
                  label={"Contractor"}
                  name={"contract_id"}
                  options={
                    contractorsData.data && contractorsData.data.contract
                      ? contractorsData.data.contract
                          .filter((i) => i.progress_status == 1)
                          .map((i) => ({
                            key: `${i.contractor_name} - ${i.contract_number} - ${i.contractor_address}`,
                            value: i.id,
                          }))
                      : []
                  }
                  handler={handleContractor}
                  required
                  preventMouse
                  width="31.5%"
                  defaultValue={state.contract_id}
                  error={stateError.contract_id}
                />
                <Select
                  label={"Transaction Type"}
                  name={"transaction_type"}
                  options={[
                    {
                      key: "ADVANCE/RETENTION",
                    },
                    {
                      key: "COMMERCIAL INVOICE (LC)",
                    },
                    {
                      key: "VAT BILL",
                    },
                  ]}
                  handler={handleBillType}
                  required
                  preventMouse
                  width="31.5%"
                  defaultValue={state.transaction_type}
                  error={stateError.transaction_type}
                />
                <Input
                  label={"Particular"}
                  required
                  name="particular"
                  customStyle={{ width: "31.5%" }}
                  placeholder={"eg: Smart Meter"}
                  onChange={handleInput}
                  defaultValue={state.particular}
                  error={stateError.particular}
                />
                <Select
                  label={"Forex Conversion Basis"}
                  name={"forex_conversion_basis"}
                  options={[
                    {
                      key: "buy",
                    },
                    {
                      key: "sell",
                    },
                  ]}
                  handler={handleInput}
                  required
                  preventMouse
                  width="31.5%"
                  defaultValue={state.forex_conversion_basis}
                  error={stateError.forex_conversion_basis}
                />
                <Select
                  label={"Bill Type"}
                  name={"bill_type"}
                  options={[
                    {
                      key: "CONSULTANCY",
                    },
                    {
                      key: "SUPPLY",
                    },
                    {
                      key: "CONSTRUCTION",
                    },
                  ]}
                  handler={handleInput}
                  required
                  preventMouse
                  width="31.5%"
                  defaultValue={state.bill_type}
                  error={stateError.bill_type}
                />
                {state.donor_currency === "SDR" ? (
                  <Input
                    label={"1USD to SDR Conversion Rate"}
                    required
                    name="sdr_conversion_rate"
                    customStyle={{ width: "31.5%" }}
                    placeholder={"eg: 1.4"}
                    onChange={handleInput}
                    defaultValue={state.sdr_conversion_rate}
                    error={stateError.sdr_conversion_rate}
                  />
                ) : null}
              </div>
              <h4 className="addbill-form--header">
                Bill Information: {state.transaction_type}
              </h4>
              {state.transaction_type === "ADVANCE/RETENTION" &&
              state.forex_conversion_basis &&
              state.donor_currency &&
              state.contract_id &&
              (state.donor_currency === "SDR"
                ? state.sdr_conversion_rate
                : true) ? (
                <AdvanceOrRetentionBill
                  handleInput={handleInput}
                  state={state}
                  stateError={stateError}
                  handleConversion={handleConversion}
                />
              ) : null}

              {state.transaction_type === "COMMERCIAL INVOICE (LC)" &&
              state.forex_conversion_basis &&
              state.donor_currency &&
              state.contract_id &&
              (state.donor_currency === "SDR"
                ? state.sdr_conversion_rate
                : true) ? (
                <CommercialInvoiceBill
                  handleInput={handleInput}
                  state={state}
                  stateError={stateError}
                  handleConversion={handleConversion}
                />
              ) : null}

              {state.transaction_type === "VAT BILL" &&
              state.forex_conversion_basis &&
              state.donor_currency &&
              state.contract_id &&
              (state.donor_currency === "SDR"
                ? state.sdr_conversion_rate
                : true) ? (
                <VatBill
                  handleInput={handleInput}
                  state={state}
                  stateError={stateError}
                  handleConversion={handleConversion}
                />
              ) : null}

              {!state.transaction_type ||
              !state.forex_conversion_basis ||
              !state.donor_currency ||
              !state.contract_id ||
              (state.donor_currency === "SDR"
                ? !state.sdr_conversion_rate
                : false) ? (
                <p className="notfound notfound-bordered">
                  Please select Transaction Type
                </p>
              ) : null}

              <h4 className="addbill-form--header">Documents:</h4>
              <div className="addbill-form--wrapper">
                <FileInput handleSubmitImage={handleDocument} />
              </div>
            </div>
          </div>
        </>
      ) : isLoading ? (
        <Loading />
      ) : (
        <p className="addbill notfound">N/A (Contracts & Loans are required)</p>
      )}
    </ModalLayout>
  );
}

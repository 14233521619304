import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { removeUserFromLocalStorage } from "../../../Services/Helpers";
import { handleLogout } from "../../../Slice/SystemSlice";
import ChangePassword from "../ChangePassword/ChangePassword";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showChangePasswordMddal, setShowChangePasswordModal] = useState(false);
  const handleChangePassword = () => {
    setShowChangePasswordModal((prev) => !prev);
  };
  const handlelogout = () => {
    removeUserFromLocalStorage();
    dispatch(handleLogout());
    navigate("/login");
  };

  return (
    <>
      {showChangePasswordMddal && (
        <ChangePassword onClose={handleChangePassword} />
      )}
      <div className="profilemenu">
        {/* <div className="profilemenu-item" onClick={handleProfile}>
          <i className="profilemenu-item--icon fa-light fa-user"></i>

          <span className="profilemenu-item--name">Profile</span>
        </div>
        <div className="profilemenu-item" onClick={handleChangePassword}>
          <i className="profilemenu-item--icon fa-light fa-key"></i>
          <span className="profilemenu-item--name">Change Password</span>
        </div> */}
        <div className="profilemenu-item">
          <i className="profilemenu-item--icon fa-light fa-right-from-bracket"></i>
          <span className="profilemenu-item--name" onClick={handlelogout}>
            Log Out
          </span>
        </div>
      </div>
    </>
  );
};

export default ProfileMenu;

import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import FilesModal from "../../../../Components/FilesModal/FilesModal";
import TopBar from "../../../../Components/TopBar/TopBar";
import SmallButton from "../../../../UI/Button/SmallButton";
import Table from "../../../../UI/Table/Table";
import View from "../../../../UI/View/View";
import { errorToast, warningToast } from "../../../../Utils/Toastify";
import AddBill from "./AddBill/AddBill";
import EditBill from "./EditBill/EditBill";
import BillingController from "./_billing";

import BillDetails from "../../../../Components/Billdetails/BillDetails";

export default function AC_Billing() {
  const params = useParams();
  const navigate = useNavigate();
  const projectid = params.id;
  useLayoutEffect(() => {
    if (!projectid || isNaN(+projectid)) return navigate(-1);
  }, [projectid]);

  const { Columns, fetchSubProjectBill, statusEnums } = BillingController;
  const [showBillCreationModal, setShowBillCreationModal] = useState(false);
  const [showEditBillModal, setShowEditBillModal] = useState(false);
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [billType, setBillType] = useState("");
  const [documents, setDocuments] = useState(null);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["BillInformation", projectid],
    queryFn: () => fetchSubProjectBill(projectid),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching bills"),
  });

  const handleShowBillCreation = () => setShowBillCreationModal(true);
  const handleShowEditBill = (item) => {
    setSelectedBill(item);
    setShowEditBillModal(true);
  };
  const handleCloseEditBill = () => {
    setSelectedBill(null);
    setShowEditBillModal(false);
  };
  const handleShowBillDetails = (item) => {
    setSelectedBill(item);
    setBillType(item.type);
    setShowBillDetails(true);
  };
  const handleCloseBillDetails = () => {
    setSelectedBill(null);
    setShowBillDetails(false);
  };
  const handleShowDocuments = (id) => {
    data && data.images && data.images.length > 0
      ? setDocuments(data.images.filter((i) => i.document_type_id === id))
      : warningToast("No documents found");
  };
  const handleCloseDocuments = () => {
    setDocuments(null);
  };

  console.log(data);
  return (
    projectid && (
      <div className="viewsubproject">
        {showBillCreationModal && (
          <AddBill
            onClose={() => setShowBillCreationModal(false)}
            projectid={projectid}
          />
        )}
        {showBillDetails && selectedBill && (
          <BillDetails
            onClose={handleCloseBillDetails}
            item={selectedBill}
            type={billType}
          />
        )}
        {showEditBillModal && selectedBill && (
          <EditBill
            onClose={handleCloseEditBill}
            item={selectedBill}
            projectid={projectid}
          />
        )}
        {documents && (
          <FilesModal
            files={documents.map((item) => {
              return item;
            })}
            handleClose={handleCloseDocuments}
          />
        )}
        <TopBar
          title={"Billing"}
          subtitle={"Billing"}
          backPage={true}
          children={
            <SmallButton
              icon={"fa-light fa-plus"}
              content={"Add Bill"}
              onClick={handleShowBillCreation}
            />
          }
        />
        <div className="viewsubproject-main">
          <div className="viewsubproject-main--table">
            <Table
              Columns={Columns}
              Rows={
                data && data.billsInfos && data.billsInfos.length > 0
                  ? data.billsInfos.map((item, index) => ({
                      sn: index + 1,
                      status: (
                        <View
                          label={statusEnums[item.progress_status].label}
                          customStyle={{
                            backgroundColor:
                              statusEnums[item.progress_status].color,
                          }}
                          prefix={
                            item.progress_status == "2" ? (
                              <i className="fa-light fa-edit"></i>
                            ) : null
                          }
                          type="button"
                          onClick={() =>
                            item.progress_status == "2"
                              ? handleShowEditBill(item)
                              : null
                          }
                        />
                      ),
                      loan_id: item.loan_number,
                      contractor_name: item.contractor_name,
                      contract_number: item.contract_number,
                      transaction_type: item.transaction_type,
                      particular: item.particular,
                      forex_conversion_basis: item.forex_conversion_basis,
                      bill_type: item.bill_type,
                      bill_details: (
                        <View
                          label={"Bill details"}
                          type={"button"}
                          onClick={() =>
                            handleShowBillDetails({
                              id: item,
                              type: item.transaction_type,
                            })
                          }
                        />
                      ),
                      documents: (
                        <View
                          label={"Documents"}
                          type="button"
                          onClick={() => handleShowDocuments(item.id)}
                        />
                      ),
                      created_at: item.created_at.split("T")[0],
                    }))
                  : []
              }
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    )
  );
}

import React from "react";
import ModalLayout from "../../../UI/Modal/ModalLayout";

const DonorModal = ({ onClose, donor }) => {
  const TITLE = [
    { key: "donor agency", value: "donor_agency" },
    { key: "donor currency", value: "donor_currency" },
    { key: "donor agreement date", value: "donor_agreement_date" },
    { key: "donor agreement date ad", value: "donor_agreement_date_ad" },
    { key: "donor service charge", value: "donor_service_charge" },
    { key: "donor service charge type", value: "donor_service_charge_type" },
    {
      key: "donor service charge amount",
      value: "donor_service_charge_amount",
    },
    {
      key: "donor service charge amount usd",
      value: "donor_service_charge_amount_usd",
    },
    {
      key: "donor service charge amount npr",
      value: "donor_service_charge_amount_npr",
    },
  ];
  return (
    <ModalLayout onClose={onClose} title="Donor Details">
      <div className="viewloan-donordetails">
        <div className="viewloan-donordetails--grid">
          {TITLE.map((item) => (
            <div key={item} className="viewloan-donordetails--grid__item">
              <div className="viewloan-donordetails--grid__item-key">
                {item.key} -
              </div>
              <div className="viewloan-donordetails--grid__item-value">
                {donor[item.value]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default DonorModal;

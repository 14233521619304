import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../../Components/Modal/ConfirmationModal";
import { doPost, doPut } from "../../../../../Services/Axios";
import PrimaryButton from "../../../../../UI/Button/PrimaryButton";
import ModalLayout from "../../../../../UI/Modal/ModalLayout";
import TextArea from "../../../../../UI/TextArea/TextArea";
import { errorToast } from "../../../../../Utils/Toastify";

const RejectModal = ({ onClose, handleReject }) => {
  const [message, setMessage] = useState("");
  return (
    <ModalLayout
      onClose={onClose}
      title={"Reject"}
      customStyle={{ zIndex: 9999 }}
    >
      <div className="pmcontractor-verifymodal--reject">
        <div className="pmcontractor-verifymodal--reject__textarea">
          <TextArea
            name={"Remarks"}
            placeholder="Eg. code is invalid"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="pmcontractor-verifymodal--reject__submit">
          <PrimaryButton
            onClick={() => handleReject(message)}
            content="Submit"
          />
        </div>
      </div>
    </ModalLayout>
  );
};

const VerifyModal = ({ onClose, contract }) => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const queryClient = useQueryClient();
  const handleConfirmationModal = async (item) => {
    if (item === "REJECT") {
      setShowRejectModal((prev) => !prev);
    } else {
      try {
        const response = await doPut("sub-contract/progress-status/approved", {
          id: contract.id,
        });
        queryClient.refetchQueries("subcontractor");
        onClose();
      } catch (error) {
        errorToast("Error while updating status!!");
      }
    }
  };
  const handleReject = async (message) => {
    try {
      if (!message) return toast.error("Remarks is required");
      const response = await doPut("sub-contract/progress-status/rejected", {
        id: contract.id,
        remarks: message,
      });
      queryClient.refetchQueries("subcontractor");
      onClose();
    } catch (error) {
      errorToast("Error while updating status!!");
    }
  };

  return (
    <>
      {showRejectModal && (
        <RejectModal onClose={onClose} handleReject={handleReject} />
      )}

      <ModalLayout onClose={onClose} title={"Verify Contractor"}>
        <div className="pmcontractor-verifymodal">
          <div className="pmcontractor-verifymodal--message">
            <div className="pmcontractor-verifymodal--message__main">
              Do you really want to verify the contractor?
            </div>
            <div className="pmcontractor-verifymodal--message__wrn">
              <span className="pmcontractor-verifymodal--message__warning">
                Warning:
              </span>
              This process cannot be reverted.
            </div>
          </div>
          <div className="pmcontractor-verifymodal--submit">
            <PrimaryButton
              className="viewpmsubprojects-submit--reject"
              content={"Reject"}
              onClick={() => handleConfirmationModal("REJECT")}
              icon="fa-light fa-times"
            />
            <PrimaryButton
              className="viewpmsubprojects-submit--approve"
              onClick={() => handleConfirmationModal("APPROVE")}
              icon="fa-light fa-check"
              content={"Approve"}
            />
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default VerifyModal;

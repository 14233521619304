import React from "react";
import ModalLayout from "../../UI/Modal/ModalLayout";

const ConfirmationModal = ({ onClose, onSuccess, text, caption }) => {
  return (
    <ModalLayout onClose={onClose} isSpecific>
      <div className="confirmationmodal">
        <div className="confirmationmodal-main">
          {/* <i className="confirmationmodal-main--excl fa-regular fa-circle-exclamation"></i> */}
          {text}
          {caption && (
            <div className="confirmationmodal-sub">
              <span className="confirmationmodal-sub--static">Warning: </span>
              <span className="confirmationmodal-sub--dynamic">{caption}</span>
            </div>
          )}
        </div>
        <div className="confirmationmodal-option">
          <div className="confirmationmodal-option--cross" onClick={onClose}>
            No
          </div>
          <div className="confirmationmodal-option--check" onClick={onSuccess}>
            Yes
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmationModal;

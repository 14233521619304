import React from "react";
import Select from "../../UI/Select/Select";
import { useNavigate } from "react-router-dom";
import useReport from "./useReport";
import { doGet } from "../../Services/Axios";
import { errorToast } from "../../Utils/Toastify";
import { useQuery } from "react-query";

const Report = () => {
  const navigate = useNavigate();
  const { reportEnums, selectedReport } = useReport();

  const handleBack = () => navigate(-1);
  return (
    <div className="report">
      <div className="report-header">
        <div className="report-header--title">
          {/* <div
            className="report-header--title__back"
            title="Back"
            onClick={handleBack}
          >
            <i className="fa-light fa-chevron-left"></i>
          </div> */}
          <span className="report-header--title__name">Report</span>
        </div>
        <div className="report-header--action">
          <div className="report-header--action__source">
            <Select
              label={"Select Report"}
              options={reportEnums}
              preventMouse
              handler={selectedReport.set}
              defaultValue={selectedReport.value}
            />
          </div>
        </div>
      </div>
      <div className="report-body">
        {!selectedReport.value || !selectedReport.component ? (
          <p
            className="notfound notfound-bordered"
            style={{ marginTop: "10px" }}
          >
            No Report Selected
          </p>
        ) : (
          selectedReport.component
        )}
      </div>
    </div>
  );
};

export default Report;

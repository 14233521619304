import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel, downloadExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../../../../Services/Axios";
import { errorToast } from "../../../../Utils/Toastify";
import { toggleLoader } from "../../../../Slice/SystemSlice";
import { intlFormat } from "../../../../Services/Helpers";

const ForexLedger = ({ selectedProject }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { nepaliDate } = useSelector((state) => state.System);
  const [details, setDetails] = useState(null);
  const [toggler, setToggler] = useState({});
  const fetchReport = async (id) => {
    const todaysDate = nepaliDate.getTodayDate().BS;
    const fiscalYear =
      todaysDate.month <= 12 && todaysDate.month > 3
        ? `${todaysDate.year}/${todaysDate.year + 1}`
        : `${todaysDate.year - 1}/${todaysDate.year}`;
    try {
      dispatch(toggleLoader(true));
      const { data } = await doGet(
        `/reports/ctr_loan_ledger/${id}/${fiscalYear.split("/")[0]}%252f${
          fiscalYear.split("/")[1]
        }/forex_ledger`
      );
      dispatch(toggleLoader(false));

      const entries =
        data &&
        data.forex_ledger &&
        Object.keys(data.forex_ledger).length > 0 &&
        data.forex_ledger;

      if (!entries) return;

      // const tempData = []

      const tempData = Object.values(entries).map((i) => {
        const values = Object.values(i);
        const {
          id,
          data: {
            original_data: {
              loan_no,
              contract_no,
              particulars,
              value_date_ad,
              value_date_bs,
              advance_disbursed,
              advance_disbursed_npr,
              advance_disbursed_usd,
              commercial_net_payable_amount,
              commercial_net_payable_amount_npr,
              commercial_net_payable_amount_usd,
              net_payable_amount,
              net_payable_amount_npr,
              net_payable_amount_usd,
              transaction_type,
            },
          },
        } = values[0];
        return {
          basic: {
            id,
            loan_no,
            contract_no,
            particulars,
            value_date_ad,
            value_date_bs,
            advance_disbursed,
            advance_disbursed_npr,
            advance_disbursed_usd,
            commercial_net_payable_amount,
            commercial_net_payable_amount_npr,
            commercial_net_payable_amount_usd,
            net_payable_amount,
            net_payable_amount_npr,
            net_payable_amount_usd,
            transaction_type,
          },
          years: i,
        };
      });

      setDetails(tempData);
    } catch (error) {
      errorToast(error.message);
    }
  };
  useEffect(() => {
    setDetails(null);
    selectedProject && fetchReport(selectedProject.id);
  }, [selectedProject && selectedProject.id]);

  const downloadTable = () => {
    downloadExcel(
      { fileName: `ledger_${Date.now()}`, sheet: "Ledger" },
      ref.current
    );
  };

  const handleToggler = (id) => {
    setToggler((prev) => ({
      ...prev,
      [id]: prev[id] === "rowclose" ? "rowopen" : "rowclose",
    }));
  };

  return details ? (
    <>
      <PrimaryButton
        onClick={() => downloadTable()}
        content={"Export"}
        title="Ledger"
        icon={"fa-light fa-file-spreadsheet"}
        className={"ledger-heading--actions__export"}
      />
      <table width={100} border={1} className="ledger-wrapper--table" ref={ref}>
        <thead>
          <tr>
            <th className="center" colSpan={"100%"}>
              <h1>नेपाल विद्युत प्राधिकरण</h1>
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={"100%"}>
              अर्थ निर्देशनालय
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={"100%"}>
              संस्थागत वित्तिय विभाग
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={"100%"}>
              वित्तिय योजना तथा लगानी महाशाखा
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={"100%"}>
              Forex Ledger -{" "}
              {selectedProject &&
                `${selectedProject.name} - Office Code (${selectedProject.office_code})`}
              {selectedProject &&
                selectedProject.loan_repayment_effective_date &&
                selectedProject.sub_status === 3 &&
                `(Close Date: ${selectedProject.loan_repayment_effective_date})`}
            </th>
          </tr>
          <tr>
            <th rowSpan={3}>Particulars</th>
            <th rowSpan={3}>Loan no</th>
            <th rowSpan={3}>Contract no</th>
            <th colSpan={2}>Value Date</th>
            <th colSpan={3}>Total Disbursed</th>
            <th colSpan={21}>Year/s Details</th>
          </tr>
          <tr>
            <th rowSpan={2}>BS</th>
            <th rowSpan={2}>AD</th>

            <th rowSpan={2}>Donor</th>
            <th rowSpan={2}>USD</th>
            <th rowSpan={2}>NPR</th>

            <th rowSpan={2}>Loss/gain calculation year</th>
            <th colSpan={3}>Ex Rate at disbursed date</th>
            <th colSpan={3}>Previous Year FOREX Rate</th>
            <th colSpan={3}>Current Year FOREX Rate</th>
            <th rowSpan={2}>FLUCTUATED RATE</th>
            <th colSpan={3}>Forex bear by GoN</th>
            <th colSpan={3}>Forex bear by NEA</th>
            <th colSpan={3}>Forex Gain/Loss(-)</th>
          </tr>
          <tr>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
          </tr>
        </thead>
        <tbody>
          {details.length > 0 ? (
            details.map((item, index) => {
              let totalForexAmount = {
                donor: 0,
                usd: 0,
                npr: 0,
              };
              const rows = Object.keys(item.years).length;
              return (
                <>
                  <tr key={index}>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.particulars}
                    </td>
                    <td rowSpan={rows ? rows + 2 : 0}>{item.basic.loan_no}</td>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.contract_no}
                    </td>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.value_date_bs}
                    </td>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.value_date_ad}
                    </td>

                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.transaction_type === "ADVANCE/RETENTION"
                        ? intlFormat(item.basic.advance_disbursed)
                        : item.basic.transaction_type ===
                          "COMMERCIAL INVOICE (LC)"
                        ? intlFormat(item.basic.commercial_net_payable_amount)
                        : intlFormat(item.basic.net_payable_amount)}
                    </td>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.transaction_type === "ADVANCE/RETENTION"
                        ? intlFormat(item.basic.advance_disbursed_usd)
                        : item.basic.transaction_type ===
                          "COMMERCIAL INVOICE (LC)"
                        ? intlFormat(
                            item.basic.commercial_net_payable_amount_usd
                          )
                        : intlFormat(item.basic.net_payable_amount_usd)}
                    </td>
                    <td rowSpan={rows ? rows + 2 : 0}>
                      {item.basic.transaction_type === "ADVANCE/RETENTION"
                        ? intlFormat(item.basic.advance_disbursed_npr)
                        : item.basic.transaction_type ===
                          "COMMERCIAL INVOICE (LC)"
                        ? intlFormat(
                            item.basic.commercial_net_payable_amount_npr
                          )
                        : intlFormat(item.basic.net_payable_amount_npr)}
                    </td>
                  </tr>
                  {Object.keys(item.years).map((y, yi) => {
                    const c = item.years[y].data;

                    totalForexAmount.donor += +c.forex_amount;
                    totalForexAmount.npr += +c.forex_amount_npr;
                    totalForexAmount.usd += +c.forex_amount_usd;

                    return (
                      <tr
                        key={index + yi}
                        // className={`${toggler[item.basic.id] ?? "rowclose"}`}
                      >
                        <th>{y}</th>
                        <td>
                          {intlFormat(
                            (+c.original_data.bill_exchange_rate_donor).toFixed(
                              2
                            )
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.original_data
                              .bill_exchange_rate_donor_usd).toFixed(2)
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.original_data
                              .bill_exchange_rate_donor_npr).toFixed(2)
                          )}
                        </td>

                        <td>
                          {intlFormat(
                            (+c.previous_forex_currency_rate).toFixed(2)
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.previous_forex_currency_rate_usd).toFixed(2)
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.previous_forex_currency_rate_npr).toFixed(2)
                          )}
                        </td>

                        <td>
                          {intlFormat(
                            (+c.current_forex_currency_rate).toFixed(2)
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.current_forex_currency_rate_usd).toFixed(2)
                          )}
                        </td>
                        <td>
                          {intlFormat(
                            (+c.current_forex_currency_rate_npr).toFixed(2)
                          )}
                        </td>

                        <td>
                          {c.gon_exchange_rate_donor_npr
                            ? intlFormat(
                                (+c.gon_exchange_rate_donor_npr).toFixed(2)
                              )
                            : intlFormat(
                                (+c.nea_exchange_rate_donor_npr).toFixed(2)
                              )}
                        </td>

                        <td>{intlFormat((+c.gon_amount_donor).toFixed(2))}</td>
                        <td>{intlFormat((+c.gon_amount_usd).toFixed(2))}</td>
                        <td>{intlFormat((+c.gon_amount_npr).toFixed(2))}</td>

                        <td>{intlFormat((+c.nea_amount_donor).toFixed(2))}</td>
                        <td>{intlFormat((+c.nea_amount_usd).toFixed(2))}</td>
                        <td>{intlFormat((+c.nea_amount_npr).toFixed(2))}</td>

                        <th>{intlFormat((+c.forex_amount).toFixed(2))}</th>
                        <th>{intlFormat((+c.forex_amount_usd).toFixed(2))}</th>
                        <th>{intlFormat((+c.forex_amount_npr).toFixed(2))}</th>
                      </tr>
                    );
                  })}
                  <tr className="primarycolor">
                    <th>
                      {/* <button onClick={() => handleToggler(item.basic.id)}>
                        toggle
                      </button> */}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total Gain/Loss</th>
                    <th>
                      {intlFormat(
                        (+totalForexAmount.donor.toFixed(2)).toFixed(2)
                      )}
                    </th>
                    <th>
                      {intlFormat(
                        (+totalForexAmount.usd.toFixed(2)).toFixed(2)
                      )}
                    </th>
                    <th>
                      {intlFormat(
                        (+totalForexAmount.npr.toFixed(2)).toFixed(2)
                      )}
                    </th>
                  </tr>
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan={"100%"}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  ) : (
    <p className="notfound notfound-bordered">No Data Found</p>
  );
};

export default ForexLedger;

import React from "react";

const TextArea = ({
  customStyle = { main: {}, label: {}, input: {}, error: {} },
  name,
  type,
  placeholder,
  onChange,
  error,
  required,
  readOnly,
  defaultValue,
}) => {
  return (
    <div className="textarea" style={customStyle.label}>
      <label htmlFor={name} className="textarea-label">
        {name && `${name} :`}
        {required && "*"}
      </label>
      <textarea
        className={error ? `textarea-input textarea-error` : `textarea-input`}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        style={
          Object.entries(customStyle.error).length === 0
            ? customStyle.input
            : customStyle.error
        }
        required={required ? true : false}
        readOnly={readOnly ? true : false}
        defaultValue={defaultValue}
      ></textarea>
      <div className="textarea-error--text">{error}</div>
    </div>
  );
};

export default TextArea;

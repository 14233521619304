import React, { useState } from "react";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import DateInput from "../../../UI/DateInput/DateInput";
import { doPut } from "../../../Services/Axios";
import { errorToast, successToast } from "../../../Utils/Toastify";
import { useQueryClient } from "react-query";

export default function CloseProjectModal({ onClose, item }) {
  const queryClient = useQueryClient();
  const handleApprove = async () => {
    try {
      const { data } = await doPut("/sub-project/close/sub-status/approved", {
        sub_project_id: item.id,
      });
      successToast("Project Closed");
      queryClient.refetchQueries(["PM_SubProjects"]);
      onClose();
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleReject = async () => {
    try {
      const { data } = await doPut("/sub-project/close/sub-status/rejected", {
        sub_project_id: item.id,
      });
      successToast("Project Close Request Rejected");
      queryClient.refetchQueries(["PM_SubProjects"]);
      onClose();
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <ModalLayout title={"Project Closing Request"} onClose={onClose}>
      <div className="pmcloseproject">
        <div className="pmcloseproject-title">
          Loan Repayment Effective Date: {item.loan_repayment_effective_date}
        </div>
        <div className="pmcloseproject-title">
          Loan Repayment Effective Date (In AD):{" "}
          {item.loan_repayment_effective_date_ad}
        </div>

        <div className="pmcloseproject-option">
          <div className="pmcloseproject-option--cross" onClick={handleReject}>
            Reject
          </div>
          <div className="pmcloseproject-option--check" onClick={handleApprove}>
            Approve
          </div>
        </div>
      </div>
    </ModalLayout>
  );
}

import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TopBar from "../../Components/TopBar/TopBar";

import { fetchAccountChief } from "../AccountChief/AccountChief";
import AdminController from "../Admin/_admin";
import { NoticeController } from "../Notice/NoticeController";
import { fetchProjectManager } from "../ProjectManager/ProjectManager";
import ProjectController from "../Projects/View/_project";

export default function DashboardScreen() {
  const page = 1;
  const { user } = useSelector((state) => state.System);
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["notices", page],
    queryFn: () => NoticeController.fetchNotices(page),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    keepPreviousData: true,
  });

  const accountchief = useQuery({
    queryKey: "AccountChief",
    queryFn: fetchAccountChief,
    enabled: true,
    cacheTime: 5 * 60 * 1000,
  });
  const { fetchProject } = ProjectController;
  const { fetchAdmin } = AdminController;
  const Projects = useQuery({
    queryKey: ["Projects", page],
    queryFn: () => fetchProject(page),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
  });
  const Admin = useQuery({
    queryKey: "Admin",
    queryFn: fetchAdmin,
    enabled: true,
    cacheTime: 15 * 60 * 1000,
  });
  const ProjectManager = useQuery({
    queryKey: "ProjectManager",
    queryFn: fetchProjectManager,
    enabled: true,
    cacheTime: 30 * 60 * 1000,
  });

  return (
    <div className="dashboardpage">
      <TopBar title={"Dashboard"} />
      <div className="dashboardpage-main">
        {user &&
        user.user &&
        user.user.user_type &&
        user.user.user_type === "Admin" ? (
          <>
            {" "}
            <Link to={"/admin"}>
              <div className="card">
                <div className="card-main">
                  <i className="card-main--icon fa-regular fa-users"></i>
                  <div className="card-main--name">Admins</div>
                  <div className="card-main--number">
                    {Admin &&
                      Admin.data &&
                      Admin.data.users &&
                      Admin.data.users.data &&
                      Admin.data.users.data.length}
                  </div>
                </div>
              </div>
            </Link>
            <Link to={"/accountchief"}>
              <div className="card">
                <div className="card-main">
                  <i className="card-main--icon fa-regular fa-users"></i>
                  <div className="card-main--name">Account Chief</div>
                  <div className="card-main--number">
                    {accountchief &&
                      accountchief.data &&
                      accountchief.data.users &&
                      accountchief.data.users &&
                      accountchief.data.users.length}
                  </div>
                </div>
              </div>
            </Link>
            <Link to={"/projectmanager"}>
              <div className="card">
                <div className="card-main">
                  <i className="card-main--icon fa-regular fa-users"></i>
                  <div className="card-main--name">Project Manager</div>
                  <div className="card-main--number">
                    {ProjectManager &&
                      ProjectManager.data &&
                      ProjectManager.data.users &&
                      ProjectManager.data.users &&
                      ProjectManager.data.users.length}
                  </div>
                </div>
              </div>
            </Link>
          </>
        ) : null}
        <Link to={"/report"}>
          <div className="card">
            <div className="card-main">
              <i className="card-main--icon fa-regular fa-file-lines"></i>
              <div className="card-main--name">Reports</div>
              <div className="card-main--number">{4}</div>
            </div>
          </div>
        </Link>
        {/* <Link
          to={
            user &&
            user.user &&
            user.user.user_type &&
            user.user.user_type === "Admin"
              ? "projects"
              : user.user.user_type === "Account Chief"
              ? "ac/projects"
              : "pm/projects"
          }
        >
          <div className="card">
            <div className="card-main">
              <i className="card-main--icon fa-regular fa-diagram-project"></i>
              <div className="card-main--name">Projects</div>
              <div className="card-main--number">
                {Projects &&
                  Projects.data &&
                  Projects.data.projects &&
                  Projects.data.projects.data &&
                  Projects.data.projects.data.length}
              </div>
            </div>
          </div>
        </Link> */}
        <Link to={"/notice"}>
          <div className="card">
            <div className="card-main">
              <i className="card-main--icon fa-regular fa-square-info"></i>
              <div className="card-main--name">Notices</div>
              <div className="card-main--number">
                {data &&
                  data.data &&
                  data.data.notices &&
                  data.data.notices.data &&
                  data.data.notices.data.length}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

import { doGet } from "../../../Services/Axios";

const SubProjectController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "sub_project_name", sortable: true, searchable: true },
    { name: "loan/grant", sortable: true, searchable: true },
    { name: "account_chief", sortable: true, searchable: true },
    { name: "project_manager", sortable: true, searchable: true },
    { name: "pre_information", sortable: false, searchable: false },
    { name: "contractors", sortable: false, searchable: false },
    { name: "budget", sortable: false, searchable: false },
    { name: "billing", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "created_by", sortable: true, searchable: true },
    { name: "created_at", sortable: true, searchable: false },
  ],
  fetchSubProject: async (id, page) => {
    try {
      const { data } = await doGet(`/sub-project/${id}/show?page=${page}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  statusEnums: {
    0: { label: "Pending", color: "#6b6a6a" },
    1: { label: "In Progress", color: "#f1d900" },
    2: { label: "Approved", color: "#1fb626" },
    3: { label: "Rejected", color: "#c71c1c" },
  },
};
export default SubProjectController;

import Auth from "./Auth";
import Frame from "./Frame";
const WrapWithFrame = ({ children, frameLess, index }) => {
  return !frameLess ? (
    <Auth>
      <Frame index={index}>{children}</Frame>
    </Auth>
  ) : (
    <Auth>{children}</Auth>
  );
};
export default WrapWithFrame;

import React from 'react';
import LoginScreen from '../Pages/Login/Login';
import { getUserFromLocalStorage } from '../Services/Helpers';

const Jumper = ({ children }) => {
  //   if (getUserFromLocalStorage()) {
  //     return (window.location.href = '/');
  //   } else {
  return children;
  //   }
};

export default Jumper;

import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { doPut } from "../../../../../Services/Axios";
import PrimaryButton from "../../../../../UI/Button/PrimaryButton";
import ModalLayout from "../../../../../UI/Modal/ModalLayout";
import TextArea from "../../../../../UI/TextArea/TextArea";
import { errorToast, successToast } from "../../../../../Utils/Toastify";

export default function AddRemarkModel({
  onClose,
  subprojectid,
  parentModalToggler,
}) {
  const queryClient = useQueryClient();
  const [remarks, setRemarks] = useState("");
  const handleRemarks = (e) => setRemarks(e.target.value);

  const handleSubmit = async () => {
    try {
      if (!remarks) throw new Error("Remark is required");
      const { data } = await doPut("/sub-project/progress-status/rejected", {
        id: subprojectid,
        remarks,
      });
      successToast("Status updated");
      onClose();
      parentModalToggler();
      queryClient.refetchQueries(["PM_SubProjects"]);
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    subprojectid && (
      <ModalLayout title={"Add Rejection Comment"} onClose={onClose}>
        <div className="addremarkmodal">
          <div className="addremarkmodal-action">
            <PrimaryButton content="Save Remark" onClick={handleSubmit} />
          </div>
          <TextArea
            onChange={handleRemarks}
            placeholder="eg. Due to some reason..."
            defaultValue={remarks}
          />
        </div>
      </ModalLayout>
    )
  );
}

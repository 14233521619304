import React, { useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Nav from "../Components/Nav/Nav";

export default function Frame({ children, index }) {
  return (
    <div className="frame">
      <Sidebar active={index} />
      <div className="frame-body">
        <Nav />
        {children}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { baseUrl } from "../../Constants/Constants";
import FileInput from "../../UI/FileInput/FileInput";
import ModalLayout from "../../UI/Modal/ModalLayout";
import PrimaryButton from "../../UI/Button/PrimaryButton";

const FilesModal = ({ handleClose, files, edit, type, handleAdd }) => {
  const [images, setImages] = useState([]);
  const handleAddProp = (e) => {
    setImages(e);
  };
  const acceptedType = {
    png: true,
    jpg: true,
  };

  return (
    <ModalLayout title={"Files"} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem 0",
        }}
      >
        {images.length > 0 && (
          <PrimaryButton onClick={() => handleAdd(images)} content={"Add"} />
        )}
      </div>
      <div
        className={` ${
          files.length === 0 ? "filesmodal-nodata" : "filesmodal"
        }`}
      >
        {edit && (
          <FileInput handleSubmitImage={handleAddProp} imagesProp={images} />
        )}

        {files.length > 0 ? (
          <div className="filesmodal-main">
            {files &&
              files.length > 0 &&
              files.map((item, index) => {
                return item &&
                  item.document &&
                  acceptedType[
                    item.document.split(".")[
                      item.document.split(".").length - 1
                    ]
                  ] ? (
                  <div className="filesmodal-main--item__wrapper">
                    <a
                      href={`${baseUrl.split("/api/v1")[0]}/storage/${
                        item.document
                      }`}
                      target="_blank"
                      rel="noreferrer"
                      key={item}
                    >
                      <div className="filesmodal-main--item">
                        <img
                          src={`${baseUrl.split("/api/v1")[0]}/storage/${
                            item.document
                          }`}
                          alt={item.document}
                          className="filesmodal-main--item__image"
                        ></img>
                      </div>
                      <div className="filesmodal-main--item__label">
                        {item.document
                          .split(".")[0]
                          .split("/")[1]
                          .slice(0, 12) +
                          "." +
                          item.document.split(".")[1]}
                      </div>
                    </a>
                    {/* {edit && (
                        <div className="filesmodal-main--item__edit">
                          <i className="fa-light fa-trash"></i>
                        </div>
                      )} */}
                  </div>
                ) : (
                  <div className="filesmodal-main--item__wrapper">
                    <a
                      href={`${baseUrl.split("/api/v1")[0]}/storage/${
                        item.document
                      }`}
                      target="_blank"
                      rel="noreferrer"
                      key={item.document}
                    >
                      <div className="filesmodal-main--item">
                        <i className="fa-regular fa-file-lines filesmodal-main--item__icon"></i>
                      </div>
                      <div className="filesmodal-main--item__label">
                        {item.document
                          .split(".")[0]
                          .split("/")[1]
                          .slice(0, 12) +
                          "." +
                          item.document.split(".")[1]}
                      </div>
                    </a>
                    {/* {edit && (
                        <div className="filesmodal-main--item__edit">
                          <i className="fa-light fa-trash"></i>
                        </div>
                      )} */}
                  </div>
                );
              })}
          </div>
        ) : (
          <p className="notfound">No Files Found</p>
        )}
      </div>
    </ModalLayout>
  );
};

export default FilesModal;

import { doGet } from "../../../Services/Axios";
const LoanController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "type", sortable: true, searchable: true },
    { name: "loan/share number", sortable: true, searchable: true },
    { name: "donor_agency", sortable: true, searchable: true },
    { name: "currency", sortable: true, searchable: true },
    { name: "amount", sortable: true, searchable: true },
    { name: "agreement_date (Donor-GoN)", sortable: true, searchable: true },
    { name: "agreement_date (GoN-NEA)", sortable: true, searchable: true },
    { name: "donor_service_charge", sortable: false, searchable: false },
    { name: "forex_bearer", sortable: false, searchable: false },
    { name: "penalty", sortable: false, searchable: false },
    { name: "other_details", sortable: false, searchable: false },
    { name: "documents", sortable: false, searchable: false },
    { name: "created_by", sortable: true, searchable: true },
    { name: "created_at", sortable: true, searchable: false },
  ],
  fetchLoans: async (id) => {
    try {
      const { data } = await doGet(`/loan/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default LoanController;

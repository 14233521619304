import React from "react";
import ModalLayout from "../../../UI/Modal/ModalLayout";

const DescriptionModal = ({ item, handleClose }) => {
  const d = document.createElement("div");
  d.innerHTML = item;
  return (
    <ModalLayout onClose={handleClose} title={"Description"}>
      <div className="descriptionmodal">{item}</div>
    </ModalLayout>
  );
};

export default DescriptionModal;

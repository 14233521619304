import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createProject: {
    type: "Internal" | "External",
    title: "XYZ Project in Butwal",
    subProjects: [
      {
        title: "XYZ Sub Project",
        projectManager: null | "28934782934",
        accountChief: null | "28934782934",
        status: true | false,

        loans: [
          {
            //!Loan Information
            loanNumber: "2309482934",
            donorAgency: "Korea",
            currency: "USA" | "NPR" | "INR", //!Limit to only Projects Donor Currency
            amount: 2_00_00_000,
            inUSD: 2_00_00_000,
            inNPR: 2_00_00_000,
            agreementDate: "2022-01-01",
            serviceCharge:
              null |
              {
                type: "One time Charge" | "Yearly Charge",
                checked:
                  "Additive/Deductive to Loan" |
                  "Non Additive/Deductive to Loan",
                amount: 2_00_00_000,
              },
            forexRiskBearer: {
              type: "NEA" | "GoN" | null,
              checked:
                "Additive/Deductive to Loan" |
                "Non Additive/Deductive to P/L Account" |
                null,
            },
            penalty: null | 4,

            //!Grace Period
            gracePeriodStart: "2022-01-01",
            gracePeriodEnd: "2029-01-01",
            interestDuringGracePeriod: 9, //Revised
            interestAfterGracePeriod: 13, //Revised

            //!Installment
            installmentStartDate: "2022-01-01",
            installmentEndDate: "2022-01-01",
            installmentDuration: "Quarterly" | "Half Yearly" | "Yearly",
            installmentInDays: 450, //Auto

            //!IDC
            idcEffectiveDate: "2022-01-01",
            idcEndingDate: "2022-01-01",
            idcInDays: 450,
            idcBearing: {
              NEA: 30,
              GoN: 70,
            },
            idcConversion1: {
              payableToGoN: 80,
              transferToShare: 20,
            },
            idcConversion2: {
              transferToCWIP: 70,
              transferToLoan: 30,
            },
          },
        ],
      },
    ],
    loans: [
      {
        //!Loan Information
        type: "GoN Loan | Share" | "Donor Loan | Grant",
        loanNumber: "2309482934",
        donorAgency: "Korea",
        currency: "USA" | "NPR" | "INR",
        amount: 2_00_00_000,
        inUSD: 2_00_00_000,
        inNPR: 2_00_00_000,
        agreementDate: "2022-01-01",
        serviceCharge:
          null |
          {
            type: "One time Charge" | "Yearly Charge",
            checked:
              "Additive/Deductive to Loan" | "Non Additive/Deductive to Loan",
            amount: 2_00_00_000,
          },
        forexRiskBearer: {
          type: "NEA" | "GoN" | null,
          checked:
            "Additive/Deductive to Loan" |
            "Non Additive/Deductive to P/L Account" |
            null,
        },
        penalty: null | 4,

        //!Grace Period
        gracePeriodStart: "AD: 2022-01-01" | "BS: (converted in BS)",
        gracePeriodEnd: "2029-01-01",
        interestDuringGracePeriod: 9, //Revised
        interestAfterGracePeriod: 13, //Revised

        //!Installment
        installmentStartDate: "2022-01-01",
        installmentEndDate: "2022-01-01",
        installmentDuration: "Quarterly" | "Half Yearly" | "Yearly" | "Monthly",
        installmentInDays: 450, //Auto

        //!IDC
        idcEffectiveDate: "2022-01-01",
        idcEndingDate: "2022-01-01",
        idcInDays: 450,
        idcBearing: {
          NEA: 30,
          GoN: 70,
        },
        idcConversion1: {
          payableToGoN: 80,
          transferToShare: 20,
        },
        idcConversion2: {
          transferToCWIP: 70,
          transferToLoan: 30,
        },
      },
    ],
  },

  createSubProject: {
    projectId: "2837423423",
    title: "XYZ Sub Project",
    projectManager: null | "28934782934",
    accountChief: null | "28934782934",
    status: true | false,

    loans: [
      {
        //!Loan Information
        loanNumber: "2309482934",
        donorAgency: "Korea", //!Limit to Project Donor Agency
        currency: "USA" | "NPR" | "INR", //!Auto Pick From donorAgency
        amount: 2_00_00_000,
        inUSD: 2_00_00_000,
        inNPR: 2_00_00_000,
        agreementDate: "2022-01-01",
        serviceCharge:
          null |
          {
            type: "One time Charge" | "Yearly Charge",
            checked:
              "Additive/Deductive to Loan" | "Non Additive/Deductive to Loan",
            amount: 2_00_00_000,
          },
        forexRiskBearer: {
          type: "NEA" | "GoN" | null,
          checked:
            "Additive/Deductive to Loan" |
            "Non Additive/Deductive to P/L Account" |
            null,
        },
        penalty: null | 4,

        //!Grace Period
        gracePeriodStart: "2022-01-01",
        gracePeriodEnd: "2029-01-01",
        interestDuringGracePeriod: 9, //Revised
        interestAfterGracePeriod: 13, //Revised

        //!Installment
        installmentStartDate: "2022-01-01",
        installmentEndDate: "2022-01-01",
        installmentDuration: "Quarterly" | "Half Yearly" | "Yearly",
        installmentInDays: 450, //Auto

        //!IDC
        idcEffectiveDate: "2022-01-01",
        idcEndingDate: "2022-01-01",
        idcInDays: 450,
        idcBearing: {
          NEA: 30,
          GoN: 70,
        },
        idcConversion1: {
          payableToGoN: 80,
          transferToShare: 20,
        },
        idcConversion2: {
          transferToCWIP: 70,
          transferToLoan: 30,
        },
      },
    ],
  },

  createLoan: {
    projectId: "2837423423",

    //!Loan Information
    loanNumber: "2309482934",
    donorAgency: "Korea",
    currency: "USA" | "NPR" | "INR", //!Limit to only Projects Donor Currency
    amount: 2_00_00_000,
    inUSD: 2_00_00_000,
    inNPR: 2_00_00_000,
    agreementDate: "2022-01-01",
    serviceCharge:
      null |
      {
        type: "One time Charge" | "Yearly Charge",
        checked:
          "Additive/Deductive to Loan" | "Non Additive/Deductive to Loan",
        amount: 2_00_00_000,
      },
    forexRiskBearer: {
      type: "NEA" | "GoN" | null,
      checked:
        "Additive/Deductive to Loan" |
        "Non Additive/Deductive to P/L Account" |
        null,
    },
    penalty: null | 4,

    //!Grace Period
    gracePeriodStart: "2022-01-01",
    gracePeriodEnd: "2029-01-01",
    interestDuringGracePeriod: 9, //Revised
    interestAfterGracePeriod: 13, //Revised

    //!Installment
    installmentStartDate: "2022-01-01",
    installmentEndDate: "2022-01-01",
    installmentDuration: "Quarterly" | "Half Yearly" | "Yearly",
    installmentInDays: 450, //Auto

    //!IDC
    idcEffectiveDate: "2022-01-01",
    idcEndingDate: "2022-01-01",
    idcInDays: 450,
    idcBearing: {
      NEA: 30,
      GoN: 70,
    },
    idcConversion1: {
      payableToGoN: 80,
      transferToShare: 20,
    },
    idcConversion2: {
      transferToCWIP: 70,
      transferToLoan: 30,
    },
  },

  createSubProjectLoan: {
    subProjectId: "2837423423",

    //!Loan Information
    loanNumber: "2309482934",
    donorAgency: "Korea",
    currency: "USA" | "NPR" | "INR", //!Limit to only Projects Donor Currency
    amount: 2_00_00_000,
    inUSD: 2_00_00_000,
    inNPR: 2_00_00_000,
    agreementDate: "2022-01-01",
    serviceCharge:
      null |
      {
        type: "One time Charge" | "Yearly Charge",
        checked:
          "Additive/Deductive to Loan" | "Non Additive/Deductive to Loan",
        amount: 2_00_00_000,
      },
    forexRiskBearer: {
      type: "NEA" | "GoN" | null,
      checked:
        "Additive/Deductive to Loan" |
        "Non Additive/Deductive to P/L Account" |
        null,
    },
    penalty: null | 4,

    //!Grace Period
    gracePeriodStart: "2022-01-01",
    gracePeriodEnd: "2029-01-01",
    interestDuringGracePeriod: 9, //Revised
    interestAfterGracePeriod: 13, //Revised

    //!Installment
    installmentStartDate: "2022-01-01",
    installmentEndDate: "2022-01-01",
    installmentDuration: "Quarterly" | "Half Yearly" | "Yearly",
    installmentInDays: 450, //Auto

    //!IDC
    idcEffectiveDate: "2022-01-01",
    idcEndingDate: "2022-01-01",
    idcInDays: 450,
    idcBearing: {
      NEA: 30,
      GoN: 70,
    },
    idcConversion1: {
      payableToGoN: 80,
      transferToShare: 20,
    },
    idcConversion2: {
      transferToCWIP: 70,
      transferToLoan: 30,
    },
  },
};

const adminSlice = createSlice({
  name: "AdminSlice",
  initialState: initialState,
  reducers: {
    resetCreateProject: (state) => {
      state.createProject = null;
    },
    resetCreateSubProject: (state) => {
      state.createSubProject = null;
    },
    resetCreateLoans: (state) => {
      state.createLoan = null;
    },
  },
});

export const { resetCreateLoans, resetCreateProject, resetCreateSubProject } =
  adminSlice.actions;

export default adminSlice.reducer;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import FilesModal from "../../../../Components/FilesModal/FilesModal";
import TopBar from "../../../../Components/TopBar/TopBar";

import { doGet } from "../../../../Services/Axios";

import Table from "../../../../UI/Table/Table";
import View from "../../../../UI/View/View";
import { errorToast, warningToast } from "../../../../Utils/Toastify";
import ViewApg from "./ApgModal";

const AdminBudget = () => {
  const params = useParams();

  const [documents, setDocuments] = useState(null);
  const [apg, setApg] = useState(null);

  const COLUMNS = [
    { name: "S.N.", sortable: true, searchable: true },

    { name: "status", sortable: true, searchable: true },
    { name: "loan_number", sortable: true, searchable: true },
    { name: "contract_number", sortable: true, searchable: true },
    { name: "contractor_name", sortable: true, searchable: true },
    { name: "fiscal_year", sortable: true, searchable: true },
    { name: "donor_agency", sortable: true, searchable: true },
    { name: "activity_number", sortable: true, searchable: true },
    { name: "budget_head", sortable: true, searchable: true },
    { name: "expenses_head", sortable: true, searchable: true },
    { name: "budget_re_imburse", sortable: true, searchable: true },
    { name: "approved_budget", sortable: false, searchable: false },
    { name: "estimated_cost", sortable: false, searchable: false },
    { name: "Advance payment guarentee", sortable: false, searchable: false },
    { name: "Documents", sortable: false, searchable: false },
    { name: "created_at", sortable: true, searchable: true },
  ];

  const Budget = useQuery({
    enabled: true,
    queryKey: ["budget", params.id],
    queryFn: async () => {
      try {
        const { data } = await doGet(`/budget-info/${params.id}/shows`);
        return data;
      } catch (error) {
        throw error;
      }
    },
    onError: () => {
      errorToast("Error while fetching Budget");
    },
  });
  const statusEnums = {
    0: { label: "In Progress", color: "#f1d900" },
    1: { label: "Approved", color: "#1fb626" },
    2: { label: "Rejected", color: "#c71c1c" },
  };

  const handleShowDocuments = (id) => {
    Budget.data && Budget.data.images && Budget.data.images.length > 0
      ? setDocuments(
          Budget.data.images.filter((i) => i.document_type_id === id)
        )
      : warningToast("No documents found");
  };
  const handleShowApg = (item) => {
    Budget.data &&
    Budget.data.advance_payment_guarantee &&
    Budget.data.advance_payment_guarantee.length > 0
      ? setApg(
          Budget.data.advance_payment_guarantee.filter(
            (i) => i.budget_id === item
          )
        )
      : warningToast("No advance Payment fount");
  };
  return (
    <div>
      {documents && (
        <FilesModal
          files={documents.map((item) => {
            return item;
          })}
          handleClose={() => setDocuments(null)}
        />
      )}
      {apg && <ViewApg list={apg} onClose={() => setApg(null)} />}

      <TopBar title={"Budget"} backPage={true} />
      <Table
        loading={Budget.isLoading}
        Rows={
          Budget && Budget.data && Budget.data.budget_infos
            ? Budget.data.budget_infos.map((item, index) => ({
                sn: index + 1,
                status: (
                  <View
                    label={statusEnums[item.progress_status].label}
                    customStyle={{
                      backgroundColor: statusEnums[item.progress_status].color,
                    }}
                    type="button"
                  />
                ),
                loan_id: item.loan_number,
                contract_id: item.contract_number,
                contractor_name: item.contractor_name,
                fiscal_year: item.fiscal_year,
                donor_agency: item.donor_agency,

                activity_number: item.activity_number,

                budget_head: item.budget_head,
                expenses_head: item.expenses_head,

                budget_re_imburse: item.budget_re_imburse,
                approved_budget: (
                  <>
                    <strong>Amount:</strong>
                    {item.approved_budget} <br />
                    <strong>In NPR: </strong>
                    {item.approved_budget_npr} <br />
                    <strong>In USD: </strong>
                    {item.approved_budget_usd}
                  </>
                ),
                estimated_cost: (
                  <>
                    <strong>Amount:</strong>
                    {item.estimated_cost} <br />
                    <strong>In NPR: </strong>
                    {item.estimated_cost_npr} <br />
                    <strong>In USD: </strong>
                    {item.estimated_cost_usd}
                  </>
                ),
                apg: (
                  <View
                    onClick={() => handleShowApg(item.id)}
                    label="View"
                    type={"button"}
                  />
                ),

                documents: (
                  <View
                    label={"Documents"}
                    type="button"
                    onClick={() => handleShowDocuments(item.id)}
                  />
                ),
                created_at: item.created_at.split("T")[0],
              }))
            : []
        }
        Columns={COLUMNS}
      />
    </div>
  );
};
export default AdminBudget;

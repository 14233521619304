import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../../Components/TopBar/TopBar";
import { useSelector } from "react-redux";
import StatusBar from "../../../UI/StatusBar/StatusBar";
import Table from "../../../UI/Table/Table";

import { errorToast } from "../../../Utils/Toastify";

import View from "../../../UI/View/View";
import SubProjectController from "./_pmsubProject";
import ViewPreInformation from "./PreInformation/ViewPreInformation";
import CloseProjectModal from "./CloseProjectModal";

const PM_SubProjects = () => {
  const { user } = useSelector((state) => state.System);
  const { id } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!id) return navigate(-1);
  }, []);

  const { Columns, fetchSubProject, statusEnums } = SubProjectController;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["PM_SubProjects"],
    queryFn: () => fetchSubProject(user.user.id, "Project Manager"),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching subprojects"),
  });
  const [subProjectDetails, setSubProjectsDetails] = useState();
  const [showSubProjectDetails, setShowSubProjectsDetails] = useState();
  const [closeProjectModal, setCloseProjectModal] = useState(null);

  const handleShowDetails = (item) => {
    setSubProjectsDetails(item);
    setShowSubProjectsDetails((prev) => !prev);
  };

  return (
    <div className="viewpmsubproject">
      {closeProjectModal && (
        <CloseProjectModal
          item={closeProjectModal}
          onClose={() => setCloseProjectModal(null)}
        />
      )}
      {showSubProjectDetails && (
        <ViewPreInformation
          onClose={() => setShowSubProjectsDetails(false)}
          subproject={subProjectDetails}
        />
      )}
      <TopBar title={"Sub Project"} subtitle={"Sub Project"} backPage={true} />
      <div className="viewpmsubproject-main">
        <div className="viewpmsubproject-main--table">
          <Table
            Columns={Columns}
            Rows={
              data &&
              data.sub_project_details &&
              data.sub_project_details.length > 0
                ? data.sub_project_details
                    .filter((i) => i.parent_id == id)
                    .map((item, index) => ({
                      sn: index + 1,
                      project_name: item.name,
                      pre_information: statusEnums[item.progress_status] ? (
                        <View
                          label={statusEnums[item.progress_status].label}
                          customStyle={{
                            backgroundColor:
                              statusEnums[item.progress_status].color,
                          }}
                          type="button"
                          onClick={() =>
                            item.progress_status != "0" &&
                            handleShowDetails(item)
                          }
                        />
                      ) : (
                        "N/A"
                      ),
                      contractor: (
                        <View
                          label="Contract/s"
                          to={`/pm/contractor/${item.id}`}
                        />
                      ),

                      budget: (
                        <View label="Budget/s" to={`/pm/budget/${item.id}`} />
                      ),
                      bills: (
                        <View
                          label="Bill/s"
                          to={`/pm/projects/${item.id}/billing`}
                        />
                      ),
                      status: (
                        <StatusBar
                          status={item.sub_status}
                          isProgress={true}
                          disabled={true}
                        />
                      ),
                      created_at: item.created_at.split(" ")[0],
                      actions:
                        item.sub_status == 1 || item.sub_status == 4 ? (
                          "N/A"
                        ) : item.sub_status == 3 ? (
                          item.loan_repayment_effective_date
                        ) : (
                          <View
                            label="Verify Project Close Request"
                            type={"button"}
                            onClick={() => setCloseProjectModal(item)}
                          />
                        ),
                    }))
                : []
            }
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default PM_SubProjects;

import React from "react";
import Input from "../../../UI/Input/Input";
import CreateLoan from "../../../Components/CreateLoan/CreateLoan";

export default function Editor({ data, functions, language }) {
  const { loan, error, project, projectError } = data;
  const { handleName, handleLoan, handleImage } = functions;

  return (
    <div className="editor">
      <div className="editor-project">
        <Input
          label={"Project Name"}
          name="project_name"
          placeholder={"eg. Syange Electricity Company Limited "}
          required
          defaultValue={project}
          onChange={handleName}
          error={projectError}
          isLanguageChangeable={true}
          language={language}
        />
      </div>
      <div className="editor-loan">
        {!loan ? (
          <div className="notfound notfound-bordered">No Loan Selected</div>
        ) : (
          <CreateLoan
            loan={loan}
            errors={error}
            changeHandler={handleLoan}
            defaultCurrency={null}
            handleImage={handleImage}
          />
        )}
      </div>
    </div>
  );
}

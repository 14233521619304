import React from "react";

export default function Checkbox({ label, name, onChange, defaultValue }) {
  const handleChecked = (e) => {
    const data = { target: { value: null, name } };
    data.target.value = e.target.checked;
    onChange(data);
  };
  return (
    <div className="checkbox">
      <input
        onChange={handleChecked}
        type="checkbox"
        className="checkbox-input"
        name={name ? name : "default_checkbox"}
        id={name ? name : "default_checkbox"}
        defaultValue={defaultValue === false ? undefined : true}
        checked={!defaultValue ? false : true}
      />
      {label && <div className="checkbox-label">{label}</div>}
    </div>
  );
}

import React from "react";

const Select = ({
  options,
  handler,
  required,
  customStyle,
  width,
  label,
  name,
  defaultValue,
  disabled,
  error,
  preventMouse,
}) => {
  return (
    <div
      className={`select ${label ? "labeled" : ""}`}
      style={{
        width: width ? width : "auto",
      }}
    >
      {label && (
        <label htmlFor={name}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <select
        className={!error ? "select-select" : "select-select select-error"}
        name={name}
        id={name}
        onChange={handler}
        value={
          defaultValue !== null && defaultValue !== undefined
            ? defaultValue
            : "Select an option"
        }
        disabled={disabled}
        onMouseUp={!preventMouse ? handler : () => null}
      >
        <option value={"Select an option"} hidden unselectable="on">
          Select an option
        </option>
        {options.map((item, index) => (
          <option
            key={index}
            value={item.value ? item.value : item.key}
            disabled={item.disabled ? item.disabled : undefined}
          >
            {item.key}
          </option>
        ))}
      </select>
      {error && <div className="select-error--text">{error ? error : ""}</div>}
    </div>
  );
};

export default Select;

import React from "react";
import { json } from "react-router-dom";
import ModalLayout from "../../../UI/Modal/ModalLayout";

const FullDetails = ({ onClose, full_details }) => {
  const OPTIONS = {
    grace: {
      0: { key: "grace period start date", value: "grace_period_start_date" },
      1: {
        key: "grace period start date ad",
        value: "grace_period_start_date_ad",
      },
      2: { key: "grace period end date", value: "grace_period_end_date" },
      4: {
        key: "grace period end date ad",
        value: "grace_period_end_date_ad",
      },
      5: {
        key: "grace period interest rate",
        value: "grace_period_interest_rate",
      },
      6: {
        key: "interest rate after grace period",
        value: "interest_rate_after_grace_period",
      },
    },
    idc_details: {
      0: { key: "idc effective date", value: "idc_effective_date" },
      1: { key: "idc effective date ad", value: "idc_effective_date_ad" },
      2: { key: "idc ending date", value: "idc_ending_date" },
      3: { key: "idc ending date ad", value: "idc_ending_date_ad" },
    },
    IDC_BEARING: {
      4: { key: "idc no of days", value: "idc_no_of_days" },
      5: { key: "idc nea portion", value: "idc_nea_portion" },
      6: { key: "idc gon portion", value: "idc_gon_portion" },
    },
    IDC_CONVERSION_1: {
      7: { key: "idc payable to gon", value: "idc_payable_to_gon" },
      8: { key: "idc transfer to share", value: "idc_transfer_to_share" },
    },
    IDC_CONVERSION_2: {
      9: { key: "idc transfer to cwip", value: "idc_transfer_to_cwip" },
      10: { key: "idc transfer to loan", value: "idc_transfer_to_loan" },
    },

    installment: {
      1: { key: "installment start date", value: "installment_start_date" },
      2: {
        key: "installment start date ad",
        value: "installment_start_date_ad",
      },
      3: { key: "installment end date", value: "installment_end_date" },
      4: { key: "installment end date ad", value: "installment_end_date_ad" },
      5: { key: "installment period", value: "installment_period" },
      6: { key: "no of installment", value: "no_of_installment" },
    },
  };
  return (
    <ModalLayout onClose={onClose} title={"Full Details"}>
      <div className="viewloan-fulldetails">
        <div className="viewloan-fulldetails--grid">
          {/* {OPTIONS.map((item) => (
            <div key={item} className="viewloan-donordetails--grid__item">
              <div className="viewloan-donordetails--grid__item-key">
                {item.key} -
              </div>
              <div className="viewloan-donordetails--grid__item-value">
                {full_details[item.value]}
              </div>
            </div>
          ))} */}

          {Object.entries(OPTIONS).map((item) => (
            <div
              key={item}
              className="viewloan-fulldetails--grid__item--wrapper"
            >
              <div className="viewloan-fulldetails--grid__item-title">
                {item[0]}
              </div>
              <div className="viewloan-fulldetails--grid__item-desc">
                {Object.values(item[1]).map((el) => (
                  <div className="viewloan-fulldetails--grid__item">
                    <div className="viewloan-fulldetails--grid__item-key">
                      {el.key}
                    </div>
                    <div className="viewloan-fulldetails--grid__item-value">
                      {full_details[el.value]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default FullDetails;

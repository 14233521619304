import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TopBar from "../../../Components/TopBar/TopBar";
import Table from "../../../UI/Table/Table";
import LoanController from "./_loan";
import { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { errorToast, successToast } from "../../../Utils/Toastify";
import View from "../../../UI/View/View";
import SmallButton from "../../../UI/Button/SmallButton";
import { useState } from "react";
import AddLoanModal from "./AddLoan/AddLoanModal";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import DonorModal from "./DonorModal";
import ForexModal from "./ForexModal";
import FullDetails from "./FullDetails";
import FilesModal from "../../../Components/FilesModal/FilesModal";
import { doPost } from "../../../Services/Axios";

const Loan = () => {
  const { id } = useParams();
  const from = new URL(document.location).searchParams.get("from");
  const parentId = new URL(document.location).searchParams.get("parent");
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (!id || !from || (from === "subproject" && !parentId))
      return navigate(-1);
  }, []);

  const [addLoan, setAddLoan] = useState(false);
  const [showDonor, setShowDonor] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState({});
  const [showForex, setShowForex] = useState(false);
  const [loanImageId, setLoanImageId] = useState(null);
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [documents, setDocuments] = useState(null);
  const { Columns, fetchLoans } = LoanController;

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["Loans", id, from],
    queryFn: () => fetchLoans(id),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });

  const projectLoans = useQuery({
    queryKey: ["OneTimeLoan", parentId],
    queryFn: () => fetchLoans(parentId),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => (parentId ? errorToast("Error fetching loans") : null),
  });

  const handleShowFullDetails = (item) => {
    setSelectedLoan(item);
    setShowFullDetails((prev) => !prev);
  };
  const handleShowForex = (item) => {
    setSelectedLoan(item);
    setShowForex((prev) => !prev);
  };
  const handleShowDonor = (item) => {
    setSelectedLoan(item);
    setShowDonor((prev) => !prev);
  };
  const handleAddImage = async (images) => {
    try {
      const formData = new FormData();
      formData.append("loan_id", loanImageId);
      Array.from(images).forEach((item) => {
        formData.append("images[]", item);
      });
      const response = await doPost(`/loan/image/upload`, formData);
      setDocuments(null);
      refetch();
      successToast("Image added successfully");
    } catch (error) {
      errorToast("Error while adding image");
    }
  };
  return (
    data &&
    data.loan && (
      <div className="viewloan">
        {addLoan && (
          <AddLoanModal
            onClose={setAddLoan}
            defaultCurrency={
              parentId &&
              projectLoans &&
              projectLoans.data &&
              projectLoans.data.loan &&
              projectLoans.data.loan.length > 0
                ? projectLoans.data.loan
                : null
            }
            data={{
              id: id,
              source:
                (data.loan[0] && data.loan[0].type === "GoN Loan") ||
                (data.loan[0] && data.loan[0].type === "Share")
                  ? "Internal"
                  : "External",
            }}
            refetch={refetch}
          />
        )}
        {showDonor && (
          <DonorModal onClose={handleShowDonor} donor={selectedLoan} />
        )}
        {showForex && (
          <ForexModal onClose={handleShowForex} forex={selectedLoan} />
        )}
        {showFullDetails && (
          <FullDetails
            onClose={handleShowFullDetails}
            full_details={selectedLoan}
          />
        )}
        <TopBar
          title={"Loan/Grant"}
          subtitle={from + " Loan Management"}
          backPage={true}
          children={
            <SmallButton
              content={
                <div>
                  <i className="fa-light fa-plus"></i>
                  Add Loan/Grant
                </div>
              }
              onClick={() => setAddLoan((prev) => !prev)}
            />
          }
        />
        {documents && (
          <FilesModal
            files={documents}
            handleClose={() => setDocuments(null)}
            edit={true}
            handleAdd={handleAddImage}
          />
        )}
        <div className="viewloan-main">
          <div className="viewloan-main--table">
            <Table
              loading={isLoading}
              Columns={Columns}
              Rows={
                data.loan.length > 0
                  ? data.loan.map((item, index) => ({
                      sn: index + 1,
                      type: item.type,
                      loan_number: item.loan_number,
                      donor_agency: item.donor_agency,
                      currency: item.donor_currency,
                      amount: (
                        <>
                          <strong>In Donor:</strong>
                          {item.loan_amount} <br />
                          <strong>In NPR:</strong>
                          {item.loan_amount_npr} <br />
                          <strong>In USD:</strong>
                          {item.loan_amount_usd}
                        </>
                      ),
                      agreement_date: (
                        <>
                          <strong>In BS:</strong>
                          {item.donor_agreement_date} <br />
                          <strong>In AD: </strong>
                          {item.donor_agreement_date_ad}
                        </>
                      ),
                      agreement_date_donor: (
                        <>
                          <strong>In BS:</strong>
                          {item.agreement_date} <br />
                          <strong>In AD: </strong>
                          {item.agreement_date_ad}
                        </>
                      ),
                      donor_service_charge:
                        item.type === "Share" ||
                        item.type === "Grant Share" ||
                        item.donor_service_charge_flag === "0" ||
                        item.donor_service_charge_flag === "false" ? (
                          "N/A"
                        ) : (
                          <View
                            label={"View"}
                            onClick={() => handleShowDonor(item)}
                            type="button"
                          />
                        ),
                      a:
                        (item.type === "Donor Loan" &&
                          item.forex_risk_bearer_flag === "1") ||
                        item.forex_risk_bearer_flag === "true" ? (
                          <View
                            label={"View"}
                            onClick={() => handleShowForex(item)}
                            type="button"
                          />
                        ) : (
                          "N/A"
                        ),
                      penalty:
                        item.type === "Share" ||
                        item.type === "Grant Share" ||
                        item.penalty_flag === "0" ||
                        item.penalty_flag === "false"
                          ? "N/A"
                          : item.penalty_percentage + "%",

                      full_details:
                        item.type === "Share" || item.type === "Grant Share" ? (
                          "N/A"
                        ) : (
                          <View
                            label={"View"}
                            onClick={() => handleShowFullDetails(item)}
                            type="button"
                          />
                        ),
                      images: (
                        <View
                          label={
                            data.image.filter(
                              (el) => el.document_type_id == item.id
                            ).length + " Documents"
                          }
                          onClick={() => {
                            setLoanImageId(item.id);
                            setDocuments(
                              data.image.filter(
                                (el) => el.document_type_id == item.id
                              )
                            );
                          }}
                          type="button"
                        />
                      ),
                      created_by: item.user_name ? item.user_name : "N/A",
                      created_at: item.created_at.split("T")[0],
                    }))
                  : []
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Loan;

import React, { useState } from "react";
import ModalLayout from "../../UI/Modal/ModalLayout";
import Input from "../../UI/Input/Input";
import PrimaryButton from "../../UI/Button/PrimaryButton";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../Slice/SystemSlice";
import { doPost, doPut } from "../../Services/Axios";
import { errorToast, successToast } from "../../Utils/Toastify";

export default function ChangePassword({ onClose, userId }) {
  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const handler = (e) => {
    const isError = !e.target.value ? "Field is required" : null;
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setError((prev) => ({
      ...prev,
      [e.target.name]: isError ? isError : null,
    }));
  };

  const submit = async () => {
    dispatch(toggleLoader(true));
    const err = {};
    if (state.newpassword && state.newpassword.length < 8)
      error.newpassword = "Invalid format (Minimum 8 characters)";
    if (state.confirmpassword && state.confirmpassword.length < 8)
      error.confirmpassword = "Invalid format (Minimum 8 characters)";

    if (state.newpassword !== state.confirmpassword)
      err.confirmpassword = "Password mismatched";
    if (!state.newpassword) error.newpassword = "Field is required";
    if (!state.confirmpassword) error.confirmpassword = "Field is required";

    if ("newpassword" in err || "confirmpassword" in err) {
      setError(err);
      return dispatch(toggleLoader(false));
    }

    try {
      const response = await doPut(`user/password/${userId}/change`, {
        id: userId,
        password: state.newpassword,
        confirm_password: state.confirmpassword,
      });
      onClose();
      successToast("Password changed successfully");
    } catch (error) {
      errorToast(error.message);
    }
    dispatch(toggleLoader(false));
  };
  return (
    <ModalLayout title={"Change Password"} onClose={onClose}>
      <div className="changepassword">
        <Input
          error={error.newpassword}
          defaultValue={state.newpassword}
          label={"New Password"}
          name={"newpassword"}
          placeholder={"********"}
          required
          onChange={handler}
          type={"password"}
        />
        <Input
          error={error.confirmpassword}
          defaultValue={state.confirmpassword}
          label={"Confirm Password"}
          name={"confirmpassword"}
          placeholder={"********"}
          required
          onChange={handler}
          type={"password"}
        />
        <PrimaryButton
          onClick={submit}
          icon={"fa-light fa-key"}
          title={"Change Password"}
          content={"Change Password"}
        />
      </div>
    </ModalLayout>
  );
}

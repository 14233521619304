import { createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../Services/Helpers";
import NepaliDate from "../Services/NepaliDate";

const systemSlice = createSlice({
  name: "SystemSlice",
  initialState: {
    sideBar: true,
    nepaliDate: new NepaliDate(),
    language: "en",
    isLoading: false,
    user: getUserFromLocalStorage(),
  },
  reducers: {
    toggleSideBar: (state) => {
      state.sideBar = !state.sideBar;
    },
    toggleLanguage: (state, action) => {
      state.language = action.payload;
    },
    toggleLoader: (state, { payload }) => {
      console.log(payload);
      state.isLoading = payload;
    },
    handleLogout: (state) => {
      state.user = "";
    },
  },
});

export const { toggleSideBar, toggleLanguage, toggleLoader, handleLogout } =
  systemSlice.actions;
export default systemSlice.reducer;

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { errorToast } from "../../../../Utils/Toastify";
import { doGet } from "../../../../Services/Axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";

const LoanContractProfile = ({ selectedProject }) => {
  const ref = useRef();
  const [details, setDetails] = useState(null);
  const fetchReport = async (id) => {
    try {
      const { data } = await doGet(
        `/reports/ctr_loan_ledger/${id}/loan_grant_contract_profile`
      );
      data &&
        data.loan_grant_profile &&
        data.loan_grant_profile.length > 0 &&
        setDetails(data);
    } catch (error) {
      errorToast(error.message);
    }
  };
  useEffect(() => {
    setDetails(null);
    selectedProject && fetchReport(selectedProject.id);
  }, [selectedProject && selectedProject.id]);
  return details && selectedProject ? (
    <>
      <DownloadTableExcel
        currentTableRef={ref.current}
        filename={`ledger_${Date.now()}`}
        sheet="Ledger"
      >
        <PrimaryButton
          content={"Export"}
          title="Ledger"
          icon={"fa-light fa-file-spreadsheet"}
          className={"ledger-heading--actions__export"}
        />
      </DownloadTableExcel>
      <table width={100} className="ledger-wrapper--table" ref={ref} border={1}>
        {details.loan_grant_profile.map((item, index) => {
          const availableContractors = details.contract_profile.filter(
            (i) => i.loan_number === item.loan_number
          );
          return (
            <>
              <thead key={index}>
                <tr>
                  <th colSpan={6}>{item.sub_project_name}</th>
                </tr>
                <tr>
                  <th>Loan No</th>
                  <td colSpan={5}>{item.loan_number}</td>
                </tr>
                <tr>
                  <th>Donor Name</th>
                  <td colSpan={5}>{item.donor_agency}</td>
                </tr>
                <tr>
                  <th rowSpan={2}>Loan Amount</th>
                  <th colSpan={2}>{item.donor_currency}</th>
                  <th colSpan={2}>USD</th>
                  <th>NPR</th>
                </tr>
                <tr>
                  <td colSpan={2}>{item.loan_amount}</td>
                  <td colSpan={2}>{item.loan_amount_usd}</td>
                  <td>{item.loan_amount_npr}</td>
                </tr>
                <tr>
                  <th>SERVICE/COMMITMENT/INTEREST %</th>
                  <td colSpan={2}>{item.donor_service_charge_amount}</td>
                  <td colSpan={2}>{item.donor_service_charge_amount_usd}</td>
                  <td>{item.donor_service_charge_amount_npr}</td>
                </tr>
                <tr>
                  <th>NET LOAN /GRANT</th>
                  <td colSpan={2}>{item.net_loan_amount}</td>
                  <td colSpan={2}>{item.net_loan_amount_usd}</td>
                  <td>{item.net_loan_amount_npr}</td>
                </tr>
                <tr>
                  <th>LOAN GRACE PERIOD INTEREST RATE</th>
                  <td colSpan={5}>{item.grace_period_interest_rate}</td>
                </tr>
                <tr>
                  <th rowSpan={2}>LOAN GRACE PERIOD </th>
                  <th colSpan={2}>Starting</th>
                  <th colSpan={2}>Ending</th>
                  <th>Extension</th>
                </tr>
                <tr>
                  <td colSpan={2}>{item.grace_period_start_date}</td>
                  <td colSpan={2}>{item.grace_period_end_date}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>REPAYMENT INTEREST RATE AFTER GRACE PERIOD</th>
                  <td colSpan={5}>{item.interest_rate_after_grace_period}</td>
                </tr>
                <tr>
                  <th>INSTALLMENT STARTING DATE </th>
                  <td colSpan={5}>{item.installment_start_date}</td>
                </tr>
                <tr>
                  <th>MONTHLY /QUATERLY/HALF YEARLY/YEARLY </th>
                  <td colSpan={5}>{item.installment_period}</td>
                </tr>
                <tr>
                  <th>TOTAL NO INSTALLMENT </th>
                  <td colSpan={5}>{item.no_of_installment}</td>
                </tr>
                <tr>
                  <th rowSpan={2}>Installment Amount</th>
                  <th colSpan={2}>{item.donor_currency}</th>
                  <th colSpan={2}>USD</th>
                  <th>NPR</th>
                </tr>
                <tr>
                  <td colSpan={2}>{item.installment_amount}</td>
                  <td colSpan={2}>{item.installment_amount_usd}</td>
                  <td>{item.installment_amount_npr}</td>
                </tr>
              </thead>
              {availableContractors.length > 0 ? (
                <thead>
                  <tr>
                    <td colSpan={6}></td>
                  </tr>
                </thead>
              ) : (
                ""
              )}
              {availableContractors.map((contract, contractIndex) => {
                const apg = details.apg.filter(
                  (i) => i.contract_id === contract.id
                );
                return (
                  <>
                    <thead key={contractIndex}>
                      <tr>
                        <th>Contract No</th>
                        <td colSpan={5}>{contract.contract_number}</td>
                      </tr>
                      <tr>
                        <th>Loan No</th>
                        <td colSpan={5}>{contract.loan_number}</td>
                      </tr>
                      <tr>
                        <th>Contract Procurement</th>
                        <td colSpan={5}>{contract.type}</td>
                      </tr>
                      <tr>
                        <th>Contract Nature</th>
                        <td colSpan={5}>{contract.types_of_service}</td>
                      </tr>
                      <tr>
                        <th>Contract Name</th>
                        <td colSpan={5}>{contract.contractor_name}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td colSpan={5}>{contract.contractor_address}</td>
                      </tr>
                      <tr>
                        <th>CONTRACT AGREEMENT(CONFIRMATION) DATE </th>
                        <td colSpan={5}>{contract.agreement_date}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2}>CONTRACT AGREEMENT AMOUNT</th>
                        <th colSpan={2}>{item.donor_currency}</th>
                        <th colSpan={2}>USD</th>
                        <th>NPR</th>
                      </tr>
                      <tr>
                        <td colSpan={2}>{contract.agreement_amount}</td>
                        <td colSpan={2}>{contract.agreement_amount_usd}</td>
                        <td>{contract.agreement_amount_npr}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2}>PERFORMANCE SECURITY</th>
                        <th>ISSUER BANK</th>
                        <th>GUARANTEE NO</th>
                        <th>AMOUNT </th>
                        <th>VALIDITY PERIOD</th>
                        <th>EXTENSION</th>
                      </tr>
                      <tr>
                        <td>{contract.performance_security_bank_name}</td>
                        <td>
                          {contract.performance_security_guarantee_number}
                        </td>
                        <td>
                          <b>{item.donor_currency}: </b>
                          {contract.performance_security_amount} <br />
                          <b>USD: </b>
                          {contract.performance_security_amount_usd} <br />
                          <b>NPR: </b>
                          {contract.performance_security_amount_npr} <br />
                        </td>
                        <td>{contract.performance_validity_period}</td>
                        <td></td>
                      </tr>
                      {apg.length > 0 ? (
                        <tr>
                          <th rowSpan={apg.length + 1}>ADVANCE GUARANTEE</th>
                          <th>ISSUER BANK</th>
                          <th>GUARENTEE NO</th>
                          <th>AMOUNT</th>
                          <th>VALIDITY PERIOD</th>
                          <th>EXTENSION</th>
                        </tr>
                      ) : (
                        ""
                      )}
                      {apg.map((apgDetails, apgIndex) => (
                        <tr key={apgIndex}>
                          <td>{apgDetails.bank_name}</td>
                          <td>{apgDetails.advance_guarantee_no}</td>
                          <td>
                            <b>{item.donor_currency}: </b>
                            {apgDetails.advance_amount} <br />
                            <b>USD: </b>
                            {apgDetails.advance_amount_usd} <br />
                            <b>NPR: </b>
                            {apgDetails.advance_amount_npr} <br />
                          </td>
                          <td>{apgDetails.advance_validity_period}</td>
                          <td></td>
                        </tr>
                      ))}
                      <tr>
                        <th>PAYMENT TERMS & REMARKS </th>
                        <td colSpan={5}>{contract.payment_terms}</td>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <td colSpan={6}></td>
                      </tr>
                    </thead>
                  </>
                );
              })}
            </>
          );
        })}
      </table>
    </>
  ) : (
    <p className="notfound notfound-bordered">No Data Found</p>
  );
};

export default LoanContractProfile;

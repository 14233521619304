import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const PerformanceModal = ({ onClose, perfDetails }) => {
  const TITLE = [
    {
      key: "performance security amount",
      value: "performance_security_amount",
    },
    {
      key: "performance security amount in npr",
      value: "performance_security_amount_npr",
    },
    {
      key: "performance security amount in usd",
      value: "performance_security_amount_usd",
    },
    {
      key: "performance security validity period",
      value: "performance_security_validity_period",
    },
    {
      key: "performance security validity period ad",
      value: "performance_security_validity_period_ad",
    },
    {
      key: "performance security guarantee number",
      value: "performance_security_guarantee_number",
    },
    {
      key: "performance security bank name",
      value: "performance_security_bank_name",
    },
  ];
  return (
    <ModalLayout onClose={onClose} title="Performance Details">
      <div className="pmcontractor-perfDetails">
        <div className="pmcontractor-perfDetails--grid">
          {TITLE.map((item) => (
            <div key={item} className="pmcontractor-perfDetails--grid__item">
              <div className="pmcontractor-perfDetails--grid__item-key">
                {item.key} :
              </div>
              <div className="pmcontractor-perfDetails--grid__item-value">
                {perfDetails[item.value]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default PerformanceModal;

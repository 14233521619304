import { doGet, doPost, doPut } from "../../../../../Services/Axios";

const EditBillController = {
  transactionTypes: {
    "ADVANCE/RETENTION": "advance_disbursed_date_ad",
    "COMMERCIAL INVOICE (LC)": "commercial_disbursed_date_ad",
    "VAT BILL": "disbursed_date_ad",
  },
  isValidValue: (value, isPercentage = false) =>
    value !== "" &&
    !isNaN(+value) &&
    +value >= 0 &&
    (isPercentage ? +value <= 100 : true),

  fetchLoans: async (id) => {
    try {
      const { data } = await doGet(`/loan/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  fetchContractors: async (id) => {
    try {
      const { data } = await doGet(`/contract/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },

  validateKey: (e) => {
    const name = e.target.name;
    if (name == "Select an option") return;

    const validation = e.target.validation;
    const value = e.target.value;
    if (!value) return "Field is Required";
    if (!validation) return;
    if (validation["isNumber"] && (isNaN(+value) || +value < 0))
      return "Invalid field type";
    if (validation["isRange"] && (isNaN(+value) || +value < 0 || +value > 100))
      return "Ranges between 0-100";
  },
  validateData: (data) => {
    const error = {};
    const isRequired = "Field is Required";
    const shouldContain = "Contains only Number";

    function isValidPercentage(value) {
      const percentage = parseFloat(value);
      return !isNaN(percentage) && percentage >= 0 && percentage <= 100;
    }
    function validatePercentageField(fieldName) {
      const value = data[fieldName];
      if (!isValidPercentage(value)) {
        error[fieldName] = "Invalid percentage value";
      }
    }
    const validateField = (field, errorMessage) => {
      if (!data[field]) {
        error[field] = isRequired;
      } else if (errorMessage && (isNaN(+data[field]) || +data[field] < 0)) {
        error[field] = errorMessage;
      }
    };

    // Basic
    validateField("loan_id");
    validateField("donor_currency");
    validateField("contract_id");
    validateField("particular");
    validateField("bill_type");
    validateField("transaction_type");
    validateField("forex_conversion_basis");

    // Advance/Retention
    if (data.transaction_type === "ADVANCE/RETENTION") {
      validateField("advance_retention_bill_number");
      validateField("advance_release_date");
      validateField("advance_release_date_ad");
      validateField("advance_disbursed_date");
      validateField("advance_disbursed_date_ad");
      validateField("advance_disbursed", shouldContain);
      validateField("advance_disbursed_npr");
      validateField("advance_disbursed_usd");
    }

    // COMMERCIAL INVOICE (LC)
    if (data.transaction_type === "COMMERCIAL INVOICE (LC)") {
      validateField("commercial_bill_date");
      validateField("commercial_bill_date_ad");
      validateField("commercial_disbursed_date");
      validateField("commercial_disbursed_date_ad");
      validateField("invoice_number");
      validateField("invoice_bill_amount", shouldContain);
      validateField("invoice_bill_amount_npr");
      validateField("invoice_bill_amount_usd");
      validatePercentageField("commercial_vat_percentage");
      validateField("invoice_bill_amount_inclusive_vat", shouldContain);
      validateField("invoice_bill_amount_inclusive_vat_npr");
      validateField("invoice_bill_amount_inclusive_vat_usd");

      // Deduction
      validateField("commercial_advance_amount", shouldContain);
      validateField("commercial_advance_amount_npr");
      validateField("commercial_advance_amount_usd");
      validateField("commercial_other_deduction", shouldContain);
      validateField("commercial_other_deduction_npr");
      validateField("commercial_other_deduction_usd");
      validatePercentageField("commercial_contract_tax_percentage");
      validatePercentageField("commercial_retention_percentage");
      validatePercentageField("commercial_liqudate_damage_percentage");
      validateField("commercial_total_deduction", shouldContain);
      validateField("commercial_total_deduction_npr");
      validateField("commercial_total_deduction_usd");
      validateField("commercial_net_payable_amount", shouldContain);
      validateField("commercial_net_payable_amount_npr");
      validateField("commercial_net_payable_amount_usd");
    }

    // VAT BILL
    if (data.transaction_type === "VAT BILL") {
      validateField("bill_date");
      validateField("bill_date_ad");
      validateField("disbursed_date");
      validateField("disbursed_date_ad");
      validateField("bill_number");
      validateField("bill_amount", shouldContain);
      validateField("bill_amount_npr");
      validateField("bill_amount_usd");
      validatePercentageField("vat_percentage");
      validateField("bill_amount_inclusive_vat", shouldContain);
      validateField("bill_amount_inclusive_vat_npr");
      validateField("bill_amount_inclusive_vat_usd");

      // Deduction
      validateField("advance_amount", shouldContain);
      validateField("advance_amount_npr");
      validateField("advance_amount_usd");
      validateField("other_deduction", shouldContain);
      validateField("other_deduction_npr");
      validateField("other_deduction_usd");
      validatePercentageField("prior_collection_of_vat_percentage");
      validatePercentageField("tds_percentage");
      validatePercentageField("retention_percentage");
      validatePercentageField("liqudate_damage_percentage");
      validateField("total_deduction", shouldContain);
      validateField("total_deduction_npr");
      validateField("total_deduction_usd");
      validateField("net_payable_amount", shouldContain);
      validateField("net_payable_amount_npr");
      validateField("net_payable_amount_usd");
    }

    if (Object.keys(error).length === 0) {
      return { success: true };
    } else {
      return { succes: false, error };
    }
  },
  submitForm: async (data, currency) => {
    try {
      if (!data) throw new Error("Invalida data format");

      const response = await doPut(`/bills-infos/${data.id}/edit`, {
        ...data,
        currency,
      });
      if (!response.data) throw new Error("Internal server error");

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDisabledValue: (data) => {
    return data !== undefined && data !== null && data !== ""
      ? undefined
      : true;
  },
};
export default EditBillController;

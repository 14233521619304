import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createSubProjectContractor: {
    subProjectId: "84735243234",

    //!Information about Sub Project - SLA - Seperate Table is Required
    directorateOrOfficeName: "XYZ",
    directorateOrOfficeCode: "2344",
    officeName: "XYZ Office",
    officeCode: "4856546",
    slaAgreementDate: {
      AD: "2022-01-01",
      BS: "2022-01-01",
    },
    currency: "NPR" | "INR" | "USD" | "JP",
    amount: 2_00_00_000,
    inUSD: 2_00_00_000,
    inNPR: 2_00_00_000,
    status: false, // Should be verified by Project Manager

    contractors: [
      {
        status: false, // Should be verified by Project Manager
        type: "ICB" | "NCB",
        contractNumber: "2342343",
        name: "XYZ Contractor",
        isResident: true | false,
        registrationNumber: {
          type: "PAN" | "VAT",
          number: "2983749234",
        },

        //!Agreement
        currency: "INR" | "NPR" | "USD" | "JP",
        amount: 2_00_00_000, // Revised
        inUSD: 2_00_00_000,
        inNPR: 2_00_00_000,
        agreementDate: "2022-01-01",
        workCompletionDate: "2022-01-01", //Revised
        serviceType: "Supply" | "Construction" | "Service",
        retention: 2,
        performanceSercurityCurrency: "INR" | "NPR" | "JP" | "USD",
        performanceSercurityAmount: 2_00_00_000, //Revised
        validityPeriod: 4, //Revised
        guaranteeNumber: 234234, //Revised
        bankName: "XYZ Bank", //Revised

        //!LC Information
        lcDate: "2022-01-01",
        lcCurrency: "INR" | "NPR" | "JP" | "USD",
        lcAmount: 2_00_00_000,
        issuerBank: "XYZ Bank",
        duration: 3, //Year

        //!Documents
        //Revised
        documents: [], //!Array of Documents

        //!Sub Contractors
        subContractors: [
          {
            status: false, // Should be verified by Project Manager
            type: "ICB" | "NCB",
            contractNumber: "2342343",
            name: "XYZ Contractor",
            isResident: true | false,
            registrationNumber: {
              type: "PAN" | "VAT",
              number: "2983749234",
            },

            //!Agreement
            currency: "INR" | "NPR" | "USD" | "JP",
            amount: 2_00_00_000, //Revised
            inUSD: 2_00_00_000,
            inNPR: 2_00_00_000,
            agreementDate: "2022-01-01",
            workCompletionDate: "2022-01-01", //Revised
            serviceType: "Supply" | "Construction" | "Service",
            retention: 2,
            performanceSercurityCurrency: "INR" | "NPR" | "JP" | "USD",
            performanceSercurityAmount: 2_00_00_000, //Revised
            validityPeriod: 4, //Revised
            guaranteeNumber: 234234,
            bankName: "XYZ Bank", //Revised

            //!LC Information
            lcDate: "2022-01-01",
            lcCurrency: "INR" | "NPR" | "JP" | "USD",
            lcAmount: 2_00_00_000,
            issuerBank: "XYZ Bank",
            duration: 3,

            //!Documents
            //Revised
            documents: [], //!Array of Documents
          },
        ],
      },
    ],
  },

  createContractor: {
    subProjectId: "2893742934",
    status: false, // Should be verified by Project Manager
    type: "ICB" | "NCB",
    contractNumber: "2342343",
    name: "XYZ Contractor",
    isResident: true | false,
    registrationNumber: {
      type: "PAN" | "VAT",
      number: "2983749234",
    },

    //!Agreement
    currency: "INR" | "NPR" | "USD" | "JP", //Options from multiple Sub Project Loans
    amount: 2_00_00_000, //Revised
    inUSD: 2_00_00_000,
    inNPR: 2_00_00_000,
    agreementDate: "2022-01-01",
    workCompletionDate: "2022-01-01", //Revised
    serviceType: "Supply" | "Construction" | "Service",
    retention: 2, //!Default -> 0
    performanceSercurityCurrency: "INR" | "NPR" | "JP" | "USD", //From Contract Currency
    performanceSercurityAmount: 2_00_00_000, //Revised
    validityPeriod: 4, //Revised
    guaranteeNumber: 234234, //Revised
    bankName: "XYZ Bank", //Revised

    //!LC Information
    lcDate: "2022-01-01",
    lcCurrency: "INR" | "NPR" | "JP" | "USD", //From Contract Currency
    lcAmount: 2_00_00_000,
    issuerBank: "XYZ Bank",
    duration: 3,

    //!Documents
    documents: [], //?Array of Documents

    //!Sub Contractors
    subContractors: [
      {
        status: false, // Should be verified by Project Manager
        type: "ICB" | "NCB",
        contractNumber: "2342343",
        name: "XYZ Contractor",
        isResident: true | false,
        registrationNumber: {
          type: "PAN" | "VAT",
          number: "2983749234",
        },

        //!Agreement
        currency: "INR" | "NPR" | "USD" | "JP", //!From Contractor
        amount: 2_00_00_000, //Revised, Must not exceed Contractor Amount
        inUSD: 2_00_00_000,
        inNPR: 2_00_00_000,
        agreementDate: "2022-01-01",
        workCompletionDate: "2022-01-01", //Revised
        serviceType: "Supply" | "Construction" | "Service",
        retention: 2,
        performanceSercurityCurrency: "INR" | "NPR" | "JP" | "USD",
        performanceSercurityAmount: 2_00_00_000, //Revised
        validityPeriod: 4, //Revised
        guaranteeNumber: 234234, //Revised
        bankName: "XYZ Bank", //Revised

        //!LC Information
        lcDate: "2022-01-01",
        lcCurrency: "INR" | "NPR" | "JP" | "USD",
        lcAmount: 2_00_00_000,
        issuerBank: "XYZ Bank",
        duration: 3,

        //!Documents
        //Revised
        documents: [], //?Array of Documents
      },
    ],
  },

  createSubContractor: {
    contractorId: "298374234",
    status: false, // Should be verified by Project Manager

    type: "ICB" | "NCB",
    contractNumber: "2342343",
    name: "XYZ Contractor",
    isResident: true | false,
    registrationNumber: {
      type: "PAN" | "VAT",
      number: "2983749234",
    },

    //!Agreement
    currency: "INR" | "NPR" | "USD" | "JP",
    amount: 2_00_00_000, //Revised
    inUSD: 2_00_00_000,
    inNPR: 2_00_00_000,
    agreementDate: "2022-01-01",
    workCompletionDate: "2022-01-01", //Revised
    serviceType: "Supply" | "Construction" | "Service",
    retention: 2,
    performanceSercurityCurrency: "INR" | "NPR" | "JP" | "USD",
    performanceSercurityAmount: 2_00_00_000, //Revised
    validityPeriod: 4, //Revised
    guaranteeNumber: 234234, //Revised
    bankName: "XYZ Bank", //Revised

    //!LC Information
    lcDate: "2022-01-01",
    lcCurrency: "INR" | "NPR" | "JP" | "USD",
    lcAmount: 2_00_00_000,
    issuerBank: "XYZ Bank",
    duration: 3,

    //!Documents
    documents: [], //?Array of Documents
  },

  createBudget: {
    sn: "87238457234", //!This field is required during first bill and auto generated after ward
    loanNumber: "3298452344",
    fiscalYear: {
      inAD: "2022-01-01",
      inBS: "2079-01-01",
    },
  },

  createBillInformation: {
    sn: "78234", //!This field is required during first bill and auto generated after ward
    loanNumber: "287346234",
    projectName: "XYZ", //Auto Generated,
    subProjectName: "XYZ", //Auto Generated,
    contractorName: "XYZ", //Select Field
    contractNumber: "89324234", //Auto Generated using Select Field

    invoiceNumber: "28374234",
    billingDate: "2022-01-01",

    letterOfCredit: true | false,
    registrationNumber: {
      type: "VAT" | "PAN",
      number: "345345345",
    },
    particular: "XYZ Particular",
    billAmount: 2_00_00_000, //Nepali
    billAmountInUSD: 2_00_00_000,
    billAmountInDonorCurrency: 2_00_00_000,
    taxOnBillAmount: 7,
    tdsAmount: 2_00_00_000,
    tdsAmountInUSD: 2_00_00_000,
    tdsAmountInDonorCurrency: 2_00_00_000,

    advanceDeduction:
      null |
      {
        amount: 2_00_00_000,
        inUSD: 2_00_00_000,
        inDonorCurrency: 2_00_00_000,
      },

    retention: 3, //!Default -> 0
    liquidityDamage: 4, //!Default -> 0
    taxToDepositInLTOffice: 4, //!Default -> 0

    netBillPayable: {
      amount: 2_00_00_000,
      inUSD: 2_00_00_000,
      inDonorCurrency: 2_00_00_000,
    },
    valueDateDisburseByBank: "2022-01-01",
    netPayableAmount: {
      amount: 2_00_00_000,
      inUSD: 2_00_00_000,
      inDonorCurrency: 2_00_00_000,
    },
    customDuty:
      null |
      [
        {
          name: "XYZ",
          quantity: 4,
          amount: {
            bidedAmount: 2_00_00_000,
            amount: 2_00_00_000,
          },
          customDuty: 6,
          tax:
            null |
            {
              percentage: 4,
              amount: 2_00_00_000, // Auto Generated -> Created on basis of customDuty.amount.amount
            },
        },
      ],

    variations:
      null |
      {
        item: "XYZ",
        quanity: 20,
        totalVariation: {
          amount: 2_00_00_000,
          inUSD: 2_00_00_000,
          inDonorCurrency: 2_00_00_000,
        },
        percentage: 6,
      },
    documents: [],
  },
};

const accountChiefSlice = createSlice({
  name: "AccountChiefSlice",
  initialState: initialState,
  reducers: {
    resetSubProjectContractor: (state) => {
      state.createSubProjectContractor = null;
    },
    resetContractor: (state) => {
      state.createContractor = null;
    },
    resetSubContractor: (state) => {
      state.createSubContractor = null;
    },
  },
});

export const {
  resetContractor,
  resetSubContractor,
  resetSubProjectContractor,
} = accountChiefSlice.actions;

export default accountChiefSlice.reducer;

import React, { useState } from "react";
import DonorInfo from "./Components/DonorInfo";
import Ledger from "./Components/Ledgers/Ledger";
import DirectPayment from "./Components/DirectPayment";
import ProjectWiseDirectPayment from "./Components/ProjectWiseDirectPayment";

const Components = {
  "Donor's Info": <DonorInfo />,
  Ledger: <Ledger />,
  "Direct Payment": <DirectPayment />,
  "Project Wise Direct Payment": <ProjectWiseDirectPayment />,
};

const useReport = () => {
  const [reportEnums, setReportEnums] = useState([
    { key: "Donor's Info", value: "Donor's Info" },
    { key: "Ledger", value: "Ledger" },
    { key: "Direct Payment", value: "Direct Payment" },
    {
      key: "Project Wise Direct Payment",
      value: "Project Wise Direct Payment",
    },
  ]);
  const [selectedReport, setSelectedReport] = useState(null);

  return {
    reportEnums,
    selectedReport: {
      value: selectedReport,
      set: (e) => setSelectedReport(e.target.value),
      component: Components[selectedReport],
    },
  };
};
export default useReport;

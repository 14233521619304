import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const RemarksModal = ({ description, onClose }) => {
  return (
    <ModalLayout onClose={onClose} title={"Remark"}>
      <div className="contractor-descriptionmodal">{description.remarks}</div>
    </ModalLayout>
  );
};

export default RemarksModal;

import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { doPost } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import { errorToast } from "../../../Utils/Toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";

const AddEmployeeModal = ({ onClose }) => {
  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const handleSubmitImage = (e) => {
    setImages(e);
  };
  const initialState = {
    username: "",
    newPassword: "",
    confirmPassword: "",
    user_type: "",
    employee_id: "",
    name: "",
    office_name: "",
    address: "",
    contact_number: "",
    email: "",
  };
  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        const formData = new FormData();
        formData.append("user_type", "Admin");
        formData.append("level", "1");
        Object.keys(states).forEach((item) => {
          ["user_type", "level", "password"].indexOf(item) < 0 &&
            formData.append(item, states[item]);
        });
        formData.append("password", states.newPassword);
        for (let i = 0; i < images.length; i++) {
          formData.append("images[]", images[i]);
        }
        await doPost("/user/admin/create", formData);
      } else {
        throw Error("Validation Error");
      }
    } catch (error) {
      throw error;
    }
  };
  const { mutate } = useMutation({
    mutationKey: "Admin",
    mutationFn: handleSubmit,
    onError: (error) => {
      if (error && error.message && typeof error.message === "string") {
        return errorToast(error.message);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        return toast.error("Error while adding admin!!");
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("Admin");
      toast.success("Admin Added Successfully");
      onClose();
    },
  });
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };

  return (
    <ModalLayout title={"Add Employee"} onClose={handleClose}>
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to edit admin ??"}
          caption={"Please verify all details before clicking yes"}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            mutate();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel editing admin ??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      <div className="addemployee-main">
        <div className="addemployee-main--submit">
          <PrimaryButton
            content={"Create"}
            onClick={() => setConfirmationModal(true)}
            icon="fa-light fa-plus"
          />
        </div>
        <Input
          placeholder={"Employee Id"}
          label={"Employee Id"}
          onChange={handleChange}
          name={"employee_id"}
          error={errors.employee_id}
        />
        <Input
          placeholder={"Employee Name"}
          label={"Employee Name"}
          onChange={handleChange}
          name="name"
          error={errors.name}
        />
        <Input
          placeholder={"User Id"}
          label={"User Id"}
          onChange={handleChange}
          name="username"
          error={errors.username}
        />
        <Input
          placeholder={"Email"}
          label={"Email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
        />
        <Input
          placeholder={"Derectrate/Office Name"}
          label={"Derectrate/Office Name"}
          onChange={handleChange}
          name="office_name"
          error={errors.office_name}
        />

        <Input
          placeholder={"Address"}
          label={"Address"}
          onChange={handleChange}
          name="address"
          error={errors.address}
        />
        <Input
          placeholder={"Contact Number"}
          label={"Contact Number"}
          onChange={handleChange}
          name="contact_number"
          intlNotRequired={true}
          error={errors.contact_number}
        />

        <Input
          placeholder={"password"}
          label={"password"}
          onChange={handleChange}
          name="newPassword"
          type={"password"}
          error={errors.newPassword}
        />
        <Input
          placeholder={"confirm Password"}
          label={"confirm Password"}
          name="confirmPassword"
          type="password"
          onChange={handleChange}
          error={errors.confirmPassword}
        />
        <div className="addemployee-main--single">
          <FileInput isSingle={true} handleSubmitImage={handleSubmitImage} />
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddEmployeeModal;

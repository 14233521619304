import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { doGet } from "../../../../Services/Axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";

import LedgerController from "./_ledgerController";
import { intlFormat } from "../../../../Services/Helpers";
const { BILL_PREFIX } = LedgerController;

const CWIPLedger = ({ selectedProject }) => {
  console.log(selectedProject);
  const ref = useRef();
  const [rowOneShow, setRowOneShow] = useState(true);
  const [rowTwoShow, setRowTwoShow] = useState(true);
  const [rowThreeShow, setRowThreeShow] = useState(true);
  const { nepaliDate } = useSelector((state) => state.System);
  const [idc_ledger, setidc_ledger] = useState();
  const todaysDate = nepaliDate.getTodayDate().BS;
  const fiscalYear =
    todaysDate.month <= 12 && todaysDate.month > 3
      ? `${todaysDate.year}/${todaysDate.year + 1}`
      : `${todaysDate.year - 1}/${todaysDate.year}`;

  const fetchLedger = async () => {
    try {
      const response = await doGet(
        `/reports/ctr_loan_ledger/${selectedProject.id}/${
          fiscalYear.split("/")[0]
        }%252f${fiscalYear.split("/")[1]}/contract_idc_ledger`
      );
      console.log(response.data);
      setidc_ledger(response.data.contract_idc_ledger);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowToggle = (type) => {
    switch (type) {
      case "one":
        setRowOneShow(!rowOneShow);
        break;
      case "two":
        setRowTwoShow(!rowTwoShow);
        break;
      case "three":
        setRowThreeShow(!rowThreeShow);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    fetchLedger();
  }, [fiscalYear, selectedProject && selectedProject.id]);
  return (
    <>
      <DownloadTableExcel
        currentTableRef={ref.current}
        filename={`ledger_${Date.now()}`}
        sheet="Ledger"
      >
        <PrimaryButton
          content={"Export"}
          title="Ledger"
          icon={"fa-light fa-file-spreadsheet"}
          className={"ledger-heading--actions__export"}
        />
      </DownloadTableExcel>
      <table width={100} border={1} className="ledger-wrapper--table" ref={ref}>
        <thead>
          <tr>
            <th className="center" colSpan={14}>
              <h1>नेपाल विद्युत प्राधिकरण</h1>
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={14}>
              अर्थ निर्देशनालय
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={14}>
              संस्थागत वित्तिय विभाग
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={14}>
              वित्तिय योजना तथा लगानी महाशाखा
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={14}>
              Contract IDC Ledger -{" "}
              {selectedProject &&
                `${selectedProject.name} - Office Code (${selectedProject.office_code})`}
              {selectedProject &&
                selectedProject.loan_repayment_effective_date &&
                selectedProject.sub_status === 3 &&
                `(Close Date: ${selectedProject.loan_repayment_effective_date})`}
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>Particulars</th>
            <th rowSpan={2}>Loan no</th>
            <th rowSpan={2}>Contract no</th>
            <th rowSpan={2}>Advice no</th>
            <th colSpan={2}>Value Date</th>
            <th colSpan={3}>Total value</th>
            <th colSpan={5}>IDC</th>
          </tr>
          <tr>
            <th>BS</th>
            <th>AD</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>% INT </th>
            <th>NO OF DAYS</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
          </tr>
        </thead>
        {idc_ledger && idc_ledger.length > 0 ? (
          <tbody>
            {idc_ledger
              .filter((item) => item.particulars === "UP TO THIS YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th
                    className="cursor"
                    data-cursor={rowOneShow ? "🔽 " : "▶️ "}
                    onClick={() => handleRowToggle("one")}
                  >
                    {item.particulars}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{(+item.total_value_donor).toFixed(2)}</th>
                  <th>{(+item.total_value_usd).toFixed(2)}</th>
                  <th>{(+item.total_value_npr).toFixed(2)}</th>
                  <th></th>
                  <th>{item.idc_no_of_days}</th>
                  <th>{(+item.total_idc_donor).toFixed(2)}</th>
                  <th>{(+item.total_idc_usd).toFixed(2)}</th>
                  <th>{(+item.total_idc_npr).toFixed(2)}</th>
                </tr>
              ))}
            {idc_ledger
              .filter((item) => item.type === "UP TO THIS YEAR")
              .map((item, index) => {
                const billType = BILL_PREFIX[item.transaction_type];
                return (
                  <tr
                    key={index}
                    className={`${rowOneShow ? "rowopen" : "rowclose"}`}
                  >
                    <td>{item.particulars}</td>
                    <td>{item.loan_no}</td>
                    <td>{item.contract_no}</td>
                    <td>{item[billType.type]}</td>
                    <td>{item.value_date_bs}</td>
                    <td>{item.value_date_ad}</td>

                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_usd`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_npr`]).toFixed(2)
                      )}
                    </td>
                    <td>{item.idc_interest_rate}</td>
                    <td>{item.idc_no_of_days}</td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}idc_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_usd`]).toFixed(2))}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_npr`]).toFixed(2))}
                    </td>
                  </tr>
                );
              })}
            {idc_ledger
              .filter((item) => item.particulars === "PREVIOUS YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th
                    className="cursor"
                    data-cursor={rowTwoShow ? "🔽 " : "▶️ "}
                    onClick={() => handleRowToggle("two")}
                  >
                    {item.particulars}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{intlFormat((+item.total_value_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_value_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_value_npr).toFixed(2))}</th>
                  <th></th>
                  <th>{item.idc_no_of_days}</th>
                  <th>{intlFormat((+item.total_idc_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_idc_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_idc_npr).toFixed(2))}</th>
                </tr>
              ))}
            {idc_ledger
              .filter((item) => item.type === "PREVIOUS YEAR")
              .map((item, index) => {
                const billType = BILL_PREFIX[item.transaction_type];
                return (
                  <tr
                    key={index}
                    className={`${rowTwoShow ? "rowopen" : "rowclose"}`}
                  >
                    <td>{item.particulars}</td>
                    <td>{item.loan_no}</td>
                    <td>{item.contract_no}</td>
                    <td>{item[billType.type]}</td>
                    <td>{item.value_date_bs}</td>
                    <td>{item.value_date_ad}</td>

                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_usd`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_npr`]).toFixed(2)
                      )}
                    </td>
                    <td>{item.idc_interest_rate}</td>
                    <td>{item.idc_no_of_days}</td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}idc_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_usd`]).toFixed(2))}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_npr`]).toFixed(2))}
                    </td>
                  </tr>
                );
              })}

            {idc_ledger
              .filter((item) => item.particulars === "CURRENT YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th
                    className="cursor"
                    data-cursor={rowThreeShow ? "🔽 " : "▶️ "}
                    onClick={() => handleRowToggle("three")}
                  >
                    {item.particulars}
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{intlFormat((+item.total_value_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_value_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_value_npr).toFixed(2))}</th>
                  <th></th>
                  <th>{item.idc_no_of_days}</th>
                  <th>{intlFormat((+item.total_idc_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_idc_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_idc_npr).toFixed(2))}</th>
                </tr>
              ))}
            {idc_ledger
              .filter((item) => item.type === "CURRENT YEAR")
              .map((item, index) => {
                const billType = BILL_PREFIX[item.transaction_type];
                return (
                  <tr
                    className={`${rowThreeShow ? "rowopen" : "rowclose"}`}
                    key={index}
                  >
                    <td>{item.particulars}</td>
                    <td>{item.loan_no}</td>
                    <td>{item.contract_no}</td>
                    <td>{item[billType.type]}</td>
                    <td>{item.value_date_bs}</td>
                    <td>{item.value_date_ad}</td>

                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_usd`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}total_value_npr`]).toFixed(2)
                      )}
                    </td>
                    <td>{item.idc_interest_rate}</td>
                    <td>{item.idc_no_of_days}</td>
                    <td>
                      {intlFormat(
                        (+item[`${billType.amt}idc_donor`]).toFixed(2)
                      )}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_usd`]).toFixed(2))}
                    </td>
                    <td>
                      {intlFormat((+item[`${billType.amt}idc_npr`]).toFixed(2))}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={"100%"}>No Data Found</td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default CWIPLedger;

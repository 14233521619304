import { doGet, doPost } from "../../../../../Services/Axios";

const AddBillController = {
  transactionTypes: {
    "ADVANCE/RETENTION": "advance_disbursed_date_ad",
    "COMMERCIAL INVOICE (LC)": "commercial_disbursed_date_ad",
    "VAT BILL": "disbursed_date_ad",
  },
  isValidValue: (value, isPercentage = false) =>
    value !== "" &&
    !isNaN(+value) &&
    +value >= 0 &&
    (isPercentage ? +value <= 100 : true),

  fetchLoans: async (id) => {
    try {
      const { data } = await doGet(`/loan/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  fetchContractors: async (id) => {
    try {
      const { data } = await doGet(`/contract/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },

  validateKey: (e) => {
    const name = e.target.name;
    if (name == "Select an option") return;

    const validation = e.target.validation;
    const value = e.target.value;
    if (!value) return "Field is Required";
    if (!validation) return;
    if (validation["isNumber"] && (isNaN(+value) || +value < 0))
      return "Invalid field type";
    if (validation["isRange"] && (isNaN(+value) || +value < 0 || +value > 100))
      return "Ranges between 0-100";
  },
  validateData: (data) => {
    const error = {};
    const isRequired = "Field is Required";
    const shouldContain = "Contains only Number";

    function isValidPercentage(value) {
      const percentage = parseFloat(value);
      return !isNaN(percentage) && percentage >= 0 && percentage <= 100;
    }
    function validatePercentageField(fieldName) {
      const value = data[fieldName];
      if (!isValidPercentage(value)) {
        error[fieldName] = "Invalid percentage value";
      }
    }
    const validateField = (field, errorMessage) => {
      if (!data[field]) {
        error[field] = isRequired;
      } else if (errorMessage && (isNaN(+data[field]) || +data[field] < 0)) {
        error[field] = errorMessage;
      }
    };

    // Basic
    validateField("loan_id");
    validateField("donor_currency");
    validateField("contract_id");
    validateField("particular");
    validateField("bill_type");
    validateField("transaction_type");
    validateField("forex_conversion_basis");

    // Advance/Retention
    if (data.transaction_type === "ADVANCE/RETENTION") {
      validateField("advance_retention_bill_number");
      validateField("advance_release_date");
      validateField("advance_release_date_ad");
      validateField("advance_disbursed_date");
      validateField("advance_disbursed_date_ad");
      validateField("advance_disbursed", shouldContain);
      validateField("advance_disbursed_npr");
      validateField("advance_disbursed_usd");
    }

    // COMMERCIAL INVOICE (LC)
    if (data.transaction_type === "COMMERCIAL INVOICE (LC)") {
      validateField("commercial_bill_date");
      validateField("commercial_bill_date_ad");
      validateField("commercial_disbursed_date");
      validateField("commercial_disbursed_date_ad");
      validateField("invoice_number");
      validateField("invoice_bill_amount", shouldContain);
      validateField("invoice_bill_amount_npr");
      validateField("invoice_bill_amount_usd");
      validatePercentageField("commercial_vat_percentage");
      validateField("invoice_bill_amount_inclusive_vat", shouldContain);
      validateField("invoice_bill_amount_inclusive_vat_npr");
      validateField("invoice_bill_amount_inclusive_vat_usd");

      // Deduction
      validateField("commercial_advance_amount", shouldContain);
      validateField("commercial_advance_amount_npr");
      validateField("commercial_advance_amount_usd");
      validateField("commercial_other_deduction", shouldContain);
      validateField("commercial_other_deduction_npr");
      validateField("commercial_other_deduction_usd");
      validatePercentageField("commercial_contract_tax_percentage");
      validatePercentageField("commercial_retention_percentage");
      validatePercentageField("commercial_liqudate_damage_percentage");
      validateField("commercial_total_deduction", shouldContain);
      validateField("commercial_total_deduction_npr");
      validateField("commercial_total_deduction_usd");
      validateField("commercial_net_payable_amount", shouldContain);
      validateField("commercial_net_payable_amount_npr");
      validateField("commercial_net_payable_amount_usd");
    }

    // VAT BILL
    if (data.transaction_type === "VAT BILL") {
      validateField("bill_date");
      validateField("bill_date_ad");
      validateField("disbursed_date");
      validateField("disbursed_date_ad");
      validateField("bill_number");
      validateField("bill_amount", shouldContain);
      validateField("bill_amount_npr");
      validateField("bill_amount_usd");
      validatePercentageField("vat_percentage");
      validateField("bill_amount_inclusive_vat", shouldContain);
      validateField("bill_amount_inclusive_vat_npr");
      validateField("bill_amount_inclusive_vat_usd");

      // Deduction
      validateField("advance_amount", shouldContain);
      validateField("advance_amount_npr");
      validateField("advance_amount_usd");
      validateField("other_deduction", shouldContain);
      validateField("other_deduction_npr");
      validateField("other_deduction_usd");
      validatePercentageField("prior_collection_of_vat_percentage");
      validatePercentageField("tds_percentage");
      validatePercentageField("retention_percentage");
      validatePercentageField("liqudate_damage_percentage");
      validateField("total_deduction", shouldContain);
      validateField("total_deduction_npr");
      validateField("total_deduction_usd");
      validateField("net_payable_amount", shouldContain);
      validateField("net_payable_amount_npr");
      validateField("net_payable_amount_usd");
    }

    if (Object.keys(error).length === 0) {
      return { success: true };
    } else {
      return { succes: false, error };
    }
  },
  validateForm: (data) => {
    const error = {};
    let isRequired = "Field is Required";
    let shouldContain = "Contains only Number";
    let shouldRangeBetween = "Ranges between 0 - 100";

    //!Basic
    if (!data.loan_id) error["loan_id"] = isRequired;
    if (!data.donor_currency) error["donor_currency"] = isRequired;
    if (!data.contract_id) error["contract_id"] = isRequired;
    if (!data.particular) error["particular"] = isRequired;
    if (!data.bill_type) error["bill type is required"] = isRequired;
    if (!data.transaction_type)
      error["transaction type is required"] = isRequired;
    if (!data.forex_conversion_basis)
      error["forex conversion basis is required"] = isRequired;

    //!Advance/Retention
    if (data.transaction_type === "ADVANCE/RETENTION") {
      if (!data.advance_retention_bill_number)
        error["advance_retention_bill_number"] = isRequired;
      if (!data.advance_release_date)
        error["advance_release_date"] = isRequired;
      if (!data.advance_release_date_ad)
        error["advance_release_date_ad"] = isRequired;
      if (!data.advance_disbursed_date)
        error["advance_disbursed_date"] = isRequired;
      if (!data.advance_disbursed_date_ad)
        error["advance_disbursed_date_ad"] = isRequired;
      if (!data.advance_disbursed) error["advance_disbursed"] = isRequired;
      else {
        if (isNaN(+data.advance_disbursed) || +data.advance_disbursed < 0)
          error["advance_disbursed"] = shouldContain;
      }
      if (!data.advance_disbursed_npr)
        error["advance_disbursed_npr"] = isRequired;
      if (!data.advance_disbursed_usd)
        error["advance_disbursed_usd"] = isRequired;
    }
    //!COMMERCIAL INVOICE (LC)
    if (data.transaction_type === "COMMERCIAL INVOICE (LC)") {
      if (!data.commercial_bill_date)
        error["commercial_bill_date"] = isRequired;
      if (!data.commercial_bill_date_ad)
        error["commercial_bill_date_ad"] = isRequired;
      if (!data.commercial_disbursed_date)
        error["commercial_disbursed_date"] = isRequired;
      if (!data.commercial_disbursed_date_ad)
        error["commercial_disbursed_date_ad"] = isRequired;
      if (!data.invoice_number) error["invoice_number"] = isRequired;

      if (!data.commercial_bill_amount)
        error["commercial_bill_amount"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_bill_amount) ||
          +data.commercial_bill_amount < 0
        )
          error["commercial_bill_amount"] = shouldContain;
      }
      if (!data.commercial_bill_amount_npr)
        error["commercial_bill_amount_npr"] = isRequired;
      if (!data.commercial_bill_amount_usd)
        error["commercial_bill_amount_usd"] = isRequired;
      if (!data.commercial_vat_percentage)
        error["commercial_vat_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_vat_percentage) ||
          +data.commercial_vat_percentage < 0 ||
          +data.commercial_vat_percentage > 100
        )
          error["commercial_vat_percentage"] = shouldRangeBetween;
      }
      if (!data.commercial_bill_amount_inclusive_vat)
        error["commercial_bill_amount_inclusive_vat"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_bill_amount_inclusive_vat) ||
          +data.commercial_bill_amount_inclusive_vat < 0
        )
          error["commercial_bill_amount_inclusive_vat"] = shouldContain;
      }
      if (!data.commercial_bill_amount_inclusive_vat_npr)
        error["commercial_bill_amount_inclusive_vat_npr"] = isRequired;
      if (!data.commercial_bill_amount_inclusive_vat_usd)
        error["commercial_bill_amount_inclusive_vat_usd"] = isRequired;

      //!Deduction
      if (!data.commercial_advance_amount)
        error["commercial_advance_amount"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_advance_amount) ||
          +data.commercial_advance_amount < 0
        )
          error["commercial_advance_amount"] = shouldContain;
      }
      if (!data.commercial_advance_amount_npr)
        error["commercial_advance_amount_npr"] = isRequired;
      if (!data.commercial_advance_amount_usd)
        error["commercial_advance_amount_usd"] = isRequired;
      if (!data.commercial_other_deduction)
        error["commercial_other_deduction"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_other_deduction) ||
          +data.commercial_other_deduction < 0
        )
          error["commercial_other_deduction"] = shouldContain;
      }
      if (!data.commercial_other_deduction_npr)
        error["commercial_other_deduction_npr"] = isRequired;
      if (!data.commercial_other_deduction_usd)
        error["commercial_other_deduction_usd"] = isRequired;
      if (!data.commercial_contract_tax_percentage)
        error["commercial_contract_tax_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_contract_tax_percentage) ||
          +data.commercial_contract_tax_percentage < 0 ||
          +data.commercial_contract_tax_percentage > 100
        )
          error["commercial_contract_tax_percentage"] = shouldRangeBetween;
      }
      if (!data.commercial_retention_percentage)
        error["commercial_retention_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_retention_percentage) ||
          +data.commercial_retention_percentage < 0 ||
          +data.commercial_retention_percentage > 100
        )
          error["commercial_retention_percentage"] = shouldRangeBetween;
      }
      if (!data.commercial_liqudate_damange_percentage)
        error["commercial_liqudate_damange_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_liqudate_damange_percentage) ||
          +data.commercial_liqudate_damange_percentage < 0 ||
          +data.commercial_liqudate_damange_percentage > 100
        )
          error["commercial_liqudate_damange_percentage"] = shouldRangeBetween;
      }
      if (!data.commercial_total_deduction)
        error["commercial_total_deduction"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_total_deduction) ||
          +data.commercial_total_deduction < 0
        )
          error["commercial_total_deduction"] = shouldContain;
      }
      if (!data.commercial_total_deduction_npr)
        error["commercial_total_deduction_npr"] = isRequired;
      if (!data.commercial_total_deduction_usd)
        error["commercial_total_deduction_usd"] = isRequired;
      if (!data.commercial_net_payable_amount)
        error["commercial_net_payable_amount"] = isRequired;
      else {
        if (
          isNaN(+data.commercial_net_payable_amount) ||
          +data.commercial_net_payable_amount < 0
        )
          error["commercial_net_payable_amount"] = shouldContain;
      }
      if (!data.commercial_net_payable_amount_npr)
        error["commercial_net_payable_amount_npr"] = isRequired;
      if (!data.commercial_net_payable_amount_usd)
        error["commercial_net_payable_amount_usd"] = isRequired;
    }
    //!VAT BILL
    if (data.transaction_type === "VAT BILL") {
      if (!data.bill_date) error["bill_date"] = isRequired;
      if (!data.bill_date_ad) error["bill_date_ad"] = isRequired;
      if (!data.disbursed_date) error["disbursed_date"] = isRequired;
      if (!data.disbursed_date_ad) error["disbursed_date_ad"] = isRequired;
      if (!data.invoice_number) error["invoice_number"] = isRequired;

      if (!data.bill_amount) error["bill_amount"] = isRequired;
      else {
        if (isNaN(+data.bill_amount) || +data.bill_amount < 0)
          error["bill_amount"] = shouldContain;
      }
      if (!data.bill_amount_npr) error["bill_amount_npr"] = isRequired;
      if (!data.bill_amount_usd) error["bill_amount_usd"] = isRequired;
      if (!data.bill_amount_inclusive_vat)
        error["bill_amount_inclusive_vat"] = isRequired;
      else {
        if (
          isNaN(+data.bill_amount_inclusive_vat) ||
          +data.bill_amount_inclusive_vat < 0
        )
          error["bill_amount_inclusive_vat"] = shouldContain;
      }
      if (!data.bill_amount_inclusive_vat_npr)
        error["bill_amount_inclusive_vat_npr"] = isRequired;
      if (!data.bill_amount_inclusive_vat_usd)
        error["bill_amount_inclusive_vat_usd"] = isRequired;

      //!Deduction
      if (!data.advance_amount) error["advance_amount"] = isRequired;
      else {
        if (isNaN(+data.advance_amount) || +data.advance_amount < 0)
          error["advance_amount"] = shouldContain;
      }
      if (!data.advance_amount_npr) error["advance_amount_npr"] = isRequired;
      if (!data.advance_amount_usd) error["advance_amount_usd"] = isRequired;
      if (!data.other_deduction) error["other_deduction"] = isRequired;
      else {
        if (isNaN(+data.other_deduction) || +data.other_deduction < 0)
          error["other_deduction"] = shouldContain;
      }
      if (!data.other_deduction_npr) error["other_deduction_npr"] = isRequired;
      if (!data.other_deduction_usd) error["other_deduction_usd"] = isRequired;
      if (!data.prior_collection_of_vat_percentage)
        error["prior_collection_of_vat_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.prior_collection_of_vat_percentage) ||
          +data.prior_collection_of_vat_percentage < 0 ||
          +data.prior_collection_of_vat_percentage > 100
        )
          error["prior_collection_of_vat_percentage"] = shouldRangeBetween;
      }
      if (!data.tds_percentage) error["tds_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.tds_percentage) ||
          +data.tds_percentage < 0 ||
          +data.tds_percentage > 100
        )
          error["tds_percentage"] = shouldRangeBetween;
      }
      if (!data.retention_percentage)
        error["retention_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.retention_percentage) ||
          +data.retention_percentage < 0 ||
          +data.retention_percentage > 100
        )
          error["retention_percentage"] = shouldRangeBetween;
      }
      if (!data.liqudate_damange_percentage)
        error["liqudate_damange_percentage"] = isRequired;
      else {
        if (
          isNaN(+data.liqudate_damange_percentage) ||
          +data.liqudate_damange_percentage < 0 ||
          +data.liqudate_damange_percentage > 100
        )
          error["liqudate_damange_percentage"] = shouldRangeBetween;
      }
      if (!data.total_deduction) error["total_deduction"] = isRequired;
      else {
        if (isNaN(+data.total_deduction) || +data.total_deduction < 0)
          error["total_deduction"] = shouldContain;
      }
      if (!data.total_deduction_npr) error["total_deduction_npr"] = isRequired;
      if (!data.total_deduction_usd) error["total_deduction_usd"] = isRequired;
      if (!data.net_payable_amount) error["net_payable_amount"] = isRequired;
      else {
        if (isNaN(+data.net_payable_amount) || +data.net_payable_amount < 0)
          error["net_payable_amount"] = shouldContain;
      }
      if (!data.net_payable_amount_npr)
        error["net_payable_amount_npr"] = isRequired;
      if (!data.net_payable_amount_usd)
        error["net_payable_amount_usd"] = isRequired;
    }

    const doesErrorExistInMain = Object.keys(error).find(
      (j) => typeof error[j] === "string"
    );
    return doesErrorExistInMain
      ? { error: true, mainError: error, data }
      : { error: false, data };
  },
  submitForm: async (data, sub_project_id, documents, currency) => {
    try {
      if (!data || !sub_project_id || !currency)
        throw new Error("Invalida data format");

      const formData = new FormData();
      formData.append("project_id", sub_project_id);
      Object.keys(data).forEach((i) => formData.append(i, data[i]));
      for (let i = 0; i < documents.length; i++) {
        const element = documents[i];
        formData.append("images[]", element);
      }
      currency.forEach((i) => {
        formData.append("currency[]", JSON.stringify(i));
      });

      // const [bsY, bsM, bsD] = data.disburse_by_bank_date.split("-");
      // const [adY, adM, adD] = data.disburse_by_bank_date_ad.split("-");

      // const fiscalYear = +bsM <= 3 ? `${bsY - 1}/${bsY}` : `${bsY}/${+bsY + 1}`;

      // const ad = todaysDate.AD;
      // const adFiscalYear =
      //   +adM < 7 || (+adM == 7 && +adD <= 16)
      //     ? `${adY - 1}/${adY}`
      //     : `${adY}/${+adY + 1}`;

      // formData.append("fiscal_year", fiscalYear);
      // formData.append("fiscal_year_ad", adFiscalYear);

      const response = await doPost("/bills-infos/create", formData);
      if (!response.data) throw new Error("Internal server error");

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDisabledValue: (data) => {
    return data !== undefined && data !== null && data !== ""
      ? undefined
      : true;
  },
};
export default AddBillController;

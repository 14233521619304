import React, { useState } from "react";

import { LOGO_MAIN } from "../../Assets/Images";
import { doPost } from "../../Services/Axios";
import { setUserDetailsToLocalStorage } from "../../Services/Helpers";
import { useForm } from "../../Services/useForm";

import SmallButton from "../../UI/Button/SmallButton";
import Input from "../../UI/Input/Input";
import { errorToast } from "../../Utils/Toastify";

export default function LoginScreen() {
  const [error, setError] = useState(undefined);
  const { errors, handleChange, states, validate } = useForm({
    username: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    try {
      if (validate()) {
        const response = await doPost("auth/login", {
          username: states.username,
          password: states.password,
        });

        if (response.data.success) {
          setUserDetailsToLocalStorage({
            token: response.data.token,
            user: response.data.user,
          });
        }
        console.log("====================================");
        console.log(response.data.token);
        console.log("====================================");
        if (!response.data.success)
          return setError("username or password incorrect");
        else {
          return (window.location.href = "/");
        }
      }
    } catch (error) {
      setError("username or password incorrect");
      error &&
        error.response &&
        error.response.data &&
        error.response.data.message &&
        errorToast(error.response.data.message);
    }
  };

  return (
    <div className="login">
      <div className="login-main">
        <div className="login-main--title">
          <div className="login-main--title__logo">
            <img src={LOGO_MAIN} alt="logo main" width="100%"></img>
          </div>
          <div className="login-main--title__main">
            Finance Directorate Corporate Finance Department FPID
          </div>
        </div>
        <div className="login-main--form">
          <div className="login-main--form__title">Login as</div>
          <Input
            label={"Username"}
            name={"username"}
            placeholder="Admin"
            type={"text"}
            onChange={handleChange}
            error={errors.username}
            customStyle={{ width: "30rem" }}
          />
          <Input
            label={"password"}
            name={"password"}
            placeholder={"********"}
            type={"password"}
            onChange={handleChange}
            error={errors.password || error}
            customStyle={{ width: "30rem" }}
          />
          <SmallButton
            content={"Login"}
            customStyle={{ width: "30rem" }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TopBar = ({ title, subtitle, children, id, backPage }) => {
  const navigate = useNavigate();
  return (
    <div className="topbar">
      <div className="topbar-title">
        <div className="topbar-title--head">
          {backPage && (
            <i
              className="fa-light fa-chevron-left topbar-back"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
          )}

          {title}
        </div>
        <div className="topbar-title--body">
          <i className="fa-light fa-grid-2"></i>
          <span>
            / {subtitle ? subtitle : title}
            &nbsp;
            {id && id}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default TopBar;

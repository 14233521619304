import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
import useForex from "../../../Hooks/useForex";
import { doGet, doPost } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import { currencyList, InternalSource } from "../../../Store/DataSet";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import DateInput from "../../../UI/DateInput/DateInput";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import Select from "../../../UI/Select/Select";
import { errorToast } from "../../../Utils/Toastify";
import { useBudgetForm } from "../useBudgetForm";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../../Slice/SystemSlice";

const InsertBudget = ({
  onClose,
  project,
  donorCurrency,
  loans,
  contractors,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { nepaliDate } = useSelector((state) => state.System);
  const { filterForexWithDate, getConversionRate } = useForex();
  const [images, setImages] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const {
    handleChange,
    errors,
    states,
    handleCurrency,

    selectedApg,
    selectedId,
    handleAddApg,
    handleApgChange,
    handleApgCurrency,
    apg,

    validateForm,
    apgError,
    setSelectedApg,
    setSelectedId,
    setApg,
    isFormvalid,
    setApgError,
  } = useBudgetForm(
    {
      serial_number: "",
      loan_id: "",
      agreement_date: "",
      agreement_date_ad: "",
      donor_currency: "",
      donor_agency: "",
      fiscal_year: "",
      activity_number: "",
      budget_head: "",
      budget_re_imburse: "",
      expenses_head: "",
      contract_id: "",
      approved_budget: "",
      approved_budget_npr: "",
      approved_budget_usd: "",
      estimated_cost: "",
      estimated_cost_usd: "",
      estimated_cost_npr: "",
    },
    filterForexWithDate
  );

  const handleImage = (e) => {
    setImages(e);
  };

  const submitForm = async () => {
    try {
      dispatch(toggleLoader(true));
      if (validateForm()) {
        const currency = await getConversionRate(states.agreement_date_ad, {
          USD: true,
          [states.donor_currency.toUpperCase()]: true,
        });
        const formData = new FormData();
        currency.forEach((i) => {
          formData.append("currency[]", JSON.stringify(i));
        });
        formData.append("project_id", project);
        formData.append("approved_budget_currency", states.donor_currency);
        formData.append("estimated_cost_currency", states.donor_currency);
        for (let i = 0; i < apg.length; i++) {
          Object.keys(apg[i]).forEach((el) => {
            formData.append(`advancePaymentGuarantee[${i}][${el}]`, apg[i][el]);
          });
        }
        for (let index = 0; index < Object.entries(states).length; index++) {
          formData.append(
            Object.entries(states)[index][0],
            Object.entries(states)[index][1]
          );
        }
        for (let index = 0; index < images.length; index++) {
          formData.append("images[]", images[index]);
        }
        formData.set("donor_currency", states.donor_currency);
        formData.set("loan_id", states.loan_id);

        await doPost("/budget-info/create", formData);
        onClose();
        queryClient.refetchQueries(["budget"]);
      } else {
        setIsValid(false);
        return errorToast("Validation error");
      }
    } catch (error) {
      errorToast("Error while inserting budget");
    } finally {
      dispatch(toggleLoader(false));
    }
  };
  const handleClose = () => {
    setCloseConfirmationModal(true);
  };
  console.log(states);

  return (
    <ModalLayout onClose={handleClose} title={"Insert Budget"}>
      {confirmationModal && (
        <ConfirmationModal
          text={"Do you really  want to insert budget ??"}
          caption={"Please verify all details before clicking yes "}
          onClose={() => setConfirmationModal(false)}
          onSuccess={() => {
            submitForm();
            setConfirmationModal(false);
          }}
        />
      )}
      {closeConfirmationModal && (
        <ConfirmationModal
          caption={"this will remove all filled data"}
          text={"Do you really  want to cancel inserting budget ??"}
          onClose={() => setCloseConfirmationModal(false)}
          onSuccess={() => {
            setConfirmationModal(false);
            onClose();
          }}
        />
      )}
      {contractors.length > 0 && loans.length > 0 ? (
        <div className="insertbudget-wrapper">
          <div className="insertbudget-submit">
            <PrimaryButton
              content={"Add Budget"}
              onClick={() => setConfirmationModal(true)}
            />
          </div>
          <div className="insertbudget">
            <div className="insertbudget-title">Basic Details</div>
            <div className="insertbudget-first">
              <div
                style={{
                  height: "5rem",
                }}
              >
                <Select
                  name="loan_id"
                  label={"Loan number"}
                  error={errors.loan_id}
                  defaultValue={`${states.loan_id}|${states.donor_currency}|${states.donor_agency}`}
                  handler={handleChange}
                  preventMouse
                  options={loans.map((item) => {
                    return {
                      key: `${item.donor_agency}-${item.loan_number}-${item.donor_currency}`,
                      value: `${item.id}|${item.donor_currency}|${item.donor_agency}`,
                    };
                  })}
                />
              </div>

              <Select
                options={Object.keys(nepaliDate.NEPALI_YEARS).map((i) => ({
                  key: `${i}/${(+i + 1).toString()}`,
                }))}
                error={errors.fiscal_year}
                defaultValue={states.fiscal_year}
                handler={handleChange}
                name={"fiscal_year"}
                label={"Fiscal Year"}
                preventMouse
              />
              <div
                style={{
                  height: "5rem",
                }}
              >
                <Select
                  handler={handleChange}
                  name="contract_id"
                  label={"Contract Id"}
                  error={errors.contract_id}
                  defaultValue={states.contract_id}
                  options={
                    contractors
                      ? contractors
                          .filter((i) => i.progress_status == 1)
                          .map((el) => {
                            return {
                              key: `${el.contractor_name}-${el.contract_number}-${el.contractor_address}`,
                              value: el.id,
                            };
                          })
                      : []
                  }
                />
              </div>
              <div
                style={{
                  height: "5rem",
                }}
              >
                <DateInput
                  onChange={handleChange}
                  name="agreement_date"
                  label={"Budget Approval Date"}
                  error={errors.agreement_date}
                  customDate={states.agreement_date_ad}
                />
              </div>
              <Input
                name="donor_agency"
                label={"Donor agency"}
                error={errors.donor_agency}
                placeholder={"Eg.Asian Development Bank"}
                disabled={true}
                defaultValue={states.donor_agency}
              />
              <Input
                onChange={handleChange}
                name="serial_number"
                label={"serial_number"}
                placeholder="Eg. SE12312"
                error={errors.serial_number}
                defaultValue={states.serial_number}
              />
              <Input
                onChange={handleChange}
                name="activity_number"
                label={"activity number"}
                placeholder={"Eg.ACT-1111"}
                error={errors.activity_number}
                defaultValue={states.activity_number}
              />
            </div>

            {/* <Input
              onChange={handleChange}
              name="directorate_office"
              label={"directorate/officename"}
              placeholder={"Eg.Dhangadi Electricity"}
              error={errors.directorate_office}
              defaultValue={states.directorate_office}
            />
            <Input
              onChange={handleChange}
              name="directorate_office_code"
              label={"directorate/officecode"}
              placeholder={"Eg.OFC-1011"}
              error={errors.directorate_office_code}
              defaultValue={states.directorate_office_code}
            />
            <Input
              onChange={handleChange}
              name="office_name"
              label={"Office name"}
              placeholder={"Eg.Dharan Electricity"}
              error={errors.office_name}
              defaultValue={states.office_name}
            />
            <Input
              onChange={handleChange}
              name="office_code"
              label={"Office Code"}
              placeholder={"Eg.OFC-1020"}
              error={errors.office_code}
              defaultValue={states.office_code}
            /> */}
            <div className="insertbudget-title">Budget Details</div>
            <Input
              onChange={handleChange}
              name="budget_head"
              placeholder={"Eg.BH111"}
              label={"budget upa sirsak"}
              error={errors.budget_head}
              defaultValue={states.budget_head}
            />
            <Input
              onChange={handleChange}
              name="expenses_head"
              placeholder={"Eg.EH111"}
              label={"kharcha sirsak"}
              error={errors.expenses_head}
              defaultValue={states.expenses_head}
            />
            <Input
              onChange={handleChange}
              name="budget_re_imburse"
              label={"Nikash bidhi"}
              placeholder={"Eg.RMB123"}
              error={errors.budget_re_imburse}
              defaultValue={states.budget_re_imburse}
            />
            <div className="insertbudget-title">Amount Details</div>

            {states.donor_currency === "SDR" ? (
              <Input
                label={"1USD to SDR Conversion Rate"}
                required
                name="sdr_conversion_rate"
                placeholder={"eg: 1.4"}
                onChange={handleChange}
                defaultValue={states.sdr_conversion_rate}
                error={errors.sdr_conversion_rate}
              />
            ) : null}
            <div
              style={{
                gridColumn: "1/-1",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "2rem",
              }}
            >
              <Input
                onChange={handleChange}
                name="approved_budget_npr"
                label={"Approved Budget(In Npr)"}
                onBlur={handleCurrency}
                placeholder={"Eg. 10000"}
                error={errors.approved_budget_npr}
                defaultValue={states.approved_budget_npr}
              />

              <Input
                onChange={handleChange}
                name="approved_budget"
                label={"In Donor Currency"}
                disabled={true}
                placeholder={"AUTO"}
                error={errors.approved_budget}
                defaultValue={states.approved_budget}
              />
              <Input
                onChange={handleChange}
                name="approved_budget_usd"
                label={"In USD"}
                placeholder={"AUTO"}
                error={errors.approved_budget_usd}
                defaultValue={states.approved_budget_usd}
                disabled={true}
              />

              <Input
                onChange={handleChange}
                name="estimated_cost_npr"
                onBlur={handleCurrency}
                label={"Estimate cost(In Npr)"}
                placeholder={"10000"}
                error={errors.estimated_cost_npr}
                defaultValue={states.estimated_cost_npr}
              />

              <Input
                onChange={handleChange}
                name="estimated_cost"
                label={" In Donor Currency"}
                disabled={true}
                placeholder={"AUTO"}
                error={errors.estimated_cost}
                defaultValue={states.estimated_cost}
              />
              <Input
                onChange={handleChange}
                name="estimated_cost_usd"
                label={"In USD"}
                error={errors.estimated_cost_usd}
                placeholder={"AUTO"}
                defaultValue={states.estimated_cost_usd}
                disabled={true}
              />
            </div>
          </div>
          <div className="insertbudget-apg">
            <div className="insertbudget-apg--title__wrapper">
              <div className="insertbudget-apg--title">
                <div>APG(Advance Payment Guarentee)</div>
                <div className="insertbudget-apg--title__select">
                  <Select
                    options={
                      apg && apg.length > 0
                        ? apg.map((item, i) => {
                            return {
                              key: !item.bank_name
                                ? `item-${i}`
                                : item.bank_name,
                              value: item.id,
                            };
                          })
                        : []
                    }
                    handler={(e) => {
                      setSelectedId(e.target.value);
                      setSelectedApg(e.target.value);
                    }}
                    defaultValue={selectedApg}
                    error={!isValid}
                  />
                  <PrimaryButton
                    content={
                      <div>
                        <i className="fa-regular fa-add"></i>
                        Add Apg
                      </div>
                    }
                    onClick={handleAddApg}
                  />
                </div>
              </div>
            </div>
            {!selectedId || selectedApg === "Select an option" ? (
              <p className="notfound notfound-bordered">
                {apg && apg[0]
                  ? "Please choose one option from drop down"
                  : "No custom duty found"}
              </p>
            ) : (
              apg &&
              apg
                .filter((item) => +item.id === +selectedId)
                .map((item, index) => (
                  <div>
                    <div className="insertbudget-apg--remove">
                      <div className="insertbudget-apg--title__remove">
                        <PrimaryButton
                          onClick={() => {
                            setApg(
                              apg.filter((item) => {
                                return +item.id !== +selectedId;
                              })
                            );
                            setApgError((prev) => (delete prev[item.id], prev));
                            setIsValid(true);
                          }}
                          customStyle={{ backgroundColor: "#c71c1c" }}
                          content={
                            <>
                              <i className="fa-regular fa-trash"></i>
                              Delete
                            </>
                          }
                        />
                      </div>
                    </div>
                    <div className="insertbudget-apg--main">
                      <Input
                        label={"Name of Bank:"}
                        name={"bank_name"}
                        placeholder="Eg. Sidhartha Bank"
                        onChange={(e) => {
                          handleApgChange(item.id, e);
                          setSelectedApg(item.id);
                          setSelectedId(item.id);
                        }}
                        defaultValue={item.bank_name}
                        error={
                          apgError[item.id] ? apgError[item.id].bank_name : null
                        }
                      />
                      <Input
                        label={"Guarentee number"}
                        placeholder="Eg. 123123"
                        name={"advance_guarantee_no"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        defaultValue={item.advance_guarantee_no}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_guarantee_no
                            : null
                        }
                      />
                      <DateInput
                        label={"Validity Period"}
                        placeholder="Eg. 5"
                        name={"validity_period"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        customDate={item.validity_period_ad}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].validity_period
                            : null
                        }
                      />
                      <Input
                        label={"Advance amount(In Npr)"}
                        placeholder="Eg. 10000"
                        name="advance_amount_npr"
                        onBlur={(e) => handleApgCurrency(item.id, e)}
                        onChange={(e) => handleApgChange(item.id, e)}
                        defaultValue={item.advance_amount_npr}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_amount_npr
                            : null
                        }
                      />
                      <div className="insertbudget-apg--currency">
                        <Input
                          label={"In USD"}
                          disabled={true}
                          placeholder={"AUTO"}
                          customStyle={{ width: "50%" }}
                          defaultValue={item.advance_amount_usd}
                          error={
                            apgError[item.id]
                              ? apgError[item.id].advance_amount_usd
                              : null
                          }
                        />
                        <Input
                          label={"In Donor Currency"}
                          disabled={true}
                          placeholder={"AUTO"}
                          customStyle={{ width: "50%" }}
                          defaultValue={item.advance_amount}
                          error={
                            apgError[item.id]
                              ? apgError[item.id].advance_amount
                              : null
                          }
                        />
                      </div>
                      <DateInput
                        label={"Advance Release date"}
                        placeholder="YYYY-MM-DD"
                        name={"advance_release_date"}
                        onChange={(e) => handleApgChange(item.id, e)}
                        customDate={item.advance_release_date_ad}
                        error={
                          apgError[item.id]
                            ? apgError[item.id].advance_release_date
                            : null
                        }
                      />
                    </div>
                  </div>
                ))
            )}
          </div>
          <div className="insertbudget-files">
            <h4 className="addbill-form--header">Documents:</h4>
            <FileInput handleSubmitImage={handleImage} />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "40vw",
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="notfound">Loans and Contracts are required</div>
        </div>
      )}
    </ModalLayout>
  );
};
export default InsertBudget;

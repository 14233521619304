import React from "react";
import { Link } from "react-router-dom";
import Select from "../../../UI/Select/Select";
import CreateProjectController from "./_createProject";
import Loans from "./Loans";
import { useState } from "react";
import Editor from "./Editor";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage, toggleLoader } from "../../../Slice/SystemSlice";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../Utils/Toastify";
import useForex from "../../../Hooks/useForex";

export default function CreateProject() {
  const { language } = useSelector((state) => state.System);
  const handleToggleLanguage = (item) => {
    if (item === "en") {
      dispatch(toggleLanguage("en"));
    } else {
      dispatch(toggleLanguage("np"));
    }
  };
  //!System
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);

  //!Project
  const [project, setProject] = useState("");
  const [loans, setLoans] = useState([]);
  const [errors, setErrors] = useState({});

  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedSource, setSelectedSource] = useState("Internal");
  const [selectedLoanType, setSelectedLoanType] = useState("GoN Loan");

  const { getConversionRate } = useForex();
  const { loanType, projectSources, submitForm, validateForm } =
    CreateProjectController;

  const handleSelectedSource = (e) => {
    const value = e.target.value;
    setSelectedSource(value);
    setSelectedLoanType(loanType[value][Object.keys(loanType[value])[0]].key);
    setLoans([]);
    setSelectedLoan(null);
  };

  const handleName = (e) => {
    const value = e.target.value.trim();
    setErrors((prev) => (delete prev["project"], prev));
    setProject(value);
    if (!value)
      setErrors((prev) => ({ ...prev, project: "Field is required" }));
  };
  const handleLoan = (data, error) => {
    if (selectedLoan.key !== data.key) return;
    setLoans(
      loans.map((i) => {
        if (i.key === data.key) return data;
        return i;
      })
    );
    setErrors((prev) => ({ ...prev, [data.key]: error }));
  };

  const addLoan = () => {
    setLoans((prev) => [
      ...prev,
      { key: Date.now(), type: loanType[selectedSource][selectedLoanType].key },
    ]);
  };
  const resetProject = () => {
    setLoans([]);
    setProject("");
    setErrors({});
    setEditMode(false);
    setSelectedLoan(null);
  };
  const removeLoan = (index) => {
    if (loans.length < 1) setSelectedLoan(null);
    setLoans(loans.filter((i) => i.key !== index));
    setErrors((prev) => ({ ...prev, [index]: null }));
  };

  const initiateSubmit = async () => {
    dispatch(toggleLoader(true));
    const response = validateForm(loans, project);
    if (!response) {
      errorToast("Loan is required");
      return dispatch(toggleLoader(false));
    }
    setErrors(response.errors);
    setLoans(response.loans);
    if (!response.status) {
      errorToast("Validation Failed");
      return dispatch(toggleLoader(false));
    }

    try {
      const apiResponse = await submitForm(
        response.loans,
        project,
        selectedSource,
        getConversionRate
      );

      successToast("Project Created");
      apiResponse && resetProject();
    } catch (error) {
      errorToast("Internal Server Error ");
    }

    dispatch(toggleLoader(false));
    return;
  };

  const handleConfirmationModal = (action) => {
    setConfirmation(true);
    switch (action) {
      case "BACK":
        setConfirmationDetails({
          text: "Are you sure you want to go back?",
          caption: "Going back will reset all project details",
          action: () => navigate(-1),
        });
        break;
      case "RESET":
        setConfirmationDetails({
          text: "Are you sure you want to reset project?",
          caption:
            "By confirming you will reset project data and cannot be reverted",
          action: () => resetProject(),
        });
        break;
      case "SAVE":
        setConfirmationDetails({
          text: "Are you sure you want to save this project?",
          caption: "Please verify all details before clicking yes",
          action: () => initiateSubmit(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {confirmation && confirmationDetails && (
        <ConfirmationModal
          onSuccess={() => (
            confirmationDetails.action(), setConfirmation(false)
          )}
          onClose={() => setConfirmation(false)}
          text={confirmationDetails.text}
          caption={confirmationDetails.caption}
        />
      )}
      <div className="createProject">
        <div className="createProject-header">
          <div className="createProject-header--title">
            <div
              className="createProject-header--title__back"
              title="Back"
              onClick={() => handleConfirmationModal("BACK")}
            >
              <i className="fa-light fa-chevron-left"></i>
            </div>
            <span className="createProject-header--title__name">
              Create New Project
            </span>
          </div>
          <div className="createProject-header--action">
            <div className="createProject-header--action__source">
              <div className="nav-action--language">
                <button
                  className={`nav-action--language__en ${
                    language === "en" ? "language-selected" : ""
                  }`}
                  onClick={() => handleToggleLanguage("en")}
                >
                  EN
                </button>
                <button
                  className={`nav-action--language__np ${
                    language === "np" ? "language-selected" : ""
                  }`}
                  onClick={() => handleToggleLanguage("np")}
                >
                  NP
                </button>
              </div>
            </div>
            <div className="createProject-header--action__source">
              <Select
                options={Object.keys(projectSources).map(
                  (i) => projectSources[i]
                )}
                handler={handleSelectedSource}
                defaultValue={selectedSource}
                preventMouse
              />
            </div>
            <PrimaryButton
              content={"Reset Form"}
              icon="fa-light fa-trash"
              title="Reset Form"
              onClick={() => handleConfirmationModal("RESET")}
              isSecondary={true}
            />
            <PrimaryButton
              content={"Save Now"}
              icon="fa-light fa-floppy-disk"
              title="Save Now"
              onClick={() => handleConfirmationModal("SAVE")}
            />
          </div>
        </div>
        <div className="createProject-body">
          <Loans
            data={{
              loans,
              loanType,
              selectedLoan,
              selectedLoanType,
              selectedSource,
              editMode,
              errors,
            }}
            functions={{
              setSelectedLoanType,
              addLoan,
              setEditMode,
              setSelectedLoan,
              removeLoan,
            }}
          />
          <Editor
            data={{
              loan: selectedLoan,
              error:
                selectedLoan && errors[selectedLoan.key]
                  ? errors[selectedLoan.key]
                  : {},
              project,
              projectError: errors.project,
            }}
            language={language}
            functions={{ handleName, handleLoan }}
          />
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import TopBar from "../../Components/TopBar/TopBar";

import SmallButton from "../../UI/Button/SmallButton";
import CreateNotice from "./CreateNotice/CreateNotice";

import FilesModal from "../../Components/FilesModal/FilesModal";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { doGet } from "../../Services/Axios";
import Loading from "../../UI/Loader/Loader";
import LoadingModal from "../../UI/LoadingModal/LoadingModal";
import TextArea from "../../UI/TextArea/TextArea";
import PrimaryButton from "../../UI/Button/PrimaryButton";
import { NoticeController } from "./NoticeController";
import Table from "../../UI/Table/Table";
import ActionButton from "../../UI/Button/ActionButton";
import EditNotice from "./EditNotice/EditNotice";
import View from "../../UI/View/View";
import DescriptionModal from "./Description/DescriptionModal";
import { useSelector } from "react-redux";
const Notice = () => {
  const { user } = useSelector((state) => state.System);
  const [showCreateNotice, setShowCreateNotice] = useState(false);
  const [showImage, setShowImages] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedImage, setSelectedImages] = useState({
    noticeImage: [],
  });
  const [editAccess, setEditAccess] = useState(false);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [page, setPage] = useState(1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleshowCreateNotice = () => {
    setShowCreateNotice((prev) => !prev);
  };
  const handleShowImageModal = async (item) => {
    try {
      const response = await doGet(`/notice/${item}/show`);

      setSelectedImages(response.data && response.data);

      setShowImages((prev) => !prev);
    } catch (error) {}
  };

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["notices", page],
    queryFn: () => NoticeController.fetchNotices(page),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    keepPreviousData: true,
  });

  let Columns = [
    { name: "id", sortable: true, searchable: true },
    { name: "title", sortable: true, searchable: true },
    { name: "description", sortable: false, searchable: false },
    { name: "documents", sortable: false, searchable: false },
    { name: "created_at", sortable: true, searchable: true },
    user &&
      user.user &&
      user.user.user_type &&
      user.user.user_type === "Admin" && {
        name: "action",
        sortable: false,
        searchable: false,
      },
  ];
  const handleEditNotice = (item) => {
    if (item) {
      setSelected(item);
    }
    setOpenEditModal((prev) => !prev);
  };
  const handlePagination = (type) => {
    if (data.data.notices.next_page_url && type === "pos") {
      setPage((prev) => prev + 1);
    } else if (data.data.notices.prev_page_url && type === "neg") {
      setPage((prev) => prev - 1);
    }
  };

  const handleOpenDescriptionModal = (selected) => {
    setSelected(selected);
    setDescriptionModal((prev) => !prev);
  };
  useEffect(() => {}, [selected]);
  return (
    <div className="notice">
      {showCreateNotice &&
        user &&
        user.user &&
        user.user.user_type &&
        user.user.user_type === "Admin" && (
          <CreateNotice handleClose={handleshowCreateNotice} />
        )}
      {showImage && (
        <FilesModal
          handleClose={handleShowImageModal}
          files={selectedImage.noticeImage}
          edit={false}
          type="Notice"
        />
      )}
      {openEditModal && (
        <EditNotice onClose={handleEditNotice} item={selected} />
      )}
      {descriptionModal && (
        <DescriptionModal
          item={selected.description ?? ""}
          handleClose={() => setDescriptionModal((prev) => !prev)}
        />
      )}
      <TopBar title={"Notice"}>
        {user &&
          user.user &&
          user.user.user_type &&
          user.user.user_type === "Admin" && (
            <SmallButton
              content={
                <>
                  <i className="fa-light fa-plus"></i>
                  Add Notices
                </>
              }
              onClick={handleshowCreateNotice}
            />
          )}
      </TopBar>

      <div className="notice-main">
        {/* {isLoading ? (
          <LoadingModal />
        ) : (
          <>
            <div className="notice-main--number">
              <div className="notice-main--number__title">
                <div>Previous Notices</div>
                <div
                  className="notice-main--number__title-icon"
                  onClick={handleDeleteAccess}
                  title="Edit Mode"
                >
                  <i
                    className={`fa-light ${
                      editAccess ? "fa-multiply" : "fa-pen"
                    }`}
                  ></i>
                </div>
              </div>
              <div className="notice-main--number__description">
                {data &&
                  data.data &&
                  data.data.notices.length > 0 &&
                  data.data.notices.map((el, index) => (
                    <div
                      className={`${
                        selected === index
                          ? "notice-main--notice active"
                          : " notice-main--notice "
                      }`}
                      onClick={() => handleSelectNotice(el)}
                    >
                      <div className="notice-main--notice__name">
                        {el.title}
                      </div>
                      {editAccess && (
                        <div className="notice-main--notice__action">
                          <i
                            className="fa-regular fa-trash "
                            style={{ color: "black" }}
                            onClick={() => handleDelete(el)}
                          ></i>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="notice-main--description">
              <div className="notice-main--description__title">
                Description:
                <div className="notice-main--description__title-action">
                  <SmallButton
                    customStyle={{
                      color: "#000",
                      backgroundColor: "#fff",
                      height: "max-content",
                      padding: "0.1rem 1rem",
                      borderRadius: "0.2rem",
                    }}
                    content={
                      descriptionEditMode ? "Edit Images" : "Show Images"
                    }
                    onClick={handleShowImageModal}
                  />
                  <div
                    className="notice-main--description__title-action--edit"
                    onClick={handleDescriptionEditMode}
                  >
                    <i
                      className={`fa-light ${
                        descriptionEditMode ? "fa-multiply" : "fa-pen"
                      }`}
                      title="Delete mode"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="notice-main--description__main">
                {descriptionEditMode ? (
                  <>
                    <TextArea
                      defaultValue={selected.description}
                      onChange={(el) => handleDescriptionEdit(selected.id)}
                    />
                    <div className="notice-main--description__main-update">
                      <PrimaryButton content={<>Update</>} />
                    </div>
                  </>
                ) : (
                  <div className="notice-main--description__main-show">
                    {selected && selected.description}
                  </div>
                )}
              </div>
            </div>
          </>
        )} */}
        <Table
          hasNextPage={
            data &&
            data.data &&
            data.data.notices &&
            data.data.notices.next_page_url
              ? true
              : false
          }
          hasPrevPage={
            data &&
            data.data &&
            data.data.notices &&
            data.data.notices.prev_page_url
              ? true
              : false
          }
          Columns={Columns}
          Rows={
            data &&
            data.data &&
            data.data.notices &&
            data.data.notices.data &&
            data.data.notices.data.map((item, index) => {
              return {
                id: (page - 1) * 10 + (index + 1),
                title: item.title,
                description: (
                  <View
                    label={"Read all"}
                    onClick={() => handleOpenDescriptionModal(item)}
                    type="button"
                    prefix={<i className="fa-light fa-eye"></i>}
                  />
                ),
                document: (
                  <View
                    label={"documents"}
                    onClick={() => handleShowImageModal(item.id)}
                    type="button"
                    prefix={<i className="fa-light fa-file-lines"></i>}
                  />
                ),

                created_at: item.created_at.split("T")[0],
                action: user &&
                  user.user &&
                  user.user.user_type &&
                  user.user.user_type === "Admin" && (
                    <>
                      <ActionButton
                        type="primary"
                        title={"Edit"}
                        icon={"fa-regular fa-edit"}
                        onClick={() => handleEditNotice(item)}
                      />
                    </>
                  ),
              };
            })
          }
          loading={isLoading || isRefetching}
          handlePagination={handlePagination}
          pageProp={page}
        />
      </div>
    </div>
  );
};

export default Notice;

import React from "react";
import Input from "../../UI/Input/Input";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Select from "../../UI/Select/Select";
import DateInput from "../../UI/DateInput/DateInput";
import { currencyList, InternalSource } from "../../Store/DataSet";
import { useEffect } from "react";
import { useState } from "react";
import { CreateLoanController } from "./_createLoan";
import useForex from "../../Hooks/useForex";
import { useSelector } from "react-redux";
import FileInput from "../../UI/FileInput/FileInput";

const correspondingValues = {
  idc_transfer_to_cwip: "idc_transfer_to_loan",
  idc_payable_to_gon: "idc_transfer_to_share",
  idc_nea_portion: "idc_gon_portion",
};
export default function CreateLoan({
  loan,
  errors,
  changeHandler,
  defaultCurrency = null,
  projectLoan,
}) {
  const [state, setState] = useState(loan);
  const [error, setError] = useState(errors);

  const { filterForex, filterForexWithDate } = useForex();
  const { nepaliDate } = useSelector((state) => state.System);

  const {
    validator,
    convertDate,
    getDisabledValue,
    modifyDate,
    installmentCriteria,
    installmentConversionOptions,
    idcConversionOptions,
    idcCriteria,
    installmentPeriods,
  } = CreateLoanController;

  useEffect(() => {
    setState(loan);
  }, [loan.key]);
  useEffect(() => {
    setError(errors);
  }, [JSON.stringify(errors)]);

  //!Checks
  useEffect(() => {
    idcConversion();
  }, [state.idc_effective_date, state.idc_factor_mode, state.idc_factor]);
  useEffect(() => {
    installmentConversion();
  }, [
    state.installment_start_date_ad,
    state.installment_factor_mode,
    state.installment_factor,
    state.installment_period,
  ]);

  const applyStateChange = (name, value) => {
    console.log(value);
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoanSelection = (e) => {
    const selectedProjectLoan = projectLoan.find((i) => i.id == e.target.value);
    selectedProjectLoan &&
      setState((prev) => ({
        ...prev,
        loan_id: selectedProjectLoan.id,
        loan_number: selectedProjectLoan.loan_number,
        donor_agency: selectedProjectLoan.donor_agency,
        donor_currency: selectedProjectLoan.donor_currency,
      }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "Select an option") return;

    correspondingValues[name] &&
      applyStateChange(
        correspondingValues[name],
        value && +value >= 0 && +value <= 100 ? 100 - +value : ""
      );
    console.log(correspondingValues[name]);
    applyStateChange(name, value);

    const isError = validator(e);
    setError((prev) =>
      isError
        ? { ...prev, [name]: isError }
        : (prev[name] && (prev[name] = null), prev)
    );

    //!Misc
    cleanUpFields(e);
    chainEffect(e);
  };

  const handleCurrency = async (e) => {
    if (!state.donor_currency || !state.donor_agreement_date_ad) return;
    console.log("here1");
    if (isNaN(+e.target.value) || +e.target.value < 0) {
      setState(
        (prev) => (
          delete prev[`${e.target.name}_usd`],
          delete prev[`${e.target.name}_npr`],
          prev
        )
      );
      return;
    }
    const convertedData = await filterForexWithDate(
      state.donor_currency,
      +e.target.value,
      [{ currency: "" }],
      state.donor_agreement_date_ad
    );
    if (!convertedData || !convertedData[0]) {
      setState(
        (prev) => (
          delete prev[`${e.target.name}_usd`],
          delete prev[`${e.target.name}_npr`],
          prev
        )
      );
      return;
    }
    setState((prev) => ({
      ...prev,
      [`${e.target.name}_usd`]: +convertedData[0].value,
      [`${e.target.name}_npr`]: +convertedData[1].value,
    }));
  };
  const installmentConversion = () => {
    if (!installmentCriteria(state)) {
      setState(
        (prev) => (
          delete prev[`no_of_installment`],
          delete prev[`installment_end_date`],
          delete prev[`installment_end_date_ad`],
          prev
        )
      );
      return;
    }

    const start = state.installment_start_date_ad;
    const value = +state.installment_factor;
    const type = state.installment_factor_mode;
    if (isNaN(value) || value < 0) {
      setState(
        (prev) => (
          delete prev[`no_of_installment`],
          delete prev[`installment_end_date`],
          delete prev[`installment_end_date_ad`],
          prev
        )
      );
      return;
    }

    const data = modifyDate(start, value, type, nepaliDate);
    if (data === "Invalid Date") {
      setState(
        (prev) => (
          delete prev[`no_of_installment`],
          delete prev[`installment_end_date`],
          delete prev[`installment_end_date_ad`],
          prev
        )
      );
      return;
    }

    const period = installmentPeriods[state.installment_period];
    const updatedNoOfDays = convertDate(start, data.AD, period);
    if (!updatedNoOfDays) {
      setState(
        (prev) => (
          delete prev[`no_of_installment`],
          delete prev[`installment_end_date`],
          delete prev[`installment_end_date_ad`],
          prev
        )
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      ["installment_end_date"]: data.BS,
      ["installment_end_date_ad"]: data.AD,
      ["no_of_installment"]: updatedNoOfDays,
    }));
  };
  const idcConversion = () => {
    if (!idcCriteria(state)) {
      setState(
        (prev) => (
          delete prev[`idc_no_of_days`],
          delete prev[`idc_ending_date`],
          delete prev[`idc_ending_date_ad`],
          prev
        )
      );
      return;
    }

    const start = state.idc_effective_date_ad;
    const value = +state.idc_factor;
    const type = state.idc_factor_mode;
    if (isNaN(value) || value < 0) {
      setState(
        (prev) => (
          delete prev[`idc_no_of_days`],
          delete prev[`idc_ending_date`],
          delete prev[`idc_ending_date_ad`],
          prev
        )
      );
      return;
    }

    const data = modifyDate(start, value, type, nepaliDate);
    if (data === "Invalid Date") {
      setState(
        (prev) => (
          delete prev[`idc_no_of_days`],
          delete prev[`idc_ending_date`],
          delete prev[`idc_ending_date_ad`],
          prev
        )
      );
      return;
    }

    const updatedNoOfDays = convertDate(start, data.AD);
    if (!updatedNoOfDays) {
      setState(
        (prev) => (
          delete prev[`idc_no_of_days`],
          delete prev[`idc_ending_date`],
          delete prev[`idc_ending_date_ad`],
          prev
        )
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      ["idc_ending_date"]: data.BS,
      ["idc_ending_date_ad"]: data.AD,
      ["idc_no_of_days"]: updatedNoOfDays,
    }));
  };

  const chainEffect = (e) => {
    switch (e.target.name) {
      case "grace_period_start_date":
        setState(
          (prev) => (
            delete prev["grace_period_end_date"],
            delete prev["grace_period_end_date_ad"],
            prev
          )
        );
        break;
      case "donor_agreement_date":
        if (e.target.value !== state.donor_agreement_date) {
          setState(
            (prev) => (
              delete prev["loan_amount"],
              delete prev["loan_amount_npr"],
              delete prev["loan_amount_usd"],
              delete prev["donor_service_charge_amount"],
              delete prev["donor_service_charge_amount_npr"],
              delete prev["donor_service_charge_amount_usd"],
              prev
            )
          );
          setError((prev) => (delete prev["sdr_conversion_rate"], prev));
        }
        break;
      case "donor_currency":
        if (e.target.value !== state.donor_currency) {
          setState(
            (prev) => (
              delete prev["sdr_conversion_rate"],
              delete prev["loan_amount"],
              delete prev["loan_amount_npr"],
              delete prev["loan_amount_usd"],
              delete prev["donor_service_charge_amount"],
              delete prev["donor_service_charge_amount_npr"],
              delete prev["donor_service_charge_amount_usd"],
              prev
            )
          );
          setError((prev) => (delete prev["sdr_conversion_rate"], prev));
        }
        break;
      default:
        break;
    }
  };
  const cleanUpFields = (e) => {
    if (!e.target.value) {
      setState((prev) => (delete prev[e.target.name], prev));
    }
    switch (e.target.name) {
      case "forex_risk_bearer":
        if (e.target.value === "GoN")
          setState((prev) => (delete prev["forex_risk_bearer_type"], prev));
        break;
      case "forex_risk_bearer_flag":
        if (!e.target.value) {
          setState(
            (prev) => (
              delete prev["forex_risk_bearer_flag"],
              delete prev["forex_risk_bearer"],
              delete prev["forex_risk_bearer_type"],
              prev
            )
          );
        }
        break;

      //!Donor Service Charge
      case "donor_service_charge_flag":
        if (!e.target.value) {
          setState(
            (prev) => (
              delete prev["donor_service_charge_flag"],
              delete prev["donor_service_charge"],
              delete prev["donor_service_charge_amount"],
              delete prev["donor_service_charge_amount_usd"],
              delete prev["donor_service_charge_amount_npr"],
              delete prev["donor_service_charge_type"],
              prev
            )
          );
        }
        break;

      //!Penalty
      case "penalty_flag":
        if (!e.target.value) {
          setState(
            (prev) => (
              delete prev["penalty_flag"],
              delete prev["penalty_percentage"],
              prev
            )
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    state && changeHandler(state, error);
  }, [state, error]);
  console.log(state.idc_gon_portion);
  return (
    state && (
      <div className="createLoan">
        <div className="createLoan-header">
          <span className="createLoan-header__name">{state.type}</span>
        </div>
        <div className="createLoan-body">
          {/* Basic Details */}
          <div className="createLoan-body--wrapper">
            <Input
              label="Loan/Share Number"
              name="loan_number"
              onChange={handleChange}
              required
              placeholder="eg. 32872"
              customStyle={{ width: "32%" }}
              defaultValue={state.loan_number}
              error={error.loan_number}
              intlNotRequired={true}
            />
            <Input
              label="Donor Agency"
              name="donor_agency"
              onChange={handleChange}
              required
              placeholder="eg. Asian Development Bank"
              customStyle={{ width: "32%" }}
              defaultValue={state.donor_agency}
              error={error.donor_agency}
              disabled={getDisabledValue(state.loan_number)}
            />
            <Select
              options={
                InternalSource[state.type]
                  ? [{ value: "NPR", key: "NPR-Nepali Rupee" }]
                  : !defaultCurrency || defaultCurrency.length < 1
                  ? currencyList
                  : currencyList.filter(
                      (i) => defaultCurrency.indexOf(i.value) > -1
                    )
              }
              label="Currency"
              name="donor_currency"
              handler={handleChange}
              width="32%"
              required
              customStyle={{ padding: "1rem .7rem" }}
              defaultValue={state.donor_currency}
              disabled={getDisabledValue(state.donor_agency)}
              error={error.donor_currency}
              preventMouse={true}
            />
          </div>
          <div className="createLoan-body--wrapper">
            <DateInput
              label="SLA Agreement Date (Donor - GoN)"
              name="donor_agreement_date"
              required
              customStyle={{ width: "32%" }}
              onChange={handleChange}
              customDate={state.donor_agreement_date_ad}
              error={error.donor_agreement_date}
              disabled={getDisabledValue(state.donor_currency)}
            />
            <DateInput
              label="Agreement Date (GoN - NEA)"
              name="agreement_date"
              required
              customStyle={{ width: "32%" }}
              onChange={handleChange}
              customDate={state.agreement_date_ad}
              error={error.agreement_date}
              disabled={getDisabledValue(state.donor_agreement_date)}
            />

            {state.donor_currency === "SDR" ? (
              <Input
                label="1USD to SDR Conversion Rate"
                name="sdr_conversion_rate"
                onChange={handleChange}
                required
                placeholder="eg. 2.4"
                customStyle={{ width: "32%" }}
                defaultValue={state.sdr_conversion_rate}
                error={error.sdr_conversion_rate}
                disabled={getDisabledValue(state.agreement_date)}
              />
            ) : null}

            <Input
              label={`Loan/Share Amount ${
                state.donor_currency === "SDR"
                  ? "(In USD)"
                  : "(In Donor Currency)"
              }`}
              name="loan_amount"
              onChange={handleChange}
              onBlur={handleCurrency}
              required
              placeholder="eg. 200000"
              customStyle={{ width: "32%" }}
              defaultValue={state.loan_amount}
              error={error.loan_amount}
              disabled={getDisabledValue(state.agreement_date)}
            />
            {state.donor_currency === "SDR" ? (
              <Input
                label="In SDR"
                placeholder="Auto"
                customStyle={{ width: "15.5%" }}
                readOnly
                defaultValue={
                  state.sdr_conversion_rate &&
                  state.loan_amount_usd &&
                  state.sdr_conversion_rate * state.loan_amount_usd
                }
                disabled={true}
              />
            ) : (
              <Input
                label="In USD"
                name="loan_amount_usd"
                onChange={handleChange}
                placeholder="Auto"
                customStyle={{ width: "15.5%" }}
                readOnly
                defaultValue={state.loan_amount_usd}
                disabled={true}
              />
            )}
            <Input
              label="In NPR"
              name="loan_amount_npr"
              onChange={handleChange}
              placeholder="Auto"
              customStyle={{ width: "15.5%" }}
              readOnly
              defaultValue={state.loan_amount_npr}
              disabled={true}
            />
          </div>

          {state.type !== "Share" && state.type !== "Grant Share" ? (
            <>
              {/*! Donor Service/Commitment Charge */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  Donor Service/Commitment Charge:
                </span>
                <Checkbox
                  onChange={handleChange}
                  name={"donor_service_charge_flag"}
                  defaultValue={state.donor_service_charge_flag}
                />
              </div>
              <div className="createLoan-body--wrapper">
                {!state.donor_service_charge_flag ? (
                  <div className="notfound notfound-bordered">
                    Not Applicable
                  </div>
                ) : (
                  <>
                    <Select
                      options={[
                        { key: "One Time Charge" },
                        { key: "Yearly Charge" },
                      ]}
                      handler={handleChange}
                      label="Plan"
                      name="donor_service_charge_type"
                      width="32%"
                      customStyle={{ padding: "1rem .7rem" }}
                      defaultValue={state.donor_service_charge_type}
                      error={error.donor_service_charge_type}
                      required
                      disabled={getDisabledValue(state.donor_agreement_date)}
                      preventMouse={true}
                    />
                    <Select
                      options={[
                        { key: "Additive/Deductive to Loan" },
                        { key: "Non Additive/Deductive to Loan" },
                      ]}
                      handler={handleChange}
                      label="Charge Type"
                      name="donor_service_charge"
                      width="32%"
                      customStyle={{ padding: "1rem .7rem" }}
                      defaultValue={state.donor_service_charge}
                      error={error.donor_service_charge}
                      required
                      disabled={getDisabledValue(
                        state.donor_service_charge_type
                      )}
                      preventMouse={true}
                    />
                    <Input
                      label={`Loan/Share Amount ${
                        state.donor_currency === "SDR"
                          ? "(In USD)"
                          : "(In Donor Currency)"
                      }`}
                      name="donor_service_charge_amount"
                      onChange={handleChange}
                      onBlur={handleCurrency}
                      required
                      placeholder="eg. 200000"
                      customStyle={{ width: "32%" }}
                      defaultValue={state.donor_service_charge_amount}
                      error={error.donor_service_charge_amount}
                      disabled={getDisabledValue(
                        state.donor_service_charge_type &&
                          state.donor_service_charge &&
                          state.donor_currency
                      )}
                    />
                    {state.donor_currency === "SDR" ? (
                      <Input
                        label="In SDR"
                        placeholder="Auto"
                        customStyle={{ width: "15.5%" }}
                        readOnly
                        defaultValue={
                          state.sdr_conversion_rate &&
                          state.donor_service_charge_amount_usd &&
                          state.sdr_conversion_rate *
                            state.donor_service_charge_amount_usd
                        }
                        disabled={true}
                      />
                    ) : (
                      <Input
                        label="In USD"
                        name="donor_service_charge_amount_usd"
                        placeholder="Auto"
                        customStyle={{ width: "16.2%" }}
                        readOnly
                        defaultValue={state.donor_service_charge_amount_usd}
                        error={error.donor_service_charge_amount_usd}
                        disabled={true}
                      />
                    )}
                    <Input
                      label="In NPR"
                      name="donor_service_charge_amount_npr"
                      placeholder="Auto"
                      customStyle={{ width: "16.2%" }}
                      readOnly
                      defaultValue={state.donor_service_charge_amount_npr}
                      error={error.donor_service_charge_amount_npr}
                      disabled={true}
                    />
                  </>
                )}
              </div>

              {/*! FOREX Risk Bearer */}
              {state.type !== "GoN Loan" && (
                <>
                  <div className="createLoan-body--heading">
                    <span className="createLoan-body--heading__name">
                      FOREX Risk Bearer:
                    </span>
                    <Checkbox
                      onChange={handleChange}
                      name={"forex_risk_bearer_flag"}
                      defaultValue={state.forex_risk_bearer_flag}
                    />
                  </div>
                  <div className="createLoan-body--wrapper">
                    {!state.forex_risk_bearer_flag ? (
                      <div className="notfound notfound-bordered">
                        Not Applicable
                      </div>
                    ) : (
                      <>
                        <Select
                          options={[{ key: "NEA" }, { key: "GoN" }]}
                          handler={handleChange}
                          label="Bearer"
                          name="forex_risk_bearer"
                          width="30%"
                          customStyle={{ padding: "1rem .7rem" }}
                          defaultValue={state.forex_risk_bearer}
                          error={error.forex_risk_bearer}
                          required
                          preventMouse={true}
                        />
                        <Select
                          options={[
                            { key: "Additive/Deductive to Loan" },
                            {
                              key: "Additive/Deductive to P/L Account",
                            },
                          ]}
                          handler={handleChange}
                          label="Charge Type"
                          name="forex_risk_bearer_type"
                          width="30%"
                          customStyle={{ padding: "1rem .7rem" }}
                          defaultValue={state.forex_risk_bearer_type}
                          error={error.forex_risk_bearer_type}
                          required
                          disabled={
                            state.forex_risk_bearer &&
                            state.forex_risk_bearer !== "GoN"
                              ? undefined
                              : true
                          }
                        />
                      </>
                    )}
                  </div>
                </>
              )}

              {/*! Penalty */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">Penalty:</span>
                <Checkbox
                  onChange={handleChange}
                  name={"penalty_flag"}
                  defaultValue={state.penalty_flag}
                />
              </div>
              <div className="createLoan-body--wrapper">
                {!state.penalty_flag ? (
                  <div className="notfound notfound-bordered">
                    Not Applicable
                  </div>
                ) : (
                  <Input
                    label="Percentage"
                    name="penalty_percentage"
                    onChange={handleChange}
                    required
                    placeholder="eg. 5"
                    customStyle={{ width: "30%" }}
                    defaultValue={state.penalty_percentage}
                    error={error.penalty_percentage}
                  />
                )}
              </div>

              {/* Grace Period */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  Grace Period:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <DateInput
                  label="Starting Date"
                  name="grace_period_start_date"
                  required
                  customStyle={{ width: "30%" }}
                  onChange={handleChange}
                  customDate={state.grace_period_start_date_ad}
                  error={error.grace_period_start_date}
                  disabled={getDisabledValue(state.loan_amount)}
                />
                <DateInput
                  label="Ending Date"
                  name="grace_period_end_date"
                  required
                  customStyle={{ width: "30%" }}
                  onChange={handleChange}
                  customDate={state.grace_period_end_date_ad}
                  min={state.grace_period_start_date_ad}
                  error={error.grace_period_end_date}
                  disabled={getDisabledValue(state.grace_period_start_date)}
                />
                <Input
                  label="Interest Rate (%)"
                  name="grace_period_interest_rate"
                  onChange={handleChange}
                  placeholder="eg: 5"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.grace_period_interest_rate}
                  error={error.grace_period_interest_rate}
                  disabled={getDisabledValue(state.grace_period_end_date)}
                />
                <Input
                  label="Interest Rate After Grace Period (%)"
                  name="interest_rate_after_grace_period"
                  onChange={handleChange}
                  placeholder="eg: 5"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.interest_rate_after_grace_period}
                  error={error.interest_rate_after_grace_period}
                  disabled={getDisabledValue(state.grace_period_interest_rate)}
                />
              </div>

              {/* Installment */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  Installment:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <DateInput
                  label="Installment Start Date"
                  name="installment_start_date"
                  required
                  customStyle={{ width: "30%" }}
                  onChange={handleChange}
                  customDate={state.installment_start_date_ad}
                  error={error.installment_start_date}
                  disabled={getDisabledValue(
                    state.interest_rate_after_grace_period
                  )}
                />
                <Input
                  label="Ends In"
                  name="installment_factor"
                  onChange={handleChange}
                  placeholder="eg: 5"
                  customStyle={{ width: "20%" }}
                  defaultValue={state.installment_factor}
                  error={error.installment_factor}
                  disabled={getDisabledValue(state.installment_start_date)}
                />
                <Select
                  options={[{ key: "Years" }, { key: "Months" }]}
                  handler={handleChange}
                  label="Type"
                  name="installment_factor_mode"
                  width="15%"
                  customStyle={{ padding: "1rem .7rem" }}
                  defaultValue={state.installment_factor_mode}
                  error={error.installment_factor_mode}
                  required
                  disabled={getDisabledValue(state.installment_factor)}
                />
                <Select
                  options={[
                    { key: "Quarterly" },
                    { key: "Half Yearly" },
                    { key: "Yearly" },
                  ]}
                  handler={handleChange}
                  label="Installment Period"
                  name="installment_period"
                  width="30%"
                  customStyle={{ padding: "1rem .7rem" }}
                  defaultValue={state.installment_period}
                  error={error.installment_period}
                  required
                  disabled={getDisabledValue(state.installment_factor_mode)}
                />
                <Input
                  label="Installment End Date"
                  name="installment_end_date"
                  placeholder="BS: YYYY-MM-DD, AD: YYYY-MM-DD"
                  customStyle={{ width: "30%" }}
                  defaultValue={
                    state.installment_end_date && state.installment_end_date_ad
                      ? `BS: ${state.installment_end_date.split("-")[0]}-${
                          state.installment_end_date.split("-")[1]
                        }-${state.installment_end_date.split("-")[2]}, AD: ${
                          state.installment_end_date_ad.split("-")[0]
                        }-${state.installment_end_date_ad.split("-")[1]}-${
                          state.installment_end_date_ad.split("-")[2]
                        }`
                      : ""
                  }
                  readOnly
                  disabled={true}
                />
                <Input
                  label="Number of Installment"
                  name="no_of_installment"
                  placeholder="eg: 5"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.no_of_installment}
                  readOnly
                  disabled={true}
                />
              </div>

              {/* IDC */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  IDC Details:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <DateInput
                  label="IDC Effective Date"
                  name="idc_effective_date"
                  required
                  customStyle={{ width: "30%" }}
                  onChange={handleChange}
                  customDate={state.idc_effective_date_ad}
                  error={error.idc_effective_date}
                  disabled={getDisabledValue(state.no_of_installment)}
                />
                <Input
                  label="Ends In"
                  name="idc_factor"
                  onChange={handleChange}
                  placeholder="eg: 5"
                  customStyle={{ width: "20%" }}
                  defaultValue={state.idc_factor}
                  error={error.idc_factor}
                  disabled={getDisabledValue(state.idc_effective_date)}
                />
                <Select
                  options={[{ key: "Years" }, { key: "Months" }]}
                  handler={handleChange}
                  label="Type"
                  name="idc_factor_mode"
                  width="15%"
                  customStyle={{ padding: "1rem .7rem" }}
                  defaultValue={state.idc_factor_mode}
                  error={error.idc_factor_mode}
                  required
                  disabled={getDisabledValue(state.idc_factor)}
                />
                {/* <DateInput
                      label="IDC Ending Date"
                      name="idc_ending_date"
                      required
                      customStyle={{ width: "30%" }}
                      customDate={state.idc_ending_date_ad}
                      error={error.idc_ending_date}
                      min={state.idc_effective_date_ad}
                      disabled={true}
                    /> */}
                <Input
                  label="IDC Ending Date"
                  name="idc_ending_date"
                  placeholder="BS: YYYY-MM-DD, AD: YYYY-MM-DD"
                  customStyle={{ width: "30%" }}
                  defaultValue={
                    state.idc_ending_date && state.idc_ending_date_ad
                      ? `BS: ${state.idc_ending_date.split("-")[0]}-${
                          state.idc_ending_date.split("-")[1]
                        }-${state.idc_ending_date.split("-")[2]}, AD: ${
                          state.idc_ending_date_ad.split("-")[0]
                        }-${state.idc_ending_date_ad.split("-")[1]}-${
                          state.idc_ending_date_ad.split("-")[2]
                        }`
                      : ""
                  }
                  readOnly
                  disabled={true}
                />
                <Input
                  label="Number of Days For IDC"
                  name="idc_no_of_days"
                  onChange={handleChange}
                  placeholder="eg: 5"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_no_of_days}
                  readOnly
                  disabled={true}
                />
              </div>
              {/* IDC Bearing */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  IDC Bearing:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <Input
                  label="NEA Portion (%)"
                  name="idc_nea_portion"
                  onChange={handleChange}
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_nea_portion}
                  disabled={getDisabledValue(state.idc_no_of_days)}
                  error={error.idc_nea_portion}
                />
                <Input
                  label="GoN Portion (%)"
                  name="idc_gon_portion"
                  onChange={handleChange}
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_gon_portion}
                  disabled={true}
                  error={error.idc_gon_portion}
                />
              </div>

              {/* IDC Conversion 1 */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  IDC Conversion 1:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <Input
                  label="Payable to GoN (%)"
                  name="idc_payable_to_gon"
                  onChange={handleChange}
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_payable_to_gon}
                  disabled={getDisabledValue(state.idc_gon_portion)}
                  error={error.idc_payable_to_gon}
                />
                <Input
                  label="Transfer to Share (%)"
                  name="idc_transfer_to_share"
                  onChange={handleChange}
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_transfer_to_share}
                  disabled={true}
                  error={error.idc_transfer_to_share}
                />
              </div>

              {/* IDC Conversion 2 */}
              <div className="createLoan-body--heading">
                <span className="createLoan-body--heading__name">
                  IDC Conversion 2:
                </span>
              </div>
              <div className="createLoan-body--wrapper">
                <Input
                  label="Transfer to CWIP (%)"
                  name="idc_transfer_to_cwip"
                  onChange={handleChange}
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_transfer_to_cwip}
                  disabled={getDisabledValue(state.idc_transfer_to_share)}
                  error={error.idc_transfer_to_cwip}
                />
                <Input
                  label="Transfer to Loan (%)"
                  name="idc_transfer_to_loan"
                  placeholder="eg: 5%"
                  customStyle={{ width: "30%" }}
                  defaultValue={state.idc_transfer_to_loan}
                  disabled={true}
                  error={error.idc_transfer_to_loan}
                />
              </div>
            </>
          ) : null}
          <div className="createLoan-body--heading">
            <span className="createLoan-body--heading__name">Documents:</span>
          </div>
          <div className="createLoan-body--wrapper">
            <FileInput
              handleSubmitImage={(e) =>
                handleChange({
                  target: {
                    name: "image",
                    value: e,
                  },
                })
              }
              imagesProp={state.image}
              title={
                "If multiple please press CTRL and select multiple at once"
              }
            />
          </div>
        </div>
      </div>
    )
  );
}

import { doPut } from "../../../../Services/Axios";

const ViewSubProjectDetails = {
  validateKey: (e) => validate(e.target.name, e.target.value),
  validateForm: (state) => {
    console.log(state);
    const err = {};
    Object.keys(state).forEach((i) => {
      const isError = validate(i, state[i]);
      isError && (err[i] = isError);
    });
    return err;
  },
  doesErrorExist: (err) =>
    Object.keys(err).find((j) => typeof err[j] === "string"),
  submitForm: async (info) => {
    try {
      const { data } = await doPut("/sub-project/pre-infos", info);

      return data;
    } catch (error) {
      throw error;
    }
  },
};
function validate(name, value) {
  const required = {
    directorate_office_name: true,
    directorate_office_code: true,
    office_name: true,
    office_code: true,
    sla_agreement_date: true,
    sla_agreement_date_ad: true,
    donor_currency: true,
    sla_agreement_amount: true,
    usd_equivalent: true,
    npr_equivalent: true,
  };
  const isAmount = {
    npr_equivalent: true,
    usd_equivalent: true,
    sla_agreement_amount: true,
  };

  if (required[name]) {
    if (!value || value === "0.000") return "Field is required";
  }
  if (name in isAmount) {
    if (isNaN(+value)) return "Contains only number";
  }

  return null;
}

export default ViewSubProjectDetails;

import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const AgreementModal = ({ onClose, agreementdetails }) => {
  const TITLE = [
    { key: "agreement amount", value: "agreement_amount" },
    { key: "agreement amount usd", value: "agreement_amount_usd" },
    { key: "agreement amount npr", value: "agreement_amount_npr" },
    { key: "agreement date", value: "agreement_date" },
    { key: "agreement date ad", value: "agreement_date_ad" },
  ];
  return (
    <ModalLayout onClose={onClose} title="Agreement Details">
      <div className="admincontractor-agreementdetails">
        <div className="admincontractor-agreementdetails--grid">
          {TITLE.map((item) => (
            <div
              key={item}
              className="admincontractor-agreementdetails--grid__item"
            >
              <div className="admincontractor-agreementdetails--grid__item-key">
                {item.key} :
              </div>
              <div className="admincontractor-agreementdetails--grid__item-value">
                {agreementdetails[item.value]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default AgreementModal;

import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import useForex from "../../../../Hooks/useForex";
import { doGet, doPut } from "../../../../Services/Axios";
import { toggleLoader } from "../../../../Slice/SystemSlice";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";
import DateInput from "../../../../UI/DateInput/DateInput";
import Input from "../../../../UI/Input/Input";
import ModalLayout from "../../../../UI/Modal/ModalLayout";
import Select from "../../../../UI/Select/Select";
import { errorToast, successToast } from "../../../../Utils/Toastify";
import AddRemarkModel from "./AddRemarkModal/AddRemarkModel";

const ViewPreInformation = ({ onClose, subproject }) => {
  const queryClient = useQueryClient();
  const TITLE = [
    { key: "Directorate/Office Name", value: "directorate_office_name" },
    { key: "Directorate/Office Code", value: "directorate_office_code" },
    { key: "Office Name", value: "office_name" },
    { key: "Office Code", value: "office_code" },
    { key: "SLA Agreement Date", value: "sla_agreement_date" },
    { key: "SLA Agreement Date AD", value: "sla_agreement_date_ad" },
    { key: "Donor Currency", value: "donor_currency" },
    { key: "SLA Agreement Amount", value: "sla_agreement_amount" },
    { key: "USD Equivalent", value: "usd_equivalent" },
    { key: "NPR Equivalent", value: "npr_equivalent" },
    { key: "Remarks", value: "remarks" },
  ];
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [openRemark, setOpenRemark] = useState(false);

  const handleConfirmationModal = (action) => {
    setConfirmationModal(true);
    switch (action) {
      case "REJECT":
        setConfirmationDetails({
          text: "Are you sure you want to reject?",
          caption: "This will reinitiate entry part from Account Chief",
          action: () => setOpenRemark(true),
        });
        break;
      case "APPROVE":
        setConfirmationDetails({
          text: "Are you sure you want to approve?",
          caption: "This will permanently store this sub project information",
          action: () => handleApprove(),
        });
        break;
      default:
        break;
    }
  };

  const handleApprove = async () => {
    try {
      const { data } = await doPut("/sub-project/progress-status/approved", {
        id: subproject.id,
      });
      successToast("Status updated");
      onClose();
      queryClient.refetchQueries(["PM_SubProjects"]);
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    subproject && (
      <ModalLayout onClose={onClose} title={"Pre Information Verification"}>
        {openRemark && (
          <AddRemarkModel
            onClose={() => setOpenRemark(false)}
            subprojectid={subproject.id}
            parentModalToggler={onClose}
          />
        )}
        {confirmationModal && confirmationDetails && (
          <ConfirmationModal
            onSuccess={() => (
              confirmationDetails.action(), setConfirmationModal(false)
            )}
            onClose={() => setConfirmationModal(false)}
            text={confirmationDetails.text}
            caption={confirmationDetails.caption}
          />
        )}
        <div className="viewpmsubprojects">
          <div className="viewpmsubprojects-fulldetails--grid">
            {TITLE.map((item, index) => (
              <div
                className="viewpmsubprojects-fulldetails--grid__item"
                key={index}
              >
                <div className="viewpmsubprojects-fulldetails--grid__item-key">
                  {item.key} -
                </div>
                <div className="viewpmsubprojects-fulldetails--grid__item-value">
                  {subproject[item.value] ? subproject[item.value] : "N/A"}
                </div>
              </div>
            ))}
          </div>
          {subproject.progress_status != "2" &&
            subproject.progress_status != "3" && (
              <div className="viewpmsubprojects-submit">
                <PrimaryButton
                  className="viewpmsubprojects-submit--approve"
                  content={"Approve"}
                  onClick={() => handleConfirmationModal("APPROVE")}
                  icon="fa-light fa-check"
                />
                <PrimaryButton
                  className="viewpmsubprojects-submit--reject"
                  content={"Reject"}
                  onClick={() => handleConfirmationModal("REJECT")}
                  icon="fa-light fa-times"
                />
              </div>
            )}
        </div>
      </ModalLayout>
    )
  );
};

export default ViewPreInformation;

import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import TopBar from "../../Components/TopBar/TopBar";
import { baseUrl } from "../../Constants/Constants";
import { DUMMY_USER } from "../../Constants/Images";
import ActionButton from "../../UI/Button/ActionButton";
import SmallButton from "../../UI/Button/SmallButton";
import Preview from "../../UI/Preview/Preview";
import StatusBar from "../../UI/StatusBar/StatusBar";
import Table from "../../UI/Table/Table";
import { errorToast, successToast } from "../../Utils/Toastify";
import AddEmployeeModal from "./AddEmplyee/AddEmployeeModal";
import EditEmployee from "./EditEmployee/EditEmployee";
import AdminController from "./_admin";

const Admin = () => {
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editUser, setEditUser] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.System);

  const { Columns, fetchAdmin, handleStatusUpdate } = AdminController;
  const { data, isLoading } = useQuery({
    queryKey: ["Admin", page],
    queryFn: () => fetchAdmin(page),
    enabled: true,
    cacheTime: 5 * 60 * 1000,
  });

  const handleOpenModal = () => {
    setOpenModal((prev) => !prev);
  };
  const handleEditAdmin = (e) => {
    setEditUser(e);
    setOpenEditModal((prev) => !prev);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal((prev) => !prev);
  };

  const handleAdminStatus = useMutation(handleStatusUpdate, {
    onError: (err, newData, context) => {
      errorToast(err.message);
      queryClient.setQueryData(["Admin", page], context);
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ["Admin", page] });
      const previousData = JSON.parse(
        JSON.stringify(queryClient.getQueryData(["Admin", page]))
      );
      queryClient.setQueryData(["Admin", page], (oldData) => {
        oldData.users.data = oldData.users.data.map((i) =>
          i.id === data ? ((i.status = i.status === 1 ? 0 : 1), i) : i
        );
        return oldData;
      });
      return previousData;
    },
    onSuccess: () => successToast("Status Updated"),
  }).mutate;

  const handlePagination = (type) => {
    if (
      data &&
      data.projects &&
      data.projects.next_page_url &&
      type === "pos"
    ) {
      setPage((prev) => prev + 1);
    } else if (
      data &&
      data.projects &&
      data.projects.prev_page_url &&
      type === "neg"
    ) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <div className="admin">
      {openModal && (
        <AddEmployeeModal
          onClose={handleOpenModal}
          onSuccess={handleOpenModal}
        />
      )}
      {openEditModal && (
        <EditEmployee onClose={handleCloseEditModal} item={editUser} />
      )}
      <TopBar title={"Admin"}>
        <SmallButton
          icon={"fa-light fa-plus"}
          content={"Add Admin"}
          onClick={handleOpenModal}
        />
      </TopBar>
      <div className="admin-main">
        <div className="admin-main--table">
          <Table
            handlePagination={handlePagination}
            hasNextPage={
              data && data.users && data.users.next_page_url ? true : false
            }
            hasPrevPage={
              data && data.users && data.users.prev_page_url ? true : false
            }
            pageProp={page}
            Rows={
              data && data.users && data.users.data.length > 0
                ? data.users.data.map((item, index) => ({
                    sn: index + 1,
                    images: (
                      <Preview
                        alt={item.name + "'s Image"}
                        file={
                          item.images
                            ? `${baseUrl.split("api/v1")[0]}storage/${
                                item.images
                              }`
                            : DUMMY_USER
                        }
                        clickable={item.images}
                      />
                    ),
                    employee_id: item.employee_id,
                    name: item.name,
                    email: item.email ?? "N/A",
                    office_name: item.office_name,
                    address: item.address,
                    contact_number: item.contact_number,
                    level: item.level,
                    username: item.username,
                    status:
                      user.user.id === item.id ? (
                        "N/A"
                      ) : (
                        <StatusBar
                          status={item.status}
                          handler={() => handleAdminStatus(item.id)}
                        />
                      ),
                    created_at: item.created_at.split("T")[0],
                    action:
                      user.user.id === item.id ? (
                        "N/A"
                      ) : (
                        <ActionButton
                          type="primary"
                          title={"Edit"}
                          icon={"fa-regular fa-edit"}
                          onClick={() => handleEditAdmin(item)}
                        />
                      ),
                  }))
                : []
            }
            Columns={Columns}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin;

import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const LCModal = ({ onClose, lcdetails }) => {
  const TITLE = [
    { value: "lc_date", key: "lc date" },
    { value: "lc_date_ad", key: "lc date ad" },
    { value: "lc_amount", key: "lc amount" },
    { value: "lc_amount_usd", key: "lc amount USD" },
    { value: "lc_amount_npr", key: "lc amount NPR" },
    { value: "donor_currency", key: "lc currency" },
    { value: "lc_number", key: "lc number" },
    { value: "lc_issuer_bank", key: "lc issuer bank" },
    { value: "lc_duration", key: "lc duration" },
  ];
  return (
    <ModalLayout onClose={onClose} title="LC Details">
      <div className="pmcontractor-lcdetails">
        <div className="pmcontractor-lcdetails--grid">
          {TITLE.map((item) => (
            <div key={item} className="pmcontractor-lcdetails--grid__item">
              <div className="pmcontractor-lcdetails--grid__item-key">
                {item.key} :
              </div>
              <div className="pmcontractor-lcdetails--grid__item-value">
                {lcdetails[item.value]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default LCModal;

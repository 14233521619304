import React, { useState, useEffect } from "react";
import { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../../../Components/TopBar/TopBar";
import ViewPreInformation from "./PreInformation/ViewPreInformation";
import SmallButton from "../../../UI/Button/SmallButton";
import StatusBar from "../../../UI/StatusBar/StatusBar";
import Table from "../../../UI/Table/Table";
import View from "../../../UI/View/View";
import { errorToast } from "../../../Utils/Toastify";
import AccountChief from "./Assign/AccountChief";
import ProjectManager from "./Assign/ProjectManager";
import SubProjectController from "./_subProject";
import CreateSubProject from "./Create/CreateSubProject";
import CreateSubProjectLoan from "./Loan/CreateSubProjectLoan";

const SubProjects = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!id) return navigate(-1);
  }, []);

  const [page, setPage] = useState(1);
  const [showAssignProjectManagerModal, setShowAssignProjectManagerModal] =
    useState(false);
  const [showAssignAccounthiefModal, setShowAssignAccountChiefModal] =
    useState(false);
  const { Columns, fetchSubProject, statusEnums } = SubProjectController;
  const [selectedProject, setSelectedProject] = useState();
  const [subProjectDetails, setSubProjectsDetails] = useState();
  const [showSubProjectDetails, setShowSubProjectsDetails] = useState();
  const [showCreateSubProject, setShowCreateSubProject] = useState(false);
  const [showCreateSubProjectLoan, setShowCreateSubProjectLoan] =
    useState(false);

  const handleShowDetails = (item) => {
    setSubProjectsDetails(item);
    setShowSubProjectsDetails((prev) => !prev);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["SubProjects", id],
    queryFn: () => fetchSubProject(id, page),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching subprojects"),
  });

  const handlePagination = (type) => {
    if (
      data &&
      data.sub_project &&
      data.sub_project.next_page_url &&
      type === "pos"
    ) {
      setPage((prev) => prev + 1);
    } else if (
      data &&
      data.sub_project &&
      data.sub_project.prev_page_url &&
      type === "neg"
    ) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    refetch();
  }, [page]);

  const handleShowAssignAccountChief = (item) => {
    if (item) {
      setSelectedProject(item);
    }
    setShowAssignAccountChiefModal((prev) => !prev);
  };
  const handleAssignProjectManager = (item) => {
    if (item) {
      setSelectedProject(item);
    }
    setShowAssignProjectManagerModal((prev) => !prev);
  };
  return (
    <div className="viewsubproject">
      {showSubProjectDetails && (
        <ViewPreInformation
          onClose={() => setShowSubProjectsDetails(false)}
          subproject={subProjectDetails}
        />
      )}
      {showCreateSubProject ? (
        <CreateSubProject
          project_id={id}
          onClose={() => setShowCreateSubProject(false)}
          refetch={refetch}
        />
      ) : null}
      {showCreateSubProjectLoan ? (
        <CreateSubProjectLoan
          project_id={id}
          sub_project_id={showCreateSubProjectLoan}
          onClose={() => setShowCreateSubProjectLoan(false)}
        />
      ) : null}
      {showAssignAccounthiefModal && (
        <AccountChief
          onClose={handleShowAssignAccountChief}
          project={selectedProject}
          refetch={refetch}
        />
      )}
      {showAssignProjectManagerModal && (
        <ProjectManager
          onClose={handleAssignProjectManager}
          project={selectedProject}
          refetch={refetch}
        />
      )}
      <TopBar
        title={"Sub Project"}
        subtitle={"Sub Project"}
        backPage={true}
        children={
          <SmallButton
            icon={"fa-light fa-plus"}
            content={"Add SubProject"}
            onClick={() => setShowCreateSubProject(true)}
          />
        }
      />
      <div className="viewsubproject-main">
        <div className="viewsubproject-main--table">
          <Table
            hasNextPage={
              data && data.sub_project && data.sub_project.next_page_url
                ? true
                : false
            }
            hasPrevPage={
              data && data.sub_project && data.sub_project.prev_page_url
                ? true
                : false
            }
            pageProp={page}
            Columns={Columns}
            Rows={
              data &&
              data.sub_project &&
              data.sub_project.data &&
              data.sub_project.data.map((item, index) => ({
                sn: index + 1,
                project_name: item.name,
                loan: (
                  <View
                    label={`${item.total_sub_loans} Loan/Grant`}
                    // onClick={() => setShowCreateSubProjectLoan(item.id)}
                    type={"button"}
                  />
                ),
                account_chief: (
                  <View
                    label={item.account_chief ? "Reassign" : "Assign"}
                    type="button"
                    onClick={() => handleShowAssignAccountChief(item)}
                  />
                ),
                project_manager: (
                  <View
                    label={item.project_manager ? "Reassign" : "Assign"}
                    type="button"
                    onClick={() => handleAssignProjectManager(item)}
                  />
                ),
                pre_information: statusEnums[item.progress_status] ? (
                  <View
                    label={statusEnums[item.progress_status].label}
                    onClick={() =>
                      item.progress_status != "0" && handleShowDetails(item)
                    }
                    type="button"
                    customStyle={{
                      backgroundColor: statusEnums[item.progress_status].color,
                    }}
                  />
                ) : (
                  "N/A"
                ),
                contractor: (
                  <View label={"contractor/s"} to={`/contractor/${item.id}`} />
                ),
                budget: <View label={"budget/s"} to={`/budget/${item.id}`} />,
                billing: (
                  <View label={"billing/s"} to={`/billing/${item.id}`} />
                ),
                status: (
                  <StatusBar status={item.sub_status} isProgress={true} />
                ),
                createdby: item.user_name ? item.user_name : "N/A",
                created_at: item.created_at.split(" ")[0],
              }))
            }
            loading={isLoading}
            handlePagination={handlePagination}
          />
        </div>
      </div>
    </div>
  );
};

export default SubProjects;

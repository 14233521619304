// import NepaliDate from "../../../Services/NepaliDate";
// import {
//   deleteLoanKey,
//   fillLoanData,
//   removeLoan,
//   resetLoan,
//   setConfig,
//   setLoan,
//   setProjectName,
//   toggleKeyBoolean,
// } from "../../../Slice/CreateProject";

import { doPost } from "../../../Services/Axios";

const CreateProjectController = {
  projectSources: {
    External: { key: "External" },
    Internal: { key: "Internal" },
  },
  loanType: {
    Internal: {
      "GoN Loan": { key: "GoN Loan" },
      Share: { key: "Share" },
    },
    External: {
      "Donor Loan": { key: "Donor Loan" },
      "Grant Share": { key: "Grant Share" },
    },
  },
  validateForm: (loans, project) => {
    let isRequired = "Field is Required";
    let shouldContain = "Contains only Number";
    let mustBeGreater = "Must be greater than 0";
    let isRate = "Rate is required";
    let shouldRangeBetween = "Ranges between 0 - 100";

    if (loans.length < 1) return null;

    let status = 0;
    let errors = {};
    if (!project) {
      errors["project"] = isRequired;
      status++;
    }
    for (let i = 0; i < loans.length; i++) {
      const item = loans[i];
      const err = {};

      //!Basic Details
      if (!item.loan_number) err["loan_number"] = isRequired;
      if (!item.donor_agency) err["donor_agency"] = isRequired;
      if (!item.donor_currency) err["donor_currency"] = isRequired;
      if (isNaN(item.loan_amount)) err["loan_amount"] = shouldContain;
      if (!item.loan_amount) err["loan_amount"] = isRequired;
      if (!item.loan_amount_usd) err["loan_amount_usd"] = isRequired;
      if (!item.loan_amount_npr) err["loan_amount_npr"] = isRequired;
      if (!item.donor_agreement_date) err["donor_agreement_date"] = isRequired;
      if (!item.donor_agreement_date_ad)
        err["donor_agreement_date_ad"] = isRequired;
      if (!item.agreement_date) err["agreement_date"] = isRequired;
      if (!item.agreement_date_ad) err["agreement_date_ad"] = isRequired;

      if (item.donor_currency === "SDR") {
        if (item.sdr_conversion_rate < 0)
          err["sdr_conversion_rate"] = shouldContain;
        if (isNaN(item.sdr_conversion_rate))
          err["sdr_conversion_rate"] = shouldContain;
        if (item.sdr_conversion_rate == null || item.sdr_conversion_rate === "")
          err["sdr_conversion_rate"] = isRequired;
      }

      //!Donor Service Charge
      if (item.type !== "Share" && item.type !== "Grant Share") {
        if (item.donor_service_charge_flag) {
          if (!item.donor_service_charge_type)
            err["donor_service_charge_type"] = isRequired;
          if (!item.donor_service_charge)
            err["donor_service_charge"] = isRequired;
          if (!item.donor_service_charge_amount)
            err["donor_service_charge_amount"] = isRequired;
          if (isNaN(item.donor_service_charge_amount))
            err["donor_service_charge_amount"] = shouldContain;
          if (item.donor_service_charge_amount < 0)
            err["donor_service_charge_amount"] = mustBeGreater;
          if (!item.donor_service_charge_amount_usd)
            err["donor_service_charge_amount_usd"] = isRequired;
          if (!item.donor_service_charge_amount_npr)
            err["donor_service_charge_amount_npr"] = isRequired;
        } else {
          loans[i]["donor_service_charge_flag"] = false;
        }

        if (item.type !== "GoN Loan") {
          if (item.forex_risk_bearer_flag) {
            //!Forex
            if (!item.forex_risk_bearer) err["forex_risk_bearer"] = isRequired;
            if (
              item.forex_risk_bearer === "NEA" &&
              !item.forex_risk_bearer_type
            )
              err["forex_risk_bearer_type"] = isRequired;
          } else {
            loans[i]["forex_risk_bearer_flag"] = false;
          }
        }

        //!Penalty
        if (item.penalty_flag) {
          if (item.penalty_percentage < 0 || item.penalty_percentage > 100)
            err["penalty_percentage"] = shouldRangeBetween;
          if (isNaN(item.penalty_percentage))
            err["penalty_percentage"] = shouldContain;
          if (item.penalty_percentage == null || item.penalty_percentage === "")
            err["penalty_percentage"] = isRate;
        } else {
          loans[i]["penalty_flag"] = false;
        }

        //!Grace Period
        if (!item.grace_period_start_date)
          err["grace_period_start_date"] = isRequired;
        if (!item.grace_period_start_date_ad)
          err["grace_period_start_date_ad"] = isRequired;
        if (!item.grace_period_end_date)
          err["grace_period_end_date"] = isRequired;
        if (!item.grace_period_end_date_ad)
          err["grace_period_end_date_ad"] = isRequired;
        if (!item.grace_period_interest_rate)
          err["grace_period_interest_rate"] = isRequired;
        if (!item.interest_rate_after_grace_period)
          err["interest_rate_after_grace_period"] = isRequired;

        //!Installment
        if (!item.installment_start_date)
          err["installment_start_date"] = isRequired;
        if (!item.installment_start_date_ad)
          err["installment_start_date_ad"] = isRequired;
        if (!item.installment_factor) err["installment_factor"] = isRequired;
        if (!item.installment_factor_mode)
          err["installment_factor_mode"] = isRequired;
        if (!item.installment_end_date)
          err["installment_end_date"] = isRequired;
        if (!item.installment_end_date_ad)
          err["installment_end_date_ad"] = isRequired;
        if (!item.installment_period) err["installment_period"] = isRequired;
        if (!item.no_of_installment) err["no_of_installment"] = isRequired;

        //!IDC
        if (!item.idc_effective_date) err["idc_effective_date"] = isRequired;
        if (!item.idc_effective_date_ad)
          err["idc_effective_date_ad"] = isRequired;
        if (!item.idc_factor) err["idc_factor"] = isRequired;
        if (!item.idc_factor_mode) err["idc_factor_mode"] = isRequired;
        if (!item.idc_ending_date) err["idc_ending_date"] = isRequired;
        if (!item.idc_ending_date_ad) err["idc_ending_date_ad"] = isRequired;
        if (!item.idc_no_of_days) err["idc_no_of_days"] = isRequired;

        //!IDC Conversion
        if (item.idc_nea_portion < 0 || item.idc_nea_portion > 100)
          err["idc_nea_portion"] = shouldRangeBetween;
        if (isNaN(item.idc_nea_portion)) err["idc_nea_portion"] = shouldContain;
        if (item.idc_nea_portion == null || item.idc_nea_portion === "")
          err["idc_nea_portion"] = isRate;

        if (item.idc_gon_portion < 0 || item.idc_gon_portion > 100)
          err["idc_gon_portion"] = shouldRangeBetween;
        if (isNaN(item.idc_gon_portion)) err["idc_gon_portion"] = shouldContain;
        if (item.idc_gon_portion == null || item.idc_gon_portion === "")
          err["idc_gon_portion"] = isRate;

        if (item.idc_payable_to_gon < 0 || item.idc_payable_to_gon > 100)
          err["idc_payable_to_gon"] = shouldRangeBetween;
        if (isNaN(item.idc_payable_to_gon))
          err["idc_payable_to_gon"] = shouldContain;
        if (item.idc_payable_to_gon == null || item.idc_payable_to_gon === "")
          err["idc_payable_to_gon"] = isRate;

        if (item.idc_transfer_to_share < 0 || item.idc_transfer_to_share > 100)
          err["idc_transfer_to_share"] = shouldRangeBetween;
        if (isNaN(item.idc_transfer_to_share))
          err["idc_transfer_to_share"] = shouldContain;
        if (
          item.idc_transfer_to_share == null ||
          item.idc_transfer_to_share === ""
        )
          err["idc_transfer_to_share"] = isRate;

        if (item.idc_transfer_to_cwip < 0 || item.idc_transfer_to_cwip > 100)
          err["idc_transfer_to_cwip"] = shouldRangeBetween;
        if (isNaN(item.idc_transfer_to_cwip))
          err["idc_transfer_to_cwip"] = shouldContain;
        if (
          item.idc_transfer_to_cwip == null ||
          item.idc_transfer_to_cwip === ""
        )
          err["idc_transfer_to_cwip"] = isRate;

        if (item.idc_transfer_to_loan < 0 || item.idc_transfer_to_loan > 100)
          err["idc_transfer_to_loan"] = shouldRangeBetween;
        if (isNaN(item.idc_transfer_to_loan))
          err["idc_transfer_to_loan"] = shouldContain;
        if (
          item.idc_transfer_to_loan == null ||
          item.idc_transfer_to_loan === ""
        )
          err["idc_transfer_to_loan"] = isRate;
      }

      const doesErrorExist = Object.keys(err).find(
        (j) => typeof err[j] === "string"
      );

      if (doesErrorExist) {
        errors[item.key] = err;
        status++;
      }
    }

    if (status !== 0) {
      return { status: false, errors, loans };
    }

    return { status: true, errors, loans };
  },
  submitForm: async (loans, name, type, getConversionRate) => {
    try {
      const formData = new FormData();
      let finalData = [];

      for (let i = 0; i < loans.length; i++) {
        const tempLoan = { ...loans[i] };
        const currency = await getConversionRate(
          loans[i].donor_agreement_date_ad,
          {
            USD: true,
            [loans[i].donor_currency.toUpperCase()]: true,
          }
        );
        tempLoan["currency"] = currency;

        delete tempLoan["idc_factor"];
        delete tempLoan["idc_factor_mode"];
        delete tempLoan["installment_factor"];
        delete tempLoan["installment_factor_mode"];
        delete tempLoan["key"];
        formData.set("type", type);
        formData.set("name", name);
        currency.forEach((item) => {
          formData.append(`loan[${i}][currency][]`, JSON.stringify(item));
        });

        Object.entries(loans[i]).forEach((item) => {
          if (item[0] === "image") {
            for (let m = 0; m < Array.from(item[1]).length; m++) {
              formData.append(`loan[${i}][images][]`, item[1][m]);
            }
          } else {
            formData.append(`loan[${i}][${item[0]}]`, `${item[1]}`);
          }
        });

        finalData.push(tempLoan);
      }

      const response = await doPost("project/create", formData);

      return true;
    } catch (error) {
      throw error;
    }
  },
};
export default CreateProjectController;

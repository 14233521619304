import React, { useEffect, useState } from "react";
import TopBar from "../../../../Components/TopBar/TopBar";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";
import ContractModal from "../../../../Components/ContractModal/ContractModal";
import Table from "../../../../UI/Table/Table";
import View from "../../../../UI/View/View";
import { useLocation } from "react-router-dom";
import { doGet } from "../../../../Services/Axios";
import { errorToast } from "../../../../Utils/Toastify";
import { useQuery } from "react-query";
import LCModal from "./LCmodal";
import AgreementModal from "./AgreementModal";
import PerformanceModal from "./PerformanceDetails";
import DescriptionModal from "./DescriptionModal";
import StatusBar from "../../../../UI/StatusBar/StatusBar";
import ActionButton from "../../../../UI/Button/ActionButton";
import EditContractModal from "../../../../Components/ContractModal/EditContractModal";
import FilesModal from "../../../../Components/FilesModal/FilesModal";
import useForex from "../../../../Hooks/useForex";

const Contractor = () => {
  const location = useLocation();

  const [showContractorModal, setContractorModal] = useState(false);
  const handleShowContractorModal = () => {
    setContractorModal((prev) => !prev);
  };

  const fetchProjectLoans = async (id) => {
    try {
      const { data } = await doGet(`/project/${id}/show`);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [
      "OneTimeProject",
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ],
    queryFn: () =>
      fetchProjectLoans(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      ),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });
  const handleFetchContractor = async (id) => {
    try {
      const response = await doGet(`/contract/${id}/shows`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const fetchContractor = useQuery({
    queryKey: [
      "contractor",
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ],
    enabled: true,
    queryFn: () =>
      handleFetchContractor(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      ),
    onError: () => errorToast("Error while fetching contractor"),
  });

  const COLUMNS = [
    { name: "S.N.", sortable: true, searchable: true },

    { name: "status", sortable: true, searchable: false },
    { name: "sub_contractor", sortable: true, searchable: true },
    { name: "type", sortable: true, searchable: true },
    { name: "contract_number", sortable: true, searchable: true },
    { name: "is_resident", sortable: true, searchable: false },
    { name: "contractor_name", sortable: true, searchable: true },
    { name: "contractor_address", sortable: true, searchable: true },
    { name: "company_registration_type", sortable: true, searchable: true },
    { name: "company_registration_number", sortable: true, searchable: true },

    { name: "donor_currency", sortable: true, searchable: true },

    { name: "work_completion_date", sortable: true, searchable: true },
    { name: "work_completion_date_ad", sortable: true, searchable: true },

    { name: "types_of_service", sortable: true, searchable: false },
    { name: "retention_percentage", sortable: true, searchable: true },
    { name: "Agreement details", sortable: false, searchable: false },
    { name: "Performance details", sortable: false, searchable: false },
    { name: "Lc Details", sortable: false, searchable: false },
    { name: "Payment terms", sortable: false, searchable: false },
    { name: "Images", sortable: false, searchable: false },

    { name: "created_at", sortable: true, searchable: true },
  ];
  const [showLcDetailsModal, setShowLcDetailsMOdal] = useState(false);
  const [showperfDetailsModal, setShowperfDetailsMOdal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showAgreementDetailsModal, setshowAgreementDetailsModal] =
    useState(false);
  const [showEditContractModal, setShowEditContractModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [contract, setContract] = useState({});
  const handleShowLcDetails = (item) => {
    setContract(item);
    setShowLcDetailsMOdal((prev) => !prev);
  };
  const handleShowAgreement = (item) => {
    setContract(item);
    setshowAgreementDetailsModal((prev) => !prev);
  };
  const handleShowPerformance = (item) => {
    setContract(item);
    setShowperfDetailsMOdal((prev) => !prev);
  };
  const handleShowDescription = (item) => {
    setContract(item);
    setShowDescriptionModal((prev) => !prev);
  };

  const handleEditContractor = (item) => {
    if (item.progress_status == 2) {
      setContract(item);
      setShowEditContractModal((prev) => !prev);
    }
  };
  const statusEnums = {
    0: { label: "In progress", color: "#f1d900" },
    1: { label: "Approved", color: "#1fb626" },
    2: { label: "Rejected", color: "#c71c1c" },
  };

  const handleShowImagess = (item) => {
    setContract(item);
    setShowImageModal((prev) => !prev);
  };
  useEffect(() => {
    fetchContractor.refetch();
  }, []);
  return (
    <div className="contractor">
      {showContractorModal && (
        <ContractModal
          title={"Contractor"}
          onClose={handleShowContractorModal}
          donorCurrency={[
            ...new Set(
              data && data.loan ? data.loan.map((i) => i.donor_currency) : []
            ),
          ]}
        />
      )}
      {showLcDetailsModal && (
        <LCModal lcdetails={contract} onClose={handleShowLcDetails} />
      )}
      {showAgreementDetailsModal && (
        <AgreementModal
          agreementdetails={contract}
          onClose={handleShowAgreement}
        />
      )}
      {showperfDetailsModal && (
        <PerformanceModal
          onClose={handleShowPerformance}
          perfDetails={contract}
        />
      )}
      {showDescriptionModal && (
        <DescriptionModal
          description={contract}
          onClose={handleShowDescription}
        />
      )}
      {showEditContractModal && (
        <EditContractModal
          title={"Contractor"}
          onClose={() => setShowEditContractModal(false)}
          donorCurrency={[
            ...new Set(
              data && data.loan ? data.loan.map((i) => i.donor_currency) : []
            ),
          ]}
          item={contract}
        />
      )}
      {showImageModal && (
        <FilesModal
          files={
            contract &&
            contract.images.split(",").map((item) => {
              return { document: item };
            })
          }
          handleClose={() => setShowImageModal(false)}
        />
      )}
      <TopBar
        title={"Contractor"}
        children={
          <PrimaryButton
            icon={"fa-light fa-plus"}
            content={"Add Contractor"}
            onClick={handleShowContractorModal}
          />
        }
        backPage={true}
      />
      <Table
        Rows={
          fetchContractor && fetchContractor.data
            ? fetchContractor.data.contract.map((item, index) => {
                return {
                  id: index + 1,
                  status:
                    item.progress_status != 2 ? (
                      <View
                        label={
                          item.progress_status == 0 ? "In progress" : "Approved"
                        }
                        type={"button"}
                        customStyle={{
                          backgroundColor:
                            statusEnums[item.progress_status].color,
                        }}
                      />
                    ) : (
                      <View
                        label={"Rejected"}
                        prefix={<i className="fa-light fa-edit"></i>}
                        onClick={() => handleEditContractor(item)}
                        type={"button"}
                        customStyle={{
                          backgroundColor:
                            statusEnums[item.progress_status].color,
                        }}
                      />
                    ),
                  sub_contractor:
                    item.progress_status == 1 ? (
                      <View
                        to={`/ac/subcontractor/${item.id}?projectid=${
                          location.pathname.split("/")[
                            location.pathname.split("/").length - 1
                          ]
                        }`}
                        label="Subcontractor"
                      />
                    ) : (
                      "N/A"
                    ),
                  type: item.type,
                  contract_number: item.contract_number,

                  is_resident: item.is_resident ? "Resident" : "Non-resident",
                  contractor_name: item.contractor_name,
                  contractor_address: item.contractor_address,
                  company_registration_type: item.company_registration_type,
                  company_registration_number: item.company_registration_number,

                  donor_currency: item.donor_currency,
                  work_completion_date: item.work_completion_date,
                  work_completion_date_ad: item.work_completion_date_ad,

                  types_of_service: item.types_of_service,
                  retention_percentage: item.retention_percentage,
                  agreement: (
                    <View
                      label={"Agreement details"}
                      onClick={() => handleShowAgreement(item)}
                      type="button"
                    />
                  ),
                  perf: (
                    <View
                      label={"Performance details"}
                      onClick={() => handleShowPerformance(item)}
                      type="button"
                    />
                  ),
                  lc: (
                    <View
                      label={"LC details"}
                      onClick={() => handleShowLcDetails(item)}
                      type="button"
                    />
                  ),
                  terms: (
                    <View
                      label={"Payment terms"}
                      onClick={() => handleShowDescription(item)}
                      type="button"
                    />
                  ),
                  images: item.images ? (
                    <View
                      label={"Images"}
                      type="button"
                      onClick={() => handleShowImagess(item)}
                    />
                  ) : (
                    "N/A"
                  ),
                  created_at: item.created_at.split("T")[0],
                };
              })
            : []
        }
        Columns={COLUMNS}
      />
    </div>
  );
};

export default Contractor;

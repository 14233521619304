import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const projectManagerSlice = createSlice({
  name: "ProjectManagerSlice",
  initialState: initialState,
  reducers: {},
});

export const {} = projectManagerSlice.actions;

export default projectManagerSlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import { doGet } from "../../../Services/Axios";
import { errorToast } from "../../../Utils/Toastify";
import { useQuery } from "react-query";
import Input from "../../../UI/Input/Input";
import useComponentVisible from "./useVisible";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import Select from "../../../UI/Select/Select";
import NEA from "../../../Assets/Images/NEA_COLOR.png";
import DateInput from "../../../UI/DateInput/DateInput";
import { useSelector } from "react-redux";
const DirectPayment = () => {
  const tableRef = useRef();
  const { nepaliDate } = useSelector((state) => state.System);
  const todaysDate = nepaliDate.getTodayDate().BS;
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedResponseProject, setSelectedResponseProject] = useState([]);
  const [dateState, setDateState] = useState();

  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [fiteredProjects, setFilteredProjects] = useState([]);
  const [reportType, setReportType] = useState();
  const fetchProject = async (pageParam) => {
    try {
      const { data } = await doGet(`/project/all-list`);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllProjects"],
    queryFn: () => fetchProject(),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching projects"),
  });
  const fetchReport = async (date) => {
    try {
      if (
        !date ||
        !/^\d{4}\/(?!0000)(\d{3}[1-9]|\d[1-9]\d{2}|\d{2}[1-9]\d|\d{1}[1-9]\d{2})$/.test(
          date
        )
      ) {
        setSelectedProject("");
        setFilterTerm("");
        return errorToast("Please Enter valid date");
      }
      const { data } = await doGet(
        `reports/${date.split("/")[0]}%252f${date.split("/")[1]}/direct_payment`
      );

      data && Object.values(data.direct_payment_loan).length > 0
        ? setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_loan: Object.values(data.direct_payment_loan),
          }))
        : setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_loan: [],
          }));

      data && Object.values(data.direct_payment_share).length > 0
        ? setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_share: Object.values(data.direct_payment_share),
          }))
        : setSelectedResponseProject((prev) => ({
            ...prev,
            direct_payment_share: [],
          }));
    } catch (error) {
      errorToast(
        error.message === "date is undefined"
          ? "Please enter valid fiscal year"
          : "Error while fetching report"
      );
      setSelectedProject("");
      setFilterTerm("");
      setIsComponentVisible(false);
    }
  };
  // const handleSelect = async (item) => {
  //   try {
  //     setFilterTerm(item.name);
  //     setSelectedProject(item);
  //     fetchReport(item.id);
  //     setIsComponentVisible(false);
  //   } catch (error) {}
  // };
  // const handleFilter = (e) => {
  //   setFilterTerm(e.target.value);
  // };
  useEffect(() => {
    setFilteredProjects(
      data &&
        data.projects &&
        filterTerm !== null &&
        data.projects.filter((el) =>
          el.name.toLowerCase().includes(filterTerm.toLowerCase())
        )
    );
    selectedProject &&
      selectedProject.name !== filterTerm &&
      setIsComponentVisible(true);
  }, [
    data,
    filterTerm,
    selectedProject && selectedProject.name,
    selectedProject && selectedProject.created_at,
  ]);

  const handleDateChange = (e) => {
    const { value } = e.target;
    setDateState(value);
    fetchReport(value);
  };

  const nepali = {
    0: "\u0966", // ०
    1: "\u0967", // १
    2: "\u0968", // २
    3: "\u0969", // ३
    4: "\u096A", // ४
    5: "\u096B", // ५
    6: "\u096C", // ६
    7: "\u096D", // ७
    8: "\u096E", // ८
    9: "\u096F", //
  };
  const projectsFromLoan = selectedResponseProject.direct_payment_loan
    ? selectedResponseProject.direct_payment_loan.map(
        (item) => item.project_name
      )
    : [];
  const projectsFromShare = selectedResponseProject.direct_payment_share
    ? selectedResponseProject.direct_payment_share.map((el) => el.project_name)
    : [];
  const AllProjects = Array.from(
    new Set([...projectsFromLoan, ...projectsFromShare])
  );

  function numberConverter(value) {
    return value
      ? `${value}`
          .split("")
          .map((el) => nepali[el] || el)
          .join("")
      : 0;
  }
  return (
    <>
      <div className="directpayment">
        <div className="directpayment-heading">
          <div className="directpayment-heading--title">Direct Payment</div>
          <div className="directpayment-heading--actions">
            <div className="directpayment-heading--actions__date">
              <Select
                options={Object.keys(nepaliDate.NEPALI_YEARS).map((i) => ({
                  key: `${i}/${(+i + 1).toString()}`,
                }))}
                defaultValue={dateState}
                handler={handleDateChange}
                name={"fiscal_year"}
                preventMouse
                width={"15rem"}
              />
            </div>
            <DownloadTableExcel
              currentTableRef={tableRef.current}
              filename={`DirectPayment_${Date.now()}`}
              sheet="Direct Payment"
            >
              <PrimaryButton
                content={"Export"}
                title="Donor Info"
                icon={"fa-light fa-file-spreadsheet"}
                className={"directpayment-heading--actions__export"}
              />
            </DownloadTableExcel>
          </div>
        </div>

        <div className="directpayment-wrapper">
          <table
            width={"100%"}
            className="directpayment-wrapper--table"
            ref={tableRef}
            border={1}
          >
            <thead>
              <tr>
                <th colSpan={45}>
                  <h1>नेपाल विद्युत प्राधिकरण</h1>
                </th>
              </tr>
              <tr>
                <th colSpan={45}>अर्थ निर्देशनालय</th>
              </tr>
              <tr>
                <th colSpan={45}>संस्थागत वित्तिय विभाग</th>
              </tr>
              <tr>
                <th colSpan={45}>वित्तिय योजना तथा लगानी महाशाखा</th>
              </tr>
              <tr>
                <th colSpan={45}>
                  आ.व.{" "}
                  {dateState &&
                    dateState
                      .split("")
                      .map((el) => nepali[el] || "/")
                      .join("")}{" "}
                  कार्यक्रम तथा क्रियाकलापवाईज सोझै भुक्तानी विवरण
                </th>
              </tr>
              <tr>
                {<th rowSpan={3}>क्रियाकलाप क्र.सं.</th>}
                <th rowSpan={3}>क्रियाकलाप नं</th>
                <th rowSpan={3}>आयोजना कार्यक्रम/क्रियाकलाप को नाम</th>
                <th rowSpan={3}>बजेट</th>
                <th rowSpan={3}>बजेट उप शिर्षक </th>
                <th rowSpan={3}>खर्च शिर्षक</th>
                <th rowSpan={3}>स्रोत</th>
                <th rowSpan={3}>निकाशा विधि</th>
                <th rowSpan={3}>ऋण न.</th>
                <th rowSpan={3}>अनुदान न.</th>
                <th colSpan={3}>कुल</th>
                <th colSpan={10}>पहिलो त्रैमासिक</th>
                <th colSpan={10}>दोस्रो त्रैमासिक</th>
                <th colSpan={10}>तेस्रो त्रैमासिक</th>
                <th colSpan={2}>कूल जम्मा</th>
              </tr>
              <tr>
                <th rowSpan={2}>शेयर </th>
                <th rowSpan={2}>ऋण</th>
                <th rowSpan={2}>कैफियत</th>
                <th colSpan={2}>श्रावण</th>
                <th colSpan={2}>भदौ</th>
                <th colSpan={2}>आश्विन</th>
                <th colSpan={2}>कार्तिक</th>
                <th colSpan={2}>कुल</th>
                <th colSpan={2}>मंसिर</th>
                <th colSpan={2}>पुष</th>
                <th colSpan={2}>माघ</th>
                <th colSpan={2}>फाल्गुन</th>
                <th colSpan={2}>कुल</th>
                <th colSpan={2}>चैत्र</th>
                <th colSpan={2}>बैशाख</th>
                <th colSpan={2}>जेठ</th>
                <th colSpan={2}>असार</th>
                <th colSpan={2}>कुल</th>
                <th colSpan={2}>कुल</th>
              </tr>
              <tr>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
                <th>शेयर</th>
                <th>ऋण</th>
              </tr>
            </thead>
            <tbody>
              {AllProjects.map((el, index) => {
                return (
                  <>
                    <tr key={Math.random()}>
                      <td></td>
                      <td></td>
                      <td>{el}</td>
                      <td>
                        {numberConverter(
                          selectedResponseProject &&
                            selectedResponseProject.direct_payment_loan
                            ? selectedResponseProject.direct_payment_loan
                                .filter((i) => i.project_name == el)
                                .reduce(
                                  (sum, first) =>
                                    +first.budget_amount_npr + sum,
                                  0
                                )
                            : 0
                        )}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* First Quarter */}

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* Second Quarter */}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* Third Quarter */}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {selectedResponseProject.direct_payment_loan &&
                      selectedResponseProject.direct_payment_loan.length >
                        0 && (
                        <tr key={Math.random()}>
                          <td></td>
                          <td>{el.activity_number}</td>
                          <td>सार्वजनिक संस्थानमा ऋण लगानी</td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? selectedResponseProject.direct_payment_loan
                                    .filter((elx) => elx.project_name === el)
                                    .reduce(
                                      (sum, first) =>
                                        first.budget_amount_npr + sum,
                                      0
                                    )
                                : 0
                            )}
                          </td>
                          <td>{el.budget_head}</td>
                          <td>{el.expenses_head}</td>
                          <td>{el.agency_shrot}</td>
                          <td>{el.budget_re_imburse}</td>
                          <td>{el.loan_no}</td>
                          <td>{el.contract_no}</td>
                          <td>{el.total_share}</td>
                          <td></td>
                          <td></td>
                          {/* First Quarter */}
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.shrawn + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.shrawn + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.bhadra + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.bhadra + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.ashwin + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.ashwin + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.kartik + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.kartik + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? selectedResponseProject.direct_payment_loan
                                    .filter((elx) => elx.project_name === el)
                                    .reduce(
                                      (sum, first) =>
                                        first.total_first_quarter + sum,
                                      0
                                    )
                                : 0
                            )}
                          </td>
                          {/* Second Quarter */}
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.mangsir + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.mangsir + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.poush + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.poush + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.magh + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.magh + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.falgun + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.falgun + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? selectedResponseProject.direct_payment_loan
                                    .filter((elx) => elx.project_name === el)
                                    .reduce(
                                      (sum, first) =>
                                        first.total_second_quarter + sum,
                                      0
                                    )
                                : 0
                            )}
                          </td>
                          {/* Third Quarter */}
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.chaitra + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.chaitra + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.baisakh + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.baisakh + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.jestha + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.jestha + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? isNaN(
                                    selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.ashad + sum,
                                        0
                                      )
                                  )
                                  ? 0
                                  : selectedResponseProject.direct_payment_loan
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => +first.ashad + sum,
                                        0
                                      )
                                : 0
                            )}
                          </td>
                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? selectedResponseProject.direct_payment_loan
                                    .filter((elx) => elx.project_name === el)
                                    .reduce(
                                      (sum, first) =>
                                        first.total_third_quarter + sum,
                                      0
                                    )
                                : 0
                            )}
                          </td>

                          <td></td>
                          <td>
                            {numberConverter(
                              selectedResponseProject &&
                                selectedResponseProject.direct_payment_loan
                                ? selectedResponseProject.direct_payment_loan
                                    .filter((elx) => elx.project_name === el)
                                    .reduce(
                                      (sum, first) => first.grand_total + sum,
                                      0
                                    )
                                : 0
                            )}
                          </td>
                        </tr>
                      )}
                    <>
                      {selectedResponseProject &&
                      selectedResponseProject.direct_payment_loan
                        ? selectedResponseProject.direct_payment_loan
                            .filter((elx) => elx.project_name === el)
                            .map((item, index) => (
                              <tr key={Math.random()}>
                                <td>{item.serial_number}</td>
                                <td>{item.activity_number}</td>
                                <td>{item.sub_project_name}</td>
                                <td>
                                  {numberConverter(item.budget_amount_npr)}
                                </td>
                                <td>{item.budget_head}</td>
                                <td>{item.expenses_head}</td>
                                <td>{item.agency_shrot}</td>
                                <td>{item.budget_re_imburse}</td>
                                <td>{item.loan_no}</td>
                                <td>{item.contract_no}</td>

                                <td></td>
                                <td>{numberConverter(item.total_loan)}</td>
                                <td></td>
                                {/* First Quarter */}
                                <td></td>
                                <td>{numberConverter(item.shrawn)}</td>
                                <td></td>
                                <td>{numberConverter(item.bhadra)}</td>
                                <td></td>
                                <td>{numberConverter(item.ashwin)}</td>
                                <td></td>
                                <td>{numberConverter(item.kartik)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_first_quarter)}
                                </td>
                                {/* Second Quarter */}
                                <td></td>
                                <td>{numberConverter(item.mangsir)}</td>
                                <td></td>
                                <td>{numberConverter(item.poush)}</td>
                                <td></td>
                                <td>{numberConverter(item.magh)}</td>
                                <td></td>
                                <td>{numberConverter(item.falgun)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_second_quarter)}
                                </td>
                                {/* Third Quarter */}
                                <td></td>
                                <td>{numberConverter(item.chaitra)}</td>
                                <td></td>
                                <td>{numberConverter(item.baisakh)}</td>
                                <td></td>
                                <td>{numberConverter(item.jestha)}</td>
                                <td></td>
                                <td>{numberConverter(item.ashad)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_third_quarter)}
                                </td>

                                <td></td>
                                <td>{numberConverter(item.grand_total)}</td>
                              </tr>
                            ))
                        : []}
                    </>
                    <>
                      {selectedResponseProject.direct_payment_share &&
                        selectedResponseProject.direct_payment_share.length >
                          0 && (
                          <tr key={Math.random()}>
                            <td>{index + 1}</td>
                            <td>{el.activity_number}</td>
                            <td>सार्वजनिक संस्थानमा शेयर लगानी</td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? selectedResponseProject.direct_payment_share
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) =>
                                          first.budget_amount_npr + sum,
                                        0
                                      )
                                  : 0
                              )}
                            </td>
                            <td>{el.budget_head}</td>
                            <td>{el.expenses_head}</td>
                            <td>{el.agency_shrot}</td>
                            <td>{el.budget_re_imburse}</td>
                            <td>{el.loan_no}</td>
                            <td>{el.contract_no}</td>
                            <td>{el.total_share}</td>
                            <td></td>
                            <td></td>
                            {/* First Quarter */}
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.shrawn + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.shrawn + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.bhadra + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.bhadra + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.ashwin + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.ashwin + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.kartik + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.kartik + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? selectedResponseProject.direct_payment_share
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) =>
                                          first.total_first_quarter + sum,
                                        0
                                      )
                                  : 0
                              )}
                            </td>
                            {/* Second Quarter */}
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.mangsir + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.mangsir + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.poush + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.poush + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.magh + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.magh + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.falgun + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.falgun + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? selectedResponseProject.direct_payment_share
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) =>
                                          first.total_second_quarter + sum,
                                        0
                                      )
                                  : 0
                              )}
                            </td>
                            {/* Third Quarter */}
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.chaitra + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.chaitra + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.baisakh + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.baisakh + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.jestha + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.jestha + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? isNaN(
                                      selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.ashad + sum,
                                          0
                                        )
                                    )
                                    ? 0
                                    : selectedResponseProject.direct_payment_share
                                        .filter(
                                          (elx) => elx.project_name === el
                                        )
                                        .reduce(
                                          (sum, first) => +first.ashad + sum,
                                          0
                                        )
                                  : 0
                              )}
                            </td>
                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? selectedResponseProject.direct_payment_share
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) =>
                                          first.total_third_quarter + sum,
                                        0
                                      )
                                  : 0
                              )}
                            </td>

                            <td></td>
                            <td>
                              {numberConverter(
                                selectedResponseProject &&
                                  selectedResponseProject.direct_payment_share
                                  ? selectedResponseProject.direct_payment_share
                                      .filter((elx) => elx.project_name === el)
                                      .reduce(
                                        (sum, first) => first.grand_total + sum,
                                        0
                                      )
                                  : 0
                              )}
                            </td>
                            <td></td>
                          </tr>
                        )}
                    </>
                    <>
                      {selectedResponseProject &&
                      selectedResponseProject.direct_payment_share
                        ? selectedResponseProject.direct_payment_share
                            .filter((elx) => elx.project_name === el)
                            .map((item, index) => (
                              <tr key={Math.random()}>
                                <td>{index + 1}</td>
                                <td>{item.activity_number}</td>
                                <td>{item.sub_project_name}</td>
                                <td>{item.budget_amount_npr}</td>
                                <td>{item.budget_head}</td>
                                <td>{item.expenses_head}</td>
                                <td>{item.agency_shrot}</td>
                                <td>{item.budget_re_imburse}</td>
                                <td>{item.loan_no}</td>
                                <td>{item.contract_no}</td>
                                <td>{item.total_share}</td>
                                <td></td>
                                <td></td>
                                {/* First Quarter */}

                                <td>{numberConverter(item.shrawn)}</td>
                                <td></td>
                                <td>{numberConverter(item.bhadra)}</td>
                                <td></td>
                                <td>{numberConverter(item.ashwin)}</td>
                                <td></td>
                                <td>{numberConverter(item.kartik)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_first_quarter)}
                                </td>
                                {/* Second Quarter */}
                                <td></td>
                                <td>{numberConverter(item.mangsir)}</td>
                                <td></td>
                                <td>{numberConverter(item.poush)}</td>
                                <td></td>
                                <td>{item.magh}</td>
                                <td></td>
                                <td>{numberConverter(item.falgun)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_second_quarter)}
                                </td>
                                {/* Third Quarter */}
                                <td></td>
                                <td>{numberConverter(item.chaitra)}</td>
                                <td></td>
                                <td>{item.baisakh}</td>
                                <td></td>
                                <td>{numberConverter(item.jestha)}</td>
                                <td></td>
                                <td>{numberConverter(item.ashad)}</td>
                                <td></td>
                                <td>
                                  {numberConverter(item.total_third_quarter)}
                                </td>

                                <td></td>
                                <td>{numberConverter(item.grand_total)}</td>
                                <td></td>
                              </tr>
                            ))
                        : []}
                    </>
                  </>
                );
              })}

              {(!selectedResponseProject ||
                !selectedResponseProject.direct_payment_loan) &&
                (!selectedResponseProject ||
                  !selectedResponseProject.direct_payment_share) && (
                  <tr>
                    <td colSpan={"100%"}>No Data Found</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default DirectPayment;

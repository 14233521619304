import React, { useEffect, useRef, useState } from "react";
import Calendar from "../../Components/Calendar/Calendar";
import { useSelector } from "react-redux";

export default function DateInput({
  customStyle,
  onChange,
  error,
  required,
  label,
  name,
  customDate,
  disabled,
  min,
}) {
  const pickerRef = useRef();
  const [focus, setFocus] = useState(false);
  const [dateMode, setDateMode] = useState("BS");
  const [date, setDate] = useState(null);
  const [custom, setCustom] = useState(customDate);
  const { nepaliDate } = useSelector((state) => state.System);

  const handleDateMode = (e) => (
    setDateMode(e.target.value), setFocus(false), setDate(null)
  );
  const handleDatePicker = (e) => setFocus((prev) => !prev);
  const handleDateChange = (e) => {
    if (dateMode == "BS") {
      return setDate(e);
    }
    const [y, m, d] = e.target.value.split("-");
    const bsDate = nepaliDate.getNepaliDate(+y, +m, +d);
    setDate({
      BS: bsDate,
      AD: {
        year: +y,
        month: +m,
        day: +d,
      },
    });
  };

  useEffect(() => {
    if (date) {
      const bsData = {
        target: {
          name: name,
          value: `${date.BS.year}-${date.BS.month}-${date.BS.day}`,
        },
      };
      const adData = {
        target: {
          name: name + "_ad",
          value: `${date.AD.year}-${date.AD.month}-${date.AD.day}`,
        },
      };

      if (onChange) {
        onChange(adData);
        onChange(bsData);
      }
    }
  }, [date]);

  return (
    <div style={customStyle} className="inputdate">
      <div className="inputdate-header">
        <label htmlFor={name} className="inputdate-header--label">
          {label && `${label}`}{" "}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
        <select
          className="inputdate-header--action"
          onChange={handleDateMode}
          value={dateMode}
        >
          <option value="BS">BS</option>
          <option value="AD">AD</option>
        </select>
      </div>
      <div className="inputdate-wrapper">
        <input
          className={
            !error ? "inputdate-display" : "inputdate-display inputdate-error"
          }
          value={
            date && customDate
              ? `BS: ${date.BS.year}-${date.BS.month}-${date.BS.day}, AD: ${date.AD.year}-${date.AD.month}-${date.AD.day}`
              : "BS: YYYY-MM-DD, AD: YYYY-MM-DD"
          }
          type="text"
          readOnly={true}
          disabled={disabled}
          onFocus={handleDatePicker}
        />
        {dateMode == "BS"
          ? !disabled && (
              <Calendar
                close={handleDatePicker}
                open={focus}
                name={name}
                onChange={handleDateChange}
                customDate={custom ? custom : undefined}
                min={min ? min : undefined}
              />
            )
          : !disabled && (
              <input
                type="date"
                ref={pickerRef}
                onClick={() => pickerRef.current.showPicker()}
                className="inputdate-ad"
                min={
                  min
                    ? `${min.split("-")[0]}-${
                        min.split("-")[1] < 10
                          ? `0${min.split("-")[1]}`
                          : min.split("-")[1]
                      }-${
                        min.split("-")[2] < 10
                          ? `0${min.split("-")[2]}`
                          : min.split("-")[2]
                      }`
                    : "1919-04-13"
                }
                max="2091-01-01"
                value={
                  date
                    ? `${date.AD.year}-${
                        date.AD.month < 10 ? `0${date.AD.month}` : date.AD.month
                      }-${date.AD.day < 10 ? `0${date.AD.day}` : date.AD.day}`
                    : ""
                }
                onChange={handleDateChange}
              />
            )}
      </div>
      <div className="inputdate-error--text">{error ? error : ""}</div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { FILE_ICON } from "../../Assets/Images";

const FileInput = ({ title, handleSubmitImage, imagesProp, isSingle }) => {
  const [images, setImages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const handleImages = (e) => {
    setImages(e.target.files);
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    handleSubmitImage(images);
  }, [refresh]);

  const acceptedType = {
    "image/jpeg": true,
    "image/png": true,
    "image/jpg": true,
  };

  return (
    <div className="fileinput">
      <div className="fileinput-title">{title}</div>
      <div className="fileinput-main">
        <label htmlFor="fileinput">
          <i className="fa-regular fa-cloud-arrow-down fileinput-main--label"></i>
        </label>
        <input
          id="fileinput"
          className="fileinput-main--input"
          type={"file"}
          multiple={isSingle ? false : true}
          onChange={handleImages}
          accept=".jpg, .jpeg  , .png, .pdf"
        />
      </div>
      <div className="fileinput-preview">
        {images && Array.from(images).length > 0 ? (
          Array.from(images).map((item, index) => {
            return (
              <div key={index}>
                <div className="fileinput-preview--images" key={index}>
                  <img
                    className="fileinput-preview--images__main"
                    alt=""
                    title="Document 1"
                    src={
                      acceptedType[item.type]
                        ? URL.createObjectURL(item)
                        : FILE_ICON
                    }
                  ></img>
                </div>
                <div className="fileinput-document">{`${
                  item.name.split(".")[0].slice(0, 5) +
                  "." +
                  item.name.split(".")[1]
                }`}</div>
              </div>
            );
          })
        ) : (
          <div className="fileinput-preview--noimage">
            No Documents Selected
          </div>
        )}
      </div>
    </div>
  );
};

export default FileInput;

export const loanInitialState = {
  //!Loan Information
  type: null,
  loan_number: null,
  donor_agency: null,
  donor_currency: null,
  loan_amount: null,
  loan_amount_usd: null,
  loan_amount_npr: null,
  donor_agreement_date: null,
  donor_agreement_date_ad: null,

  donor_service_charge_flag: false,
  donor_service_charge_type: null,
  donor_service_charge: null,
  donor_service_charge_amount: null,
  donor_service_charge_amount_usd: null,
  donor_service_charge_amount_npr: null,

  forex_risk_bearer_flag: false,
  forex_risk_bearer: null,
  forex_risk_bearer_type: null,

  penalty_flag: false,
  penalty_percentage: null,

  grace_period_start_date: null,
  grace_period_start_date_ad: null,
  grace_period_end_date: null,
  grace_period_end_date_ad: null,
  grace_period_interest_rate: null,
  interest_rate_after_grace_period: null,

  installment_start_date: null,
  installment_start_date_ad: null,
  installment_end_date: null,
  installment_end_date_ad: null,
  installment_period: null,
  no_of_installment: null,

  idc_effective_date: null,
  idc_effective_date_ad: null,
  idc_ending_date: null,
  idc_ending_date_ad: null,
  idc_no_of_days: null,
  idc_nea_portion: null,
  idc_gon_portion: null,
  idc_payable_to_gon: null,
  idc_transfer_to_share: null,
  idc_transfer_to_cwip: null,
  idc_transfer_to_loan: null,
};

export const CreateLoanController = {
  //!IDC
  idcConversionOptions: {
    idc_effective_date: true,
    idc_factor_mode: true,
    idc_factor: true,
  },
  idcCriteria: (state) => {
    return state.idc_effective_date_ad &&
      state.idc_factor_mode &&
      state.idc_factor
      ? true
      : false;
  },
  //!Installment
  installmentConversionOptions: {
    installment_start_date_ad: true,
    installment_factor_mode: true,
    installment_factor: true,
    installment_period: true,
  },
  installmentPeriods: {
    Yearly: 360,
    "Half Yearly": 360 / 2,
    Quarterly: 360 / 4,
  },
  installmentCriteria: (state) =>
    state.installment_start_date_ad &&
    state.installment_factor_mode &&
    state.installment_factor &&
    state.installment_period,

  //!Validators
  validator: (e) => {
    const name = e.target.name;

    if (name.indexOf("_flag") > -1) return;

    const value = e.target.value;

    if (name === "loan_number") {
      if (!value) return "Field is required";
    }
    if (name === "donor_agency") {
      if (!value) return "Field is required";
    }
    if (name === "loan_amount") {
      if (!value) return "Field is required";
      if (isNaN(value)) return "Contains only numbers";
      if (value < 0) return "Amount must be greater than 0";
    }
    if (name === "donor_service_charge_amount") {
      if (!value) return "Field is required";
      if (isNaN(value)) return "Contains only numbers";
      if (value < 0) return "Amount must be greater than 0";
    }
    if (name === "installment_factor" || name === "idc_factor") {
      if (!value) return "Field is required";
      if (isNaN(value)) return "Contains only numbers";
    }

    if (name === "sdr_conversion_rate") {
      if (!value) return "Field is required";
      if (isNaN(value)) return "Contains only numbers";
      if (value < 0) return "Amount must be greater than 0";
    }

    if (
      name === "grace_period_interest_rate" ||
      name === "interest_rate_after_grace_period" ||
      name === "idc_nea_portion" ||
      name === "idc_payable_to_gon" ||
      name === "idc_transfer_to_cwip" ||
      name === "penalty_percentage"
    ) {
      if (isNaN(value)) return "Contains only numbers";
      if (+value < 0 || +value > 100) return "Ranges between 0 - 100";
    }

    return null;
  },
  modifyDate: (date, value, factor, nepaliDate) => {
    const s = new Date(date);
    if (s === "Invalid Date") return s;

    let days = 0;
    if (factor === "Years") {
      for (let i = 0; i < +value; i++) {
        days += 360;
      }
    }
    if (factor === "Months") {
      for (let i = 0; i < +value; i++) {
        days += 30;
      }
    }
    s.setDate(s.getDate() + days);

    if (s.getFullYear() >= nepaliDate.END_EN) return "Invalid Date";
    const newDate = nepaliDate.getNepaliDate(
      s.getFullYear(),
      s.getMonth() + 1,
      s.getDate()
    );
    if (newDate === "Invalid Date") return newDate;
    return {
      AD: `${s.getFullYear()}-${s.getMonth() + 1}-${s.getDate()}`,
      BS: `${newDate.year}-${newDate.month}-${newDate.day}`,
    };
  },
  convertDate: (start, end, period = null) => {
    const s = new Date(start);
    const e = new Date(end);
    const difference = e - s;
    if (difference < 0) return null;
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

    if (period) {
      return Math.ceil(days / period);
    }
    return days;
  },
  getDisabledValue: (data) => {
    return data !== undefined && data !== null && data !== ""
      ? undefined
      : true;
  },
};

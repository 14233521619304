import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "../../UI/Select/Select";

export default function Calendar({
  customStyle,
  onChange,
  open,
  close,
  customDate,
  min,
  name,
}) {
  const { nepaliDate } = useSelector((state) => state.System);
  const [selected, setSelected] = useState(null);
  const [availableDays, setAvailableDays] = useState(null);
  const [minConverted, setMinConverted] = useState(null);
  const calendarRef = useRef(null);
  const [custom, setCustom] = useState();

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target))
        close(false);
    });
    return () => {
      document.removeEventListener("mousedown", null);
    };
  }, [calendarRef]);

  useEffect(() => {
    if (min) {
      const [cy, cm, cd] = min.split("-");
      const y = +cy;
      const m = +cm - 1;
      const d = +cd;
      const convertedDate = nepaliDate.getNepaliDate(+y, m + 1, +d);
      setMinConverted(convertedDate !== "Invalid Date" ? convertedDate : null);
    }
  }, [min]);

  useEffect(() => {
    setCustom(customDate);
  }, [customDate]);

  useEffect(() => {
    let date = null;
    if (min && !custom) date = min;
    if (custom && min) {
      const cd = new Date(custom);
      const md = new Date(min);
      if (cd - md < 0) {
        date = min;
      }
    }
    if (!custom) {
      const current = new Date();
      date = `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
    }
    if (custom) date = custom;
    const [cy, cm, cd] = date.split("-");
    const y = +cy;
    const m = +cm - 1;
    const d = +cd;
    const convertedDate = nepaliDate.getNepaliDate(+y, m + 1, +d);

    if (convertedDate !== "Invalid Date") {
      setSelected({
        AD: { year: +y, month: m + 1, day: +d },
        BS: convertedDate,
      });
    }
  }, [custom]);

  useEffect(() => {
    if (selected) {
      custom && onChange(selected);
      const days = [];
      for (
        let i = 0;
        i < nepaliDate.get().year[selected.BS.year][selected.BS.month - 1];
        i++
      ) {
        days.push({
          key: i + 1,
          disabled: !minConverted
            ? undefined
            : selected.BS.year !== minConverted.year
            ? undefined
            : selected.BS.month !== minConverted.month
            ? undefined
            : i + 1 < minConverted.day
            ? true
            : undefined,
        });
      }
      setAvailableDays(days);
    }
  }, [selected]);

  const callBack = ({ year, month, day }) => {
    const y = year ? year : selected.BS.year;
    const m = month ? month : selected.BS.month;
    const d = day ? day : selected.BS.day;

    const convertedDate = nepaliDate.getEnglishDate(y, m, d);
    if (convertedDate === "Invalid Date") return;

    const generatedData = {
      AD: {
        year: convertedDate.getFullYear(),
        month: convertedDate.getMonth() + 1,
        day: convertedDate.getDate(),
      },
      BS: {
        year: y,
        month: m,
        day: d,
      },
    };
    setSelected(generatedData);

    onChange(generatedData);
  };

  const handleYear = (e) => callBack({ year: +e.target.value });
  const handleMonth = (e) => callBack({ month: +e.target.value });
  const handleDay = (e) => callBack({ day: +e.target.value });

  const handleADChange = (e) => {
    console.log(e);
  };
  return (
    open &&
    selected &&
    nepaliDate &&
    availableDays && (
      <div className="calendar" style={{ ...customStyle }} ref={calendarRef}>
        <div className="calendar-header">
          <span className="calendar-header--title">Calendar</span>
          <i
            className="fa-light fa-xmark calendar-header--close"
            onClick={() => close(false)}
          ></i>
        </div>
        <div className="calendar-controls">
          <div className="calendar-controls--item">
            <Select
              options={Object.keys(nepaliDate.get().year).map((i) => ({
                key: i,
                disabled: !minConverted
                  ? undefined
                  : +i >= +minConverted.year
                  ? undefined
                  : true,
              }))}
              defaultValue={selected.BS.year}
              label="Year"
              name="year"
              handler={handleYear}
              disabled={undefined}
            />
          </div>
          <div className="calendar-controls--item ">
            <Select
              options={nepaliDate.get().month.map((i, index) => ({
                key: i,
                value: index + 1,
                disabled: !minConverted
                  ? undefined
                  : selected.BS.year !== minConverted.year
                  ? undefined
                  : index + 1 < minConverted.month
                  ? true
                  : undefined,
              }))}
              defaultValue={selected.BS.month}
              label="Month"
              name="month"
              handler={handleMonth}
              disabled={undefined}
            />
          </div>
          <div className="calendar-controls--item ">
            <Select
              options={availableDays}
              defaultValue={selected.BS.day}
              label="day"
              name="day"
              handler={handleDay}
              disabled={undefined}
            />
          </div>
        </div>
      </div>
    )
  );
}

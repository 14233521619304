import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleLanguage } from "../../Slice/SystemSlice";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import NavController from "./_nav";

export default function Nav() {
  const { user, language } = useSelector((state) => state.System);
  const router = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const dispatch = useDispatch();
  const navController = new NavController(dispatch);

  const handleSetShowProfileMenu = () => {
    setShowProfileMenu((prev) => !prev);
  };
  useEffect(() => {
    setShowProfileMenu(false);
  }, [router.pathname]);
  const handleToggleLanguage = (item) => {
    if (item === "en") {
      dispatch(toggleLanguage("en"));
    } else {
      dispatch(toggleLanguage("np"));
    }
  };
  console.log(language);
  return (
    <nav className="nav">
      <div className="nav-burger" onClick={navController.toggleSideBar}>
        <i className="fa-light fa-bars-staggered nav-burger__icon"></i>
      </div>
      <div className="nav-action">
        <div className="nav-action--notification">
          {/* <i className="fa-light fa-bell nav-action--notification__icon"></i> */}
        </div>
        <div className="nav-action--profile">
          <div className="nav-action--language">
            <button
              className={`nav-action--language__en ${
                language === "en" ? "language-selected" : ""
              }`}
              onClick={() => handleToggleLanguage("en")}
            >
              EN
            </button>
            <button
              className={`nav-action--language__np ${
                language === "np" ? "language-selected" : ""
              }`}
              onClick={() => handleToggleLanguage("np")}
            >
              NP
            </button>
          </div>
          <i
            className="fa-light fa-user nav-action--profile__user"
            onClick={handleSetShowProfileMenu}
          ></i>
          <span
            className="nav-action--profile__name"
            onClick={handleSetShowProfileMenu}
          >
            {user && user.user && user.user.username
              ? user.user.username
              : "admin"}
          </span>
          {showProfileMenu && <ProfileMenu />}
        </div>
      </div>
    </nav>
  );
}

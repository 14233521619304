import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";
import { useSelector } from "react-redux";
import { doGet } from "../../../../Services/Axios";
import { errorToast } from "../../../../Utils/Toastify";
import { intlFormat } from "../../../../Services/Helpers";

const ForexConversion = ({ selectedProject }) => {
  const ref = useRef();
  const { nepaliDate } = useSelector((state) => state.System);
  const [details, setDetails] = useState(null);
  const fetchReport = async (id) => {
    const todaysDate = nepaliDate.getTodayDate().BS;
    const fiscalYear =
      todaysDate.month <= 12 && todaysDate.month > 3
        ? `${todaysDate.year}/${todaysDate.year + 1}`
        : `${todaysDate.year - 1}/${todaysDate.year}`;
    try {
      const { data } = await doGet(
        `/reports/ctr_loan_ledger/${id}/${fiscalYear.split("/")[0]}%252f${
          fiscalYear.split("/")[1]
        }/forex_conversion_ledger`
      );
      data &&
        data.forex_conversion_ledger &&
        data.forex_conversion_ledger.length > 0 &&
        setDetails(data.forex_conversion_ledger);
    } catch (error) {
      errorToast(error.message);
    }
  };
  useEffect(() => {
    setDetails(null);
    selectedProject && fetchReport(selectedProject.id);
  }, [selectedProject && selectedProject.id]);

  return details ? (
    <>
      <DownloadTableExcel
        currentTableRef={ref.current}
        filename={`ledger_${Date.now()}`}
        sheet="Ledger"
      >
        <PrimaryButton
          content={"Export"}
          title="Ledger"
          icon={"fa-light fa-file-spreadsheet"}
          className={"ledger-heading--actions__export"}
        />
      </DownloadTableExcel>
      <table width={100} border={1} className="ledger-wrapper--table" ref={ref}>
        <thead>
          <tr>
            <th rowSpan={2}>Particulars</th>
            <th rowSpan={2}>Loan no</th>
            <th rowSpan={2}>Contract no</th>

            <th colSpan={2}>Value Date</th>
            <th colSpan={3}>Total Forex</th>
            <th colSpan={3}>Forex Borne GoN</th>
            <th colSpan={3}>Forex Borne NEA</th>
            <th colSpan={3}>Forex Borne NEA (To P/A Account)</th>
          </tr>
          <tr>
            <th>BS</th>
            <th>AD</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
          </tr>
        </thead>
        {details.length > 0 ? (
          <tbody>
            {details
              .filter((item) => item.particulars === "UP TO THIS YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th>{item.particulars}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{intlFormat((+item.total_forex_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</th>
                </tr>
              ))}
            {details
              .filter((item) => item.type === "UP TO THIS YEAR")
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>
                  <td>{item.value_date_bs}</td>
                  <td>{item.value_date_ad}</td>
                  <td>{intlFormat((+item.total_forex_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</td>
                </tr>
              ))}
            {details
              .filter((item) => item.particulars === "PREVIOUS YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th>{item.particulars}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{intlFormat((+item.total_forex_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</th>
                </tr>
              ))}
            {details
              .filter((item) => item.type === "PREVIOUS YEAR")
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>
                  <td>{item.value_date_bs}</td>
                  <td>{item.value_date_ad}</td>
                  <td>{intlFormat((+item.total_forex_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</td>
                </tr>
              ))}

            {details
              .filter((item) => item.particulars === "CURRENT YEAR")
              .map((item, index) => (
                <tr key={index} className="ledger-table--title">
                  <th>{item.particulars}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{intlFormat((+item.total_forex_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.total_forex_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_gon_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.forex_nea_npr).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</th>
                  <th>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</th>
                </tr>
              ))}
            {details
              .filter((item) => item.type === "CURRENT YEAR")
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>
                  <td>{item.value_date_bs}</td>
                  <td>{item.value_date_ad}</td>
                  <td>{intlFormat((+item.total_forex_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.total_forex_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_gon_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.forex_nea_npr).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_donor).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_usd).toFixed(2))}</td>
                  <td>{intlFormat((+item.nea_to_pl_npr).toFixed(2))}</td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={"100%"}>No Data Found</td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  ) : (
    <p className="notfound notfound-bordered">No Data Found</p>
  );
};

export default ForexConversion;

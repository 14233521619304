import React from "react";

const SmallButton = ({ content, onClick, icon, title, customStyle }) => {
  return (
    <button
      className={`smallbutton`}
      onClick={onClick}
      title={title ? title : content}
      style={customStyle}
    >
      {icon && <i className={icon}></i>}
      {content}
    </button>
  );
};

export default SmallButton;

import { doDelete, doGet } from "../../Services/Axios";

export const NoticeController = {
  fetchNotices: (page) => {
    return doGet(`notice/list/?page=${page}`);
  },
  fetchNotice: (id) => {
    return doGet(`notice/${id}/show`);
  },
  removeNotices: (id) => {
    return doDelete(`notices/`);
  },
};

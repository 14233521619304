import { doGet } from "../../../Services/Axios";

const ProjectController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "source", sortable: true, searchable: true },
    { name: "project_name", sortable: true, searchable: true },
    { name: "sub_projects", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "created_at", sortable: false, searchable: false },
  ],
  fetchSubProject: async (user_id, type) => {
    try {
      const { data } = await doGet(
        `/sub-project/user/assigned/list/${user_id}/${type}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default ProjectController;

import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Components/Modal/ConfirmationModal";
import { doPost, doPut } from "../../../Services/Axios";
import { useForm } from "../../../Services/useForm";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
import FileInput from "../../../UI/FileInput/FileInput";
import Input from "../../../UI/Input/Input";
import ModalLayout from "../../../UI/Modal/ModalLayout";
import { errorToast } from "../../../Utils/Toastify";

const EditAccountChief = ({
  onClose,
  item = {
    username: "",
    user_type: "",
    employee_id: "",
    name: "",
    office_name: "",
    address: "",
    contact_number: "",
    id: "",
  },
}) => {
  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const handleSubmitImage = (e) => {
    setImages(e);
  };
  const {
    address,
    contact_number,
    employee_id,
    name,
    office_name,
    user_type,
    username,
    email,
    id,
  } = item;

  const initialState = {
    username,
    user_type,
    employee_id,
    name,
    office_name,
    address,
    contact_number,
    email,
    id,
  };
  const { handleChange, errors, states, validate } = useForm(initialState);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        await doPut(`/user/account-chief/${id}/edit`, {
          ...states,
          user_type: "Account Chief",
          level: "1",
        });
        queryClient.refetchQueries("AccountChief");
      } else {
        throw Error("Validation failed");
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      throw error;
    }
  };
  const { mutate } = useMutation({
    mutationKey: "AccountChief",
    mutationFn: handleSubmit,
    onError: (error) => {
      if (error && error.message && typeof error.message === "string") {
        return errorToast(error.message);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message
      ) {
        return toast.error(error.response.data.message);
      } else {
        return toast.error("Account chief edit error!!");
      }
    },
    onSuccess: () => {
      toast.success("Account chief edited successfully");
      onClose();
    },
  });

  const handleClose = () => {
    setCloseConfirmationModal(true);
  };
  console.log(states);
  return (
    <ModalLayout title={"Edit Account Chief"} onClose={handleClose}>
      <div className="addac-main">
        {confirmationModal && (
          <ConfirmationModal
            text={"Do you really  want to edit account chief ??"}
            caption={"Please verify all details before clicking yes"}
            onClose={() => setConfirmationModal(false)}
            onSuccess={() => {
              mutate();
              setConfirmationModal(false);
            }}
          />
        )}
        {closeConfirmationModal && (
          <ConfirmationModal
            caption={"this will remove all filled data"}
            text={"Do you really  want to cancel editing account chief ??"}
            onClose={() => setCloseConfirmationModal(false)}
            onSuccess={() => {
              setConfirmationModal(false);
              onClose();
            }}
          />
        )}
        <div className="addac-main--submit">
          <PrimaryButton
            content={"Edit"}
            onClick={() => setConfirmationModal(true)}
            icon={"fa-light fa-edit"}
          />
        </div>
        <Input
          placeholder={"Employee Id"}
          label={"Employee Id"}
          onChange={handleChange}
          name={"employee_id"}
          error={errors.employee_id}
          defaultValue={initialState.employee_id}
        />
        <Input
          placeholder={"Employee Name"}
          label={"Employee Name"}
          onChange={handleChange}
          name="name"
          error={errors.name}
          defaultValue={initialState.name}
        />
        <Input
          placeholder={"User Id"}
          label={"User Id"}
          onChange={handleChange}
          name="username"
          error={errors.username}
          defaultValue={initialState.username}
        />
        <Input
          placeholder={"Email"}
          label={"Email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
          defaultValue={initialState.email}
        />
        <Input
          placeholder={"Derectrate/Office Name"}
          label={"Derectrate/Office Name"}
          onChange={handleChange}
          name="office_name"
          error={errors.office_name}
          defaultValue={initialState.office_name}
        />

        <Input
          placeholder={"Address"}
          label={"Address"}
          onChange={handleChange}
          name="address"
          error={errors.address}
          defaultValue={initialState.address}
        />
        <Input
          placeholder={"Contact Number"}
          label={"Contact Number"}
          onChange={handleChange}
          name="contact_number"
          error={errors.contact_number}
          intlNotRequired={true}
          defaultValue={initialState.contact_number}
        />
        <div className="addac-main--single">
          <FileInput handleSubmitImage={handleSubmitImage} />
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditAccountChief;

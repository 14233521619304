import React from "react";
import Select from "../../../UI/Select/Select";
import { useSelector } from "react-redux";

export default function Loans({ data, functions }) {
  const {
    loans,
    loanType,
    selectedLoan,
    selectedLoanType,
    selectedSource,
    editMode,
    errors,
  } = data;
  const {
    setSelectedLoanType,
    addLoan,
    setEditMode,
    setSelectedLoan,
    removeLoan,
  } = functions;

  return (
    selectedLoanType && (
      <div className="loan">
        <div className="loan-heading">
          <div className="loan-heading--title">Loan/Grant</div>
          <div className="loan-heading--action">
            <div className="loan-heading--action__option">
              <Select
                options={Object.keys(loanType[selectedSource]).map(
                  (i) => loanType[selectedSource][i]
                )}
                handler={(e) => setSelectedLoanType(e.target.value)}
                defaultValue={selectedLoanType}
              />
            </div>
            <div
              className="loan-heading--action__add"
              onClick={addLoan}
              title="Add Loan"
            >
              <i className="fa-light fa-plus"></i>
            </div>
            <div
              className="loan-heading--action__add"
              onClick={() => setEditMode((prev) => !prev)}
              title="Edit Mode"
            >
              <i className={`fa-light ${editMode ? "fa-times" : "fa-pen"}`}></i>
            </div>
          </div>
        </div>
        <div className="loan-list">
          {loans.length > 0 ? (
            loans.map((item, index) => (
              <div
                className={`loan-list--item ${
                  selectedLoan && item.key === selectedLoan.key ? "active" : ""
                } ${editMode ? "editMode" : ""} ${
                  errors[item.key] &&
                  Object.keys(errors[item.key]).findIndex(
                    (j) => typeof errors[item.key][j] === "string"
                  ) > -1
                    ? "error"
                    : ""
                }`}
                key={index}
                onClick={() => setSelectedLoan(item)}
              >
                <span className={`loan-list--item__name`}>
                  #{index + 1 + " "}
                  {item.type + " "}
                  {item.loan_number ? " - " + item.loan_number : ""}
                </span>
                <i
                  className="fa-light fa-trash-can loan-list--item__delete"
                  onClick={() => removeLoan(item.key)}
                ></i>
              </div>
            ))
          ) : (
            <p className="notfound">No Loans Found</p>
          )}
        </div>
      </div>
    )
  );
}

import { toggleSideBar } from "../../Slice/SystemSlice";

class NavController {
  constructor(dispatch, profileMenu) {
    this.dispatch = dispatch;
    this.profileMenu = false;
  }
  toggleSideBar = () => {
    this.dispatch(toggleSideBar());
  };
}

export default NavController;

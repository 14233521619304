import React from "react";

export default function Preview({ file, alt, title, clickable }) {
  return (
    <a
      href={file}
      target={clickable && "_blank"}
      className="preview"
      title={title ? title : "Click to View"}
    >
      <img src={file} alt={alt ? alt : "Image"} className="preview-img" />
    </a>
  );
}

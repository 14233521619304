import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { useSelector } from "react-redux";
import TopBar from "../../../Components/TopBar/TopBar";
import { doGet } from "../../../Services/Axios";
import StatusBar from "../../../UI/StatusBar/StatusBar";
import Table from "../../../UI/Table/Table";
import View from "../../../UI/View/View";
import { errorToast } from "../../../Utils/Toastify";
import ProjectController from "./_acproject";

const AC_Projects = () => {
  const { user } = useSelector((state) => state.System);
  const { Columns, fetchSubProject, handleFetchContractor } = ProjectController;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AC_SubProjects"],
    queryFn: () => fetchSubProject(user.user.id, "Account Chief"),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching details"),
  });

  return (
    <div className="viewproject">
      <TopBar title={"Project"} />
      <div className="viewproject-main">
        <div className="viewproject-main--table">
          <Table
            Columns={Columns}
            Rows={
              data &&
              data.sub_project_details &&
              data.sub_project_details.length > 0
                ? [
                    ...new Set(
                      data.sub_project_details.map((i) => i.parent_id + "")
                    ),
                  ].map((item, index) => {
                    const itemData = data.sub_project_details.find(
                      (i) => i.parent_id === +item
                    );
                    return (
                      itemData && {
                        sn: index + 1,
                        source: itemData.type,
                        project_name: itemData.project_name,
                        sub_projects: (
                          <View label={"Manage"} to={`/ac/projects/${item}`} />
                        ),
                        status: (
                          <StatusBar
                            status={itemData.status}
                            isProgress={true}
                            diasbled={true}
                          />
                        ),
                        created_at: itemData.created_at.split(" ")[0],
                      }
                    );
                  })
                : []
            }
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AC_Projects;

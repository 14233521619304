import { doGet, doPost, doPut } from "../../Services/Axios";
import { errorToast, successToast } from "../../Utils/Toastify";

const AdminController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "image", sortable: true, searchable: true },
    { name: "employee_id", sortable: true, searchable: true },
    { name: "name", sortable: true, searchable: true },
    { name: "email", sortable: true, searchable: true },
    { name: "office_name", sortable: true, searchable: true },
    { name: "address", sortable: true, searchable: true },
    { name: "contact_number", sortable: true, searchable: true },
    { name: "level", sortable: true, searchable: true },
    { name: "username", sortable: true, searchable: true },
    { name: "status", sortable: true, searchable: true },
    { name: "created_at", sortable: true, searchable: true },
    { name: "action", sortable: false, searchable: false },
  ],
  fetchAdmin: async (page) => {
    try {
      const { data } = await doGet("user/admin/list/?page=" + page);
      return data;
    } catch (error) {
      throw error;
    }
  },
  handleStatusUpdate: async (_id) => {
    await doPut("user/status", {
      id: _id,
    });
    return _id;
  },
};
export default AdminController;

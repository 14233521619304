import React, { useEffect, useState } from "react";
import Loading from "../Loader/Loader";

const Table = ({
  Rows,
  Columns = [{ name: "", sortable: false, searchable: false }],
  loading,
  handlePagination,
  hasNextPage,
  hasPrevPage,
  pageProp,
}) => {
  const [sortedList, setSortedList] = useState([]);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedSearchType] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const handleSetPage = handlePagination
    ? handlePagination
    : (data) => {
        if (data === "neg") {
          if (page <= 1) return;
          setPage((prev) => prev - 1);
        } else {
          if (paginatedData.length < 7) return;
          setPage((prev) => prev + 1);
        }
      };
  const handleSort = (index) => {
    if (
      Rows[0][`${Object.keys(Rows[0])[index]}`] >
      Rows[1][`${Object.keys(Rows[1])[index]}`]
    ) {
      if (isNaN(+Rows[0][`${Object.keys(Rows[0])[index]}`])) {
        setSortedList(
          Rows.sort((a, b) => {
            if (a[`${Object.keys(a)[index]}`] > b[`${Object.keys(b)[index]}`]) {
              return 1;
            } else {
              return -1;
            }
          })
        );
      } else {
        setSortedList(
          Rows.sort(
            (a, b) =>
              +a[`${Object.keys(a)[index]}`] - +b[`${Object.keys(b)[index]}`]
          )
        );
      }
    } else {
      if (isNaN(+Rows[0][`${Object.keys(Rows[0])[index]}`])) {
        setSortedList(
          Rows.sort((a, b) => {
            if (a[`${Object.keys(a)[index]}`] > b[`${Object.keys(b)[index]}`]) {
              return -1;
            } else {
              return 1;
            }
          })
        );
      } else {
        setSortedList(
          Rows.sort(
            (b, a) =>
              +a[`${Object.keys(a)[index]}`] - +b[`${Object.keys(b)[index]}`]
          )
        );
      }
    }

    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    setSortedList(Rows ?? []);
  }, [Rows]);
  useEffect(() => {
    setPaginatedData(
      handlePagination
        ? [...filteredList]
        : [...filteredList].slice((page - 1) * 10, page * 10)
    );
  }, [
    refresh,
    page,
    filteredList,
    Columns,
    search,
    selectedType,
    handlePagination,
  ]);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSetSelectedSearchType = (e) => {
    setSelectedSearchType(e.target.value);
  };
  useEffect(() => {
    setFilteredList(() =>
      sortedList.filter((el) => {
        return `${
          el[
            `${
              Object.keys(el)[
                Columns.findIndex((item) => item.name === selectedType) > -1
                  ? Columns.findIndex((item) => item.name === selectedType)
                  : 0
              ]
            }`
          ]
        }`
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    );
  }, [Columns, search, selectedType, sortedList, refresh]);

  return (
    <div className="tablewrapper">
      <div className="tablewrapper-title">
        <div className="tablewrapper-title--search__wrapper">
          {Columns.filter((item) => item.searchable === true).length > 0 && (
            <select
              onChange={(e) => handleSetSelectedSearchType(e)}
              className="tablewrapper-title--select"
            >
              {Columns.map((item, index) => {
                return (
                  item.searchable && (
                    <option
                      value={item.name}
                      className="tablewrapper-title--select__option"
                      key={index}
                    >
                      {item.name ?? ""}
                    </option>
                  )
                );
              })}
            </select>
          )}
          <div className="tablewrapper-title--search">
            <input
              className="tablewrapper-title--search__input"
              onChange={handleSearch}
              placeholder="Keywords"
            />
            <i className="tablewrapper-title--search__icon fa-light fa-search" />
          </div>
        </div>
      </div>
      <div className="responsive">
        <table className="table">
          <thead className="table-thead">
            <tr className="table-thead--tr">
              {Columns.map((item, index) => (
                <td
                  className="table-thead--tr__td"
                  key={index}
                  style={item.customStyle}
                >
                  {item.name && item.name}
                  {item.action && item.action}
                  {item.sortable && (
                    <i
                      className="table-thead--tr__td-sort fa-light fa-arrow-down-arrow-up"
                      onClick={() => handleSort(index)}
                    ></i>
                  )}
                </td>
              ))}
            </tr>
          </thead>
          {!loading ? (
            <tbody className="table-tbody">
              {paginatedData.length > 0 ? (
                paginatedData.map((item) => (
                  <tr className="table-tbody--tr" key={Math.random()}>
                    {Object.values(item).map((el) => {
                      return (
                        <td key={Math.random()} className="table-tbody--tr__td">
                          {el}
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr className="table-tbody--tr noData">
                  <td
                    colSpan={Columns.length}
                    className="table-loading-td"
                    style={{ textAlign: "left" }}
                  >
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="table-loading-td" colSpan="100%">
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className="tablewrapper-pagination">
        <div className="tablewrapper-pagination--main">
          {!handlePagination ? (
            page === 1 ? (
              <div className="tablewrapper-pagination--main__left-disabled">
                <i className="tablewrapper-pagination--main__left-icon fa-light fa-chevron-left" />
              </div>
            ) : (
              <div
                className="tablewrapper-pagination--main__left"
                onClick={() => handleSetPage("neg")}
              >
                <i className="tablewrapper-pagination--main__left-icon fa-light fa-chevron-left" />
              </div>
            )
          ) : hasPrevPage ? (
            <div
              className="tablewrapper-pagination--main__left"
              onClick={() => handleSetPage("neg")}
            >
              <i className="tablewrapper-pagination--main__left-icon fa-light fa-chevron-left" />
            </div>
          ) : (
            <div className="tablewrapper-pagination--main__left-disabled">
              <i className="tablewrapper-pagination--main__left-icon fa-light fa-chevron-left" />
            </div>
          )}
          <div className="tablewrapper-pagination--main__page">
            {handlePagination ? pageProp : page}
          </div>
          {!handlePagination ? (
            Rows.length - (page - 1) * 10 > 10 ? (
              <div
                className="tablewrapper-pagination--main__right"
                onClick={() => handleSetPage("pos")}
              >
                <i className="tablewrapper-pagination--main__right-icon fa-light fa-chevron-right" />
              </div>
            ) : (
              <div className="tablewrapper-pagination--main__right-disabled">
                <i className="tablewrapper-pagination--main__right-icon fa-light fa-chevron-right" />
              </div>
            )
          ) : hasNextPage ? (
            <div
              className="tablewrapper-pagination--main__right"
              onClick={() => handleSetPage("pos")}
            >
              <i className="tablewrapper-pagination--main__right-icon fa-light fa-chevron-right" />
            </div>
          ) : (
            <div className="tablewrapper-pagination--main__right-disabled">
              <i className="tablewrapper-pagination--main__right-icon fa-light fa-chevron-right" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;

import React, { useEffect, useState } from "react";
import TopBar from "../../../../../Components/TopBar/TopBar";
import PrimaryButton from "../../../../../UI/Button/PrimaryButton";
import ContractModal from "../../../../../Components/ContractModal/ContractModal";
import Table from "../../../../../UI/Table/Table";
import View from "../../../../../UI/View/View";
import { useLocation } from "react-router-dom";
import { doGet } from "../../../../../Services/Axios";
import { errorToast } from "../../../../../Utils/Toastify";
import { useQuery } from "react-query";
import LCModal from "../LCmodal";
import AgreementModal from "../AgreementModal";
import PerformanceModal from "../PerformanceDetails";
import DescriptionModal from "../DescriptionModal";
import StatusBar from "../../../../../UI/StatusBar/StatusBar";
import ActionButton from "../../../../../UI/Button/ActionButton";
import EditSubContractorModal from "../../../../../Components/ContractModal/SubContract/EditSubContractModal";
import SubContractorModal from "../../../../../Components/ContractModal/SubContract/SubContractModal";
import FilesModal from "../../../../../Components/FilesModal/FilesModal";

const SubContractor = () => {
  const location = useLocation();
  const queries = new URLSearchParams(location.search);
  const [showContractorModal, setContractorModal] = useState(false);
  const handleShowContractorModal = () => {
    setContractorModal((prev) => !prev);
  };

  const handleFetchContractor = async (id) => {
    try {
      const response = await doGet(`/sub-contract/${id}/shows`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const fetchContractor = useQuery({
    queryKey: [
      "subcontractor",
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ],
    enabled: true,
    queryFn: () =>
      handleFetchContractor(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      ),
    onError: () => errorToast("Error while fetching sub  contractor"),
  });

  const COLUMNS = [
    { name: "S.N.", sortable: true, searchable: true },

    { name: "status", sortable: true, searchable: false },
    { name: "type", sortable: true, searchable: true },
    { name: "contract_number", sortable: true, searchable: true },
    { name: "sub_contractor", sortable: true, searchable: true },
    { name: "is_resident", sortable: true, searchable: false },
    { name: "contractor_name", sortable: true, searchable: true },
    { name: "contractor_address", sortable: true, searchable: true },
    { name: "company_registration_type", sortable: true, searchable: true },
    { name: "company_registration_number", sortable: true, searchable: true },

    { name: "donor_currency", sortable: true, searchable: true },

    { name: "work_completion_date", sortable: true, searchable: true },
    { name: "work_completion_date_ad", sortable: true, searchable: true },

    { name: "types_of_service", sortable: true, searchable: false },
    { name: "retention_percentage", sortable: true, searchable: true },
    { name: "Agreement details", sortable: false, searchable: false },
    { name: "Performance details", sortable: false, searchable: false },

    { name: "Lc Details", sortable: false, searchable: false },
    { name: "Payment terms", sortable: false, searchable: false },
    { name: "created_at", sortable: true, searchable: true },
  ];
  const [showLcDetailsModal, setShowLcDetailsMOdal] = useState(false);
  const [showperfDetailsModal, setShowperfDetailsMOdal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showAgreementDetailsModal, setshowAgreementDetailsModal] =
    useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showEditContractModal, setShowEditContractModal] = useState(false);
  const [contract, setContract] = useState({});
  const handleShowLcDetails = (item) => {
    setContract(item);
    setShowLcDetailsMOdal((prev) => !prev);
  };
  const handleShowAgreement = (item) => {
    setContract(item);
    setshowAgreementDetailsModal((prev) => !prev);
  };
  const handleShowPerformance = (item) => {
    setContract(item);
    setShowperfDetailsMOdal((prev) => !prev);
  };
  const handleShowDescription = (item) => {
    setContract(item);
    setShowDescriptionModal((prev) => !prev);
  };

  const handleEditContractor = (item) => {
    if (item.progress_status !== 2) {
      setContract(item);
      setShowEditContractModal((prev) => !prev);
    }
  };
  const statusEnums = {
    0: { label: "Pending", color: "#f1d900" },
    1: { label: "Approved", color: "#1fb626" },
    2: { label: "Rejected", color: "#c71c1c" },
  };
  const handleShowImagess = (item) => {
    setContract(item);
    setShowImageModal((prev) => !prev);
  };
  const handleFetchContractorProp = async (id) => {
    try {
      const response = await doGet(`/contract/${id}/show`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const fetchContractorProp = useQuery({
    queryKey: [
      "contractor",
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ],
    enabled: true,
    queryFn: () =>
      handleFetchContractorProp(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      ),
    onError: () => errorToast("Error while fetching contractor"),
  });
  useEffect(() => {
    fetchContractor.refetch();
    fetchContractorProp.refetch();
  }, []);

  return (
    <div>
      {showContractorModal && (
        <SubContractorModal
          title={"Sub Contractor"}
          onClose={handleShowContractorModal}
          donorCurrency={
            fetchContractorProp &&
            fetchContractorProp.data &&
            fetchContractorProp.data.contract &&
            fetchContractorProp.data.contract.length > 0 &&
            fetchContractorProp.data.contract[0].donor_currency
          }
          project_id={queries.get("projectid")}
        />
      )}
      {showLcDetailsModal && (
        <LCModal lcdetails={contract} onClose={handleShowLcDetails} />
      )}
      {showAgreementDetailsModal && (
        <AgreementModal
          agreementdetails={contract}
          onClose={handleShowAgreement}
        />
      )}
      {showperfDetailsModal && (
        <PerformanceModal
          onClose={handleShowPerformance}
          perfDetails={contract}
        />
      )}
      {showDescriptionModal && (
        <DescriptionModal
          description={contract}
          onClose={handleShowDescription}
        />
      )}
      {showEditContractModal && (
        <EditSubContractorModal
          title={"SubContractor"}
          onClose={() => setShowEditContractModal(false)}
          donorCurrency={contract.donor_currency}
          item={contract}
          project_id={queries.get("projectid")}
        />
      )}
      {showImageModal && (
        <FilesModal
          files={
            contract &&
            contract.images.split(",").map((item) => {
              return { document: item };
            })
          }
          handleClose={() => setShowImageModal(false)}
        />
      )}
      <TopBar
        title={"Sub Contractor"}
        children={
          <PrimaryButton
            icon={"fa-light fa-plus"}
            content={"Add Sub SubContractor"}
            onClick={handleShowContractorModal}
          />
        }
        backPage={true}
      />
      <Table
        Rows={
          fetchContractor &&
          fetchContractor.data &&
          fetchContractor.data.sub_contract
            ? fetchContractor.data.sub_contract.map((item, index) => {
                return {
                  id: index + 1,
                  status:
                    item.progress_status != 2 ? (
                      <View
                        label={
                          item.progress_status == 0 ? "In progess" : "Approved"
                        }
                        type={"button"}
                        customStyle={{
                          backgroundColor:
                            statusEnums[item.progress_status].color,
                        }}
                      />
                    ) : (
                      <View
                        label={"Rejected"}
                        prefix={<i className="fa-light fa-edit"></i>}
                        onClick={() => handleEditContractor(item)}
                        type={"button"}
                        customStyle={{
                          backgroundColor:
                            statusEnums[item.progress_status].color,
                        }}
                      />
                    ),
                  type: item.type,
                  contract_number: item.contract_number,
                  sub_contractor: 1,
                  is_resident: item.is_resident ? "Resident" : "Non-resident",
                  contractor_name: item.contractor_name,
                  contractor_address: item.contractor_address,
                  company_registration_type: item.company_registration_type,
                  company_registration_number: item.company_registration_number,

                  donor_currency: item.donor_currency,
                  work_completion_date: item.work_completion_date,
                  work_completion_date_ad: item.work_completion_date_ad,

                  types_of_service: item.types_of_service,
                  retention_percentage: item.retention_percentage,
                  agreement: (
                    <View
                      label={"Agreement details"}
                      onClick={() => handleShowAgreement(item)}
                      type="button"
                    />
                  ),
                  perf: (
                    <View
                      label={"Performance details"}
                      onClick={() => handleShowPerformance(item)}
                      type="button"
                    />
                  ),
                  lc: (
                    <View
                      label={"LC details"}
                      onClick={() => handleShowLcDetails(item)}
                      type="button"
                    />
                  ),
                  terms: (
                    <View
                      label={"Payment terms"}
                      onClick={() => handleShowDescription(item)}
                      type="button"
                    />
                  ),
                  images: item.images ? (
                    <View
                      label={"Images"}
                      type="button"
                      onClick={() => handleShowImagess(item)}
                    />
                  ) : (
                    "N/A"
                  ),
                  created_at: item.created_at.split("T")[0],
                };
              })
            : []
        }
        Columns={COLUMNS}
      />
    </div>
  );
};

export default SubContractor;

import axios from "axios";
import { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { doAxios } from "../Services/Axios";

const cachedData = {};
export const useForex = () => {
  async function returnForex() {
    const data = await doAxios.get("https://www.nrb.org.np/api/forex/v1/rate");

    return data;
  }

  const { data } = useQuery("forex", returnForex, {
    staleTime: 10 * 60 * 1000,
    enabled: true,
  });

  let required = [{ currency: "npr" }, { currency: "usd" }];

  function filterForex(
    currency = "",
    value = "",
    donorCurrency = [{ currency: "" }],
    type = "sell"
  ) {
    donorCurrency.forEach((item) => required.push(item));

    if (data && data.data) {
      if (
        data &&
        data.data &&
        data.data.data &&
        data.data.data.payload.rates.length > 0
      ) {
        const filteredData = data.data.data.payload.rates.filter((el) =>
          required.some(
            (item) =>
              item.currency.toLowerCase() === el.currency.iso3.toLowerCase()
          )
        );

        let nepaliValue = 1;
        if (currency.toLowerCase() !== "npr") {
          const currencyData = data.data.data.payload.rates.find(
            (el) => el.currency.iso3.toLowerCase() === currency.toLowerCase()
          );

          if (currencyData) {
            nepaliValue = +(
              (currencyData.sell / currencyData.currency.unit) *
              value
            ).toFixed(4);
          }
        }

        let response = filteredData.map((item) => {
          return {
            currency: item.currency.iso3,
            value:
              currency.toLowerCase() === "npr"
                ? +(
                    +(
                      1 /
                      ((type === "sell" ? +item.sell : +item.buy) /
                        item.currency.unit)
                    ) * value
                  ).toFixed(5)
                : +(
                    +(
                      1 /
                      ((type === "sell" ? +item.sell : +item.buy) /
                        item.currency.unit)
                    ) * nepaliValue
                  ).toFixed(5),
          };
        });
        if (required.some((item) => item.currency.toLowerCase() === "npr")) {
          if (currency.toLowerCase() !== "npr") {
            let reso = data.data.data.payload.rates.filter(
              (item) =>
                item.currency.iso3.toLowerCase() === currency.toLowerCase()
            )[0];
            response.push({
              currency: "npr",

              value: +(
                +(
                  (type === "sell" ? +reso.sell : +reso.buy) /
                  reso.currency.unit
                ) * value
              ).toFixed(5),
            });
          } else {
            response.push({
              currency: "npr",
              value: 1 * value,
            });
          }
        }

        return response;
      }
      return [];
    } else {
      return [];
    }
  }

  async function getConversionRate(dates, list = {}, type = "sell") {
    const newDate = new Date(dates);
    const y = newDate.getFullYear();
    const m = newDate.getMonth() + 1;
    const d = newDate.getDate();
    const constructedDate = `${y}-${m < 10 ? `0${m}` : m}-${
      d < 10 ? `0${d}` : d
    }`;
    const filteredDataFromApi =
      cachedData[dates] &&
      cachedData[dates].exp - new Date() > 0 &&
      cachedData[dates].type === type
        ? { data: cachedData[dates].rates }
        : await doAxios.get(
            `https://www.nrb.org.np/api/forex/v1/rates?page=1&pages=25&per_page=100&from=${constructedDate}&to=${constructedDate}`
          );

    const expDate = new Date();

    const doesDataExist =
      filteredDataFromApi.data &&
      filteredDataFromApi.data.data &&
      filteredDataFromApi.data.data &&
      filteredDataFromApi.data.data.payload &&
      filteredDataFromApi.data.data.payload.length > 0 &&
      filteredDataFromApi.data.data.payload[0].rates;

    if (doesDataExist) {
      expDate.setDate(expDate.getDate() + 1);
      cachedData[dates] = {
        exp: expDate,
        rates: filteredDataFromApi.data,
        type: type,
      };
    }
    const filteredList = doesDataExist
      ? filteredDataFromApi.data.data.payload[0].rates
          .map((i) => list[i.currency.iso3] && i)
          .filter((i) => i !== undefined)
      : [];

    return filteredList;
  }

  async function filterForexWithDate(
    currency = "",
    value = "",
    donorCurrency = [{ currency: "" }],
    date,
    type = "sell"
  ) {
    if (!value || +value === 0) {
      const newData = donorCurrency.map((i) => ({ ...i, value: 0 }));
      newData.unshift({ currency, value: 0 });
      return newData;
    }
    if (currency === "SDR") currency = "USD";

    const newDate = new Date(date);
    const y = newDate.getFullYear();
    const m = newDate.getMonth() + 1;
    const d = newDate.getDate();
    const constructedDate = `${y}-${m < 10 ? `0${m}` : m}-${
      d < 10 ? `0${d}` : d
    }`;
    const { data } =
      cachedData[`${date}-${type}`] &&
      cachedData[`${date}-${type}`].exp - new Date() > 0
        ? { data: cachedData[`${date}-${type}`].rates }
        : await doAxios.get(
            `https://www.nrb.org.np/api/forex/v1/rates?page=1&pages=25&per_page=100&from=${constructedDate}&to=${constructedDate}`
          );

    donorCurrency.forEach((item) => required.push(item));
    if (data && data.data) {
      if (
        data &&
        data.data &&
        data.data.payload &&
        data.data.payload.length > 0 &&
        data.data.payload[0] &&
        data.data.payload[0].rates &&
        data.data.payload[0].rates.length > 0
      ) {
        const expDate = new Date();
        expDate.setDate(expDate.getDate() + 1);
        cachedData[`${date}-${type}`] = {
          exp: expDate,
          rates: data,
        };

        const filteredData = data.data.payload[0].rates.filter((el) =>
          required.some(
            (item) =>
              item.currency.toLowerCase() === el.currency.iso3.toLowerCase()
          )
        );
        let nepaliValue = 1;
        if (currency.toLowerCase() !== "npr") {
          const currencyData = data.data.payload[0].rates.find(
            (el) => el.currency.iso3.toLowerCase() === currency.toLowerCase()
          );

          if (currencyData) {
            nepaliValue = +(
              ((type === "sell" ? currencyData.sell : currencyData.buy) /
                currencyData.currency.unit) *
              value
            ).toFixed(4);
          }
        }

        let response = filteredData.map((item) => {
          return {
            currency: item.currency.iso3,
            value:
              currency.toLowerCase() === "npr"
                ? +(
                    +(
                      1 /
                      ((type === "sell" ? +item.sell : +item.buy) /
                        item.currency.unit)
                    ) * value
                  ).toFixed(5)
                : +(
                    +(
                      1 /
                      ((type === "sell" ? +item.sell : +item.buy) /
                        item.currency.unit)
                    ) * nepaliValue
                  ).toFixed(5),
            conversionrate:
              (type === "sell" ? +item.sell : +item.buy) / item.currency.unit,
          };
        });
        if (required.some((item) => item.currency.toLowerCase() === "npr")) {
          if (currency.toLowerCase() !== "npr") {
            let reso = data.data.payload[0].rates.filter(
              (item) =>
                item.currency.iso3.toLowerCase() === currency.toLowerCase()
            )[0];
            response.push({
              currency: "npr",
              value: +(
                +(
                  (type === "sell" ? +reso.sell : +reso.buy) /
                  reso.currency.unit
                ) * value
              ).toFixed(5),
              conversionrate:
                +(type === "sell" ? +reso.sell : +reso.buy) /
                reso.currency.unit,
            });
          } else {
            response.push({
              currency: "npr",
              value: 1 * value,
              conversionrate: 1,
            });
          }
        }
        return response;
      }
      return [];
    } else {
      return [];
    }
  }

  return { filterForex, getConversionRate, filterForexWithDate };
};
export default useForex;

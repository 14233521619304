import { doGet } from "../../../Services/Axios";

const SubProjectController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "sub_project_name", sortable: true, searchable: true },
    { name: "pre_information", sortable: false, searchable: false },
    {
      name: "Contractor",
      sortable: false,
      searchable: false,
    },
    {
      name: "Budget",
      sortable: false,
      searchable: false,
    },
    {
      name: "Billing",
      sortable: false,
      searchable: false,
    },
    { name: "status", sortable: false, searchable: false },
    { name: "created_at", sortable: false, searchable: false },
    { name: "actions", sortable: false, searchable: false },
  ],
  fetchSubProject: async (user_id, type) => {
    try {
      const { data } = await doGet(
        `/sub-project/user/assigned/list/${user_id}/${type}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  statusEnums: {
    0: { label: "Fill Now", color: "#006699" },
    1: { label: "In Progress", color: "#f1d900" },
    2: { label: "Approved", color: "#1fb626" },
    3: { label: "Rejected", color: "#c71c1c" },
  },
};
export default SubProjectController;

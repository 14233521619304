import "./Assets/Css/main.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RouteConfig } from "./Services/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
function App() {
  const { user } = useSelector((state) => state.System);
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          {RouteConfig.map(({ path, component, permission }, index) =>
            !permission ? (
              <Route path={path} element={component} key={index} />
            ) : user &&
              user.user &&
              user.user.user_type &&
              permission[user.user.user_type] ? (
              <Route path={path} element={component} key={index} />
            ) : null
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

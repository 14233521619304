import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage } from "../../Slice/SystemSlice";

const ModalLayout = ({
  title,
  onClose,
  children,
  titleStyle,
  isSpecific,
  customStyle,
  isLanguageChangeable,
}) => {
  const dispatch = useDispatch();
  const handleClick = (e) => {
    if (
      e.target.className !== "modal-title--cross__icon fa-light fa-xmark" &&
      e.target.className !== "modal-background" &&
      e.target.className !== "modal-title--cross"
    ) {
      return;
    }
    e.stopPropagation();
    onClose();
  };
  const { user, language } = useSelector((state) => state.System);
  const handleToggleLanguage = (item) => {
    if (item === "en") {
      dispatch(toggleLanguage("en"));
    } else {
      dispatch(toggleLanguage("np"));
    }
  };
  return (
    <div className="modal-background" style={customStyle}>
      <div className="modal">
        <div
          className={`modal-title ${isSpecific ? "specific" : "generic"}`}
          style={titleStyle}
        >
          <div className="modal-title--main">{title}</div>
          {onClose && (
            <div className="modal-title--main__handler">
              {isLanguageChangeable && (
                <div className="nav-action--language">
                  <button
                    className={`nav-action--language__en ${
                      language === "en" ? "language-selected" : ""
                    }`}
                    onClick={() => handleToggleLanguage("en")}
                  >
                    EN
                  </button>
                  <button
                    className={`nav-action--language__np ${
                      language === "np" ? "language-selected" : ""
                    }`}
                    onClick={() => handleToggleLanguage("np")}
                  >
                    NP
                  </button>
                </div>
              )}

              <div className="modal-title--cross" onClick={handleClick}>
                <i className="modal-title--cross__icon fa-light fa-xmark" />
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default ModalLayout;

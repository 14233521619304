import React, { useEffect, useRef, useState } from "react";
import { errorToast } from "../../../../Utils/Toastify";
import { doGet } from "../../../../Services/Axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";

import LedgerController from "./_ledgerController";
const { BILL_PREFIX } = LedgerController;

const ContractLedger = ({ selectedProject }) => {
  const ref = useRef();
  const [details, setDetails] = useState(null);
  const fetchReport = async (id) => {
    try {
      const { data } = await doGet(
        `/reports/ctr_loan_ledger/${id}/contract_ledger`
      );
      data &&
        data.contract_ledger &&
        data.contract_ledger.length > 0 &&
        setDetails(data.contract_ledger);
    } catch (error) {
      errorToast(error.message);
    }
  };
  useEffect(() => {
    setDetails(null);
    selectedProject && fetchReport(selectedProject.id);
  }, [selectedProject && selectedProject.id]);

  return details && selectedProject ? (
    <>
      <DownloadTableExcel
        currentTableRef={ref.current}
        filename={`ledger_${Date.now()}`}
        sheet="Ledger"
      >
        <PrimaryButton
          content={"Export"}
          title="Ledger"
          icon={"fa-light fa-file-spreadsheet"}
          className={"ledger-heading--actions__export"}
        />
      </DownloadTableExcel>
      <table width={100} border={1} className="ledger-wrapper--table" ref={ref}>
        <thead>
          <tr>
            <th className="center" colSpan={7}>
              <h1>नेपाल विद्युत प्राधिकरण</h1>
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={7}>
              अर्थ निर्देशनालय
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={7}>
              संस्थागत वित्तिय विभाग
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={7}>
              वित्तिय योजना तथा लगानी महाशाखा
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={7}>
              Contract Ledger -{" "}
              {selectedProject &&
                `${selectedProject.name} - Office Code (${selectedProject.office_code})`}
              {selectedProject &&
                selectedProject.loan_repayment_effective_date &&
                selectedProject.sub_status === 3 &&
                `(Close Date: ${selectedProject.loan_repayment_effective_date})`}
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>Particulars</th>
            <th rowSpan={2}>Advice no</th>

            <th colSpan={2}>Value Date</th>
            <th colSpan={3}>Disbursement </th>
          </tr>
          <tr>
            <th>AD</th>
            <th>BS</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
          </tr>
        </thead>
        <tbody>
          {details.length > 0 ? (
            details.map((item, index) => {
              const billType = BILL_PREFIX[item.transaction_type];
              return (
                <tr key={index}>
                  <td>{item.particular}</td>
                  <td>{item[billType.type]}</td>
                  <td>{item.disbursed_date_ad}</td>
                  <td>{item.disbursed_date}</td>
                  <td>{item[`${billType.amt}amount_donor`]}</td>
                  <td>{item[`${billType.amt}amount_usd`]}</td>
                  <td>{item[`${billType.amt}amount_npr`]}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={"100%"}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  ) : (
    <p className="notfound notfound-bordered">No Data Found</p>
  );
};

export default ContractLedger;

import React from "react";
import LoginScreen from "../Pages/Login/Login";
import { useSelector } from "react-redux";

const Auth = ({ children }) => {
  const { user } = useSelector((state) => state.System);

  if (user) {
    return children;
  } else {
    return <LoginScreen />;
  }
};

export default Auth;

import React from "react";
import { Link } from "react-router-dom";

export default function View({
  label,
  prefix,
  to,
  onClick,
  type,
  customStyle,
}) {
  return type === "button" ? (
    <div
      className="view"
      title={to ? "Click To View More" : ""}
      onClick={onClick}
      style={customStyle}
    >
      {prefix !== undefined && prefix !== null ? (
        <span className="view-prefix">{prefix ? prefix : ""}</span>
      ) : (
        ""
      )}
      {label}
    </div>
  ) : (
    <Link
      to={to ? to : null}
      className="view"
      title={to ? "Click To View More" : ""}
      style={customStyle}
    >
      {prefix !== undefined && prefix !== null ? prefix + " " : ""}
      {label}
    </Link>
  );
}

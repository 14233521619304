import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { errorToast } from "../../../Utils/Toastify";

export const useSubContractorForm = (
  initialStates,
  filterForexWithDate,
  donorCurrency
) => {
  const [states, setStates] = useState(initialStates);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validate = (fieldValues = states) => {
    const {
      type,
      company_registration_type,

      contract_number,
      contractor_name,
      contractor_address,
      company_registration_number,
      agreement_amount_npr,
      retention_percentage,
      performance_security_amount,
      performance_security_validity_period,
      performance_security_guarantee_number,
      performance_security_bank_name,
      lc_amount_npr,
      lc_issuer_bank,
      lc_number,
      lc_duration,
      lc_date,
      is_resident,
      types_of_service,
      payment_terms,
      agreement_date,
      work_completion_date,
      sdr_conversion_rate,
    } = fieldValues;
    let temp = { ...errors };
    if ("type" in fieldValues) {
      temp.type = type ? "" : "Type is required";
    }
    if ("company_registration_type" in fieldValues) {
      temp.company_registration_type = company_registration_type
        ? ""
        : "Registration type is required";
    }

    if ("agreement_date" in fieldValues) {
      temp.agreement_date = agreement_date ? "" : "Agreement date in required";
    }
    if ("types_of_service" in fieldValues) {
      temp.types_of_service = types_of_service
        ? ""
        : "Service type is required";
    }

    if ("is_resident" in fieldValues) {
      temp.is_resident = is_resident ? "" : "Resident type is required";
    }

    if ("contract_number" in fieldValues) {
      temp.contract_number = contract_number
        ? ""
        : "Contract number is required!";
    }
    if ("contractor_name" in fieldValues) {
      temp.contractor_name = contractor_name
        ? ""
        : "Contractor Name is required!";
    }

    if ("contractor_address" in fieldValues) {
      temp.contractor_address = contractor_address
        ? ""
        : "Contractor Address is required!";
    }
    if ("company_registration_number" in fieldValues) {
      temp.company_registration_number = company_registration_number
        ? ""
        : "Registration number is  required!";
    }
    if ("agreement_amount_npr" in fieldValues) {
      if (isNaN(+agreement_amount_npr) || agreement_amount_npr === "") {
        temp.agreement_amount = "Agreement amount is invalid";
        temp.agreement_amount_usd = "Agreement amount is invalid";
        temp.agreement_amount_npr = "Agreement amount is invalid";

        setStates((prev) => ({
          ...prev,
          agreement_amount: "",
          agreement_amount_usd: "",
        }));
      } else {
        temp.agreement_amount = "";
        temp.agreement_amount_usd = "";
      }
      temp.agreement_amount_npr =
        (agreement_amount_npr ? "" : "Agreement amount is required!") ||
        (isNaN(+agreement_amount_npr) ? "Agreement amount is invalid" : "");
      temp.agreement_amount =
        (agreement_amount_npr ? "" : "Agreement amount is required!") ||
        (isNaN(+agreement_amount_npr) ? "Agreement amount is invalid" : "");
      temp.agreement_amount_usd =
        (agreement_amount_npr ? "" : "Agreement amount is required!") ||
        (isNaN(+agreement_amount_npr) ? "Agreement amount is invalid" : "");
    }
    if ("work_completion_date" in fieldValues) {
      temp.work_completion_date = work_completion_date
        ? ""
        : "Work Completion date in required";
    }
    if ("retention_percentage" in fieldValues) {
      temp.retention_percentage =
        (retention_percentage ? "" : "Retention Percentage in required") ||
        (retention_percentage < 0 || retention_percentage > 100
          ? "Retention percentage is invalid"
          : "") ||
        (isNaN(+retention_percentage) ? "Retention percentage is invalid" : "");
    }

    if ("performance_security_amount" in fieldValues) {
      temp.performance_security_amount =
        (performance_security_amount
          ? ""
          : "Performance security amount is required!") ||
        (isNaN(+performance_security_amount)
          ? "Performance security amount is invalid"
          : "");
    }
    if ("performance_security_validity_period" in fieldValues) {
      temp.performance_security_validity_period =
        performance_security_validity_period
          ? ""
          : "Performance security validity period is required!";
    }
    if ("performance_security_guarantee_number" in fieldValues) {
      temp.performance_security_guarantee_number =
        performance_security_guarantee_number
          ? ""
          : "Performance security guarentee number is required!";
    }

    if ("performance_security_bank_name" in fieldValues) {
      temp.performance_security_bank_name = performance_security_bank_name
        ? ""
        : "Performance security bank name is required!";
    }
    if ("lc_number" in fieldValues) {
      temp.lc_number =
        (lc_number ? "" : "Lc number is required!") ||
        (!isNaN(+lc_number) ? "" : "Lc number is invalid!");
    }
    if ("lc_amount_npr" in fieldValues) {
      if (isNaN(+lc_amount_npr) || lc_amount_npr === "") {
        temp.lc_amount_usd = "Agreement amount is invalid";
        temp.lc_amount = "Agreement amount is invalid";
        temp.lc_amount_npr = "Agreement amount is invalid";
        setStates((prev) => ({
          ...prev,

          lc_amount: "",
          lc_amount_usd: "",
        }));
      } else {
        temp.lc_amount = "";
        temp.lc_amount_usd = "";
      }
      temp.lc_amount =
        (lc_amount_npr ? "" : "Lc amount is required!") ||
        (!isNaN(+lc_amount_npr) ? "" : "Lc amount is invalid!") ||
        (donorCurrency ? "" : "Please choose donor currency");
    }
    if ("lc_duration" in fieldValues) {
      temp.lc_duration = lc_duration ? "" : "Lc duration is required!";
    }
    if ("lc_issuer_bank" in fieldValues) {
      temp.lc_issuer_bank = lc_issuer_bank
        ? ""
        : "Lc issuer bank name is required!";
    }
    if ("lc_date" in fieldValues) {
      temp.lc_date = lc_date ? "" : "Lc date is required!";
    }
    if ("payment_terms" in fieldValues) {
      temp.payment_terms = payment_terms ? "" : "Payment terms is required";
    }

    if ("sdr_conversion_rate" in fieldValues) {
      temp.sdr_conversion_rate =
        states.donor_currency !== "SDR"
          ? ""
          : sdr_conversion_rate
          ? ""
          : "Estmate cost is required";
    }
    setErrors({ ...temp });
    if (fieldValues === states)
      return Object.values(temp).every((item) => item === "");
  };
  console.log(states);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "loan_number") {
      setStates((prev) => ({
        ...prev,
        donor_currency: value.split("-")[value.split("-").length - 1],
      }));
    } else if (name === "agreement_date") {
      if (donorCurrency) {
        states.agreement_amount_npr &&
          donorCurrency &&
          !isNaN(+donorCurrency) &&
          handleCurrency(
            {
              target: {
                name: "agreement_amount_npr",
                value: states.agreement_amount_npr,
              },
            },
            +donorCurrency
          );
        states.performance_security_amount_npr &&
          donorCurrency &&
          !isNaN(+donorCurrency) &&
          handleCurrency(
            {
              target: {
                name: "performance_security_amount_npr",
                value: states.performance_security_amount_npr,
              },
            },
            +donorCurrency
          );
        states.lc_amount_npr &&
          donorCurrency &&
          !isNaN(+donorCurrency) &&
          handleCurrency(
            {
              target: {
                name: "lc_amount_npr",
                value: states.lc_amount_npr,
              },
            },
            +donorCurrency
          );
      } else {
        states.agreement_amount_npr &&
          handleCurrency({
            target: {
              name: "agreement_amount_npr",
              value: states.agreement_amount_npr,
            },
          });
        states.performance_security_amount_npr &&
          handleCurrency({
            target: {
              name: "performance_security_amount_npr",
              value: states.performance_security_amount_npr,
            },
          });
        states.lc_amount_npr &&
          handleCurrency({
            target: {
              name: "lc_amount_npr",
              value: states.lc_amount_npr,
            },
          });
      }
      setStates((prev) => ({ ...prev, [name]: value }));
    } else {
      setStates((prev) => ({ ...prev, [name]: value }));
    }

    validate({ [name]: value });
    setTouched({ ...touched, [name]: true });
  };
  const handleCurrency = async (e, sdr_conversion_rate) => {
    if (!donorCurrency) {
      setStates((prev) => ({
        ...prev,
        [e.target.name]: "",
        [`${e.target.name}`.replace("_npr", "")]: "",
        [`${e.target.name}`.replace("_npr", "_usd")]: "",
      }));
      return errorToast("Currency must be set");
    }
    if (!states.agreement_date) {
      setStates((prev) => ({
        ...prev,
        [e.target.name]: "",
        [`${e.target.name}`.replace("_npr", "")]: "",
        [`${e.target.name}`.replace("_npr", "_usd")]: "",
      }));
      return errorToast("Agreement date must be set");
    }
    if (isNaN(+e.target.value) || +e.target.value < 0) {
      setStates((prev) => (delete prev[`${e.target.name}_usd`], prev));
      return;
    }
    const convertedData = await filterForexWithDate(
      "npr",
      +e.target.value,
      [{ currency: donorCurrency }],
      states.agreement_date_ad
    );
    if (!convertedData || !convertedData[0]) return;

    let usd_value =
      convertedData.filter((item) => item.currency.toLowerCase() === "usd")
        .length > 0
        ? convertedData.filter(
            (item) => item.currency.toLowerCase() === "usd"
          )[0].value
        : 0;
    console.log(
      donorCurrency,
      sdr_conversion_rate,
      states.sdr_conversion_rate,
      !isNaN(donorCurrency)
    );
    setStates((prev) => ({
      ...prev,
      [`${e.target.name}`.replace("_npr", "")]:
        donorCurrency !== "SDR"
          ? convertedData.filter(
              (item) =>
                item.currency.toLowerCase() === donorCurrency.toLowerCase()
            ).length > 0
            ? convertedData.filter(
                (item) =>
                  item.currency.toLowerCase() === donorCurrency.toLowerCase()
              )[0].value
            : 0
          : !sdr_conversion_rate
          ? !isNaN(+states.sdr_conversion_rate)
            ? usd_value * +states.sdr_conversion_rate
            : usd_value * 1
          : !isNaN(+sdr_conversion_rate)
          ? usd_value * sdr_conversion_rate
          : usd_value * 1,
      [`${e.target.name}`.replace("_npr", "_usd")]: usd_value,
    }));
    setErrors((prev) => ({
      ...prev,
      [e.target.name]: "",
      [`${e.target.name}`.replace("_npr", "")]: "",
      [`${e.target.name}`.replace("_npr", "_usd")]: "",
    }));
  };

  console.log(states);
  return {
    states,
    handleChange,
    errors,
    touched,
    validate,
    handleCurrency,
  };
};

import { doGet } from "../../../Services/Axios";

const ProjectController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "source", sortable: true, searchable: true },
    { name: "project_name", sortable: true, searchable: true },
    { name: "loans/grants", sortable: true, searchable: true },
    { name: "sub_projects", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "created_by", sortable: true, searchable: true },
    { name: "created_at", sortable: true, searchable: false },
  ],
  fetchProject: async (pageParam) => {
    try {
      const { data } = await doGet(`/project/list?page=${pageParam}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default ProjectController;

import React, { useEffect, useRef, useState } from "react";

import keyMapping from "./KeyMapping";
import NepaliInput from "react-nepali-input";
const Input = ({
  customStyle,
  type,
  placeholder,
  onChange,
  onBlur,
  error,
  required,
  readOnly,

  defaultValue,

  label,
  name,
  onFocus,
  disabled,
  isLanguageChangeable,
  language,
  intlNotRequired,
}) => {
  const [value, setValue] = useState(
    defaultValue !== null && defaultValue !== undefined ? defaultValue : ""
  );

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValue(
      defaultValue !== null && defaultValue !== undefined ? defaultValue : ""
    );
  }, [defaultValue]);

  const handleInput = (e) => {
    // if (isNaN(+e.target.value) && isNaN(+e.target.value.replaceAll(",", ""))) {
    setValue(e.target.value);
    onChange(e);
    // } else {
    //   setValue(e.target.value.replaceAll(",", ""));
    //   onChange({
    //     target: {
    //       name: name,
    //       value: e.target.value.replaceAll(",", ""),
    //     },
    //   });
    // }
  };
  const handleBlur = (e) => {
    console.log(e);
    if (
      isNaN(+e.target.value) &&
      e.target.value &&
      isNaN(+e.target.value.replaceAll(",", ""))
    ) {
      e.target.value && onBlur && onBlur(e);
    } else {
      e.target.value &&
        onBlur &&
        onBlur({
          target: {
            name: name,
            value: e.target.value.replaceAll(",", ""),
          },
        });
    }
  };
  useEffect(() => {
    // !!language && setValue("");
  }, [language]);

  return (
    <div style={customStyle} className="input">
      <label htmlFor={name} className="input-label">
        {label && `${label}`}{" "}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      {language === "np" && isLanguageChangeable ? (
        <NepaliInput
          name={name}
          id={name}
          className={
            type !== "radio"
              ? !error
                ? "input-input"
                : "input-input input-error"
              : "radio-radio"
          }
          placeholder={placeholder}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          setValue={(value) => {
            handleInput({
              target: {
                name: name,
                value: value,
              },
            });
          }}
          required={required ? true : false}
          readOnly={readOnly ? true : false}
          value={value ? value : ""}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      ) : (
        <input
          name={name}
          id={name}
          className={
            type !== "radio"
              ? !error
                ? "input-input"
                : "input-input input-error"
              : "radio-radio"
          }
          placeholder={placeholder}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          onChange={handleInput}
          required={required ? true : false}
          readOnly={readOnly ? true : false}
          value={
            // value != null && value !== ""
            //   ? intlNotRequired
            //     ? value
            //     : !isNaN(+value) || !isNaN(+`${value}`.replaceAll(",", ""))
            //     ? new Intl.NumberFormat("en-US").format(
            //         +`${value}`.replaceAll(",", "")
            //       )
            //     : value
            //   : ""
            value
          }
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
      )}
      {type === "password" && (
        <i
          className={
            showPassword
              ? "input-input--eye fa-regular fa-eye"
              : "input-input--eye fa-regular fa-eye-slash"
          }
          onClick={() => setShowPassword((prev) => !prev)}
        />
      )}
      <div className="input-error--text">{error ? error : ""}</div>
    </div>
  );
};

export default Input;

import React from "react";
import ModalLayout from "../../UI/Modal/ModalLayout";
import Table from "../../UI/Table/Table";

export default function ViewApg({ onClose, list }) {
  const Columns = [
    { name: "id", searchable: true, sortable: true },
    { name: "budget_id", searchable: true, sortable: true },
    { name: "bank_name", searchable: true, sortable: true },
    { name: "advance_guarantee_no", searchable: true, sortable: true },
    { name: "validity_period", searchable: true, sortable: true },
    { name: "advance_amount", searchable: false, sortable: false },
    { name: "advance_release_date", searchable: false, sortable: false },

    { name: "created_at", searchable: false, sortable: false },
  ];

  return (
    <ModalLayout title={"Advance payment Guarentee"} onClose={onClose}>
      <div className="viewbillcustomduty">
        <Table
          Columns={Columns}
          Rows={
            list && list.length > 0
              ? list.map((item, index) => ({
                  sn: index + 1,
                  budget_id: item.budget_id,
                  bank_name: item.bank_name,
                  advance_guarantee_no: item.advance_guarantee_no,
                  validity_period: item.validity_period,

                  advance_release_date: (
                    <>
                      <strong>Date:</strong>
                      {item.advance_release_date} <br />
                      <strong>In Ad: </strong>
                      {item.advance_release_date_ad} <br />
                    </>
                  ),

                  advance_amount: (
                    <>
                      <strong>Amount:</strong>
                      {item.advance_amount} <br />
                      <strong>In NPR: </strong>
                      {item.advance_amount_npr} <br />
                      <strong>In USD: </strong>
                      {item.advance_amount_usd}
                    </>
                  ),
                  createdAt: item.created_at.split("T")[0],
                }))
              : []
          }
          loading={false}
        />
      </div>
    </ModalLayout>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { doGet } from "../../../Services/Axios";
import { errorToast } from "../../../Utils/Toastify";
import { useQuery } from "react-query";
import Input from "../../../UI/Input/Input";
import useComponentVisible from "./useVisible";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../UI/Button/PrimaryButton";
const DonorInfo = () => {
  const tableRef = useRef();

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedResponseProject, setSelectedResponseProject] = useState(null);
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [fiteredProjects, setFilteredProjects] = useState([]);
  const fetchProject = async (pageParam) => {
    try {
      const { data } = await doGet(`/project/all-list`);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllProjects"],
    queryFn: () => fetchProject(),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching projects"),
  });
  const fetchReport = async (id) => {
    try {
      const loanInfo = doGet(`reports/${id}/donor_pre_loan_info`);
      const grantInfo = doGet(`reports/${id}/donor_pre_grant_info`);
      const response = await Promise.all([loanInfo, grantInfo]);
      setSelectedResponseProject([
        ...response[0].data.donor_pre_loan,
        ...response[1].data.donor_pre_grant,
      ]);
    } catch (error) {}
  };
  const handleSelect = async (item) => {
    try {
      fetchReport(item.id);
      setSelectedProject(item);
      setFilterTerm(item.name);
      setIsComponentVisible(false);
    } catch (error) {}
  };
  const handleFilter = (e) => {
    setFilterTerm(e.target.value);
  };
  useEffect(() => {
    setFilteredProjects(
      data &&
        data.projects &&
        data.projects.filter((el) =>
          el.name.toLowerCase().includes(filterTerm.toLowerCase())
        )
    );
    selectedProject &&
      selectedProject.name !== filterTerm &&
      setIsComponentVisible(true);
  }, [data, filterTerm]);

  console.log(selectedProject, selectedResponseProject);
  return (
    <>
      <div className="donorinfo">
        <div className="donorinfo-heading">
          <div className="donorinfo-heading--title">
            Donor's Info {selectedProject ? `- ${selectedProject.name}` : null}
          </div>
          <div className="donorinfo-heading--actions">
            <div className="donorinfo-heading--actions__wrapper" ref={ref}>
              <div>
                <Input
                  className="donorinfo-heading--actions__input"
                  placeholder={"Eg. Khulekhani"}
                  onChange={handleFilter}
                  defaultValue={filterTerm}
                  onFocus={() => {
                    setIsComponentVisible(true);

                    setFilteredProjects(
                      data && data.projects
                        ? data.projects.filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(filterTerm.toLowerCase())
                          )
                        : []
                    );
                  }}
                  customStyle={{ all: "unset", border: "none" }}
                />
              </div>
              {fiteredProjects &&
                fiteredProjects.length > 0 &&
                isComponentVisible && (
                  <div className="donorinfo-heading--actions__dropdown">
                    {fiteredProjects.map((el, index) => (
                      <div
                        className="donorinfo-heading--actions__dropdown-item"
                        key={index}
                        onClick={() => handleSelect(el)}
                      >
                        {el.name}
                      </div>
                    ))}
                  </div>
                )}
            </div>
            <DownloadTableExcel
              currentTableRef={tableRef.current}
              filename={`DonorInfo_${Date.now()}`}
              sheet="Donor's info"
            >
              <PrimaryButton
                content={"Export"}
                title="Donor Info"
                icon={"fa-light fa-file-spreadsheet"}
                className={"donorinfo-heading--actions__export"}
              />
            </DownloadTableExcel>
          </div>
        </div>

        <div className="donorinfo-wrapper">
          <table
            width={100}
            className="donorinfo-wrapper--table"
            ref={tableRef}
            border={1}
          >
            <thead>
              <tr>
                <td rowSpan={2}>sn</td>
                <td rowSpan={2}>main project name</td>
                <td rowSpan={2}>subproject name</td>
                <td rowSpan={2}>loan no</td>
                <td rowSpan={2}>grant no</td>
                <td rowSpan={2}>donor name</td>
                <td colSpan={3}>currency buying rate of nrb</td>
                <td colSpan={3}>amount</td>
                <td colSpan={2}>interest rate</td>
                <td colSpan={6}>sla valid date in ad & grace period</td>
                <td colSpan={2}>idc</td>
                <td colSpan={3}>idc adjustment</td>
                <td colSpan={2}>forex gain/loss</td>
                <td colSpan={2}>forex gain loss adjustment</td>
              </tr>
              <tr>
                <td>donor currency</td>
                <td>usd</td>
                <td>npr</td>
                <td>donor currency</td>
                <td>usd</td>
                <td>npr</td>
                <td>% for grace period</td>
                <td>% for installment period</td>
                <td>grace period starting date</td>
                <td>grace period ending date</td>
                <td>installment starting date</td>
                <td>installment ending date</td>
                <td>revised installment ending date</td>
                <td>installment type quarterly/half yearly/yearly</td>
                <td>gon</td>
                <td>nea</td>
                <td>addition to loan</td>
                <td>trf to cwip</td>
                <td>payable to gon</td>
                <td>gon</td>
                <td>nea</td>
                <td>addition to loan</td>
                <td>trf to pl</td>
              </tr>
            </thead>
            <tbody>
              {selectedProject &&
              selectedResponseProject &&
              selectedResponseProject.length > 0 ? (
                selectedResponseProject.map((item, index) => (
                  <tr key={index}>
                    <td>{index === 0 ? index + 1 : ""}</td>
                    <td>{index === 0 ? item.project_name : ""}</td>
                    <td>{item.sub_project_name}</td>
                    <td>
                      {item.type === "Donor Loan" || item.type === "GoN Loan"
                        ? item.loan_number
                        : ""}
                    </td>
                    <td>
                      {item.type == "Share" || item.type == "Grant Share"
                        ? item.loan_number
                        : ""}
                    </td>
                    <td>{item.donor_agency}</td>
                    <td>{item.currency_rate}</td>
                    <td>{item.currency_rate_usd}</td>
                    <td>{item.currency_rate_npr}</td>
                    <td>{item.loan_amount}</td>
                    <td>{item.loan_amount_usd}</td>
                    <td>{item.loan_amount_npr}</td>
                    <td>{item.grace_period_interest_rate}</td>
                    <td>{item.interest_rate_after_grace_period}</td>
                    <td>{item.grace_period_start_date}</td>
                    <td>{item.grace_period_end_date}</td>
                    <td>{item.installment_start_date}</td>
                    <td>{item.installment_end_date}</td>
                    <td></td>
                    <td>{item.installment_period}</td>
                    <td>{item.idc_gon_portion}</td>
                    <td>{item.idc_nea_portion}</td>
                    <td>{item.idc_transfer_to_loan}</td>
                    <td>{item.idc_transfer_to_cwip}</td>
                    <td>{item.idc_payable_to_gon}</td>
                    <td>
                      {item.forex_risk_bearer &&
                      item.forex_risk_bearer === "GON"
                        ? "✔"
                        : ""}
                    </td>
                    <td>
                      {item.forex_risk_bearer &&
                      item.forex_risk_bearer === "NEA"
                        ? "✔"
                        : ""}
                    </td>
                    <td>
                      {item.forex_risk_bearer &&
                      item.forex_risk_bearer === "NEA" &&
                      item.forex_risk_bearer_type ===
                        "Additive/Deductive to Loan"
                        ? "✔"
                        : ""}
                    </td>
                    <td>
                      {item.forex_risk_bearer &&
                      item.forex_risk_bearer === "NEA" &&
                      item.forex_risk_bearer_type ===
                        "Additive/Deductive to P/L Account"
                        ? "✔"
                        : ""}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={"100%"}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default DonorInfo;

import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import FilesModal from "../../../../Components/FilesModal/FilesModal";
import TopBar from "../../../../Components/TopBar/TopBar";
import Table from "../../../../UI/Table/Table";
import View from "../../../../UI/View/View";
import { errorToast, warningToast } from "../../../../Utils/Toastify";
import VerifyModal from "./VerifyModal";
import ViewCustomDuty from "./ViewCustomDuty/ViewCustomDuty";
import BillingController from "./_billing";
import BillDetails from "../../../../Components/Billdetails/BillDetails";

export default function PM_Billing() {
  const params = useParams();
  const navigate = useNavigate();
  const projectid = params.id;
  useLayoutEffect(() => {
    if (!projectid || isNaN(+projectid)) return navigate(-1);
  }, [projectid]);

  const { Columns, fetchSubProjectBill, statusEnums } = BillingController;
  const [showCustomDuty, setShowCustomDuty] = useState(false);
  const [showBillVerify, setShowBillVerify] = useState(null);
  const [selectedBill, setSelectedBill] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [showBillDetails, setShowBillDetails] = useState(false);

  const [billType, setBillType] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["BillInformation", projectid],
    queryFn: () => fetchSubProjectBill(projectid),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching bills"),
  });

  const handleCloseCustomDuty = () => {
    setSelectedBill(null);
    setShowCustomDuty(false);
  };
  const handleShowDocuments = (id) => {
    data && data.images && data.images.length > 0
      ? setDocuments(data.images.filter((i) => i.document_type_id === id))
      : warningToast("No documents found");
  };
  const handleCloseDocuments = () => {
    setDocuments(null);
  };
  const handleShowBillVerify = (id) => {
    setShowBillVerify(id);
  };
  const handleShowBillDetails = (item) => {
    setSelectedBill(item);
    setBillType(item.type);
    setShowBillDetails(true);
  };

  const handleCloseBillDetails = () => {
    setSelectedBill(null);
    setShowBillDetails(false);
  };

  return (
    projectid && (
      <div className="viewbilling">
        {showBillDetails && selectedBill && (
          <BillDetails
            onClose={handleCloseBillDetails}
            item={selectedBill}
            type={billType}
          />
        )}
        {documents && (
          <FilesModal
            files={documents.map((item) => {
              return item;
            })}
            handleClose={handleCloseDocuments}
          />
        )}
        {showBillVerify && (
          <VerifyModal
            onClose={() => setShowBillVerify(null)}
            billid={showBillVerify}
            projectid={projectid}
          />
        )}
        <TopBar title={"Billing"} subtitle={"Billing"} backPage={true} />
        <div className="viewbilling-main">
          <div className="viewbilling-main--table">
            <Table
              Columns={Columns}
              Rows={
                data && data.billsInfos && data.billsInfos.length > 0
                  ? data.billsInfos.map((item, index) => ({
                      sn: index + 1,
                      staus: (
                        <View
                          label={statusEnums[item.progress_status].label}
                          customStyle={{
                            backgroundColor:
                              statusEnums[item.progress_status].color,
                          }}
                          type="button"
                          onClick={() =>
                            item.progress_status == "0"
                              ? handleShowBillVerify(item.id)
                              : null
                          }
                        />
                      ),
                      loan_id: item.loan_number,
                      contractor_name: item.contractor_name,
                      contract_number: item.contract_number,
                      transaction_type: item.transaction_type,
                      particular: item.particular,
                      forex_conversion_basis: item.forex_conversion_basis,
                      bill_type: item.bill_type,
                      bill_details: (
                        <View
                          label={"Bill details"}
                          type={"button"}
                          onClick={() =>
                            handleShowBillDetails({
                              id: item,
                              type: item.transaction_type,
                            })
                          }
                        />
                      ),
                      documents: (
                        <View
                          label={"Documents"}
                          type="button"
                          onClick={() => handleShowDocuments(item.id)}
                        />
                      ),
                      created_at: item.created_at.split("T")[0],
                    }))
                  : []
              }
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    )
  );
}

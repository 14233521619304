import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { doGet } from "../../../../Services/Axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import PrimaryButton from "../../../../UI/Button/PrimaryButton";

import LedgerController from "./_ledgerController";
import { intlFormat } from "../../../../Services/Helpers";
const { BILL_PREFIX } = LedgerController;

const Contractretention = ({ selectedProject }) => {
  const ref = useRef();
  const [rowOneShow, setRowOneShow] = useState(true);
  const [rowTwoShow, setRowTwoShow] = useState(true);
  const [rowThreeShow, setRowThreeShow] = useState(true);
  const { nepaliDate } = useSelector((state) => state.System);
  const [Contractretention, setContractretention] = useState();
  const todaysDate = nepaliDate.getTodayDate().BS;
  const fiscalYear =
    todaysDate.month <= 12 && todaysDate.month > 3
      ? `${todaysDate.year}/${todaysDate.year + 1}`
      : `${todaysDate.year - 1}/${todaysDate.year}`;
  const previousFiscalYear =
    todaysDate.month <= 12 && todaysDate.month > 3
      ? `${todaysDate.year - 1}/${todaysDate.year}`
      : `${todaysDate.year - 2}/${todaysDate.year - 1}`;
  console.log(fiscalYear, previousFiscalYear);

  const fetchLedger = async () => {
    try {
      const response = await doGet(
        `/reports/ctr_loan_ledger/${selectedProject.id}/${
          fiscalYear.split("/")[0]
        }%252f${fiscalYear.split("/")[1]}/contract_retention_ledger`
      );
      console.log(response.data);
      setContractretention(response.data.contract_retention_ledger);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLedger();
  }, [fiscalYear, selectedProject && selectedProject.id]);
  const handleRowToggle = (type) => {
    switch (type) {
      case "one":
        setRowOneShow(!rowOneShow);
        break;
      case "two":
        setRowTwoShow(!rowTwoShow);
        break;
      case "three":
        setRowThreeShow(!rowThreeShow);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <DownloadTableExcel
        currentTableRef={ref.current}
        filename={`ledger_${Date.now()}`}
        sheet="Ledger"
      >
        <PrimaryButton
          content={"Export"}
          title="Ledger"
          icon={"fa-light fa-file-spreadsheet"}
          className={"ledger-heading--actions__export"}
        />
      </DownloadTableExcel>
      <table width={100} border={1} className="ledger-wrapper--table" ref={ref}>
        <thead>
          <tr>
            <th className="center" colSpan={8}>
              <h1>नेपाल विद्युत प्राधिकरण</h1>
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={8}>
              अर्थ निर्देशनालय
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={8}>
              संस्थागत वित्तिय विभाग
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={8}>
              वित्तिय योजना तथा लगानी महाशाखा
            </th>
          </tr>
          <tr>
            <th className="center" colSpan={8}>
              Contract Retention Ledger -{" "}
              {selectedProject &&
                `${selectedProject.name} - Office Code (${selectedProject.office_code})`}
              {selectedProject &&
                selectedProject.loan_repayment_effective_date &&
                selectedProject.sub_status === 3 &&
                `(Close Date: ${selectedProject.loan_repayment_effective_date})`}
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>Particulars</th>
            <th rowSpan={2}>Loan no</th>
            <th rowSpan={2}>Contract no</th>

            <th colSpan={2}>Value Date</th>
            <th colSpan={3}>Retention amount</th>
          </tr>
          <tr>
            <th>AD</th>
            <th>BS</th>
            <th>Donor</th>
            <th>USD</th>
            <th>NPR</th>
          </tr>
        </thead>
        {Contractretention && Contractretention.length > 0 ? (
          <tbody>
            {Contractretention.filter(
              (item) => item.particulars === "UP TO THIS YEAR"
            ).map((item, index) => (
              <tr key={index} className="ledger-table--title">
                <th
                  className="cursor"
                  data-cursor={rowOneShow ? "🔽 " : "▶️ "}
                  onClick={() => handleRowToggle("one")}
                >
                  {item.particulars}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{intlFormat((+item.total_retention_donor).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_usd).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_npr).toFixed(2))}</th>
              </tr>
            ))}
            {Contractretention.filter(
              (item) => item.type === "UP TO THIS YEAR"
            ).map((item, index) => {
              const prefix = BILL_PREFIX[item.transaction_type].amt;
              return (
                <tr
                  className={`${rowOneShow ? "rowopen" : "rowclose"}`}
                  key={index}
                >
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>

                  <td>{item.value_date_ad}</td>
                  <td>{item.value_date_bs}</td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_donor`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_usd`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_npr`]).toFixed(2)
                    )}
                  </td>
                </tr>
              );
            })}
            {Contractretention.filter(
              (item) => item.particulars === "PREVIOUS YEAR"
            ).map((item, index) => (
              <tr key={index} className="ledger-table--title">
                <th
                  className="cursor"
                  data-cursor={rowTwoShow ? "🔽 " : "▶️ "}
                  onClick={() => handleRowToggle("two")}
                >
                  {item.particulars}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{intlFormat((+item.total_retention_donor).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_usd).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_npr).toFixed(2))}</th>
              </tr>
            ))}
            {Contractretention.filter(
              (item) => item.type === "PREVIOUS YEAR"
            ).map((item, index) => {
              const prefix = BILL_PREFIX[item.transaction_type].amt;
              return (
                <tr
                  className={`${rowTwoShow ? "rowopen" : "rowclose"}`}
                  key={index}
                >
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>

                  <td>{item.value_date_ad}</td>
                  <td>{item.value_date_bs}</td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_donor`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_usd`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_npr`]).toFixed(2)
                    )}
                  </td>
                </tr>
              );
            })}
            {Contractretention.filter(
              (item) => item.particulars === "CURRENT YEAR"
            ).map((item, index) => (
              <tr key={index} className="ledger-table--title">
                <th
                  className="cursor"
                  data-cursor={rowThreeShow ? "🔽 " : "▶️ "}
                  onClick={() => handleRowToggle("three")}
                >
                  {item.particulars}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{intlFormat((+item.total_retention_donor).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_usd).toFixed(2))}</th>
                <th>{intlFormat((+item.total_retention_npr).toFixed(2))}</th>
              </tr>
            ))}
            {Contractretention.filter(
              (item) => item.type === "CURRENT YEAR"
            ).map((item, index) => {
              const prefix = BILL_PREFIX[item.transaction_type].amt;
              return (
                <tr
                  className={`${rowThreeShow ? "rowopen" : "rowclose"}`}
                  key={index}
                >
                  <td>{item.particulars}</td>
                  <td>{item.loan_no}</td>
                  <td>{item.contract_no}</td>

                  <td>{item.value_date_ad}</td>
                  <td>{item.value_date_bs}</td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_donor`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_usd`]).toFixed(2)
                    )}
                  </td>
                  <td>
                    {intlFormat(
                      (+item[`${prefix}retention_amount_npr`]).toFixed(2)
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={"100%"}>No Data Found</td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default Contractretention;

import React from "react";

const PrimaryButton = ({
  content,
  onClick,
  icon,
  title,
  customStyle,
  isSecondary,
  className,
}) => {
  return (
    <button
      className={`button ${isSecondary ? "button-secondary" : ""} ${
        className ? className : ""
      }`}
      onClick={onClick}
      title={title ? title : content}
      style={customStyle}
    >
      {icon && <i className={icon}></i>}
      {content}
    </button>
  );
};

export default PrimaryButton;

import React, { useState } from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";
import Input from "../../../../UI/Input/Input";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { useQuery } from "react-query";
import CreateSubProjectController from "./_createSubProject";
import { errorToast, successToast } from "../../../../Utils/Toastify";
import { useDispatch, useSelector } from "react-redux";
import SmallButton from "../../../../UI/Button/SmallButton";
import { toggleLoader } from "../../../../Slice/SystemSlice";
import useForex from "../../../../Hooks/useForex";

const { fetchProjectLoans, submitForm } = CreateSubProjectController;

export default function CreateSubProject({ onClose, project_id, refetch }) {
  const { getConversionRate } = useForex();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.System);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [selectedLoans, setSelectedLoans] = useState({});
  const [subProjectName, setSubProjectName] = useState({
    value: "",
    error: "",
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ["OneTimeProject", project_id],
    queryFn: () => fetchProjectLoans(project_id),
    enabled: true,
    cacheTime: 1000 * 60 * 15,
    onError: () => errorToast("Error fetching loans"),
  });

  const handleLoanSelection = (id) => {
    selectedLoans[id]
      ? setSelectedLoans((prev) => (delete prev[id], { ...prev }))
      : setSelectedLoans((prev) => ({ ...prev, [id]: true }));
  };
  const handleSubProjectName = (e) => {
    const value = e.target.value;
    const error = value === "" || !value ? "Field is required" : "";
    setSubProjectName((_) => ({
      value,
      error,
    }));
  };

  const handleSubmit = async () => {
    dispatch(toggleLoader(true));
    const name = subProjectName.value;
    const id = project_id;
    const loans = Object.keys(selectedLoans);
    const type = data.project[0] && data.project[0].type;

    if (!type) return errorToast("Invalid project");

    try {
      await submitForm(id, loans, name, type);
      successToast("Sub Project Created");
      refetch();
      onClose(false);
    } catch (error) {
      errorToast(error.message);
    } finally {
      dispatch(toggleLoader(false));
    }
  };

  //!Confirmation
  const handleConfirmationModal = (action) => {
    setConfirmationModal(true);
    switch (action) {
      case "BACK":
        setConfirmationDetails({
          text: "Are you sure you want to cancel?",
          caption: "All data will be reset",
          action: () => onClose(false),
        });
        break;
      case "SAVE":
        if (!subProjectName.value)
          return errorToast("Sub-Project name must not be empty");

        if (Object.keys(selectedLoans).length < 1) {
          setConfirmationModal(false);
          errorToast("Please select one or more loans");
          return;
        }
        setConfirmationDetails({
          text: "Are you sure you want to save?",
          caption: "Please verify all details before clicking yes",
          action: () => handleSubmit(),
        });
        break;
      default:
        break;
    }
  };
  return (
    <ModalLayout
      onClose={() => handleConfirmationModal("BACK")}
      title="Create Sub-Project"
      isLanguageChangeable={true}
    >
      {data && data.loan && data.loan.length > 0 ? (
        <>
          {confirmationModal && confirmationDetails && (
            <ConfirmationModal
              onSuccess={() => (
                confirmationDetails.action(), setConfirmationModal(false)
              )}
              onClose={() => setConfirmationModal(false)}
              text={confirmationDetails.text}
              caption={confirmationDetails.caption}
            />
          )}
          <div className="subprojectcreate">
            <div className="subprojectcreate-actions">
              <SmallButton
                title={"Create Sub-Project"}
                content={"Create"}
                icon={"fa-light fa-plus"}
                onClick={() => handleConfirmationModal("SAVE")}
              />
            </div>
            <Input
              label={"Sub-Project Name"}
              required
              name={"subprojectname"}
              placeholder="eg. प्रसारण तथा वितरण सूपरीवेक्षण परामर्श आयोजना"
              type={"text"}
              error={subProjectName.error}
              defaultValue={subProjectName.value}
              onChange={handleSubProjectName}
              isLanguageChangeable={true}
              language={language}
            />
            <h3 className="subprojectcreate-heading">
              Select Loans: {Object.keys(selectedLoans).length}/
              {data.loan.length}
            </h3>
            <div className="subprojectcreate-loans">
              {data.loan.map((i, _) => (
                <div
                  className={`subprojectcreate-loans--item ${
                    selectedLoans[i.id]
                      ? "subprojectcreate-loans--item_selected"
                      : ""
                  }`}
                  onClick={() => handleLoanSelection(i.id)}
                  key={i.id}
                >
                  <p className="subprojectcreate-loans--item_date">
                    {i.donor_agreement_date}
                  </p>
                  <p className="subprojectcreate-loans--item_agency">
                    {i.donor_agency} | {i.loan_number}
                  </p>
                  <p className="subprojectcreate-loans--item_currency">
                    {i.donor_currency} {i.loan_amount}
                  </p>
                  <p className="subprojectcreate-loans--item_currency">
                    USD {i.loan_amount_usd}
                  </p>
                  <p className="subprojectcreate-loans--item_currency">
                    NPR {i.loan_amount_npr}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <p className="notfound notfound-bordered">
          No Loans Found (Atlease one or more should be present on Project)
        </p>
      )}
    </ModalLayout>
  );
}

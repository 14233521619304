import React from "react";
import { useSelector } from "react-redux";

const LoadingModal = () => {
  const { isLoading } = useSelector((state) => state.System);
  console.log(isLoading);
  return (
    isLoading && (
      <div className="loadingmodal">
        <div className="loadingmodal-loader"></div>
      </div>
    )
  );
};

export default LoadingModal;

import React from "react";
import ModalLayout from "../../UI/Modal/ModalLayout";

const BillDetails = ({ onClose, item, type }) => {
  const TITLE =
    type === "ADVANCE/RETENTION"
      ? [
          {
            key: "advance_retention_bill_number",
            value: "Advance Retention Bill Number",
          },
          { key: "advance_release_date_ad", value: "Advance Release Date AD" },
          { key: "advance_release_date", value: "Advance Release Date" },
          { key: "advance_disbursed_npr", value: "Advance Disbursed NPR" },
          { key: "advance_disbursed", value: "Advance Disbursed" },
          { key: "advance_disbursed_usd", value: "Advance Disbursed USD" },

          {
            key: "disbursed_date_ad",
            value: "Advance Disbursed Date AD",
          },
          { key: "disbursed_date", value: "Advance Disbursed Date" },
        ]
      : type === "COMMERCIAL INVOICE (LC)"
      ? //If Commercial Bill
        [
          { key: "commercial_bill_date_ad", value: "Commercial Bill Date AD" },
          { key: "commercial_bill_date", value: "Commercial Bill Date" },
          {
            key: "disbursed_date_ad",
            value: "Commercial Disbursed Date AD",
          },
          {
            key: "disbursed_date",
            value: "Commercial Disbursed Date",
          },
          { key: "invoice_number", value: "invoice Number" },
          { key: "invoice_bill_amount", value: "invoice Bill Amount" },
          { key: "invoice_bill_amount_npr", value: "invoice Bill Amount NPR" },
          { key: "invoice_bill_amount_usd", value: "invoice Bill Amount USD" },
          {
            key: "invoice_bill_amount_inclusive_vat",
            value: "invoice Bill Amount Inclusive Vat",
          },
          {
            key: "invoice_bill_amount_inclusive_vat_npr",
            value: "invoice Bill Amount Inclusive Vat NPR",
          },
          {
            key: "invoice_bill_amount_inclusive_vat_usd",
            value: "invoice Bill Amount Inclusive Vat USD",
          },
          {
            key: "commercial_advance_amount",
            value: "Commercial Advance Amount",
          },
          {
            key: "commercial_advance_amount_npr",
            value: "Commercial Advance Amount NPR",
          },
          {
            key: "commercial_advance_amount_usd",
            value: "Commercial Advance Amount USD",
          },
          {
            key: "commercial_vat_percentage",
            value: "Commercial Vat Percentage",
          },
          {
            key: "commercial_contract_tax_percentage",
            value: "Commercial Contract Tax Percentage",
          },
          {
            key: "commercial_retention_percentage",
            value: "Commercial Retention Percentage",
          },
          {
            key: "commercial_liqudate_damage_percentage",
            value: "Commercial Liqudate Pamage Percentage",
          },
          {
            key: "commercial_other_deduction",
            value: "Commercial Other Deduction",
          },
          {
            key: "commercial_other_deduction_usd",
            value: "Commercial Other Deduction USD",
          },
          {
            key: "commercial_other_deduction_npr",
            value: "Commercial Other Deduction NPR",
          },
          {
            key: "commercial_total_deduction",
            value: "Commercial Total Deduction",
          },
          {
            key: "commercial_total_deduction_npr",
            value: "Commercial Total Deduction NPR",
          },
          {
            key: "commercial_total_deduction_usd",
            value: "Commercial Total Deduction USD",
          },
          {
            key: "commercial_net_payable_amount",
            value: "Commercial Net Payable Amount",
          },
          {
            key: "commercial_net_payable_amount_npr",
            value: "Commercial Net Payable Amount NPR",
          },
          {
            key: "commercial_net_payable_amount_usd",
            value: "Commercial Net Payable Amount USD",
          },
        ]
      : [
          { key: "bill_date_ad", value: "Bill Date AD" },
          { key: "bill_date", value: "Bill Date" },
          { key: "disbursed_date_ad", value: "Disbursed Date AD" },
          { key: "disbursed_date", value: "Disbursed Date" },
          { key: "bill_number", value: "Bill Number" },
          { key: "bill_amount_npr", value: "Bill Amount NPR" },
          {
            key: "bill_amount_inclusive_vat_npr",
            value: "Bill Amount Inclusive Vat NPR",
          },
          { key: "bill_amount", value: "Bill Amount" },
          { key: "bill_amount_usd", value: "Bill Amount USD" },
          {
            key: "prior_collection_of_vat_percentage",
            value: "Prior Collection of VAT Percentage",
          },
          { key: "vat_percentage", value: "Vat Percentage" },
          {
            key: "bill_amount_inclusive_vat",
            value: "Bill Amount Inclusive Vat",
          },
          {
            key: "bill_amount_inclusive_vat_usd",
            value: "Bill Amount Inclusive Vat USD",
          },
          { key: "advance_amount_npr", value: "Advance Amount NPR" },
          { key: "advance_amount", value: "Advance Amount" },
          { key: "advance_amount_usd", value: "Advance Amount USD" },
          { key: "tds_percentage", value: "TDS Percentage" },
          { key: "retention_percentage", value: "Retention Percentage" },
          {
            key: "liqudate_damage_percentage",
            value: "Liqudate Damage Percentage",
          },
          { key: "other_deduction", value: "Other Deduction" },
          { key: "other_deduction_npr", value: "Other Deduction NPR" },
          { key: "other_deduction_usd", value: "Other Deduction USD" },
          { key: "total_deduction", value: "Total Deduction" },
          { key: "total_deduction_npr", value: "Total Deduction NPR" },
          { key: "total_deduction_usd", value: "Total Deduction USD" },
          { key: "net_payable_amount", value: "Net payable Amount" },
          { key: "net_payable_amount_npr", value: "Net payable Amount NPR" },
          { key: "net_payable_amount_usd", value: "Net payable Amount USD" },
        ];
  console.log(item);
  return (
    <ModalLayout onClose={onClose} title="Bill Details">
      <div className="billdetails-lcdetails">
        <div className="billdetails-lcdetails--grid">
          {item.id.donor_currency === "SDR" ? (
            <div
              key="sdr_conversion_rate"
              className="billdetails-lcdetails--grid__item"
            >
              <div className="billdetails-lcdetails--grid__item-key">
                SDR Conversion Rate :
              </div>
              <div className="billdetails-lcdetails--grid__item-value">
                {item.id.sdr_conversion_rate ? item.id.sdr_conversion_rate : 0}
              </div>
            </div>
          ) : null}
          {TITLE.map((el) => (
            <div key={el.key} className="billdetails-lcdetails--grid__item">
              <div className="billdetails-lcdetails--grid__item-key">
                {el.value} :
              </div>
              <div className="billdetails-lcdetails--grid__item-value">
                {item.id[el.key] && el.key.toLowerCase().includes("percentage")
                  ? item.id[el.key] + " %"
                  : item.id[el.key]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalLayout>
  );
};

export default BillDetails;

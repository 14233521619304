import React from "react";
import ModalLayout from "../../../../UI/Modal/ModalLayout";

const ViewPreInformation = ({ onClose, subproject }) => {
  const TITLE = [
    { key: "Directorate/Office Name", value: "directorate_office_name" },
    { key: "Directorate/Office Code", value: "directorate_office_code" },
    { key: "Office Name", value: "office_name" },
    { key: "Office Code", value: "office_code" },
    { key: "SLA Agreement Date", value: "sla_agreement_date" },
    { key: "SLA Agreement Date AD", value: "sla_agreement_date_ad" },
    { key: "Donor Currency", value: "donor_currency" },
    { key: "SLA Agreement Amount", value: "sla_agreement_amount" },
    { key: "USD Equivalent", value: "usd_equivalent" },
    { key: "NPR Equivalent", value: "npr_equivalent" },
    { key: "Remarks", value: "remarks" },
  ];

  return (
    subproject && (
      <ModalLayout onClose={onClose} title={"Pre Information Verification"}>
        <div className="viewpmsubprojects">
          <div className="viewpmsubprojects-fulldetails--grid">
            {TITLE.map((item, index) => (
              <div
                className="viewpmsubprojects-fulldetails--grid__item"
                key={index}
              >
                <div className="viewpmsubprojects-fulldetails--grid__item-key">
                  {item.key} -
                </div>
                <div className="viewpmsubprojects-fulldetails--grid__item-value">
                  {subproject[item.value] ? subproject[item.value] : "N/A"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalLayout>
    )
  );
};

export default ViewPreInformation;

import { doGet } from "../../../../Services/Axios";

const BillingController = {
  Columns: [
    { name: "sn", sortable: true, searchable: true },
    { name: "status", sortable: false, searchable: false },
    { name: "loan_number", sortable: true, searchable: true },
    { name: "contractor_name", sortable: true, searchable: true },
    { name: "contract_number", sortable: true, searchable: true },
    { name: "transaction_type", sortable: true, searchable: false },
    { name: "particular", sortable: true, searchable: true },
    { name: "forex_conversion_basis", sortable: true, searchable: false },
    { name: "bill_type", sortable: true, searchable: false },
    { name: "Bill details", sortable: true, searchable: false },
    { name: "documents", sortable: true, searchable: true },
    { name: "created_at", sortable: false, searchable: false },
  ],
  fetchSubProjectBill: async (id) => {
    try {
      const { data } = await doGet(`/bills-infos/${id}/shows`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  statusEnums: {
    0: { label: "Verify Now", color: "#006699" },
    1: { label: "Approved", color: "#1fb626" },
    2: { label: "Rejected", color: "#c71c1c" },
  },
};

export default BillingController;
